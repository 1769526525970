import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, IconButton, Box } from '@mui/material';
import {
  BasicWrapper,
  MainWrapper,
  FilterBox,
  FilterContainer,
  TextWrapper,
  Info,
  cancelButtonStyle,
  applyButtonStyle,
  newThemeCancelButtonStyle,
  newThemeApplyButtonStyle,
} from './DateRangePicker.style';
import { dateFilterList, PAGE_CONTENT } from './DateRangePicker.content';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { findPastMonthsDate } from 'utils/dateFormatter';
import CalendarElement from './CustomDatePicker/CalenderElement.react';
import { newColorThemeContext } from 'contextAPI/newColorThemeContext';
import { checkTernaryCondition } from 'utils/helper';
import { closeIconSx, modalActionButtonDefaultStyle } from 'theme/GlobalStyles';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';

const pastThreeMonth = findPastMonthsDate(3);
const pastTwoMonth = findPastMonthsDate(2);
const pastOneMonth = findPastMonthsDate(1);

const DateRangePicker = ({
  isOpen,
  setIsOpen,
  starDateSetter,
  endDateSetter,
}) => {
  const [dateFilter, setDateFilter] = useState(0);
  const [fromDate, setFromDate] = useState(
    pastOneMonth.toISOString().split('T')[0],
  );
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
  const [error, setError] = useState(false);
  const { newColorTheme } = useContext(newColorThemeContext);

  useEffect(() => {
    if (new Date(fromDate) > new Date(toDate)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [fromDate, toDate]);

  const formatDate = (dateToFormat) => {
    return `${dateToFormat.slice(5, 7)}/${dateToFormat.slice(
      8,
    )}/${dateToFormat.slice(0, 4)}`;
  };

  const dateFilterSetter = (filterRange) => {
    starDateSetter(formatDate(filterRange.toISOString().split('T')[0]));
    endDateSetter(formatDate(new Date().toISOString().split('T')[0]));
    setFromDate(filterRange.toISOString().split('T')[0]);
    setToDate(new Date().toISOString().split('T')[0]);
    setIsOpen(false);
  };

  const applyDateFilter = () => {
    if (new Date(fromDate) > new Date(toDate)) {
      setError(true);
    } else {
      starDateSetter(
        `${fromDate.slice(5, 7)}/${fromDate.slice(8)}/${fromDate.slice(0, 4)}`,
      );
      endDateSetter(
        `${toDate.slice(5, 7)}/${toDate.slice(8)}/${toDate.slice(0, 4)}`,
      );
      setIsOpen(false);
      setError(false);
      setDateFilter(0);
    }
  };
  useEffect(() => {
    switch (dateFilter) {
      case 1:
        dateFilterSetter(pastOneMonth);
        break;
      case 2:
        dateFilterSetter(pastTwoMonth);
        break;
      case 3:
        dateFilterSetter(pastThreeMonth);
        break;
      default:
    }
  }, [dateFilter]);

  return (
    <Dialog
      className="dialog-box"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="md">
      <Box display="flex" justifyContent="flex-end">
        <Box alignItems="right" justifyContent={'flex-end'}>
          <IconButton onClick={() => setIsOpen(false)} m={0} p={0}>
            <CloseIcon sx={closeIconSx} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent className="dialog-content">
        <MainWrapper>
          <BasicWrapper
            padding={'0.5rem'}
            borderRight={'1px solid #D0D0CE'}
            direction="column">
            <TextWrapper
              fontSize="0.9rem"
              fontWeight="600"
              padding="0.4rem 0.5rem">
              {PAGE_CONTENT.quick_filters}
            </TextWrapper>
            <FilterContainer>
              {dateFilterList.map((item) => (
                <FilterBox
                  key={item.value}
                  onClick={() => {
                    setDateFilter(item.key);
                  }}>
                  {item.value}
                </FilterBox>
              ))}
            </FilterContainer>
          </BasicWrapper>
          <BasicWrapper direction={PAGE_CONTENT.column}>
            <BasicWrapper padding={'0.5rem'} content={'space-between'}>
              <CalendarElement
                date={fromDate}
                text={'From'}
                border="1px solid #D0D0CE"
                otherDate={toDate}
                isFromDate={true}
                newDate={dayjs('2023-01-20')}
                setFromDate={setFromDate}
                setToDate={setToDate}
                fromDate={fromDate}
                toDate={toDate}
              />
              <CalendarElement
                date={toDate}
                text={'To'}
                otherDate={fromDate}
                isFromDate={false}
                newDate={dayjs('2023-01-31')}
                setFromDate={setFromDate}
                setToDate={setToDate}
                fromDate={fromDate}
                toDate={toDate}
              />
            </BasicWrapper>
            {error && (
              <Info>
                <InfoOutlinedIcon
                  sx={{ fontSize: '1.2rem' }}
                  color={PAGE_CONTENT.error}
                />
                <TextWrapper fontWeight="400" color="#d32f2f" fontSize="0.9rem">
                  {PAGE_CONTENT.date_error}
                </TextWrapper>
              </Info>
            )}
            {checkTernaryCondition(
              newColorTheme,
              <BasicWrapper paddingLeft="0.5rem" gap="1rem">
                <Button
                  onClick={() => applyDateFilter()}
                  variant={PAGE_CONTENT.contained}
                  sx={[
                    newThemeApplyButtonStyle,
                    primaryButtonStyle(),
                    modalActionButtonDefaultStyle,
                  ]}
                  disabled={error}>
                  {PAGE_CONTENT.apply}
                </Button>
                <Button
                  onClick={() => setIsOpen(false)}
                  color="primary"
                  sx={[
                    newThemeCancelButtonStyle,
                    tertiaryButtonStyle,
                    modalActionButtonDefaultStyle,
                  ]}>
                  {PAGE_CONTENT.cancel}
                </Button>
              </BasicWrapper>,
              <BasicWrapper paddingLeft="30rem" gap="1rem">
                <Button
                  onClick={() => setIsOpen(false)}
                  color="primary"
                  sx={cancelButtonStyle}>
                  {PAGE_CONTENT.cancel}
                </Button>
                <Button
                  onClick={() => applyDateFilter()}
                  variant={PAGE_CONTENT.contained}
                  sx={applyButtonStyle}
                  disabled={error}>
                  {PAGE_CONTENT.apply}
                </Button>
              </BasicWrapper>,
            )}
          </BasicWrapper>
        </MainWrapper>
      </DialogContent>
    </Dialog>
  );
};

DateRangePicker.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  starDateSetter: PropTypes.func,
  endDateSetter: PropTypes.func,
};

export default DateRangePicker;
