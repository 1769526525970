import React, { useEffect } from 'react';
import { CONTENT, HEADINGS } from './TermsOfUse.content';
import {
  ContentDiv,
  MainHeadingDiv,
  MainWrapper,
  PageHeadingDiv,
  SectionWrapper,
} from './TermsOfUse.style';

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainWrapper>
        <SectionWrapper>
          <MainHeadingDiv>{HEADINGS.main_heading_terms}</MainHeadingDiv>
          <ContentDiv>{CONTENT.paragraph1_terms}</ContentDiv>
          <PageHeadingDiv>{HEADINGS.heading1_terms}</PageHeadingDiv>
          <ContentDiv>{CONTENT.paragraph2_terms}</ContentDiv>
          <ContentDiv>{CONTENT.paragraph3_terms}</ContentDiv>
          <ContentDiv>{CONTENT.paragraph4_terms}</ContentDiv>
          <PageHeadingDiv>{HEADINGS.heading2_terms}</PageHeadingDiv>
          <ContentDiv>{CONTENT.paragraph5}</ContentDiv>
          <ContentDiv>{CONTENT.paragraph6}</ContentDiv>
          <ContentDiv>{CONTENT.paragraph7}</ContentDiv>
          <ContentDiv>{CONTENT.paragraph8}</ContentDiv>
          <PageHeadingDiv>{HEADINGS.heading3}</PageHeadingDiv>
          <ContentDiv>{CONTENT.paragraph9}</ContentDiv>
          <div>{CONTENT.paragraph10}</div>
        </SectionWrapper>
      </MainWrapper>
    </div>
  );
};

export default TermsOfUse;
