import styled from 'styled-components';
import { NavLink as MuiNavLink } from 'react-router-dom';
import {
  LIGHT_GRAY_SELECTED,
  QUILL_GRAY,
  ROW_HOVER_BG,
  TEXT_HOVER_COLOR,
  BLUISH_CYAN,
  DARK_BLACK,
} from 'theme/GlobalColors';
import { Container } from '@mui/material';
import { checkTernaryCondition } from 'utils/helper';

export const NavModuleWrapper = styled(Container)(({ hasNoBorder }) => ({
  borderTop: checkTernaryCondition(
    hasNoBorder,
    'none',
    `1px solid ${QUILL_GRAY}`,
  ),
  fontFamily: 'Open Sans, sans-serif',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '19.07px',
  padding: '0rem',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const NavTitle = styled.div`
  padding-left: 1rem;
  font-size: 14px;
  font-weight: 700;
  color: ${DARK_BLACK};
  margin-bottom: 5px;
`;

export const NavLinksWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const NavLink = styled(MuiNavLink)(
  ({ showHighlight, isReport, isProject, isPortfolio }) => ({
    padding: isProject
      ? checkTernaryCondition(
          showHighlight,
          '0.375rem 2.125rem 0.375rem 2.65rem',
          '0.375rem 2.125rem 0.375rem 3rem',
        )
      : checkTernaryCondition(
          isReport || isPortfolio,
          checkTernaryCondition(showHighlight, '0.9rem 0.7rem', '0.9rem 1rem'),
          checkTernaryCondition(
            showHighlight,
            '0.375rem 1.1rem',
            '0.375rem 1.5rem',
          ),
        ),
    fontSize: checkTernaryCondition(isProject, '0.75rem', '0.813rem'),
    fontWeight: checkTernaryCondition(
      showHighlight || isReport || isPortfolio,
      700,
      400,
    ),
    height: checkTernaryCondition(isReport || isPortfolio, '40px', '30px'),
    textDecoration: 'none',
    color: DARK_BLACK,
    display: 'flex',
    justifyContent: 'baseline',
    boxSizing: 'border-box',
    alignItems: 'center',
    backgroundColor: checkTernaryCondition(
      showHighlight,
      LIGHT_GRAY_SELECTED,
      '',
    ),
    borderLeft: checkTernaryCondition(
      showHighlight,
      `5px solid ${BLUISH_CYAN}`,
      '',
    ),

    '&:hover': {
      backgroundColor: ROW_HOVER_BG,
      color: TEXT_HOVER_COLOR,
    },
  }),
);
