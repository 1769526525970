import { ProjectScheduleWrapper } from '../ProjectSchedule/ProjectSchedule.style';
import { PROJECT_DESIGN_SETTING_TAB_CONTENT } from './ProjectDesignSettingTab.content';
import {
  CustomMethodName,
  DropdownSx,
  InputInfoIcon,
  InputLableWrapper,
  LabelAndInfoStyle,
  LableWrapper,
  ProjectDesignHeader,
  SwitchStyle,
  SwitchWrapper,
} from './ProjectDesignSettingTab.style';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/infoIcon.svg';
import { TextWrapper } from 'components/FormComponents/SwitchComponent/SwitchComponent.style';
import { Switch } from '@mui/material';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { InputSx } from 'components/FormComponents/FormStyles';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { dropdownProps } from 'pages/AddOriginationProject/AddOriginationProject.style';
import { useProjectDesignSettingTab } from './useProjectDesignSettingTab';
import { checkTernaryCondition } from 'utils/helper';
import { LoaderProvider } from 'contextAPI/loaderContext';

const ProjectDesignSetting = () => {
  const { customMethodology, customMethodologyName, modelingProvider } =
    useProjectDesignSettingTab();
  return (
    <ProjectScheduleWrapper>
      <ProjectDesignHeader>
        {PROJECT_DESIGN_SETTING_TAB_CONTENT.methodology}
      </ProjectDesignHeader>
      <InputLableWrapper>
        <LabelAndInfoStyle gap={STYLE_CONSTANTS.one_rem}>
          <LableWrapper width="9.375rem">
            {PROJECT_DESIGN_SETTING_TAB_CONTENT.custom_methodology}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
        <SwitchWrapper marginLeft="6rem">
          <Switch
            data-testId={PROJECT_DESIGN_SETTING_TAB_CONTENT.custom_methodology}
            sx={SwitchStyle}
            checked={customMethodology}
            onChange={() => {}}
            inputProps={{ 'aria-label': 'controlled' }}
            name={PROJECT_DESIGN_SETTING_TAB_CONTENT.custom_methodology}
          />
          <TextWrapper fontSize={'0.75rem'}>
            {checkTernaryCondition(customMethodology, 'On', 'Off')}
          </TextWrapper>
        </SwitchWrapper>
      </InputLableWrapper>

      {customMethodology && (
        <InputLableWrapper>
          <LabelAndInfoStyle gap={STYLE_CONSTANTS.zero_rem}>
            <LableWrapper width="13rem">
              {PROJECT_DESIGN_SETTING_TAB_CONTENT.custom_methodology_name}
            </LableWrapper>
            <InputInfoIcon>
              <InfoIcon />
            </InputInfoIcon>
          </LabelAndInfoStyle>
          <InputField
            value={customMethodologyName}
            width={STYLE_CONSTANTS.thirteen_point_seven_five_rem}
            customStyles={{ marginLeft: '3.3125rem' }}
            rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
            customInputSx={{ ...InputSx, ...CustomMethodName }}
            labelMarginTop={STYLE_CONSTANTS.zero_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            isDisabled
          />
        </InputLableWrapper>
      )}

      <InputLableWrapper>
        <LabelAndInfoStyle gap={STYLE_CONSTANTS.one_rem}>
          <LableWrapper width="10.6875rem">
            {PROJECT_DESIGN_SETTING_TAB_CONTENT.reference_methodology}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
        <DropdownSx marginLeft="4.625rem">
          <DropDown
            dropdownlist={['VM0042']}
            isDisabled
            value={'VM0042'}
            height={STYLE_CONSTANTS.two_rem}
            width={STYLE_CONSTANTS.thirteen_point_seven_five_rem}
            menuProps={dropdownProps}
            hasNoBottomMargin={true}
            customSx={outlinedDropdownInputSx}
            labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          />
        </DropdownSx>
      </InputLableWrapper>

      <ProjectDesignHeader>
        {PROJECT_DESIGN_SETTING_TAB_CONTENT.measurement_and_monitoring}
      </ProjectDesignHeader>
      <InputLableWrapper>
        <LableWrapper width={STYLE_CONSTANTS.thirteen_point_seven_five_rem}>
          {PROJECT_DESIGN_SETTING_TAB_CONTENT.model_provider}
        </LableWrapper>
        <DropdownSx marginLeft={STYLE_CONSTANTS.one_rem}>
          <DropDown
            dropdownlist={[modelingProvider]}
            isDisabled
            value={modelingProvider}
            setStateValue={() => null}
            width={STYLE_CONSTANTS.thirteen_point_seven_five_rem}
            height={STYLE_CONSTANTS.two_rem}
            menuProps={dropdownProps}
            hasNoBottomMargin={true}
            customSx={outlinedDropdownInputSx}
            labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          />
        </DropdownSx>
      </InputLableWrapper>
    </ProjectScheduleWrapper>
  );
};

export const ProjectDesignSettingTab = () => (
  <LoaderProvider>
    <ProjectDesignSetting />
  </LoaderProvider>
);
