import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  GRAYISH_BLUE,
  NEUTRAL_BACKGROUND_COLOR,
  NEW_DARK_GREEN,
  NEW_HOVER_DARK_GREEN,
  QUILL_GRAY,
  RED,
  TEAL_GREEN_FOCUS,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { checkboxClasses } from '@mui/material';

export const MainWrapper = styled.div`
  width: 552px;
`;
export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '36.5rem',
      maxWidth: '36.5rem',
      padding: '1.5rem 1.5rem 1rem 1.5rem',
      maxHeight: '39.3125rem',
      overflowY: 'auto',
    },
  },
};
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.75rem;
`;
export const FarmProjectTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 1.25rem;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  color: ${(props) =>
    checkTernaryCondition(props.color, props.color, DARK_CHARCOAL)};
`;
export const FarmsTitleStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const FarmTitleWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.4375rem;
  font-size: 0.875rem;
  line-height: 1.1919rem;
`;
export const ProjectTitleWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.1919rem;
  margin-left: 2rem;
`;
export const DialogTitleStyle = {
  fontSize: '20px',
  padding: '0rem',
  color: DARK_CHARCOAL,
  fontWeight: 400,
};
export const IconButtonStyle = { padding: 0 };
export const closeIconSx = {
  color: COOL_GRAY,
  cursor: 'pointer',
};
export const InputWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const DialogContentStyle = {
  marginTop: '1rem',
  padding: '0rem',
  overflowX: 'hidden',
};
export const DialogActionStyle = {
  justifyContent: 'flex-end',
  padding: '1.5rem 0rem 0rem 0rem',
  marginBottom: '0.5rem',
  gap: '1rem',
};
export const tertiaryButtonStyle = {
  textTransform: 'none',
  color: NEW_DARK_GREEN,
  '&:hover': {
    color: NEW_HOVER_DARK_GREEN,
  },
  '&:focus': {
    color: TEAL_GREEN_FOCUS,
  },
};
export const ButtonStyle = {
  textTransform: 'none',
  color: 'white',
  background: DARK_GREEN,
  '&:hover': {
    background: DARK_GREEN,
    color: 'white',
  },
  padding: '0.625rem 1rem',
  height: '2.25rem',
};

export const ButtonStyleForEditParticipantModal = {
  textTransform: 'none',
  color: 'white',
  background: DARK_GREEN,
  '&:hover': {
    background: DARK_GREEN,
    color: 'white',
  },
  padding: '0.625rem 1rem',
  height: '2.25rem',
  width: '4.75rem',
};

export const checkboxStyleForInput = {
  width: '1rem',
  height: '1rem',
};
export const CheckBoxStyle = {
  color: GRAYISH_BLUE,
  backgroundColor: WHITE,
  '&:hover': {
    color: DARK_CHARCOAL,
    backgroundColor: NEUTRAL_BACKGROUND_COLOR,
  },
  [`&.${checkboxClasses.checked}`]: {
    color: NEW_DARK_GREEN,
  },
};
export const FarmProjectError = styled.div`
  display: flex;
  justify-content: space-between;
  width: 34.625rem;
  height: 0.9375rem;
  margin-top: 0.25rem;
  font-size: 0.6875rem;
  line-height: 0.9363rem;
  color: ${(props) =>
    props.iserror
      ? RED
      : checkTernaryCondition(props.color, props.color, DARK_CHARCOAL)};
`;
export const autoCompleteSx = (isInput) => {
  return {
    '& .MuiInput-root': {
      paddingBottom: isInput && '0.188rem',
      marginTop: '0.25rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& input': {
        fontSize: '0.875rem',
      },
    },
    '& .MuiInputBase-root': {
      '& #checkboxes-tags-demo, #tags-filled': {
        fontSize: '0.875rem',
        color: COOL_GRAY,
        padding: checkTernaryCondition(
          isInput,
          '0rem 0rem 0rem 0.25rem',
          '0.25rem 0rem 0.25rem 0.5rem',
        ),
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.688rem',
      margin: 0,
    },
  };
};

export const FormSection = styled.div`
  max-height: 39rem;
  box-sizing: border-box;
  height: fit-content;
  padding: 1.5rem;
  overflow: auto;
  background-color: ${WHITE};
  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
`;

export const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-top: 0.15rem;
  margin-left: -0.5rem;
`;

export const SectionLabelWrapper = styled.div`
  align-self: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const deleteIconSx = {
  color: COOL_GRAY,
  ':hover': {
    color: DARK_GREEN,
  },
  alignSelf: 'center',
};

export const InviteSentHelpText = styled.div`
  font-family: Open Sans;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: ${COOL_GRAY};
  margin-top: 0.25rem;
`;

export const errorToastMessage = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const NavigationBtnWrapper = styled.div`
  margin-top: 1.5rem;
`;
