import { styled } from '@mui/material/styles';
import { Container, Box, Avatar, Tooltip, tooltipClasses } from '@mui/material';
import {
  BLACK,
  DARK_CHARCOAL,
  DARK_GRAY,
  DARK_GREEN,
  DIM_GRAY,
  QUILL_GRAY,
  WHITE,
} from 'theme/GlobalColors';

export const useStyles = () => ({
  HeaderContainer: styled(Container)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3rem',
  })),
  LogoBox: styled(Box)(() => ({
    display: 'flex',
    flex: '2',
    '.MuiToolbar-dense': {
      marginRight: '4rem',
    },
  })),
  AvatarIcon: styled(Avatar)(() => ({
    backgroundColor: DARK_GRAY,
    width: '1.875rem',
    height: '1.875rem',
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: DARK_CHARCOAL,
  })),
  UsernameDiv: styled('div')(() => ({
    fontSize: '0.9rem',
  })),
  UsernameTooltip: styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: DIM_GRAY,
      color: WHITE,
      border: '1px solid #333333',
      marginTop: '0',
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #333333',
      },
      marginTop: '0',
      color: DIM_GRAY,
    },
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
      bottom: '5px',
    },
  })),
});
export const BoxStyle = {
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  height: '2rem',
  padding: '0.5rem 0',
};
export const logoStyle = {
  padding: '0.3rem 0',
  maxHeight: '2rem',
  width: '15.18rem',
};
export const logoWrapper = {
  display: 'flex',
  gap: '1rem',
  minHeight: '2rem',
  padding: '0.5rem 0rem',
};
export const verticalLineSx = {
  height: '1rem',
  width: '1px',
  backgroundColor: QUILL_GRAY,
};
export const logoImgStyle = { maxHeight: '2rem' };
export const settingsIconStyles = {
  cursor: 'pointer',
  position: 'relative',
  color: BLACK,
  '&:hover': { color: DARK_GREEN },
};
export const HeaderIconBtnSx = { padding: '0rem' };
