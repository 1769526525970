import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DateItemWrapper,
  DeleteIconStyle,
  IconWrapper,
} from './NitrogentFertilizerModal.style';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { checkTernaryCondition } from 'utils/helper';
import { formFieldNames } from './NitrogenFertilizerModal.content';

const DateItemComp = ({
  keyVal,
  dateAppliedPickerProps,
  datesAppliedArray,
  setDateAppliedArray,
  item,
  index,
  customFieldChangeHandler,
  dateRequiedList,
  setDateRequiedList,
  isInTesting,
  marginBottomForDatePicker,
  setDuplicateDate,
  duplicatedDate,
  showDeleteIcon = true,
}) => {
  const [isDeleteIconVisible, setIsDeleteIconVisible] = useState(false);
  const positionOfIndex = dateRequiedList.indexOf(index);
  return (
    <DateItemWrapper
      key={keyVal}
      onMouseEnter={() => {
        setIsDeleteIconVisible(true);
      }}
      onMouseLeave={() => {
        setIsDeleteIconVisible(false);
      }}>
      <CustomDatePicker
        {...dateAppliedPickerProps(keyVal)}
        hasNoLabel={keyVal > 0}
        marginBottom={marginBottomForDatePicker}
      />
      <IconWrapper>
        {datesAppliedArray.length > 1 && showDeleteIcon && (
          <DeleteIcon
            style={DeleteIconStyle(index, isDeleteIconVisible, isInTesting)}
            data-testid="delete-icon"
            onClick={() => {
              if (datesAppliedArray.length > 1 && item === null) {
                setDateAppliedArray(
                  datesAppliedArray.filter((_, i) => i !== index),
                );
              } else {
                setDateAppliedArray(
                  datesAppliedArray.filter((dateVal) => dateVal !== item),
                );
              }
              setDateRequiedList(
                dateRequiedList
                  .filter((dateVal) => dateVal !== index)
                  .map((dateVal, elementIndex) =>
                    dateVal === 0
                      ? dateVal
                      : checkTernaryCondition(
                          elementIndex >= positionOfIndex,
                          dateVal - 1,
                          dateVal,
                        ),
                  ),
              );
              customFieldChangeHandler(
                formFieldNames.datesApplied,
                datesAppliedArray.filter((_, i) => i !== index),
              );
              const filteredDuplicateArray = [
                ...duplicatedDate.dupIndexArray,
              ].filter((item) => item !== index);
              setDuplicateDate({
                flag: filteredDuplicateArray.length > 0,
                dupIndexArray: filteredDuplicateArray,
              });
            }}
          />
        )}
      </IconWrapper>
    </DateItemWrapper>
  );
};

DateItemComp.propTypes = {
  keyVal: PropTypes.string,
  dateAppliedPickerProps: PropTypes.object,
  datesAppliedArray: PropTypes.array,
  setDateAppliedArray: PropTypes.func,
  item: PropTypes.object,
  index: PropTypes.number,
  customFieldChangeHandler: PropTypes.func,
  dateRequiedList: PropTypes.array,
  setDateRequiedList: PropTypes.func,
  isInTesting: PropTypes.bool,
  marginBottomForDatePicker: PropTypes.string,
  setDuplicateDate: PropTypes.func,
  duplicatedDate: PropTypes.shape({
    flag: PropTypes.bool,
    dupIndexArray: PropTypes.arrayOf(PropTypes.number),
  }),
  showDeleteIcon: PropTypes.bool,
};
export default DateItemComp;
