import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  CustomPopupBox,
  ItemsWrapper,
  ValueWrapper,
  GridWrapper,
  titleStyle,
  subTitleStyle,
  projectHeadingStyle,
} from './ValueTable.style';
import { VALUE_CONTENT, Table_Column_Header } from './ValueTable.content';
import {
  checkTernaryCondition,
  displayTextValue,
} from '../../../../../utils/helper';
import { notesComponent } from '../components/FreeText.react';
import DeliveryDetails from '../DeliveryDetailsSection/DeliveryDetails.react';
import { WhiteChip } from 'components/ActionModal/Components/AddedDeletedTable/AddedDeletedTable.style';
import { accordionSx } from 'components/ActionModal/ActionModal.style';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

const table = (eventDetails) => {
  return (
    <CustomPopupBox direction="column" padding="0rem 0rem 0.5rem 0rem">
      <ItemsWrapper borderBottom="1px solid #D0D0CE">
        {Object.values(Table_Column_Header).map((element) => {
          return (
            <ValueWrapper width={element.width} key={element.name}>
              <b>{element.name}</b>
            </ValueWrapper>
          );
        })}
      </ItemsWrapper>
      <GridWrapper>
        {eventDetails?.map((item) => (
          <div key={item.colum}>
            <ItemsWrapper>
              <ValueWrapper width="14rem">{item?.column}</ValueWrapper>
              <ValueWrapper width="14rem">
                {displayTextValue(item?.oldValue, VALUE_CONTENT.empty)}
              </ValueWrapper>
              <ValueWrapper width="14rem">
                {displayTextValue(item?.newValue, VALUE_CONTENT.empty)}
              </ValueWrapper>
            </ItemsWrapper>
          </div>
        ))}
      </GridWrapper>
    </CustomPopupBox>
  );
};

const ValueTable = ({
  title,
  subtitle,
  hasNotes,
  eventDetails,
  oldValue,
  newValue,
  deliveryDetails,
  totalAmount,
  widthProp = '48rem',
  notesComponentType = 'Notes',
  hasProjectDetails,
}) => {
  return (
    <Accordion sx={{ width: widthProp, ...accordionSx }} defaultExpanded>
      <AccordionSummary
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': hasProjectDetails
            ? { marginBottom: '1rem' }
            : {},
          '& .Mui-expanded': hasProjectDetails ? { paddingBottom: '0rem' } : {},
        }}
        expandIcon={<KeyboardArrowRightIcon sx={{ color: DARK_CHARCOAL }} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <div style={{ width: '100%' }}>
          <div
            style={checkTernaryCondition(
              hasProjectDetails,
              projectHeadingStyle,
              {},
            )}>
            <Typography sx={titleStyle}>{title}</Typography>
            {hasProjectDetails && (
              <WhiteChip>{VALUE_CONTENT.updated}</WhiteChip>
            )}
          </div>
          {subtitle && <Typography sx={subTitleStyle}>{subtitle}</Typography>}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {(hasNotes && (
          <div>{notesComponent(oldValue, newValue, notesComponentType)}</div>
        )) || (
          <div>
            {eventDetails.length > 0 && table(eventDetails)}
            {deliveryDetails && deliveryDetails.length > 0 && (
              <DeliveryDetails
                title={'Delivery details'}
                data={deliveryDetails}
                totalAmount={totalAmount}
              />
            )}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ValueTable.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hasNotes: PropTypes.bool,
  eventDetails: PropTypes.array,
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
  deliveryDetails: PropTypes.array,
  totalAmount: PropTypes.number,
  widthProp: PropTypes.string,
  notesComponentType: PropTypes.string,
  hasProjectDetails: PropTypes.bool,
};

export default ValueTable;
