import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  EMPTY_STATE_TEXT_COLOR,
} from 'theme/GlobalColors';

export const TitleContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #333333;
`;
export const TitleCurrentValueWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: start;
`;
export const Legendscontainer = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  grid-column-gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const LegendWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 0.25rem;
`;
export const TitleLegendWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  gap: 1rem;
`;
export const LegendBoxWrapper = styled.div`
  padding: 0.25rem 0 0.25rem 0;
`;
export const CurrentActualWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.375rem;
  color: ${({ color }) => color ?? COOL_GRAY};
`;
export const LegendBox = styled.div`
  float: left;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.125rem;
  background-color: ${(props) => props.backgroundColor};
`;
export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize ?? '1.5rem'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  line-height: ${({ lineHeight }) => lineHeight ?? '2.125rem'};
`;
export const LegendText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${(color) => color ?? DARK_CHARCOAL};
`;
export const WrapperDefaultStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  position: 'relative',
};
export const BottomLegendsContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  grid-column-gap: 1rem;
  align-items: center;
  margin-top: 0.5rem;
`;

export const noDataLabelSx = {
  fontSize: '0.75rem',
  position: 'absolute',
  color: EMPTY_STATE_TEXT_COLOR,
  top: '43%',
  left: '42%',
};

export const tooltipSx = {
  fontSize: '1rem',
};
