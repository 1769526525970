import {
  InfoWindowContent,
  InfoWindowHeader,
} from './PortfolioOriginationTab.style';

export const PORTFOLIO_ORIGINATION_TAB_CONTENT = {
  row: 'row',
  column: 'column',
  acerage_tooltip: 'Represents total acreage enrolled to date.',
  ghg_impact_tooltip: 'Represents reductions and removals to date.',
  projects: 'Projects',
  participants: 'Participants',
  acerage: 'Acreage',
  types: 'Types',
  ghg_impact: 'GHG impact',
  ghg_emissions: 'GHG reductions & removals',
  projected: 'Projected',
  ACTUAL: 'Actual',
  CO2e: 'CO₂e (K MT)',
  ghg_data_unit: ' MT',
  noDataContent: 'No data to report yet.',
  kpiDataloading: 'Data Loading...',
  googleMapLoading: 'Google Map Loading...',
  DASHEDLINE: '- -',
};

export const rangeNumArray = (start, stop) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

export const getMarkerInfoWindow = ({
  projectName,
  acreage,
  participantsCount,
  ghgData,
  navigateHandler,
}) => {
  return (
    <div>
      <InfoWindowHeader onClick={navigateHandler}>
        {projectName}
      </InfoWindowHeader>
      <InfoWindowContent>
        <div>Total acreage</div>
        <div>{acreage}</div>
      </InfoWindowContent>
      <InfoWindowContent>
        <div>Total participants</div>
        <div>{participantsCount}</div>
      </InfoWindowContent>
      <InfoWindowContent>
        <div>GHG reductions & removals</div>
        <div>{ghgData}</div>
      </InfoWindowContent>
    </div>
  );
};

export const northAmericaBounds = [
  { lat: 48.392829, lng: -124.528576 },
  { lat: 32.265731, lng: -116.434747 },
  { lat: 30.22572, lng: -82.389695 },
  { lat: 47.318679, lng: -67.67396 },
];

export const portfolioPageMapOptions = {
  mapTypeId: 'satellite',
  zoom: 10,
  labels: true,
};

export const mapPolygonOptions = {
  fillColor: 'none',
  fillOpacity: 0,
  strokeColor: '#5DACB5',
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 9,
};

export const SIDENAV_ACTIVE_TAB = {
  MODULE: 'Projects',
  TAB: 'Summary',
};
