import styled from 'styled-components';
import { BLACK, DARK_CHARCOAL, DARK_GREEN, WHITE } from 'theme/GlobalColors';

export const ModalHeading = styled.div`
  color: ${BLACK};
  font-family: 'Open Sans';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '31.25rem',
      padding: ' 0.5rem 1.5rem',

      '*': {
        fontFamily: 'Open sans, sans-serif',
      },
    },
  },
};

export const DialogContentStyle = {
  padding: '0',
  overflowY: 'hidden',
  position: 'relative',

  '&.MuiDialogContent-root': {
    overflowX: 'hidden',
  },
};
export const IconButtonStyle = { ml: 'auto' };
export const DialogTitleStyle = {
  fontSize: '1.25rem',
  padding: '0 0 1rem 0',
  color: DARK_CHARCOAL,
  boxSizing: 'border-box',
  lineHeight: '140%',
};
export const DialogActionStyle = {
  justifyContent: 'flex-start',
  padding: '1rem 0rem 0rem 0rem',
};
export const ModalDialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '31.25rem',
      maxHeight: '31.25rem',
      overflowY: 'auto',
      padding: '1.5rem',
      position: 'relative',

      '*': {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
};
export const ModalIconButtonStyle = {
  ml: 'auto',
  position: 'absolute',
  right: 4,
  top: 4,
};

export const FieldSectionWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.marginBottom || '-1rem'};
  grid-column-gap: ${(props) => props.columnGap};
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  align-items: ${(props) => props.alignItems};
`;

export const SubmitBtnStyle = {
  color: WHITE,
  backgroundColor: DARK_GREEN,
  textTransform: 'none',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  width: '5rem',
  height: '2.25rem',
};

export const CancelBtnStyle = {
  color: DARK_GREEN,
  textTransform: 'none',
  height: '2.25rem',
  width: '5rem',
  fontFamily: 'Open Sans, sans-serif',
};
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: ${(props) => props.alignItems};
`;
