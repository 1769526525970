import { ReactComponent as InProgressIcon } from 'assets/icons/InProgressIcon.svg';
import { ReactComponent as GreenTickIcon } from 'assets/icons/ActivityStatus.svg';
import { ReactComponent as NotStartedStatus } from 'assets/icons/NotStartedStatus.svg';
import { ReactComponent as InReview } from 'assets/icons/IconsInReview.svg';
import { ReactComponent as EnrolmentDefaultIcon } from 'assets/icons/Enrollment.svg';
import { ReactComponent as BaselineSelectedIcon } from 'assets/icons/Baseline reporting.svg';
import { ReactComponent as ActivityDisabledIcon } from 'assets/icons/Activity reporting.svg';
import { ReactComponent as EnrolmentSelectedIcon } from 'assets/icons/Enrollment_active.svg';
import { ReactComponent as BaselineDefaultIcon } from 'assets/icons/Baselinereporting_default.svg';
import { ReactComponent as ActivityDefaultIcon } from 'assets/icons/Activity reporting_default.svg';
import { ReactComponent as EnrolmentDisabledIcon } from 'assets/icons/Enrollment_disabled.svg';
import { ReactComponent as BaselineDisabledIcon } from 'assets/icons/BaselineNotStarted.svg';
import { ReactComponent as ActivitySelectedIcon } from 'assets/icons/Activity reporting_active.svg';
import { IconDimensions } from './InfoCard.style';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import moment from 'moment';
import {
  checkTernaryCondition,
  getBooleanValueForNullOrUndefined,
} from 'utils/helper';
export const CARD_CONTENT = {
  approved: 'Approved',
  submitted: 'Submitted',
  in_review: 'In review',
  enrolment_link: 'Request to add farms and fields.',
  reporting_date: 'Project cycle: ',
  download_pdf: 'Download responses',
  enrollment: 'Enrollment',
  not_started: 'Not started',
  interested: 'Interested',
  email_sent_success_message:
    'Your request to add farms and fields has been sent.',
  email_sent_fail_message:
    'Your request to to add farms and fields failed to send. Please try again.',
  contributor: 'PARTICIPANT_CONTRIBUTOR',
};
export const findStatusIcons = (status) => {
  switch (status) {
    case 'In progress':
      return <InProgressIcon style={IconDimensions()} />;
    case 'Interested':
      return <NotStartedStatus style={IconDimensions(true)} />;
    case 'Contract added':
      return <NotStartedStatus style={IconDimensions(true)} />;
    case 'Requested':
      return <NotStartedStatus style={IconDimensions(true)} />;
    case 'Not started':
      return <NotStartedStatus style={IconDimensions(true)} />;
    case 'In review':
      return <InReview style={IconDimensions()} />;
    case 'Approved':
      return <GreenTickIcon style={IconDimensions()} />;
    case 'Submitted':
      return <GreenTickIcon style={IconDimensions()} />;
    default:
      return <NotStartedStatus style={IconDimensions(true)} />;
  }
};
export const findCardIcons = (type, status) => {
  switch (type) {
    case 'Enrollment':
      if (status === 'disabled') {
        return <EnrolmentDisabledIcon />;
      } else if (status === 'default') {
        return <EnrolmentDefaultIcon />;
      } else {
        return <EnrolmentSelectedIcon />;
      }
    case 'Baseline reporting':
      if (status === 'disabled') {
        return <BaselineDisabledIcon />;
      } else if (status === 'default') {
        return <BaselineDefaultIcon />;
      } else {
        return <BaselineSelectedIcon />;
      }
    case 'Activity reporting':
      if (status === 'disabled') {
        return <ActivityDisabledIcon />;
      } else if (status === 'default') {
        return <ActivityDefaultIcon />;
      } else {
        return <ActivitySelectedIcon />;
      }
    default:
      return null;
  }
};
export const sendStatusToFunc = (isClicked, isHovered, isDisabled, status) => {
  if (isClicked || isHovered) {
    return 'selected';
  } else if (status === undefined || isDisabled) {
    return 'disabled';
  } else {
    return 'default';
  }
};
export const TooltipContent = (list, status, isDisabled, type, statusList) => {
  const updatedList = list?.sort(
    (a, b) => new Date(b.activityDate) - new Date(a.activityDate),
  );
  if (!isDisabled && type === 'Enrollment') {
    return (
      <>
        <TextWrapper fontSize=".75rem">
          <b>{status}</b> as of{' '}
          {moment(new Date(updatedList[0]?.activityDate)).format('MM/DD/YYYY')}
        </TextWrapper>
        <>
          {updatedList.length > 1 &&
            updatedList.slice(1).map((item) => (
              <TextWrapper key={item} fontSize=".75rem">
                {+item.area?.toFixed(2) || '0'} acres{' '}
                {
                  statusList?.find((obj) => obj?.actionId === item.actionId)
                    ?.actionStatus
                }{' '}
                on {moment(new Date(item.activityDate)).format('MM/DD/YYYY')}
              </TextWrapper>
            ))}
        </>
      </>
    );
  } else {
    return null;
  }
};

const findStatus = (type, statusList, cardDetails) => {
  const sortedCardDetails = checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    cardDetails?.activityTabContractLevelList[0]?.activityTabActivitiesList.sort(
      (a, b) => new Date(b.activityDate) - new Date(a.activityDate),
    ),
    '',
  );
  return checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    statusList?.find(
      (item) =>
        item?.actionId ===
        sortedCardDetails?.find((item) => item?.activityType === type)
          ?.actionId,
    )?.actionStatus,
    '',
  );
};

const findInstanceId = (type, cardDetails) => {
  const sortedCardDetails = checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    cardDetails?.activityTabContractLevelList[0]?.activityTabActivitiesList.sort(
      (a, b) => new Date(b.activityDate) - new Date(a.activityDate),
    ),
    '',
  );
  return checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    sortedCardDetails?.find((item) => item.activityType === type)?.activityId,
    -1,
  );
};
const findProjectCycleId = (type, cardDetails) => {
  const sortedCardDetails = checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    cardDetails?.activityTabContractLevelList[0]?.activityTabActivitiesList.sort(
      (a, b) => new Date(b.activityDate) - new Date(a.activityDate),
    ),
    '',
  );
  return checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    sortedCardDetails?.find((item) => item.activityType === type)
      ?.projectCycleId,
    -1,
  );
};

export const InitialEnrolmentArray = (statusList, cardDetails) => {
  const getProjectId = checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    cardDetails.activityTabContractLevelList[0]?.internalProjectId,
    -1,
  );

  const getParticipantId = checkTernaryCondition(
    !getBooleanValueForNullOrUndefined(
      cardDetails.activityTabContractLevelList,
    ),
    cardDetails.activityTabContractLevelList[0]?.participantOwnerId,
    -1,
  );

  return [
    {
      title: 'Enrollment',
      content: 'Establish your profile, farms, and fields to enroll.',
      status:
        findStatus('Enrollment', statusList, cardDetails) || 'Not started',
      hasLinkForEnrolment: true,
      projectId: getProjectId,
      instanceId: findInstanceId('Enrollment', cardDetails),
      projectCycleId: findProjectCycleId('Enrollment', cardDetails),
      participantId: getParticipantId,
    },
    {
      title: 'Baseline reporting',
      content: 'Enter farm and field data for baseline years.',
      status:
        findStatus('Baseline reporting', statusList, cardDetails) ||
        'Not started',
      projectId: getProjectId,
      hasLinkForEnrolment: false,
      projectCycleId: findProjectCycleId('Baseline reporting', cardDetails),
      participantId: getParticipantId,
    },
    {
      title: 'Activity reporting',
      content: 'Enter farm and field data for activity year.',
      projectId: getProjectId,
      status:
        findStatus('Activity reporting', statusList, cardDetails) ||
        'Not started',
      participantId: getParticipantId,
      hasLinkForEnrolment: false,
      projectCycleId: findProjectCycleId('Activity reporting', cardDetails),
    },
  ];
};
