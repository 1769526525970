import {
  DownloadIconWrapper,
  MainWrapper,
  CustomIconButton,
  OptionsWrapper,
} from './OptionsButton.style';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDownloadFiles } from 'hooks/useDownloadFiles';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadIcon.svg';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;

const OptionsButton = ({
  onClickHandler,
  areaCode,
  areaName,
  enabledOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { downloadCSVFile } = useDownloadFiles();

  const downloadClick = () => {
    downloadCSVFile(areaCode);
  };

  const ReplaceClickHandler = () => {
    onClickHandler(areaName, areaCode);
    handleClose();
  };
  return (
    <MainWrapper>
      <DownloadIconWrapper data-testid="download-button">
        <DownloadIcon
          aria-label="downloadFile"
          id="download-button"
          onClick={downloadClick}
        />
      </DownloadIconWrapper>
      <OptionsWrapper
        data-testid="replace-button"
        isEnabled={enabledOptions?.includes(areaCode)}>
        <CustomIconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <UploadIcon />
        </CustomIconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}>
          <MenuItem onClick={ReplaceClickHandler}>Replace</MenuItem>
        </Menu>
      </OptionsWrapper>
    </MainWrapper>
  );
};

OptionsButton.propTypes = {
  onClickHandler: PropTypes.func,
  areaCode: PropTypes.string,
  areaName: PropTypes.string,
  enabledOptions: PropTypes.array,
};

export default OptionsButton;

OptionsButton.propTypes = {
  onClickHandler: PropTypes.func,
  areaCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  areaName: PropTypes.string,
  enabledOptions: PropTypes.array,
};
