import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const ReportsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const HeaderWrapper = styled.header`
  margin-bottom: calc(1rem + 2px);
  font-size: 1.25rem;
  color: ${DARK_CHARCOAL};
`;
