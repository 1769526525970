import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { useForm } from 'hooks/useForm';
import { getValueOrDefault } from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  FETCH_MOTIVATION_LIST,
  PARTICIPANT_PROFILE,
  SUBMIT_PARTICIPANT_GENERAL_INFO,
} from 'urls';
import {
  checkTernaryCondition,
  extractAddrFromGoogleMapComponents,
  formatPhoneNumber,
  getCommaSeperatedStringValues,
  getMailingAddress,
  onlyCharsRegex,
  replaceSpecialCharactersWithSingleCharacter,
  ternaryBooleanHandler,
  validatePhone,
} from 'utils/helper';
import {
  PAGE_CONTENT,
  formModal,
  errorInitialState,
  FALSE,
  checkFieldIsNotEmpty,
  validateCommunicationMethod,
  validateFormFields,
  getCommnicationMethodList,
  heardAboutTheProjectFromOptions,
  checkArrayIsNotEmpty,
  getHeardAboutTheProjectFrom,
  getMotivationToParticipate,
  ratingList,
  STYLE_CONSTANTS,
} from './ParticipantGeneralInfoForm.content';
import {
  autoCompleteSx,
  checkBoxSx,
  communicationCheckboxPadding,
  ContentWrapper,
  disabledStyle,
  FooterWrapper,
  FormHeading,
  InputSx,
  LocationFieldWrapper,
  MainWrapper,
  motivationCheckboxPadding,
  MotivationHelpText,
  MotivationOptionWrapper,
  motivationSx,
  NavigationBtnWrapper,
  ParticipantNameSection,
  ScrollWrapper,
} from './ParticipantGeneralInfoForm.style';
import { pathName } from 'Routes/routes.const';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import AutoCompleteForm from 'components/FormComponents/AutoComplete/AutoComplete.react';
import { ErrorWrapper, FormLabels } from 'components/FormComponents/FormStyles';
import GoogleMapSearchAutocomplete from 'components/FormComponents/GoogleMapSearchAutocomplete/GoogleMapSearchAutocomplete.react';
import { COOL_GRAY, BLACK } from 'theme/GlobalColors';
import { dropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import UserNavigationButtonsV2 from '../UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import { formLabelCustomSx } from 'pages/AddOriginationProject/AddOriginationProject.style';
import Footer from 'layout/footer/Footer.react';
import { getFarmsWithSortedFields } from 'pages/ParticipantDataCollection/ReportingUtilFunctions';

const ParticipantGenralInfoForm = () => {
  const { formValue, customFieldChangeHandler, setFormValue } =
    useForm(formModal);

  const {
    setRightSectionId,
    setParticipantInfo,
    setFarmListIds,
    setCurrentFarmId,
    setOpenMapView,
    setShowFieldInfoForm,
    setExpandedFarmId,
    setFarmInfoCurrentTab,
    fetchCountyList,
    fetchStateList,
    setSubmitData,
    setUserActivityDetected,
    participantProfileStatus,
    setParticipantProfileStatus,
    isProfileDataEditable,
    farmListIds,
    farmInfo,
    addFarmClickHandler,
    triggerProfileInfoSubmit,
    setTriggerProfileInfoSubmit,
    handleSubmitCallback,
    setSelectedFieldId,
  } = useContext(participantInfoContext);
  const [errorState, setErrorState] = useState(errorInitialState);
  const [loading, setLoading] = useState(false);
  const [heardAboutProjectOthers, setHeardAboutProjectOthers] = useState(false);
  const [motivationToParticipateOthers, setMotivationToParticipateOthers] =
    useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectIdValue = searchParams.get('projectid');
  const participantIdValue = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const [isEditable, setIsEditable] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [motivationList, setMotivationList] = useState([]);
  const [commonRatingList, setCommonRatingList] = useState(ratingList);
  const [autocomplete, setAutocomplete] = useState(null);
  const [countyList, setCountyList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [wasParticipantDeleted, setWasParticipantDeleted] = useState(false);
  const [isParticipantApproved, setIsParticipantApproved] = useState(false);
  const disableAllFields = !isProfileDataEditable;

  const onSearchLoad = (autocompleteValue) => {
    setAutocomplete(autocompleteValue);
  };

  const onPlaceChanged = () => {
    if (autocomplete === null) return;

    const place = autocomplete.getPlace();
    fieldHandler(
      'mailingAddress',
      `${place.name}${getMailingAddress(place.address_components)}`,
    );

    if (place.address_components) {
      const address = extractAddrFromGoogleMapComponents(
        place.address_components,
      );
      const { city, state, zipCode, county } = address;

      if (formValue?.state === state) {
        updateCountyList(
          stateList.find((stateAddr) => stateAddr.value === formValue?.state)
            ?.stateId,
        );
      }

      setFormValue((prev) => ({
        ...prev,
        city: city,
        state: state,
        county: county,
        zipCode: zipCode,
      }));
    }
  };

  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makeFormDirty = () => setUserActivityDetected(true);

  useEffect(() => {
    setSubmitData({
      currentPage: 'Profile',
      data: formValue,
      currentId: null,
    });
  }, [formValue]);

  useEffect(() => {
    setCommonRatingList((prevList) => {
      return prevList.map((item) => {
        const motivationItem = motivationList.find(
          (motivation) =>
            String(motivation.participationMotivationScore) === item.itemLabel,
        );

        return {
          ...item,
          isDisabled: checkTernaryCondition(
            motivationItem === undefined,
            false,
            true,
          ),
        };
      });
    });
  }, [motivationList]);

  const fetchGeneralProfileInfo = () => {
    setLoading(true);
    axios
      .get(
        `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectIdValue}&participantId=${participantIdValue}`,
      )
      .then((response) => {
        const data = response.data;
        const status = data.participantStatus;
        setIsEditable(data.isDeleted !== 1);
        setWasParticipantDeleted(data.profileDeleted === 1);
        setIsEditMode(data.participantStatus !== null);
        setParticipantProfileStatus(status);
        setIsParticipantApproved(data.isCopied);
        setParticipantInfo([
          {
            id: 1,
            status: checkTernaryCondition(
              status === null || status === PAGE_CONTENT.IN_PROGRESS,
              PAGE_CONTENT.in_progress,
              PAGE_CONTENT.complete,
            ),
            value: `${response.data.firstName} ${response.data.lastName}`,
          },
        ]);
        const farmsWithSortedFields = getFarmsWithSortedFields(
          data?.farmDetails,
        );
        setFarmListIds(farmsWithSortedFields);
        const heardAboutTheProjectFrom = getHeardAboutTheProjectFrom(
          data.hearAboutProject,
        );
        const fetchedHeardAboutTheProjectFromOptions =
          heardAboutTheProjectFrom.finalArray;
        const fetchedHeardAboutTheProjectFromOthers =
          heardAboutTheProjectFrom.nonMatchingItems;
        const motivationToParticipate = getMotivationToParticipate(
          data.motivation,
        );
        const fetchedMotivationToParticipate =
          motivationToParticipate.matchingValue;
        const fetchedMotivationToParticipateOthers =
          motivationToParticipate.nonMatchingItems;
        setFormValue((prev) => ({
          ...prev,
          firstName: getValueOrDefault(data.firstName),
          lastName: getValueOrDefault(data.lastName),
          email: getValueOrDefault(data.email),
          phone: getValueOrDefault(formatPhoneNumber(data.phone ?? '')),
          mailingAddress: getValueOrDefault(data.mailingAddress1),
          city: getValueOrDefault(data.city),
          state: getValueOrDefault(data.state),
          county: getValueOrDefault(data.county),
          zipCode: getValueOrDefault(data.zip),
          communicationMethod: getCommnicationMethodList(
            data.preferredCommunicationMethod,
          ),
          heardAboutTheProjectFromOptions:
            fetchedHeardAboutTheProjectFromOptions,
          heardAboutTheProjectFromOthers: fetchedHeardAboutTheProjectFromOthers,
          motivationToParticipate: fetchedMotivationToParticipate,
          motivationToParticipateOthers: fetchedMotivationToParticipateOthers,
        }));
        setErrorState((prev) => ({
          ...prev,
          heardAboutTheProjectFromRequiredOtherError: ternaryBooleanHandler(
            !fetchedHeardAboutTheProjectFromOthers.trim() &&
              fetchedHeardAboutTheProjectFromOptions.includes(
                PAGE_CONTENT.other_specify,
              ),
          ),
          inValidPhoneError:
            data.phone && !validatePhone(data.phone) && data.phone.trim(),
        }));

        // On initial fetch we set the user activity to false, once any value is changed, we update the flag
        setUserActivityDetected(false);
      })
      .catch(() => {
        /* do nothing here */
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCountyList = (stateId) => {
    if (stateId)
      fetchCountyList(stateId).then((countyAddrList) =>
        setCountyList(countyAddrList),
      );
  };

  useEffect(() => {
    fetchGeneralProfileInfo();
    setShowFieldInfoForm(false);
    fetchMotivationList();

    fetchStateList().then((stateAddrList) => setStateList(stateAddrList));
  }, []);

  useEffect(() => {
    if (formValue?.state !== '') {
      updateCountyList(
        stateList.find((state) => state.value === formValue?.state)?.stateId,
      );
    }
  }, [formValue.state]);

  useEffect(() => {
    if (formValue.county !== '' && countyList.length > 0) {
      const countyValueNotMatching =
        countyList.findIndex((county) => county.label === formValue.county) ===
        -1;
      if (countyValueNotMatching) {
        setFormValue((prev) => ({ ...prev, county: '' }));
      }
    }
  }, [countyList]);

  useEffect(() => {
    setErrorState({
      ...errorState,
      communicationMethodRequiredError:
        validateCommunicationMethod(formValue.communicationMethod) && FALSE,
    });
  }, [formValue.communicationMethod]);

  useEffect(() => {
    setErrorState({
      ...errorState,
      stateRequiredError: checkFieldIsNotEmpty(formValue.state) && FALSE,
    });
  }, [formValue.state]);

  useEffect(() => {
    let heardAboutTheProjectFromValues =
      formValue.heardAboutTheProjectFromOptions.join(', ');
    const isOthersOptionsSelected =
      formValue.heardAboutTheProjectFromOptions.includes(
        PAGE_CONTENT.other_specify,
      );
    if (isOthersOptionsSelected) {
      setHeardAboutProjectOthers(true);
      if (formValue.heardAboutTheProjectFromOthers !== '') {
        heardAboutTheProjectFromValues += `, ${formValue.heardAboutTheProjectFromOthers?.trim()}`;
      }
    } else {
      setHeardAboutProjectOthers(false);
    }
    setFormValue((prev) => ({
      ...prev,
      heardAboutTheProjectFrom: heardAboutTheProjectFromValues,
      heardAboutTheProjectFromOthers: checkTernaryCondition(
        !isOthersOptionsSelected,
        '',
        formValue.heardAboutTheProjectFromOthers,
      ),
    }));
  }, [
    formValue.heardAboutTheProjectFromOptions,
    formValue.heardAboutTheProjectFromOthers,
  ]);

  useEffect(() => {
    setErrorState({
      ...errorState,
      motivationToParticipateRequiredError:
        checkFieldIsNotEmpty(formValue.motivationToParticipate) && FALSE,
    });
    if (formValue.motivationToParticipate === PAGE_CONTENT.other_specify) {
      setMotivationToParticipateOthers(true);
    } else {
      setMotivationToParticipateOthers(false);
    }
  }, [formValue.motivationToParticipate]);

  useEffect(() => {
    if (participantProfileStatus !== null && isEditMode) {
      setErrorState((prev) => ({
        ...prev,
        phoneRequiredError: ternaryBooleanHandler(!formValue.phone.trim()),
        mailingAddressRequiredError: ternaryBooleanHandler(
          !formValue.mailingAddress.trim(),
        ),
        cityRequiredError: ternaryBooleanHandler(!formValue.city.trim()),
        stateRequiredError: ternaryBooleanHandler(!formValue.state.trim()),
        countyRequiredError: ternaryBooleanHandler(!formValue.county.trim()),
        zipCodeRequiredError: ternaryBooleanHandler(!formValue.zipCode.trim()),
        communicationMethodRequiredError: ternaryBooleanHandler(
          !validateCommunicationMethod(formValue.communicationMethod),
        ),
        heardAboutTheProjectFromRequiredError: ternaryBooleanHandler(
          !formValue.heardAboutTheProjectFrom.trim() && !wasParticipantDeleted,
        ),
        motivationToParticipateRequiredError: ternaryBooleanHandler(
          !formValue.motivationToParticipate.trim(),
        ),
        motivationToParticipateRequiredOtherError: ternaryBooleanHandler(
          !formValue.motivationToParticipateOthers.trim() &&
            motivationToParticipateOthers,
        ),
      }));
    }
  }, [formValue]);

  const fieldHandler = (key, value) => {
    customFieldChangeHandler(key, value);

    //this switch case is used to clear the error on typing valid data in the field
    switch (key) {
      case 'phone':
        setErrorState({
          ...errorState,
          phoneRequiredError: checkFieldIsNotEmpty(value),
          inValidPhoneError:
            !validatePhone(
              replaceSpecialCharactersWithSingleCharacter(
                /[()\-\s]/g,
                value,
                '',
              ),
            ) && FALSE,
        });
        break;
      case 'mailingAddress':
        setErrorState({
          ...errorState,
          mailingAddressRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case 'city':
        setErrorState({
          ...errorState,
          cityRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case 'zipCode':
        setErrorState({
          ...errorState,
          zipCodeRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case 'heardAboutTheProjectFromOptions':
        setErrorState({
          ...errorState,
          heardAboutTheProjectFromRequiredError: checkArrayIsNotEmpty(value),
          heardAboutTheProjectFromRequiredOtherError:
            checkFieldIsNotEmpty(formValue.heardAboutTheProjectFromOthers) &&
            heardAboutProjectOthers,
        });
        break;
      case 'heardAboutTheProjectFromOthers':
        setErrorState({
          ...errorState,
          heardAboutTheProjectFromRequiredOtherError:
            checkFieldIsNotEmpty(value) && heardAboutProjectOthers,
        });
        break;
      case 'motivationToParticipateOthers':
        setErrorState({
          ...errorState,
          motivationToParticipateRequiredOtherError:
            checkFieldIsNotEmpty(value) && motivationToParticipateOthers,
        });
        break;
    }
  };

  const stateDropdownProps = {
    label: PAGE_CONTENT.state_label,
    value: checkTernaryCondition(
      formValue.state === '',
      PAGE_CONTENT.state_placeholder,
      formValue.state,
    ),
    isDisabled: !isEditable || disableAllFields,
    width: '100%',
    minWidth: '0rem',
    height: '2rem',
    name: 'state',
    ariaLabel: PAGE_CONTENT.state_label,
    dropDownPlaceholder: PAGE_CONTENT.state_placeholder,
    error: errorState.stateRequiredError && !disableAllFields,
    dropdownlist: stateList,
    isNotOutlined: true,
    onUpdate: (e) => {
      setFormValue({
        ...formValue,
        state: e.target.value,
        county: '',
      });
      makeFormDirty();
    },
    field_key: 'state',
    setStateValue: () => {
      /* do nothing here */
    },
    showLabelAsValue: true,
    customPlaceholderColor: checkTernaryCondition(
      formValue.state === '',
      COOL_GRAY,
      null,
    ),
    hasNoBottomMargin: true,
    labelMarginTop: STYLE_CONSTANTS.one_point_five_rem,
    labelMarginBottom: STYLE_CONSTANTS.zero_point_two_five_rem,
    customSx: dropdownInputSx,
  };

  const countyDropdownProps = {
    label: PAGE_CONTENT.county_label,
    value: checkTernaryCondition(
      formValue.county === '',
      PAGE_CONTENT.county_placeholder,
      formValue.county,
    ),
    isDisabled: !isEditable || disableAllFields,
    width: '100%',
    minWidth: '0rem',
    height: '2rem',
    name: 'county',
    ariaLabel: PAGE_CONTENT.county_label,
    dropDownPlaceholder: PAGE_CONTENT.county_placeholder,
    error: errorState.countyRequiredError && !disableAllFields,
    dropdownlist: countyList,
    isNotOutlined: true,
    onUpdate: (e) => {
      fieldHandler('county', e.target.value);
      makeFormDirty();
    },
    field_key: 'county',
    setStateValue: () => {
      /* do nothing here */
    },
    showLabelAsValue: true,
    customPlaceholderColor: checkTernaryCondition(
      formValue.county === '',
      COOL_GRAY,
      null,
    ),
    hasNoBottomMargin: true,
    labelMarginTop: STYLE_CONSTANTS.one_point_five_rem,
    labelMarginBottom: STYLE_CONSTANTS.zero_point_two_five_rem,
    customSx: dropdownInputSx,
  };

  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: projectIdValue,
          participantid: participantIdValue,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };

  const switchToNextInProgressPage = () => {
    setCurrentFarmId(farmInfo[0].id);
    const getInProgressFieldTabList = farmInfo[0]?.fields.filter(
      (field) => field.status === 'In Progress',
    );
    if (farmInfo[0]?.status === 'in progress') {
      setExpandedFarmId(farmInfo[0].id);
      setFarmInfoCurrentTab(0);
    } else if (
      farmInfo[0]?.status === 'complete' &&
      getInProgressFieldTabList.length > 0
    ) {
      setSelectedFieldId({
        id: getInProgressFieldTabList[0].id,
        name: getInProgressFieldTabList[0].value,
      });
      setExpandedFarmId(farmInfo[0].id);
      setOpenMapView(true);
      setShowFieldInfoForm(true);
    } else {
      setExpandedFarmId(farmInfo[0]?.id);
      setFarmInfoCurrentTab(0);
    }
  };

  const setFarmInfoHandler = () => {
    if (farmListIds.length === 0) {
      addFarmClickHandler();
    } else {
      switchToNextInProgressPage();
    }
  };

  const getSubmitBody = () => {
    const phoneValue = replaceSpecialCharactersWithSingleCharacter(
      /[()\-\s]/g,
      formValue.phone,
      '',
    );
    const status = checkTernaryCondition(
      validateFormFields(formValue, motivationList),
      PAGE_CONTENT.COMPLETED,
      PAGE_CONTENT.IN_PROGRESS,
    );
    let submitData = {
      projectId: parseInt(projectIdValue),
      participantId: parseInt(participantIdValue),
      projectCycleId: Number(projectCycleId),
      participantStatus: status,
      projectParticipantStatus: PAGE_CONTENT.Enrollment_In_Progress,
      enrollmentInstanceId: parseInt(enrollmentInstanceId),
      participantDto: {
        participantPhone: phoneValue,
        mailingAddress1: formValue.mailingAddress.trim(),
        city: formValue.city.trim(),
        state: formValue.state,
        county: formValue.county,
        zip: formValue.zipCode,
        primaryCommunication: getCommaSeperatedStringValues(
          formValue.communicationMethod
            .filter((method) => method.checked)
            .map((method) => method.itemLabel),
        ),
        projectDiscoverySource: formValue.heardAboutTheProjectFrom.trim(),
        motivationList: motivationList.map((motivation) => ({
          projectMotivationId: motivation.projectParticipantMotivationId,
          motivationId: motivation.participationMotivationId,
          score: motivation.participationMotivationScore,
        })),
      },
    };

    return { status, submitData };
  };

  const goToFarm = () => {
    setRightSectionId(2);
    setFarmInfoHandler();
  };

  const saveData = async (event) => {
    if (disableAllFields) return Promise.resolve();

    setLoading(true);
    const { status, submitData } = getSubmitBody();
    return axios
      .post(SUBMIT_PARTICIPANT_GENERAL_INFO, submitData)
      .then(() => {
        displayToast(PAGE_CONTENT.success, PAGE_CONTENT.success_toast_message);
        setParticipantInfo([
          {
            id: 1,
            status: status,
            value: `${formValue.firstName} ${formValue.lastName}`,
          },
        ]);
        fetchGeneralProfileInfo();
        fetchMotivationList();
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_toast_message);
      })
      .finally(() => {
        setLoading(false);
        event?.target?.blur();
      });
  };

  const handleContinue = (event) => {
    saveData(event).then(goToFarm);
  };

  const fetchMotivationList = () => {
    axios
      .get(FETCH_MOTIVATION_LIST, {
        params: {
          participantId: participantIdValue,
          projectId: projectIdValue,
        },
      })
      .then((response) => {
        setMotivationList(response.data);
      });
  };

  useEffect(() => {
    if (triggerProfileInfoSubmit) {
      saveData().then(handleSubmitCallback);
      setTriggerProfileInfoSubmit(false);
    }
  }, [triggerProfileInfoSubmit]);

  return (
    <MainWrapper data-testid="profile-info">
      <Backdrop open={loading} sx={{ color: '#fff', zIndex: 99999 }}>
        <CircularProgress color="success" />
      </Backdrop>
      <ScrollWrapper>
        <ContentWrapper>
          <ParticipantNameSection>
            <InputField
              rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
              customInputSx={InputSx}
              minWidth={STYLE_CONSTANTS.zero_rem}
              width="100%"
              customStyles={{ width: '49.3%' }}
              required
              label={PAGE_CONTENT.first_name_label}
              name="firstName"
              value={formValue.firstName}
              placeholder={PAGE_CONTENT.first_name_placeholder}
              primaryError={
                errorState.firstNameRequiredError && !disableAllFields
              }
              maxLength={100}
              textDisabledColor={BLACK}
              isDisabled
              labelMarginTop={STYLE_CONSTANTS.zero_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            />
            <InputField
              rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
              customInputSx={InputSx}
              minWidth={STYLE_CONSTANTS.zero_rem}
              customStyles={{ width: '49.3%' }}
              width="100%"
              required
              label={PAGE_CONTENT.last_name_label}
              name="lastName"
              value={formValue.lastName}
              textDisabledColor={BLACK}
              placeholder={PAGE_CONTENT.last_name_placeholder}
              primaryError={
                errorState.lastNameRequiredError && !disableAllFields
              }
              maxLength={100}
              isDisabled
              labelMarginTop={STYLE_CONSTANTS.zero_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            />
          </ParticipantNameSection>
          <InputField
            customInputSx={InputSx}
            minWidth={STYLE_CONSTANTS.zero_rem}
            width="100%"
            required
            label={PAGE_CONTENT.email_label}
            textDisabledColor={BLACK}
            name="email"
            value={formValue.email}
            placeholder={PAGE_CONTENT.email_placeholder}
            primaryError={errorState.emailRequiredError && !disableAllFields}
            maxLength={100}
            isDisabled
            labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          />
          <InputField
            customInputSx={InputSx}
            customStyles={{ width: '100%' }}
            minWidth={STYLE_CONSTANTS.zero_rem}
            width="120px"
            required
            label={PAGE_CONTENT.phone_label}
            name="phone"
            category="phone-field"
            value={formValue.phone}
            onUpdate={(e) => {
              const value = formatPhoneNumber(e.target.value);
              fieldHandler('phone', value);
              makeFormDirty();
            }}
            onBlurHandler={(event) => {
              if (event.target.value !== '') {
                const value = replaceSpecialCharactersWithSingleCharacter(
                  /[()\-\s]/g,
                  event.target.value,
                  '',
                );
                setErrorState({
                  ...errorState,
                  inValidPhoneError: !validatePhone(value),
                });
              }
            }}
            placeholder={PAGE_CONTENT.phone_placeholder}
            primaryError={errorState.phoneRequiredError && !disableAllFields}
            maxLength={100}
            secondaryError={errorState.inValidPhoneError && !disableAllFields}
            secondaryErrorMessage={PAGE_CONTENT.invalid_phone_number_error}
            isDisabled={!isEditable || disableAllFields}
            labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customPattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
          />

          <GoogleMapSearchAutocomplete
            label={PAGE_CONTENT.mailing_address_label}
            value={formValue.mailingAddress}
            valueChangeHandler={(e) => {
              fieldHandler('mailingAddress', e.target.value);
              makeFormDirty();
            }}
            placeholder={PAGE_CONTENT.mailing_address_placeholder}
            onPlaceChanged={onPlaceChanged}
            onSearchLoad={onSearchLoad}
            hasError={
              errorState.mailingAddressRequiredError && !disableAllFields
            }
            labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            isDisabled={!isEditable || disableAllFields}
          />
          <LocationFieldWrapper>
            <InputField
              rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
              customInputSx={InputSx}
              width="100%"
              minWidth={STYLE_CONSTANTS.zero_rem}
              required
              label={PAGE_CONTENT.city_label}
              name="city"
              value={formValue.city}
              onUpdate={(e) => {
                if (onlyCharsRegex.test(e.target.value)) {
                  fieldHandler('city', e.target.value);
                  makeFormDirty();
                }
              }}
              maxLength={100}
              placeholder={PAGE_CONTENT.city_placeholder}
              primaryError={errorState.cityRequiredError && !disableAllFields}
              isDisabled={!isEditable || disableAllFields}
              labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            />
            <DropDown {...stateDropdownProps} />
            <DropDown {...countyDropdownProps} />
            <InputField
              rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
              customInputSx={InputSx}
              width={'7.5rem'}
              minWidth={STYLE_CONSTANTS.zero_rem}
              required
              label={PAGE_CONTENT.zip_code_label}
              maxLength={5}
              name="zipCode"
              value={formValue.zipCode}
              onUpdate={(e) => {
                if (e.target.value.match(/^\d+$/) || e.target.value === '') {
                  fieldHandler('zipCode', e.target.value);
                }
                makeFormDirty();
              }}
              placeholder={PAGE_CONTENT.zip_code_placeholder}
              primaryError={
                errorState.zipCodeRequiredError && !disableAllFields
              }
              isDisabled={!isEditable || disableAllFields}
              labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            />
          </LocationFieldWrapper>
          <CheckboxFormGroup
            disableAllOptions={disableAllFields}
            list={formValue.communicationMethod}
            label={PAGE_CONTENT.communication_method_label}
            instructionText={PAGE_CONTENT.communication_method_helptext}
            primaryError={
              errorState.communicationMethodRequiredError && !disableAllFields
            }
            onClickHandler={(methodIndex) => {
              customFieldChangeHandler(
                'communicationMethod',
                formValue.communicationMethod.map((method, index) => ({
                  ...method,
                  checked: checkTernaryCondition(
                    methodIndex === index,
                    !method.checked,
                    method.checked,
                  ),
                })),
              );
              makeFormDirty();
            }}
            gap="1.5rem"
            formLabelCustomSx={formLabelCustomSx}
            name="communicationMethod"
            customStyles={disabledStyle(isEditable)}
            labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            checkboxPadding={communicationCheckboxPadding}
            titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
          />
          <FormHeading>{PAGE_CONTENT.tell_us_more_label}</FormHeading>
          <AutoCompleteForm
            isDisabled={disableAllFields}
            hasCheckbox
            label={PAGE_CONTENT.project_heard_from_label}
            instructionText={PAGE_CONTENT.project_heard_from_instruction_text}
            value={formValue.heardAboutTheProjectFromOptions}
            valueSetter={(value) => {
              fieldHandler('heardAboutTheProjectFromOptions', value);
              makeFormDirty();
            }}
            list={heardAboutTheProjectFromOptions}
            primaryError={
              errorState.heardAboutTheProjectFromRequiredError &&
              !disableAllFields &&
              !isParticipantApproved
            }
            isRequired
            placeholder={PAGE_CONTENT.select_option}
            formValue={formValue.heardAboutTheProjectFromOptions}
            onClickHandler={() => {
              /* do nothing here */
            }}
            isOutlined
            hasLimit
            limitValue={3}
            customSx={autoCompleteSx}
            marginTop={STYLE_CONSTANTS.one_rem}
            labelGap={STYLE_CONSTANTS.zero_point_two_five_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            hasNewStyles
            hasNoBottomMargin
          />
          {heardAboutProjectOthers && (
            <InputField
              isDisabled={disableAllFields}
              customInputSx={InputSx}
              width="100%"
              required
              label={PAGE_CONTENT.specify_others_label}
              name="heardAboutTheProjectFromOthers"
              value={formValue.heardAboutTheProjectFromOthers}
              placeholder={PAGE_CONTENT.specify_others_placeholder}
              primaryError={
                errorState.heardAboutTheProjectFromRequiredOtherError &&
                !disableAllFields
              }
              maxLength={100}
              onUpdate={(e) => {
                fieldHandler('heardAboutTheProjectFromOthers', e.target.value);
                makeFormDirty();
              }}
              labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
            />
          )}
          <FormLabels
            iserror={
              isEditMode &&
              motivationList.some(
                (motivation) =>
                  motivation.participationMotivationScore === 0 &&
                  !wasParticipantDeleted,
              ) &&
              !isParticipantApproved
            }
            marginTop={STYLE_CONSTANTS.one_point_five_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}>
            {PAGE_CONTENT.motivation_label}
          </FormLabels>
          <MotivationHelpText>
            <div>
              <p>{PAGE_CONTENT.motivation_help_text}</p>
            </div>
            <div>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
          </MotivationHelpText>
          <MotivationOptionWrapper>
            {motivationList.map((motivation) => {
              return (
                <CheckboxFormGroup
                  disableAllOptions={disableAllFields}
                  list={commonRatingList}
                  hideLabel={true}
                  label={motivation.participationMotivation}
                  key={motivation.participationMotivationId}
                  primaryError={
                    isEditMode &&
                    motivation.participationMotivationScore === 0 &&
                    !wasParticipantDeleted &&
                    !disableAllFields &&
                    !isParticipantApproved
                  }
                  customStyles={motivationSx()}
                  labelWidth={STYLE_CONSTANTS.motivation_label_width}
                  handleRadioBtnChange={(e) => {
                    setMotivationList(
                      motivationList.map((motivationData) =>
                        checkTernaryCondition(
                          motivation.participationMotivationId ===
                            motivationData.participationMotivationId,
                          {
                            ...motivationData,
                            participationMotivationScore: checkTernaryCondition(
                              motivationData.participationMotivationScore > 0 &&
                                motivationData.participationMotivationScore ===
                                  parseInt(e.target.value),
                              0,
                              parseInt(e.target.value),
                            ),
                          },
                          motivationData,
                        ),
                      ),
                    );
                    makeFormDirty();
                  }}
                  actAsRadioButton
                  radioBtnSelectedValue={motivation.participationMotivationScore.toString()}
                  checkboxPadding={motivationCheckboxPadding}
                  gap={STYLE_CONSTANTS.motivation_checkbox_gap}
                  customSx={checkBoxSx}
                  labelMarginBottom={STYLE_CONSTANTS.zero_rem}
                  labelMarginTop={STYLE_CONSTANTS.zero_rem}
                />
              );
            })}
          </MotivationOptionWrapper>
          {isEditMode &&
            motivationList.some(
              (motivation) =>
                motivation.participationMotivationScore === 0 &&
                !wasParticipantDeleted,
            ) &&
            !isParticipantApproved && (
              <ErrorWrapper>
                {PAGE_CONTENT.motivation_required_error}
              </ErrorWrapper>
            )}

          <NavigationBtnWrapper>
            <UserNavigationButtonsV2
              handleCancel={navigateHandler}
              handleSave={saveData}
              handleContinue={handleContinue}
            />
          </NavigationBtnWrapper>
        </ContentWrapper>
        <FooterWrapper>
          <Footer
            isParticipantLanding={true}
            isParticipantMenu={false}
            isInActivityPage={false}
          />
        </FooterWrapper>
      </ScrollWrapper>
    </MainWrapper>
  );
};

export default ParticipantGenralInfoForm;
