import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import PropTypes from 'prop-types';
import {
  NoteTag,
  NoteText,
  NoteContainer,
} from './ModalLessAccordionDisplayBox.style';

export const PAGE_CONTENT = {
  cropDataLabel: 'Crops & land use',
  cashCropCopyDisclaimer:
    'Please review the copied field data as the copied values may require updating due to field area variances.',
  CopyFieldCrop: 'Copy field crop',
};

export const CropDataMissingNote = () => {
  return (
    <NoteContainer>
      <AlertIcon />
      <div>
        <span>
          <NoteTag>Note:</NoteTag>
        </span>
        <NoteText>{` Because you selected “Cover crop” as an implemented activity above, you
            must provide detailed operations data for at least one cover crop.`}</NoteText>
      </div>
    </NoteContainer>
  );
};
export const SoilAmendmentsDataMissingNote = ({ soilAmendments }) => {
  return (
    <NoteContainer>
      <AlertIcon />
      <div>
        <span>
          <NoteTag>Note:</NoteTag>
        </span>
        <NoteText>{` Because you selected “Soil amendment - {${soilAmendments.map(
          (val) => val,
        )}} ” as an implemented activity above, you must provide detailed operations data for same soil amendment for at least one crop.`}</NoteText>
      </div>
    </NoteContainer>
  );
};

SoilAmendmentsDataMissingNote.propTypes = {
  soilAmendments: PropTypes.array,
};
