export const MODAL_CONTENT = {
  add_participant_contributors_label: 'Assign participant contributor',
  email_label: 'Email address',
  submit_btn_label: 'Submit',
  next_button: 'Next',
  aria_labelledby: 'scroll-dialog-title',
  add_participant_contributor_label: 'Add participant contributor',
  email_placeholder: 'Type email address',
  first_name_label: 'First name',
  first_name_placeholder: 'Type first name',
  phone_label: 'Phone',
  phone_placeholder: 'Type phone number',
  phone_invalid_error:
    'A phone number must follow a valid format. e.g., (555) 555-5555 (dashes and brackets not required).',
  communication_method_label: 'What’s your preferred communication method?',
  communication_method_helptext: 'Select all that apply',
  duplicate_email_error:
    'The email address {{email}} is already assigned to a different role. Please use a unique email address for each role.',
  email_invalid_domain_error:
    'An email address must follow a valid format. e.g., name@domain.com',
  cancelBtn: 'Cancel',
  last_name_label: 'Last name',
  last_name_placeholder: 'Type last name',
  aria_describedby: 'scroll-dialog-description',
  existing_participant_label:
    'Participant contributor already exists in the system.',
  add_participant_contributor_content:
    'Participant contributors added to a farm in this project will have access to report on the specific farm.',
};
export const validateEmail = (email) => {
  const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})/;
  return email.match(regex);
};
