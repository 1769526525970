import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Tabber from 'components/Tabber/Tabber.react';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import UnsavedChangesModal from '../UnsavedChangesModal/UnsavedChangesModal.react';

const FieldInfoWithTabber = ({
  currentTab,
  setCurrentTab,
  tabList,
  handleTabChange,
}) => {
  const {
    userActivityDetected,
    unsavedChangesModalOpenProps,
    setUnsavedChangesModalOpenProps,
    closeUnsavedChangesModal,
    setTriggerFieldInfoSubmit,
    setUserActivityDetected,
  } = useContext(participantInfoContext);

  const switchTab = () => {
    setCurrentTab(unsavedChangesModalOpenProps.nextTab);
    closeUnsavedChangesModal();
    setUserActivityDetected(false);
  };

  const handleSaveAndLeave = () => {
    setTriggerFieldInfoSubmit(true);
  };

  const handleFieldWithMapTabChange = (tab) => {
    if (currentTab === tab) return;
    // Note: Open unsave changes modal on tab shift if there are unsaved changes, and the tab is switched from farm to map
    if (userActivityDetected && currentTab === 0) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: tab,
        targetFieldId: null,
        targetFieldName: '',
        targetFarmId: null,
        targetFarmName: '',
        navigateNextTo: 'Tab',
        triggeredFrom: 'Tab',
      });
      return;
    }
    setCurrentTab(tab);
  };

  return (
    <>
      <Tabber
        currentTab={currentTab}
        setCurrentTab={handleFieldWithMapTabChange}
        tabList={tabList}
        onTabChange={handleTabChange}
      />

      <UnsavedChangesModal
        open={
          unsavedChangesModalOpenProps?.isOpen &&
          unsavedChangesModalOpenProps?.triggeredFrom === 'Tab'
        }
        handleClose={closeUnsavedChangesModal}
        handleLeaveWithoutSave={switchTab}
        handleSaveAndLeave={handleSaveAndLeave}
      />
    </>
  );
};

FieldInfoWithTabber.propTypes = {
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.array,
  handleTabChange: PropTypes.func,
};

export default FieldInfoWithTabber;
