import React, { useState } from 'react';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import { DARK_BLACK } from 'theme/GlobalColors';
import { Card, CardMedia, CircularProgress, Dialog } from '@mui/material';
import { ReactComponent as PlayIcon } from '../../assets/icons/play_icon.svg';
import ReactPlayer from 'react-player';
import {
  CircularProgressSx1,
  CircularProgressSx2,
  EnlargedVideoContainerSx,
  EnlargedVideoDiv,
  MainCardSmallThumbnailSx,
  MainCardSx,
  PlayIconSx,
  ThumbnailImageSx,
  VideoCardContainer,
} from './VideoTutorialsPage.style';
import { checkTernaryCondition } from 'utils/helper';
import { PAGE_CONSTANTS } from './VideoTutorialsPage.content';
import PropTypes from 'prop-types';
const VideoTutorialCard = ({
  videoTutorialUrl,
  thumbnailUrl,
  videoTitle,
  videoDescription,
}) => {
  const [isVideoClicked, setIsVideoClicked] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSmallThumbnailLoading, setIsSmallThumbnailLoading] = useState(true);

  const onThumbnailLoad = () => {
    setIsLoading(false);
  };
  const onDialogOpen = () => {
    if (!isSmallThumbnailLoading) {
      setIsLoading(true);
      setIsVideoClicked(true);
    }
  };
  const onDialogClose = () => {
    setIsVideoClicked(false);
    setIsLoading(false);
  };
  return (
    <>
      <Dialog
        open={isVideoClicked}
        onClose={onDialogClose}
        sx={EnlargedVideoContainerSx}>
        <EnlargedVideoDiv>
          <ReactPlayer
            data-testid="reactPlayer"
            onClickPreview={() => setPlaying(true)}
            playing={playing}
            stopOnUnmount
            playIcon={checkTernaryCondition(
              isLoading,
              <CircularProgress sx={CircularProgressSx1} />,
              <PlayIcon style={PlayIconSx} />,
            )}
            height={'100%'}
            width={'100%'}
            light={
              <img
                onLoad={onThumbnailLoad}
                style={ThumbnailImageSx}
                src={thumbnailUrl}
                alt={PAGE_CONSTANTS.THUMBNAIL_ENLARGED_ALT}
              />
            }
            controls
            url={videoTutorialUrl}></ReactPlayer>
        </EnlargedVideoDiv>
      </Dialog>
      <VideoCardContainer>
        <Card onClick={onDialogOpen} square sx={MainCardSx}>
          <CardMedia
            onLoad={() => {
              setIsSmallThumbnailLoading(false);
            }}
            sx={MainCardSmallThumbnailSx}
            component="img"
            alt={PAGE_CONSTANTS.THUMBNAIL_ALT}
            image={thumbnailUrl}
          />
          {checkTernaryCondition(
            isSmallThumbnailLoading,
            <CircularProgress sx={CircularProgressSx2} />,
            <PlayIcon data-testid="playIcon" style={PlayIconSx} />,
          )}
        </Card>
        <TextWrapper
          marginTop="1rem"
          fontWeight="600"
          fontSize="1rem"
          color={DARK_BLACK}>
          {videoTitle}
        </TextWrapper>
        <TextWrapper
          marginTop="0.25rem"
          fontWeight="400"
          fontSize="0.875rem"
          color={DARK_BLACK}>
          {videoDescription}
        </TextWrapper>
      </VideoCardContainer>
    </>
  );
};
VideoTutorialCard.propTypes = {
  videoTutorialUrl: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  videoDescription: PropTypes.string.isRequired,
};
export default VideoTutorialCard;
