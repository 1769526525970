export const PAGE_CONTENT = {
  noDataContent: 'Looks like you haven’t added any users yet.',
  addNewUserLabel: 'Add a new user to get started!',
  addButton: 'Add user',
  remove: 'Set',
  remove_confirmation_message: 'Are you sure you want to set',
  cancel: 'Cancel',
  toast_success_message: 'was successfully set to inactive.',
  toast_error_message: 'failed to be set to inactive. Please try again.',
  roles: 'roles',
  pending: 'Pending',
  noFilterMatch: 'No results found.',
  pending_user_activation_error_message:
    'is in pending state. Please activate the user before removing.',
  filter_content: 'We couldn’t find any results for the user',
  search_text_suffix: 'No results found',
  please_try_again: 'Please try again.',
  filter_content_width: '75%',
  search_placeholder: 'Search by name or email',
  clear_all: 'Clear All',
  participant: 'PARTICIPANT',
  to_inactive: 'to inactive',
  submit: 'Submit',
  active: 'ACTIVE',
};

export const listingUserColumn = {
  userId: {
    label: 'userId',
    field: 'userId',
  },
  userName: {
    label: 'Name',
    field: 'userName',
  },
  userEmail: {
    label: 'Contact',
    field: 'userEmail',
  },
  company: {
    label: 'Company',
    field: 'company',
  },
  userStatus: {
    label: 'User status',
    field: 'userStatus',
  },
  role: {
    label: 'Role',
    field: 'role',
  },
  createdOn: {
    label: 'Added on',
    field: 'createdDate',
  },
  lastActivity: {
    label: 'Last activity',
    field: 'lastActivity',
  },
  actions: {
    label: 'Action',
    field: 'options',
  },
};

const roleLabels = {
  ASSET_VIEWER: 'Asset - Viewer',
  ASSET_PM: 'Asset - Project Manager',
  VIEWER: 'Viewer',
  ORIGINATION_MANAGER: 'Origination Manager',
  ADMIN: 'Admin',
  PARTICIPANT_MANAGER: 'Participant Manager',
  PARTICIPANT_CONTRIBUTOR: 'Participant Contributor',
};

const roleKeys = {
  ASSET_VIEWER: 'ASSET_VIEWER',
  ASSET_PM: 'ASSET_PM',
  VIEWER: 'VIEWER',
  ORIGINATION_MANAGER: 'ORIGINATION_MANAGER',
  ADMIN: 'ADMIN',
  PARTICIPANT_MANAGER: 'PARTICIPANT_MANAGER',
  PARTICIPANT_CONTRIBUTOR: 'PARTICIPANT_CONTRIBUTOR',
};
/**
 * Converts a role key to its corresponding role label.
 *
 * @param {string} role - The key representing the role.
 * @returns {string} - The human-readable label of the role.
 */
export const getRoleLabel = (role) => {
  switch (role) {
    case roleKeys.ASSET_VIEWER:
      return roleLabels.ASSET_VIEWER;
    case roleKeys.ASSET_PM:
      return roleLabels.ASSET_PM;
    case roleKeys.VIEWER:
      return roleLabels.VIEWER;
    case roleKeys.ORIGINATION_MANAGER:
      return roleLabels.ORIGINATION_MANAGER;
    case roleKeys.ADMIN:
      return roleLabels.ADMIN;
    case roleKeys.PARTICIPANT_MANAGER:
      return roleLabels.PARTICIPANT_MANAGER;
    case roleKeys.PARTICIPANT_CONTRIBUTOR:
      return roleLabels.PARTICIPANT_CONTRIBUTOR;
    default:
      return role;
  }
};
export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
/**
 * Converts a role label to its corresponding role key.
 *
 * @param {string} role - The label representing the role.
 * @returns {string} - The key of the role.
 */
export const roleMapping = (role) => {
  switch (role) {
    case roleLabels.ASSET_VIEWER:
      return roleKeys.ASSET_VIEWER;
    case roleLabels.ASSET_PM:
      return roleKeys.ASSET_PM;
    case roleLabels.VIEWER:
      return roleKeys.VIEWER;
    case roleLabels.ORIGINATION_MANAGER:
      return roleKeys.ORIGINATION_MANAGER;
    case roleLabels.ADMIN:
      return roleKeys.ADMIN;
    case roleLabels.PARTICIPANT_MANAGER:
      return roleKeys.PARTICIPANT_MANAGER;
    case roleLabels.PARTICIPANT_CONTRIBUTOR:
      return roleKeys.PARTICIPANT_CONTRIBUTOR;
    default:
      return role;
  }
};
