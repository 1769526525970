import React from 'react';
import {
  ButtonWrapper,
  cancelBtnSx,
  continueBtnSx,
  saveBtnSx,
} from './UserNavigationButtonsV2.style';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

// Note: Using V2(version 2) to differentiate from the original UserNavigationButton component
const UserNavigationButtonsV2 = ({
  handleCancel,
  handleSave,
  handleContinue,
}) => {
  return (
    <ButtonWrapper>
      <Button
        variant="contained"
        size="medium"
        color="success"
        sx={cancelBtnSx}
        onClick={handleCancel}
        disableElevation>
        Cancel
      </Button>
      <Button
        variant="contained"
        size="medium"
        color="success"
        sx={saveBtnSx}
        onClick={(e) => {
          e.target.blur();
          handleSave();
        }}
        disableElevation>
        Save
      </Button>
      <Button
        variant="contained"
        size="medium"
        color="success"
        sx={continueBtnSx}
        onClick={handleContinue}
        disabled={false}
        disableElevation>
        Continue
      </Button>
    </ButtonWrapper>
  );
};

// proptypes
UserNavigationButtonsV2.propTypes = {
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  handleContinue: PropTypes.func,
};

export default UserNavigationButtonsV2;
