import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  checkTernaryCondition,
  convertTimeStampFormat,
  findObjectWithKey,
  verifyDateFormat,
} from 'utils/helper';
import { MODAL_CONTENT } from '../TillageModal.content';
import {
  BLACK,
  DARK_CHARCOAL,
  FARM_FIELD_LABEL_COLOR,
  GLOBAL_BLUE,
  RED,
  TEXT_HOVER_COLOR,
} from 'theme/GlobalColors';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import {
  AddOptionWrapper,
  alertIconStyling,
  DatePickerWithDelete,
  deleteIconStyles,
  dropdownStyleProps,
  TillagePracticeTooltipSx,
} from '../TillageModal.style';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { handleTextFieldValueChange } from '../../Modals.content';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import moment from 'moment';
import { TextWrapper } from 'theme/GlobalStyledComps';
import {
  FieldSectionWrapper,
  outlinedDropdownInputSx,
} from '../../Modals.style';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import { practiceTypeDeleteSx } from '../../NitrogenFertilizerModal/NitrogentFertilizerModal.style';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import { useContext } from 'react';
import { FocusedInputSx } from 'components/FormComponents/FormStyles';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { DEPTH_OF_TILLAGE } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

const TillageModalContent = ({
  modalOpen,
  noDataOptionSelected,
  setIsSubmitClicked,
  formValue,
  customFieldChangeHandler,
  setDuplicateDate,
  duplicatedDate,
  setOnHoverDateApplied,
  onHoverDateApplied,
  errorState,
  setModalErrorState,
  tillageTypeDropdown,
  setHoveredPickerIndex,
  hasTestDefaultDate,
  openDatePickersIndex,
  updateTillageDate,
  setOpenDatePickersIndex,
  tillageDatePickerProps,
  tillageDateError,
  hoveredPickerIndex,
  handleTillageDateDelete,
  actsAsModal,
  deleteHandler,
  isCombinationUnique = true,
  tillageTypeDeepTillage,
  checkTillageDepthError,
  checkTillageSurfaceError,
  disableAllFieldActions = false,
  makeFormDirty = () => {
    /* do nothing */
  },
}) => {
  const { tillageType, operationsData, isFieldDisabled } = useContext(
    implementedActivitiesWithOpsDataContext,
  );
  const {
    unitOfMeasureList: { tillage },
  } = useContext(uomContext);

  const filterTillageTypes = () => {
    if (tillageType?.[0] !== undefined) {
      if (tillageType?.[0] === MODAL_CONTENT.noTill) {
        return tillageTypeDropdown.filter(
          (x) => x.label === MODAL_CONTENT.noTillageOption,
        );
      } else {
        return tillageTypeDropdown.filter(
          (x) => x.label !== MODAL_CONTENT.noTillageOption,
        );
      }
    } else if (
      tillageType?.length === 0 &&
      operationsData?.tillageTypeDtoList.length > 0
    ) {
      const hasTillagePractice =
        operationsData?.tillageTypeDtoList[0]?.data?.tillagePractice;
      if (hasTillagePractice !== 7) {
        return tillageTypeDropdown.filter(
          (x) => x.label !== MODAL_CONTENT.noTillageOption,
        );
      }
    }
    return tillageTypeDropdown;
  };

  return (
    <>
      <FieldSectionWrapper
        columnGap="1rem"
        marginBottom="0rem"
        alignItems="flex-end">
        <DropDown
          isDisabled={
            modalOpen.data?.formData[0]?.addedFromImplementedActivities ||
            false ||
            !actsAsModal
          }
          headerColor={FARM_FIELD_LABEL_COLOR}
          minWidth={STYLE_CONSTANTS.thirteen_point_seven_five_rem}
          customPlaceholderColor={DARK_CHARCOAL}
          tooltipLabel={
            !actsAsModal &&
            !isFieldDisabled &&
            !disableAllFieldActions &&
            MODAL_CONTENT.tillage_tooltip_msg
          }
          tooltipSx={TillagePracticeTooltipSx}
          hasNoBottomMargin={true}
          value={checkTernaryCondition(
            formValue.tillagePractice === '',
            MODAL_CONTENT.tillagePracticePlaceholder,
            formValue.tillagePractice,
          )}
          label={MODAL_CONTENT.tillagePracticeLabel}
          error={errorState.tillagePractice}
          setFormFieldValue={customFieldChangeHandler}
          stateValue={formValue.tillagePractice}
          customSx={outlinedDropdownInputSx}
          setStateValue={() => {}}
          name={MODAL_CONTENT.tillagePracticeField}
          field_key={MODAL_CONTENT.tillagePracticeField}
          alertIconStyling={alertIconStyling}
          dropDownPlaceholder={MODAL_CONTENT.tillagePracticePlaceholder}
          dropdownlist={filterTillageTypes()}
          onUpdate={() => setIsSubmitClicked(false)}
          setModalErrorState={setModalErrorState}
          fieldName={MODAL_CONTENT.tillagePracticeField}
          showLabelAsValue={true}
          labelMarginTop={checkTernaryCondition(
            actsAsModal,
            STYLE_CONSTANTS.one_point_five_rem,
            STYLE_CONSTANTS.zero_rem,
          )}
          moduleError={!isCombinationUnique}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          {...dropdownStyleProps(tillageTypeDeepTillage)}
        />
        {!actsAsModal && !isFieldDisabled && !disableAllFieldActions && (
          <DeleteIcon
            sx={practiceTypeDeleteSx}
            onClick={() => deleteHandler()}
          />
        )}
      </FieldSectionWrapper>
      {!noDataOptionSelected && (
        <>
          {formValue?.tillageDates?.map((tillageDate, index) => {
            const keyVal = index;
            return (
              <DatePickerWithDelete
                key={keyVal}
                onMouseEnter={() => setHoveredPickerIndex(index)}
                onMouseLeave={() => setHoveredPickerIndex(-1)}>
                <CustomDatePicker
                  labelColor={FARM_FIELD_LABEL_COLOR}
                  calendarOpenDate={checkTernaryCondition(
                    hasTestDefaultDate,
                    new Date(),
                    `${modalOpen.data?.year}-01-01`,
                  )}
                  hasNoLabel={index > 0}
                  alertIconStyling={alertIconStyling}
                  value={tillageDate}
                  labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  open={openDatePickersIndex === index}
                  onUpdate={(newValue) => {
                    const date = newValue?.toDate();
                    updateTillageDate(date, index);
                    setIsSubmitClicked(false);
                    setModalErrorState(
                      newValue,
                      MODAL_CONTENT.tillageDatesField,
                      keyVal,
                    );
                    // Check for duplicate date
                    const duplicatedDateIndex =
                      formValue.tillageDates.findIndex((tillageDate, i) => {
                        const formattedDate = moment(date).format('YYYY-MM-DD');
                        const formattedTillageDate =
                          moment(tillageDate).format('YYYY-MM-DD');
                        return (
                          i !== index &&
                          formattedTillageDate !== MODAL_CONTENT.invalid_date &&
                          formattedDate !== MODAL_CONTENT.invalid_date &&
                          formattedDate === formattedTillageDate
                        );
                      });

                    if (duplicatedDateIndex !== -1) {
                      setDuplicateDate({
                        flag: true,
                        dupIndexArray: [...duplicatedDate.dupIndexArray, index],
                      });
                    }
                    if (
                      duplicatedDateIndex === -1 &&
                      duplicatedDate.dupIndexArray.includes(index)
                    ) {
                      setDuplicateDate({
                        flag:
                          duplicatedDate.dupIndexArray.filter(
                            (item) => item !== index,
                          ).length > 0,
                        dupIndexArray: duplicatedDate.dupIndexArray.filter(
                          (item) => item !== index,
                        ),
                      });
                    }
                    let indexesWithValue = formValue.tillageDates.reduce(
                      function (a, e, i) {
                        if (
                          moment(e).format('YYYY-MM-DD') ===
                          moment(tillageDate).format('YYYY-MM-DD')
                        )
                          a.push(i);
                        return a;
                      },
                      [],
                    );
                    if (indexesWithValue.length >= 2) {
                      indexesWithValue = [...indexesWithValue].filter(
                        (item) => item !== index,
                      );
                    }
                    if (
                      duplicatedDateIndex === -1 &&
                      duplicatedDate.dupIndexArray.includes(indexesWithValue[0])
                    ) {
                      setDuplicateDate({
                        flag:
                          duplicatedDate.dupIndexArray.filter(
                            (item) => item !== indexesWithValue[0],
                          ).length > 0,
                        dupIndexArray: duplicatedDate.dupIndexArray.filter(
                          (item) => item !== indexesWithValue[0],
                        ),
                      });
                    }
                  }}
                  onClickHandler={() => setOpenDatePickersIndex(index)}
                  onClose={() => setOpenDatePickersIndex(-1)}
                  {...tillageDatePickerProps(index)}
                  hasNoBottomMargin
                  isDisabled={!actsAsModal}
                />
                {formValue.tillageDates.length > 1 && actsAsModal && (
                  <DeleteIcon
                    data-testid="DeleteIcon"
                    style={deleteIconStyles(
                      index === 0,
                      !verifyDateFormat(convertTimeStampFormat(tillageDate)),
                      index === hoveredPickerIndex,
                    )}
                    onClick={() => handleTillageDateDelete(index)}
                  />
                )}
              </DatePickerWithDelete>
            );
          })}
          {tillageDateError.flag && actsAsModal && (
            <TextWrapper
              color={RED}
              fontSize={STYLE_CONSTANTS.zero_point_seven_five_rem}
              position="relative">
              {MODAL_CONTENT.tillage_date_error}
            </TextWrapper>
          )}
          {duplicatedDate.flag && (
            <TextWrapper
              color={RED}
              position="relative"
              fontSize={STYLE_CONSTANTS.zero_point_seven_five_rem}>
              {MODAL_CONTENT.duplicate_date_error}
            </TextWrapper>
          )}
          {actsAsModal && (
            <AddOptionWrapper
              data-testid="add-tillage-date-icon"
              onMouseEnter={() => {
                setOnHoverDateApplied(true);
              }}
              onMouseLeave={() => {
                setOnHoverDateApplied(false);
              }}
              onClick={() => {
                updateTillageDate(null, formValue.tillageDates.length);
              }}>
              <TextWrapper
                color={checkTernaryCondition(
                  onHoverDateApplied,
                  TEXT_HOVER_COLOR,
                  GLOBAL_BLUE,
                )}
                marginTop={STYLE_CONSTANTS.zero_point_three_seven_five_rem}
                fontSize={STYLE_CONSTANTS.input_font_size}
                gap={STYLE_CONSTANTS.zero_point_two_five_rem}>
                <AddIcon
                  fontSize="small"
                  sx={{
                    '&:hover': {
                      fill: onHoverDateApplied ? TEXT_HOVER_COLOR : GLOBAL_BLUE,
                    },
                  }}
                />
                {MODAL_CONTENT.addTillageDateLinkText}
              </TextWrapper>
            </AddOptionWrapper>
          )}
          <FieldSectionWrapper
            gap={checkTernaryCondition(
              errorState.surfaceDisturbance,
              STYLE_CONSTANTS.one_rem,
              STYLE_CONSTANTS.one_point_five_rem,
            )}
            marginBottom={STYLE_CONSTANTS.zero_rem}
            marginTop={checkTernaryCondition(
              actsAsModal,
              STYLE_CONSTANTS.one_point_five_rem,
              STYLE_CONSTANTS.one_rem,
            )}>
            <InputField
              isDisabled={isFieldDisabled}
              headerColor={BLACK}
              width={STYLE_CONSTANTS.three_point_seven_five_rem}
              rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
              isOptional={true}
              primaryErrorMessage={MODAL_CONTENT.depthOfTillageErrorMsg}
              primaryError={
                errorState.depthOfTillage ||
                checkTillageDepthError[formValue.id]
              }
              marginForError={STYLE_CONSTANTS.zero_point_two_five_rem}
              label={MODAL_CONTENT.depthOfTillageLabel}
              name={MODAL_CONTENT.depthOfTillageField}
              value={formValue.depthOfTillage.toString()}
              category="long-numbers-field"
              predefinedUnit={
                findObjectWithKey(tillage, DEPTH_OF_TILLAGE).DEPTH_OF_TILLAGE[0]
                  ?.uomNameDisplay
              }
              hasPredefinedUnit
              onUpdate={(event) => {
                handleTextFieldValueChange(
                  event,
                  MODAL_CONTENT.depthOfTillageField,
                  true,
                  customFieldChangeHandler,
                );
                setModalErrorState(
                  event.target.value,
                  MODAL_CONTENT.depthOfTillageField,
                );
                makeFormDirty();
              }}
              placeholder={MODAL_CONTENT.depthOfTillagePlaceholder}
              maxLength={100}
              labelMarginTop={STYLE_CONSTANTS.zero_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              minWidth={STYLE_CONSTANTS.three_point_seven_five_rem}
              customInputSx={FocusedInputSx}
            />
            <InputField
              isDisabled={isFieldDisabled}
              headerColor={BLACK}
              label={MODAL_CONTENT.surfaceDisturbanceLabel}
              name={MODAL_CONTENT.surfaceDisturbanceField}
              value={formValue.surfaceDisturbance.toString()}
              rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
              primaryErrorMessage={MODAL_CONTENT.surfaceDisturbanceErrorMsg}
              marginForError={STYLE_CONSTANTS.zero_point_two_five_rem}
              primaryError={
                errorState.surfaceDisturbance ||
                checkTillageSurfaceError[formValue.id]
              }
              paddingForError={STYLE_CONSTANTS.zero_rem}
              predefinedUnit={MODAL_CONTENT.percent_unit}
              hasPredefinedUnit
              onUpdate={(event) => {
                if (event?.target.value !== '.') {
                  handleTextFieldValueChange(
                    event,
                    MODAL_CONTENT.surfaceDisturbanceField,
                    true,
                    customFieldChangeHandler,
                  );
                }
                setModalErrorState(
                  event.target.value,
                  MODAL_CONTENT.surfaceDisturbanceField,
                );
                makeFormDirty();
              }}
              isOptional={true}
              placeholder={MODAL_CONTENT.surfaceDisturbancePlaceholder}
              maxLength={3}
              labelMarginTop={STYLE_CONSTANTS.zero_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              width={STYLE_CONSTANTS.three_point_seven_five_rem}
              minWidth={STYLE_CONSTANTS.three_point_seven_five_rem}
              category="number-field"
            />
          </FieldSectionWrapper>
        </>
      )}
    </>
  );
};

TillageModalContent.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
    data: PropTypes.shape({
      year: PropTypes.number,
      formData: PropTypes.array,
    }),
  }),
  setModalOpen: PropTypes.func,
  noDataOptionSelected: PropTypes.bool,
  setIsSubmitClicked: PropTypes.func,
  formValue: PropTypes.object,
  checkTillageDepthError: PropTypes.object,
  checkTillageSurfaceError: PropTypes.object,
  customFieldChangeHandler: PropTypes.func,
  setDuplicateDate: PropTypes.func,
  duplicatedDate: PropTypes.object,
  setOnHoverDateApplied: PropTypes.func,
  onHoverDateApplied: PropTypes.bool,
  errorState: PropTypes.object,
  setModalErrorState: PropTypes.func,
  setHoveredPickerIndex: PropTypes.func,
  hasTestDefaultDate: PropTypes.bool,
  openDatePickersIndex: PropTypes.number,
  updateTillageDate: PropTypes.func,
  setOpenDatePickersIndex: PropTypes.func,
  tillageDatePickerProps: PropTypes.func,
  tillageDateError: PropTypes.object,
  hoveredPickerIndex: PropTypes.number,
  handleTillageDateDelete: PropTypes.func,
  actsAsModal: PropTypes.bool,
  deleteHandler: PropTypes.func,
  isCombinationUnique: PropTypes.bool,
  tillageTypeDeepTillage: PropTypes.string,
  tillageTypeDropdown: PropTypes.array,
  disableAllFieldActions: PropTypes.bool,
  makeFormDirty: PropTypes.func,
};

export default TillageModalContent;
