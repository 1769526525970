import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const TitleWithCloseIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

export const DialogSx = {
  width: '35.25rem',
  margin: 'auto',
  '.MuiPaper-root': {
    padding: '1.5rem',
  },
};

export const DialogTitleSx = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: 0,
  alignItems: 'flex-start',
  paddingBottom: '1rem',
};

export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  color: ${DARK_CHARCOAL};
`;

export const DialogContentSx = {
  padding: 0,
  paddingBottom: '1rem',
  fontSize: '0.875rem',
  color: DARK_CHARCOAL,
};

export const DialogActionsSx = {
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
};
