import styled from 'styled-components';
import {
  NEW_HOVER_GREEN_BACKGROUND,
  QUILL_GRAY,
  TEAL_GREEN_HOVER,
} from 'theme/GlobalColors';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
  border-top: 1px solid ${QUILL_GRAY};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterBox = styled.div`
  padding: 0.4rem 0.5rem;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  border-radius: 2px;
  font-size: 0.8rem;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  word-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: ${({ alignItems }) => alignItems};
`;

export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
`;

export const datagridSx = {
  backgroundColor: '#FFFFFF',
  width: 'auto',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  marginTop: '0.375rem',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: '#333333',
  },
  borderBottom: 0,
  borderRadius: 0,
  borderRight: 0,
  borderLeft: 0,
  '& .MuiDataGrid-iconButtonContainer': {
    marginLeft: '2px',
    visibility: 'visible',
    width: 'auto',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
};

export const BasicWrapper = styled.div`
  display: flex;
  justify-content: ${({ content }) => content};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  border-radius: ${({ radius }) => radius};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
  height: ${({ height }) => height};
  &:hover {
    background-color: ${({ hasHoverFocusStyle = false }) =>
      hasHoverFocusStyle && NEW_HOVER_GREEN_BACKGROUND};
  }
  &:focus {
    background-color: ${({ hasHoverFocusStyle = false }) =>
      hasHoverFocusStyle && TEAL_GREEN_HOVER};
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`;

export const RowCellWrapper = styled.div`
  display: flex;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  flex-direction: ${({ direction }) => direction};
  color: ${({ color }) => color};
  gap: ${({ gap }) => gap};
  align-self: ${({ alignSelf }) => alignSelf};
  padding: ${({ padding }) => padding};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
`;

export const ActionWrapper = styled.div`
  max-width: 21rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

export const sortStyle = {
  height: '1rem',
  width: '1rem',
};

export const CellValueWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 8rem;
  color: ${(props) => props.color};
`;

export const TooltipWrapper = styled.div`
  white-space: nowrap;
  border: 0.5px solid #63666a;
  border-radius: 4px;
  padding: 2px 4px;
  color: #63666a;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  align-items: center;
  max-width: ${({ width }) => width};
`;
