import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  datagridSx,
  FilterHeader,
  DataGridWrapper,
  TransactionListText,
  TransactionListWrapper,
  SortWrapper,
  TimeStampIconWrapper,
} from './StakeholdersProfile.style';
import {
  STAKEHOLDERS_PROFILES,
  STAKEHOLDER_NAME_COLUMN,
  STAKEHOLDER_TYPE_COLUMN,
  DELETE_STAKEHOLDER,
} from '../../urls';
import {
  DEFAULT_PAGINATION_OPTIONS,
  DEFAULT_PAGE_SIZE,
  LABEL_ROWS_PER_PAGES,
  PAGINATION_MODE,
} from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import { dateTimeFormatter } from 'utils/dateFormatter';
import DialogBox from 'components/DialogBox/DialogBox.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import SimpleOptionsButton from '../../components/SimpleOptionsButton/SimpleOptionsButton.react';
import axios from 'axios';
import FilterComponent from 'components/FilterComponent/FilterComponent.react';
import {
  STAKEHOLDERS_PROFILE_CONTENT,
  SortColValues,
  SortColNames,
  SortHandler,
} from './StakeholdersProfile.content.js';
import CircleIcon from '@mui/icons-material/Circle';
import { covertDateToLocalTimeZone } from 'utils/helper';
import NodataComponent from 'components/NoDataComp/NodataContent.react';
import { pathName } from 'Routes/routes.const';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';

const dayInMonthComparator = (v1, v2) => new Date(v1) - new Date(v2);

const StakeholdersProfile = ({ disableVirtualization }) => {
  const [currentRow, setCurrentRow] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setErrorIsDialogOpen] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogSubtitle, setDialogSubtitle] = useState();
  const [stakeholderData, setStakeholdersData] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalElements, setTotalElements] = useState();
  const [stakeholderTypeSelectedFilter, setStakeholderTypeSelectedFilter] =
    useState([]);
  const [stakeholderNameSelectedFilter, setStakeholderNameSelectedFilter] =
    useState([]);
  const [filterData, setFilterData] = useState({
    STAKEHOLDER_NAME: [],
    STAKEHOLDER_TYPE: [],
  });
  const [modifiedList, setModifiedList] = useState({
    STAKEHOLDER_NAME: [],
    STAKEHOLDER_TYPE: [],
  });
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState({
    nameFlag: false,
    typeFlag: false,
    dateFlag: false,
  });
  const [order, setOrder] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const navigate = useNavigate();

  const postData = {
    filter: {
      stakeholderName: stakeholderNameSelectedFilter,
      stakeholderType: stakeholderTypeSelectedFilter,
    },
    sortFilter: {
      newestToOldest: order,
      sortType: sortColumn,
    },
    pageNumber: pageNumber,
    pageSize: pageSize,
  };

  const loadProfile = (row) => {
    const { vendorId } = row.row;
    navigate(`${pathName.assets.stakeholders.view}/${vendorId}`, {
      state: { previousPath: location.pathname },
    });
  };
  const onErrorDialogBoxClose = () => {
    setTransactionList([]);
    setErrorIsDialogOpen(false);
  };
  const deleteStakeholder = () => {
    setIsDialogOpen(false);
    axios
      .delete(DELETE_STAKEHOLDER, {
        params: { vendorId: currentRow?.vendorId },
      })
      .then((response) => {
        if (response?.data?.status === STAKEHOLDERS_PROFILE_CONTENT.success) {
          toast(
            <CustomSnackbar
              type="success"
              message={`${currentRow?.vendorName} ${STAKEHOLDERS_PROFILE_CONTENT.success_message}`}
            />,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          fetchStakeholderProfileList();
          apiCall();
        } else if (
          response?.data?.status === STAKEHOLDERS_PROFILE_CONTENT.failed
        ) {
          setDialogTitle(`${currentRow?.vendorName} couldn't be deleted`);
          setDialogSubtitle(
            `${currentRow?.vendorName} ${STAKEHOLDERS_PROFILE_CONTENT.failed_message}`,
          );
          setErrorIsDialogOpen(true);
        } else {
          setDialogTitle(`${currentRow?.vendorName} can't be deleted`);
          if (response?.data?.transactionNames.length === 1) {
            setDialogSubtitle(
              `${currentRow?.vendorName} is linked in ${response?.data?.transactionNames[0]} ${STAKEHOLDERS_PROFILE_CONTENT.denied_message_single_transaction}`,
            );
          } else if (response?.data?.transactionNames.length > 1) {
            setDialogSubtitle(
              `${currentRow?.vendorName} ${STAKEHOLDERS_PROFILE_CONTENT.denied_message_multiple_transaction}`,
            );
            setTransactionList(response?.data?.transactionNames);
          } else {
            setDialogTitle(`${currentRow?.vendorName} couldn't be deleted`);
            setDialogSubtitle(
              `${currentRow?.vendorName} ${STAKEHOLDERS_PROFILE_CONTENT.failed_message}`,
            );
          }
          setErrorIsDialogOpen(true);
        }
      })
      .catch(() => {
        setDialogTitle(`${currentRow?.vendorName} couldn't be deleted`);
        setDialogSubtitle(
          `${currentRow?.vendorName} ${STAKEHOLDERS_PROFILE_CONTENT.failed_message}`,
        );
        setErrorIsDialogOpen(true);
      });
  };

  const fetchStakeholderProfileList = () => {
    axios.post(STAKEHOLDERS_PROFILES, postData).then((response) => {
      setLoading(false);
      setStakeholdersData(
        response.data.content.filter((data) => data.vendorId !== -1),
      );
      setIsDataFetched(true);
      setTotalElements(response.data.totalElements);
    });
  };

  const apiCall = () => {
    axios
      .all(
        [STAKEHOLDER_NAME_COLUMN, STAKEHOLDER_TYPE_COLUMN].map((promise) =>
          axios.get(promise),
        ),
      )
      .then(
        axios.spread((...responses) => {
          setFilterData({
            ...filterData,
            STAKEHOLDER_NAME: responses[0].data.filter(
              (data) => data.id !== 'N/A',
            ),
            STAKEHOLDER_TYPE: responses[1].data,
          });
        }),
      )
      .catch(() => {});
  };
  const handelFilterClick = () => {
    let stakeholderNameList = filterData.STAKEHOLDER_NAME?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let stakeholderTypeList = filterData.STAKEHOLDER_TYPE?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });

    setModifiedList(() => ({
      ...modifiedList,
      STAKEHOLDER_NAME: [...stakeholderNameList],
      STAKEHOLDER_TYPE: [...stakeholderTypeList],
    }));
  };

  const handleSortClick = (params) => {
    setLoading(true);
    setSortColumn(SortColValues[params.field]);
    if (SortColValues[params.field] === 'Name') {
      setSortOrder({
        nameFlag: !sortOrder.nameFlag,
        typeFlag: false,
        dateFlag: false,
      });
      setOrder(!sortOrder.nameFlag);
    } else if (SortColValues[params.field] === 'Type') {
      setSortOrder({
        nameFlag: false,
        typeFlag: !sortOrder.typeFlag,
        dateFlag: false,
      });
      setOrder(!sortOrder.typeFlag);
    } else if (SortColValues[params.field] === 'Date') {
      setSortOrder({
        nameFlag: false,
        typeFlag: false,
        dateFlag: !sortOrder.dateFlag,
      });
      setOrder(!sortOrder.dateFlag);
    }
  };

  useEffect(() => {
    handelFilterClick();
  }, [filterData]);
  useEffect(() => {
    apiCall();
  }, []);
  useEffect(() => {
    fetchStakeholderProfileList();
  }, [
    stakeholderNameSelectedFilter,
    stakeholderTypeSelectedFilter,
    sortColumn,
    sortOrder,
  ]);
  useEffect(() => {
    setLoading(true);
    fetchStakeholderProfileList();
  }, [pageSize, pageNumber]);

  const columns = [
    {
      field: 'vendorName',
      headerName: 'Stakeholder Name',
      disableColumnMenu: true,
      sortable: false,
      flex: 4,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>{SortColNames.stakeHolderName}</b>
          <FilterComponent
            isFilterApplied={
              stakeholderNameSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={
              modifiedList.STAKEHOLDER_NAME.length > 4 ? true : false
            }
            isSearchEnabled={
              modifiedList.STAKEHOLDER_NAME.length > 9 ? true : false
            }
            list={modifiedList.STAKEHOLDER_NAME}
            setFilterList={setStakeholderNameSelectedFilter}
          />
          <SortWrapper
            data-testid="sort-stakeholder-name"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.nameFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => <span>{params.value || '-'}</span>,
    },
    {
      field: 'vendorType',
      headerName: 'Stakeholder Type',
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>{SortColNames.stakeHolderType}</b>
          <FilterComponent
            isFilterApplied={
              stakeholderTypeSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={
              modifiedList.STAKEHOLDER_TYPE.length > 4 ? true : false
            }
            isSearchEnabled={
              modifiedList.STAKEHOLDER_TYPE.length > 9 ? true : false
            }
            list={modifiedList.STAKEHOLDER_TYPE}
            setFilterList={setStakeholderTypeSelectedFilter}
          />
          <SortWrapper
            data-testid="sort-stakeholder-type"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.typeFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => <span>{params.value || '-'}</span>,
    },
    {
      field: 'addedOn',
      headerName: 'Added On',
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      sortComparator: dayInMonthComparator,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>{SortColNames.addedOn}</b>
          <SortWrapper
            data-testid="sort-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.dateFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <div>
          {params.value !== null
            ? [
                covertDateToLocalTimeZone(params.value).slice(0, 10),
                <TimeStampIconWrapper key={params.value}>
                  <CircleIcon fontSize="inherit" />
                </TimeStampIconWrapper>,
                dateTimeFormatter(covertDateToLocalTimeZone(params.value)),
              ]
            : '-'}
        </div>
      ),
      flex: 2,
    },
    {
      field: 'vendorId',
      headerName: ' ',
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <div
          onClick={() => setCurrentRow(params?.row)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              setCurrentRow(params?.row);
            }
          }}
          data-testid={`options-${params.value}`}>
          <SimpleOptionsButton
            editId={params.value}
            onEdit={() =>
              navigate(`/assets/stakeholders/edit/${params.value}`, {
                state: { previousPath: location.pathname },
              })
            }
            onDelete={() => setIsDialogOpen(true)}
          />
        </div>
      ),
      flex: 1,
    },
  ];
  if (isDataFetched && stakeholderData.length == 0) {
    return (
      <NodataComponent
        nodata={STAKEHOLDERS_PROFILE_CONTENT.noDataContent}
        addNewLabel={STAKEHOLDERS_PROFILE_CONTENT.addNewStakeLabel}
        addbuttonLabel={{
          name: STAKEHOLDERS_PROFILE_CONTENT.addButton,
          link: '/assets/stakeholders/add',
        }}
      />
    );
  } else {
    return (
      <DataGridWrapper>
        <DataGrid
          disableVirtualization={disableVirtualization}
          disableSelectionOnClick
          loading={loading}
          sx={datagridSx}
          rowCount={totalElements}
          rows={stakeholderData}
          getRowId={(row) => row.vendorId}
          columns={columns}
          paginationMode={PAGINATION_MODE}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => setPageNumber(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
          pagination
          componentsProps={{
            pagination: {
              labelRowsPerPage: LABEL_ROWS_PER_PAGES,
              SelectProps: TablePaginationMenuListStyle,
            },
          }}
          onRowClick={loadProfile}
        />
        <DialogBox
          dialogActions
          deleteOperation
          isOpen={isDialogOpen}
          onConfirm={() => deleteStakeholder()}
          subtitle={`${STAKEHOLDERS_PROFILE_CONTENT.delete_message?.slice(
            0,
            32,
          )} ${
            currentRow?.vendorName
          } ${STAKEHOLDERS_PROFILE_CONTENT.delete_message?.slice(31)}`}
          onCancel={() => setIsDialogOpen(false)}
          declineCtnLabel="Cancel"
          acceptCtnLabel="Delete"
          title={`Delete ${currentRow?.vendorName}`}></DialogBox>
        <DialogBox
          deleteOperation
          isOpen={isErrorDialogOpen}
          subtitle={dialogSubtitle}
          onCancel={() => onErrorDialogBoxClose()}
          title={dialogTitle}>
          <TransactionListWrapper>
            {transactionList.map((item) => (
              <TransactionListText key={item}>{item}</TransactionListText>
            ))}
          </TransactionListWrapper>
        </DialogBox>
      </DataGridWrapper>
    );
  }
};

StakeholdersProfile.propTypes = {
  disableVirtualization: PropTypes.bool,
};
export default StakeholdersProfile;
