import PropTypes from 'prop-types';
import { FieldSectionWrapper } from '../Modals.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  addZeroBeforeDot,
  checkTernaryCondition,
  findObjectWithKey,
  isEmpty,
} from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  MODAL_CONTENT,
  errorSetter,
  formFieldNames,
  irrigationTypeFormInitialData,
  modalErrorInitialState,
  uomOptions,
} from './IrrigationModal.content';
import { useEffect, useState, useContext } from 'react';
import {
  BLACK,
  FARM_FIELD_LABEL_COLOR,
  FORM_BG_BORDER,
} from 'theme/GlobalColors';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import { FormWrapper } from 'pages/ParticipantDataCollection/ReportingComponents/Modals.style';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import { deleteIconSx } from 'components/FormComponents/FormStyles';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { IRRIGATION_VOLUME } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

const IrrigationModal = ({
  modalOpen = { flag: false },
  submitHandler,
  existingIrrigationIds,
  setShouldSubmitWork,
  parentId,
  makeFormDirty = () => null,
  hasOnlyOneItem,
  disableAllFieldActions = false,
}) => {
  const formValue = modalOpen.data;
  const {
    unitOfMeasureList: { cropAndLandUse },
  } = useContext(uomContext);

  const customFieldChangeHandler = (key, value) => {
    submitHandler(
      {
        ...formValue,
        [key]: value,
      },
      modalOpen.id,
      'irrigationList',
    );
  };

  const setFormValue = (value) => {
    submitHandler(value, modalOpen.id, 'irrigationList');
  };
  const { irrigationMethodDropDown } = useContext(baselineDropdownContext);
  const { submitData, handlePracticeTypeDelete, isFieldDisabled } = useContext(
    implementedActivitiesWithOpsDataContext,
  );
  const [errorState, setErrorState] = useState(errorSetter(modalOpen.data));

  const setModalErrorState = (value, fieldName) => {
    switch (fieldName) {
      case 'irrigationType':
        setErrorState({ ...errorState, typeError: value === '' });
        break;
      case 'uom':
        setErrorState({ ...errorState, uomError: value === '' });
        break;
    }
  };
  const irrigationTypeDropdownProps = {
    onUpdate: () => {
      setShouldSubmitWork(true);
      makeFormDirty();
    },
    headerColor: FARM_FIELD_LABEL_COLOR,
    defaultDropdownColor: FORM_BG_BORDER,
    label: MODAL_CONTENT.label,
    value: checkTernaryCondition(
      formValue?.irrigationType === '',
      MODAL_CONTENT.dropdown_placeholder,
      formValue?.irrigationType,
    ),
    isDisabled: isFieldDisabled || disableAllFieldActions,
    width: '14rem',
    minWidth: '14rem',
    height: '2rem',
    name: formFieldNames.irrigationType,
    ariaLabel: MODAL_CONTENT.dropdown_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.dropdown_placeholder,
    error: errorState.typeError && formValue.isFetchedFromAPI,
    dropdownlist: irrigationMethodDropDown?.filter(
      (type) =>
        !existingIrrigationIds
          .filter((item) => item !== formValue.irrigationType)
          .includes(type.value),
    ),
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.irrigationType,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'irrigationType',
    dropdownIconColor: BLACK,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
  };
  const uomTypeDropdownProps = {
    headerColor: FARM_FIELD_LABEL_COLOR,
    defaultDropdownColor: FORM_BG_BORDER,
    label: MODAL_CONTENT.uom_label,
    value: checkTernaryCondition(
      formValue?.uom === '',
      MODAL_CONTENT.uom_placeholder,
      +formValue?.uom,
    ),
    isDisabled:
      isEmpty(formValue?.irrigationType) ||
      isFieldDisabled ||
      disableAllFieldActions,
    width: '8rem',
    minWidth: '8rem',
    height: '2rem',
    name: formFieldNames.uom,
    ariaLabel: MODAL_CONTENT.uom_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.uom_placeholder,
    error: errorState.uomError && formValue.isFetchedFromAPI,
    errorMessage: '',
    dropdownlist: uomOptions(
      findObjectWithKey(cropAndLandUse, IRRIGATION_VOLUME).IRRIGATION_VOLUME,
    ),
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.uom,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'uom',
    dropdownIconColor: BLACK,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
    onUpdate: () => {
      setShouldSubmitWork(true);
      makeFormDirty();
    },
  };
  const submitBtnHandler = () => {
    setErrorState(errorSetter(formValue));
  };

  useEffect(() => {
    if (irrigationMethodDropDown?.length > 0) {
      const newIrrigationType = formValue?.irrigationType;
      const value = irrigationMethodDropDown.find(
        (irrigationMethod) => irrigationMethod?.value === newIrrigationType,
      )?.label;
      if (value === MODAL_CONTENT.noneOption) {
        setFormValue({
          ...irrigationTypeFormInitialData,
          irrigationType: newIrrigationType,
          id: formValue.id,
          isFetchedFromAPI: formValue.isFetchedFromAPI,
        });
        setErrorState(modalErrorInitialState);
      }
    }
  }, [formValue?.irrigationType, irrigationMethodDropDown]);

  useEffect(() => {
    if (submitData?.editMode === true && formValue.isFetchedFromAPI) {
      submitBtnHandler();
    }
  }, [submitData]);

  return (
    <FormWrapper alignItem="center">
      <FormWrapper>
        <FieldSectionWrapper>
          <DropDown {...irrigationTypeDropdownProps} />
        </FieldSectionWrapper>
        {!(
          irrigationMethodDropDown.find(
            (irrigationMethod) =>
              irrigationMethod?.value === formValue?.irrigationType,
          )?.label === MODAL_CONTENT.noneOption
        ) && (
          <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
            <InputField
              isDisabled={
                isEmpty(formValue?.irrigationType) ||
                isFieldDisabled ||
                disableAllFieldActions
              }
              headerColor={FARM_FIELD_LABEL_COLOR}
              width="8.75rem"
              required
              label={MODAL_CONTENT.irrigation_rate_label}
              name={formFieldNames.irrigationRate}
              value={formValue?.irrigationRate}
              onUpdate={(e) => {
                const value = e.target.value;
                if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
                  customFieldChangeHandler(
                    formFieldNames.irrigationRate,
                    addZeroBeforeDot(e.target.value),
                  );
                  setErrorState({
                    ...errorState,
                    rateError: e.target.value === '',
                  });
                  setShouldSubmitWork(true);
                  makeFormDirty();
                }
              }}
              placeholder={MODAL_CONTENT.irrigation_rate_placeholder}
              primaryError={errorState.rateError && formValue.isFetchedFromAPI}
              maxLength={100}
              minWidth="8.75"
              labelMarginTop="0.5rem"
              labelMarginBottom="0.25rem"
              rightSideLabelFormLabelMargin="0"
              category="long-numbers-field"
            />
            <DropDown {...uomTypeDropdownProps} />
          </FieldSectionWrapper>
        )}
      </FormWrapper>
      {!isFieldDisabled && !disableAllFieldActions && (
        <DeleteIconWithHoverEffect
          data-testid={`delete-icon-irrigation`}
          style={{ ...deleteIconSx, paddingTop: '2rem' }}
          onClick={() => {
            handlePracticeTypeDelete(
              formValue.isFetchedFromAPI && !isNaN(formValue.id),
              parentId,
              formValue.id,
              'irrigationList',
              'deletedIrrigationList',
              {
                isNone: hasOnlyOneItem,
                formData: {
                  ...formValue,
                  irrigationType: '',
                  uom: '',
                  irrigationRate: '',
                },
              },
            );
            makeFormDirty();
          }}
        />
      )}
    </FormWrapper>
  );
};

IrrigationModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
    data: PropTypes.shape({
      id: PropTypes.string,
      irrigationType: PropTypes.string,
      irrigationRate: PropTypes.string,
      uom: PropTypes.string,
      isFetchedFromAPI: PropTypes.bool,
    }),
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  hasOnlyOneItem: PropTypes.bool,
  parentId: PropTypes.string,
  existingIrrigationIds: PropTypes.array,
  setShouldSubmitWork: PropTypes.func,
  makeFormDirty: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
};
export default IrrigationModal;
