import { Container } from '@mui/material';
import styled from 'styled-components';
import { COOL_GRAY, QUILL_GRAY, RED } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const FileName = styled.div`
  padding: 0.25rem 0.5rem;
  border: ${(props) =>
    checkTernaryCondition(
      props.isError,
      `1px solid ${RED}`,
      `1px solid ${QUILL_GRAY}`,
    )};
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  height: 1.688rem;
  display: flex;
  align-items: center;

  width: 26.25rem;
`;
export const FileNameText = styled.div`
  color: ${COOL_GRAY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  height: 1.688rem;
`;
export const IconWrapper = styled(Container)(({ mouseEnteredDeleteIcon }) => ({
  height: '1.5rem',
  width: '1.5rem',
  padding: '0rem',
  margin: '0rem',
  cursor: checkTernaryCondition(mouseEnteredDeleteIcon, 'pointer', 'default'),
}));
