import {
  ErroWrapper,
  FormFields,
  FormLabels,
  PaymentWapper,
} from './SaleProjectSubsection.style';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { clearField, PAGE_CONTENT } from './SaleProjectSubsection.content';
import { useContext, useEffect } from 'react';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import { uniqueId } from 'utils/uniqueIdGenerator';
import SaleVintageSubsection from 'pages/AddSaleTransaction/components/SaleVintageSubsection/SaleVintageSubsection.react';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import { BLUE } from 'theme/GlobalColors';
import RemoveButton from 'components/RemoveButton/RemoveButton.react';

const SaleProjectSubsection = ({
  projectObj,
  onUpdate,
  onDelete,
  isEdit,
  viewMode,
  paymentObj,
  projectList,
}) => {
  const { projectNames, vintageDates, fetchSaleProjects, fetchVintageDates } =
    useTransactionDetails();
  const { markFormDirty } = useContext(EditPurchaseTransactionFormDirtyContext);

  const projectNameChangeHandler = (e, newValue, reason) => {
    if (reason !== clearField) {
      fetchVintageDates(newValue.internalProjectId);
      onUpdate({
        ...projectObj,
        projectName: newValue,
        projectNameError: newValue !== '' && { flag: false, message: '' },
        paymentDetails: [
          ...projectObj.paymentDetails.map((payment) => ({
            ...payment,
            vintage: '',
            vintageError: false,
            vintageDuplicateError: false,
          })),
        ],
        existingProjectError: false,
      });
    } else {
      onUpdate({
        ...projectObj,
        projectName: '',
        projectNameError: newValue !== '' && { flag: false, message: '' },
        paymentDetails: [
          ...projectObj.paymentDetails.map((payment) => ({
            ...payment,
            vintage: '',
          })),
        ],
        existingProjectError: false,
      });
    }
    markFormDirty();
  };

  const paymentUpdateHandler = (paymentObj) => {
    onUpdate({
      ...projectObj,
      paymentDetails: projectObj.paymentDetails.map((payment) =>
        payment.id === paymentObj.id ? paymentObj : payment,
      ),
    });
  };

  useEffect(() => {
    if (isEdit || viewMode) {
      fetchVintageDates(projectObj.projectName.internalProjectId);
    }
  }, []);

  const addSectionHandler = () => {
    onUpdate({
      ...projectObj,
      paymentDetails: [
        ...projectObj.paymentDetails,
        { ...paymentObj, id: uniqueId() },
      ],
    });
    markFormDirty();
  };

  const vintageDeleteHandler = (id) => {
    onUpdate({
      ...projectObj,
      paymentDetails: [
        ...projectObj.paymentDetails.filter((payment) => payment.id !== id),
      ],
    });
  };
  return (
    <>
      <div>
        <FormFields>
          <FormLabels
            iserror={
              projectObj.projectNameError.flag ||
              projectObj.existingProjectError
            }>
            {PAGE_CONTENT.project_name_label}
            {projectList?.length > 1 && !viewMode && (
              <RemoveButton
                label={PAGE_CONTENT.remove_project_text}
                clickHandler={() => {
                  onDelete(projectObj.id);
                  markFormDirty();
                }}
                labelColor={BLUE}
              />
            )}
          </FormLabels>
          <Autocomplete
            disabled={viewMode || projectObj.isApiPopulated}
            options={projectNames}
            onChange={projectNameChangeHandler}
            value={projectObj.projectName || null}
            onInputChange={(e, newValue) => {
              fetchSaleProjects(newValue);
            }}
            data-testid="project"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                error={
                  projectObj.projectNameError.flag ||
                  projectObj.existingProjectError
                }
                inputProps={{
                  style: { paddingLeft: '0.625rem' },
                  ...params.inputProps,
                }}
                placeholder={PAGE_CONTENT.project_name_placeholder}
              />
            )}
          />
          {projectObj.projectNameError.flag && (
            <ErroWrapper>{projectObj.projectNameError.message}</ErroWrapper>
          )}
          {projectObj.existingProjectError && (
            <ErroWrapper>{PAGE_CONTENT.project_duplicate_error}</ErroWrapper>
          )}
        </FormFields>
        <div>
          <PaymentWapper>
            {projectObj.paymentDetails.map((payment, index) => {
              const keyVal = index;
              return (
                <SaleVintageSubsection
                  paymentObj={payment}
                  projectObj={projectObj}
                  vintageNo={index}
                  isEdit={isEdit}
                  viewMode={viewMode}
                  onUpdate={paymentUpdateHandler}
                  key={payment.id}
                  vintageYears={vintageDates}
                  onDelete={vintageDeleteHandler}
                  indexNumber={keyVal}
                />
              );
            })}
            <AddSectionButton
              onClickHanlder={addSectionHandler}
              hiddenFlag={viewMode}
              label={PAGE_CONTENT.add_vintage_label}
            />
          </PaymentWapper>
        </div>
      </div>
    </>
  );
};

SaleProjectSubsection.propTypes = {
  projectObj: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  isEdit: PropTypes.bool,
  viewMode: PropTypes.string,
  paymentObj: PropTypes.object,
  projectList: PropTypes.array,
};
export default SaleProjectSubsection;
