export const PROJECT_GENERAL_SETTING_TAB_CONTENT = {
  methodology: 'Methodology',
  measurement_and_monitoring: 'Measurement + monitoring',
  info_content:
    'Once submitted you may no longer update or delete the project cycle schedule. Please make sure final edits are made prior to submittal.',
  name: 'Name',
  origination_engine: 'Origination engine',
  commercialization: 'Commercialization',
  credit_issuance: 'Credit issuance',
  sector: 'Sector',
  aflou_sector_sub_types: 'AFOLU sector sub-types',
  project_activities: 'Project activities',
  location: 'Location',
  description: 'Description',
};
