import moment from 'moment';

export const dateFormatter = (date) => {
  if (!date) return null;
  const inputDate = date.slice(0, 10);

  const [year, month, day] = inputDate.split('-');

  const result = [month, day, year].join('/');

  return result;
};

export const itcToDateFormatter = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const dateTimeFormatter = (date) => {
  const inputTime = date.slice(11, 16);
  const [hour24, minute] = inputTime.split(':');
  const period = +hour24 < 12 ? 'AM' : 'PM';
  const hour12 = +hour24 % 12 || 12;
  return `${hour12}:${minute} ${period}`;
};

export const usToUkDateFormatter = (date) => {
  return `${date.slice(6)}-${date.slice(0, 2)}-${date.slice(3, 5)}`;
};

export const findPastMonthsDate = (month) => {
  return new Date(new Date().setMonth(new Date().getMonth() - month));
};
export const utcToUKDateFormat = (date) => {
  return date.toISOString().split('T')[0];
};

//Format MM/DD/YY
export const usDateFormatter = (date) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
};
