import { useContext } from 'react';
import { PAGE_CONTENT } from '../../ParticipantDataCollection.content';
import Button from '@mui/material/Button';
import { fieldAddTypes } from './FieldAddTypeUpdaters.content';
import { checkTernaryCondition } from 'utils/helper';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import {
  ButtonsWrapper,
  InstructionText,
  MainWrapper,
  getButtonStyle,
  getIconStyle,
} from './FieldAddTypeUpdaters.style';
import { ReactComponent as DrawBoundaryIcon } from '../../../../assets/icons/drawBoundaryButtonIcon.svg';
import { ReactComponent as UploadFileIcon } from '../../../../assets/icons/file_upload.svg';
import AddingBoundaryTooltip from './AddingBoundaryTooltip/AddingBoundaryTooltip.react';
import { useNavigate } from 'react-router';
import { pathName } from 'Routes/routes.const';

const FieldAddTypeUpdaters = () => {
  const {
    selectedFieldAddType,
    setSelectedFieldAddType,
    setShapeFileUploadModalOpen,
    currentFarmId,
    expandedFarmId,
    shouldNavigateToLandingPage,
  } = useContext(participantInfoContext);
  const navigate = useNavigate();

  const getVariant = (addType) => {
    return checkTernaryCondition(
      addType === selectedFieldAddType,
      'contained',
      'outlined',
    );
  };

  const getButtonContent = (addType) => {
    switch (addType) {
      case fieldAddTypes[0]:
        return (
          <>
            <DrawBoundaryIcon
              style={getIconStyle(selectedFieldAddType === fieldAddTypes[0])}
            />
            {addType}
          </>
        );

      // Note: Hiding drop pin for now, can be enabled later
      // case fieldAddTypes[1]:
      //   return (
      //     <>
      //       <DropPinIcon
      //         style={getIconStyle(selectedFieldAddType === fieldAddTypes[1])}
      //       />
      //       {addType}
      //     </>
      //   );
      case fieldAddTypes[2]:
        return (
          <>
            <UploadFileIcon
              style={getIconStyle(selectedFieldAddType === fieldAddTypes[2])}
            />
            {addType}
          </>
        );
      default:
        return addType;
    }
  };

  const handlefieldAddTypeChange = (addType) => {
    if (addType === fieldAddTypes[2]) {
      setShapeFileUploadModalOpen(true);
      setSelectedFieldAddType(addType);
    } else {
      setSelectedFieldAddType(
        checkTernaryCondition(addType === selectedFieldAddType, null, addType),
      );
    }
  };

  // Note: Hiding drop pin for now, can be enabled later
  const updatedFieldAddTypes = fieldAddTypes.filter(
    (addType) => addType !== 'Drop pin',
  );

  return (
    <MainWrapper>
      <InstructionText>
        {PAGE_CONTENT.fieldAddTypeInstructionText} <AddingBoundaryTooltip />
      </InstructionText>
      <ButtonsWrapper>
        {updatedFieldAddTypes.map((addType) => {
          return (
            <Button
              data-testid={`${addType}-btn`}
              key={addType}
              disabled={currentFarmId === -1 || expandedFarmId === -1}
              sx={getButtonStyle(addType === selectedFieldAddType)}
              variant={getVariant(addType)}
              onClick={async () => {
                const shouldNavigate = await shouldNavigateToLandingPage();
                if (shouldNavigate) {
                  navigate(pathName.participant.landingPage);
                  return;
                }
                handlefieldAddTypeChange(addType);
              }}>
              {getButtonContent(addType)}
            </Button>
          );
        })}
      </ButtonsWrapper>
    </MainWrapper>
  );
};

export default FieldAddTypeUpdaters;
