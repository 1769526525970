import styled from 'styled-components';
import { GLOBAL_BLUE } from 'theme/GlobalColors';

export const roleNumberstyle = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.188rem',
  display: 'flex',
  alignItems: 'center',
  color: GLOBAL_BLUE,
};

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0rem 0rem 0.5rem 0rem;
`;

export const clearIconStyle = { fontSize: '11px', cursor: 'pointer' };
