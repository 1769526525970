export const menuOptions = {
  updateUserStatus: 'Update user status',
  initiateEnrollment: 'Initiate enrollment',
  edit: 'Edit',
};

export const MENU_ITEM_INDICATORS = {
  EDIT: 'edit',
  UPDATE_USER_STATUS: 'updateUserStatus',
  DELETE: 'delete',
  INITIATE_ENROLLMENT: 'initiateEnrollment',
  REVIEW_ENROLLMENT: 'reviewEnrollment',
  REVIEW_BASELINE_REPORTING: 'reviewBaselineReporting',
  INITIATE_BASELINE_REPORTING: 'initiateBaselineReporting',
  HAS_ENABLED_ADD_CONTRACT: 'hasEnabledAddContract',
};
export const mockReviewBaseline = 'Review baseline';
