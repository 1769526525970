import { uniqueId } from 'utils/uniqueIdGenerator';

export const ADD_CROP_CONTENT = {
  add_crop: 'Add Crop',
  crop_season: 'Crop Season',
  duplicate_crop_error:
    'Please review the prior entries and update the details on the form to submit a new unique entry.',
};

export const cropSeasons = [
  {
    itemLabel: 'Summer',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'Winter',
    selected: false,
    isDisabled: false,
  },
];

export const productionPurpose = [
  {
    itemLabel: 'Cash crop',
    selected: false,
    isDisabled: false,
    value: 'CASH_CROP',
  },
  {
    itemLabel: 'Cover crop',
    selected: false,
    isDisabled: false,
    value: 'COVER_CROP',
  },
  {
    itemLabel: 'Fallow',
    selected: false,
    isDisabled: false,
    value: 'FALLOW',
  },
];
/*export const getProductionPurposeRadioBtnValue = (value) => {
  switch (value) {
    case productionPurpose[0].itemLabel:
      return productionPurpose[0].value;
    case productionPurpose[1].itemLabel:
      return productionPurpose[1].value;
    case productionPurpose[2].itemLabel:
      return productionPurpose[2].value;
    default:
      return productionPurpose;
  }
};*/
export const initialCropTypeData = {
  sampleId: '',
  cropSampleId: '',
  cropId: '',
  annualYield: '',
  plantingDensity: '',
  cropValue: '',
  plantingDensityUom: '',
  cropDepth: '',
  cropDepthUom: '',
  cropPlantDate: '',
  cropTerminationDate: '',
  cropArea: '',
  cropPlannedManagementTypeId: '',
  productionPurpose: '',
  annualYieldUom: '',
  cropAreaUom: 'acre',
  cropSeason: '',
  agricultureResiduesManaged: '',
  cropResidueRemoved: '',
  cropTypeDropDownBasedOnProductionPurpose: [],
  irrigationList: [
    {
      irrigationRate: '',
      irrigationType: '',
      uom: '',
      id: `${uniqueId()}+i`,
    },
  ],
  soilAmendmentList: [],
};
