import styled from 'styled-components';
import { WHITE } from 'theme/GlobalColors';

export const SideNavWrapper = styled.div`
  min-height: calc(100% - 46px);
  width: 13.75rem;
  background-color: ${WHITE};
  position: fixed;
  left: 0;
  z-index: 2;
  display: flex;
  display: -webkit-flex;
  overflow-y: auto;
  height: 36vh;
`;

export const ClientLogoContainer = styled.div`
  padding: 1.5rem 1.688rem 1rem 1.5rem;
  text-align: center;

  img: {
    height: 26px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 24px);
  margin-top: 0.5rem;
  flex: 1;
`;

export const logoImgStyle = { maxHeight: '45px' };
