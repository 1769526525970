export const PROJECT_DESIGN_SETTING_TAB_CONTENT = {
  methodology: 'Methodology',
  measurement_and_monitoring: 'Measurement + monitoring',
  custom_methodology_name: 'Custom methodology name',
  custom_methodology: 'Custom methodology',
  reference_methodology: 'Reference methodology',
  model_provider: 'Model provider',
  info_content:
    'Once submitted you may no longer update or delete the project cycle schedule. Please make sure final edits are made prior to submittal.',
};
