import {
  BackButtonWrapper,
  columnFlexStyle,
  HeaderWrapper,
  MainSectionWrapper,
  MainWrapper,
  MenuWrapper,
  ParticipantInfoWrapper,
  ProjectNameWrapper,
  SectionWrapper,
  backButtonStyle,
  leftSectionStyle,
} from './ParticipantDataCollection.style';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { PAGE_CONTENT } from './ParticipantDataCollection.content';
import { pathName } from 'Routes/routes.const';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { TruncatedWrapper } from 'theme/GlobalStyles';
import useBeforeUnloadEvent from 'hooks/useBeforeUnloadEvent';
import EnrollmentCompleteModal from './components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import UnsavedChangesModal from './components/UnsavedChangesModal/UnsavedChangesModal.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import Button from '@mui/material/Button';
import { ReactComponent as BackArrow } from 'assets/icons/Round.svg';
import { FETCH_PROJECT_LIFECYCLES } from 'urls';
import axios from 'axios';
import moment from 'moment';
import Loader from 'components/Loader/Loader.react';

const ParticipantDataCollection = () => {
  const {
    sectionList,
    rightSectionId,
    openMapView,
    currentFarmId,
    setFarmInfoCurrentTab,
    setShowFieldInfoForm,
    userActivityDetected,
    modalData,
    setModalData,
    setSelectedFieldId,
    setCurrentFarmLocation,
    hasNotApprovedFarmOrFields,
    setSelectedFieldAddType,
    participantProfileStatus,
    farmInfo,
    setStatusList,
    farmListStatus,
    unsavedChangesModalOpenProps,
    closeUnsavedChangesModal,
    handleLeaveWithoutSave,
    handleSaveAndLeave,
    addFarmBtnLoader,
    shouldNavigateToLandingPage,
  } = useContext(participantInfoContext);
  const [rightSectionForm, setRightSectionForm] = useState('');
  const [projectCycleData, setProjectCycleData] = useState({
    cycleStartDate: '',
    cycleEndDate: '',
  });
  const [cycleYear, setCycleYear] = useState('');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const { userRoles } = useContext(userAuthRolesContext);

  /**
   * @description Using the flag 'userActivityDetected' we are tracking if there was a change in the web forms, if there is a change we add a 'beforeunload' event which gets triggered when user tries to close the browser window/tab, it provides a warning to the user to save changes before closing the browser.
   */
  useBeforeUnloadEvent(
    userActivityDetected,
    PAGE_CONTENT.unsavedChangesPromptMsg,
  );

  useEffect(() => {
    if (rightSectionId) {
      setRightSectionForm(
        sectionList?.filter((item) => {
          return item.id === rightSectionId;
        }),
      );
    }
  }, [sectionList, rightSectionId]);

  useEffect(() => {
    let finalData;
    if (userRoles[0] === PAGE_CONTENT.PARTICIPANT_CONTRIBUTOR) {
      finalData = [];
    } else {
      finalData = [participantProfileStatus, farmListStatus];
    }
    farmInfo.map((farm) => {
      finalData.push(farm?.status);
      if (farm.fields?.length === 0) {
        finalData.push('In Progress');
      } else {
        farm.fields?.forEach((field) => finalData.push(field?.status));
      }
    });
    if (finalData.every((element) => element === PAGE_CONTENT.completed)) {
      setStatusList(PAGE_CONTENT.completed);
    } else {
      setStatusList(PAGE_CONTENT.in_progress);
    }
  }, [farmInfo, participantProfileStatus, farmListStatus]);

  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: false } },
    );
  };

  const onClickBackButton = () => {
    if (
      [PAGE_CONTENT.PARTICIPANT_CONTRIBUTOR, PAGE_CONTENT.PARTICIPANT].includes(
        userRoles[0],
      )
    ) {
      navigate(`${pathName.participant.landingPage}`);
      return;
    }
    navigate(
      `${pathName.participant.menuPage}?projectid=${projectId}&participantid=${participantId}&enrollmentInstanceId=${enrollmentInstanceId}&projectcycleid=${projectCycleId}`,
    );
  };

  const fetchLifeCycleDates = () => {
    axios.get(`${FETCH_PROJECT_LIFECYCLES}/${projectId}`).then((res) => {
      const cycleObject = res.data.filter(
        (item) => +item.projectCycleId === +projectCycleId,
      );
      const cycleYear = moment(cycleObject[0]?.cycleEndDate)
        .format('MM/DD/YYYY')
        ?.slice(6);
      if (cycleObject?.length > 0) {
        setCycleYear(cycleYear);
        setProjectCycleData({
          cycleStartDate: moment(cycleObject[0]?.cycleStartDate).format(
            'MM/DD/YYYY',
          ),
          cycleEndDate: moment(cycleObject[0]?.cycleEndDate).format(
            'MM/DD/YYYY',
          ),
        });
      }
    });
  };

  // Note: reset the modalData flag to false since hasNotApprovedFarmOrFields can be set anytime when the user adds a field/farm even though not completed, the modal may open up since modalData.flag will be true and hasNotApprovedFarmOrFields will be true
  useEffect(() => {
    if (!hasNotApprovedFarmOrFields && modalData?.flag) {
      setModalData({ flag: false, id: 0 });
    }
  }, [hasNotApprovedFarmOrFields, modalData]);

  /**
   * @description While user is drawing a boundary on the map, if user presses 'Delete' or 'Escape' key, we are not breaking the flow of "Draw boundary" by setting fieldAddType to be null. This state will be checked before adding a new boundary through "Draw boundary" method
   */
  useEffect(() => {
    fetchLifeCycleDates();
    const handleKeyDown = (e) => {
      if (e.key === 'Delete' || e.key === 'Escape') {
        setSelectedFieldAddType(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <MainWrapper>
      <Loader loading={addFarmBtnLoader} zIndex={999999} />
      <HeaderWrapper>
        <BackButtonWrapper onClick={onClickBackButton}>
          <BackArrow data-testid="back-icon" />
          <Button sx={backButtonStyle}>{PAGE_CONTENT.back}</Button>
        </BackButtonWrapper>
        <ProjectNameWrapper>
          <TruncatedWrapper title={state?.projectName} width="40rem">
            {state?.projectName + ' - ' + cycleYear || ''}
          </TruncatedWrapper>
          <span>{` (${projectCycleData?.cycleStartDate}`}</span>
          <span>{` - ${projectCycleData?.cycleEndDate})`}</span>
        </ProjectNameWrapper>
      </HeaderWrapper>
      <MainSectionWrapper>
        <ParticipantInfoWrapper>
          <MenuWrapper style={leftSectionStyle}>
            {sectionList?.map((item) => {
              return item.leftSection;
            })}
          </MenuWrapper>
          <div style={columnFlexStyle}>
            <SectionWrapper mapView={openMapView}>
              {rightSectionForm[0]?.rightSection}
            </SectionWrapper>
          </div>
        </ParticipantInfoWrapper>
      </MainSectionWrapper>
      <EnrollmentCompleteModal
        modalData={{
          ...modalData,
          // show modal only if hasNotApprovedFarmOrFields is also true
          flag: modalData.flag && hasNotApprovedFarmOrFields,
        }}
        modalCloseHandler={() => {
          setModalData({ flag: false, id: 0 });
        }}
        title={PAGE_CONTENT.submit_complete_modal_title}
        contentText={PAGE_CONTENT.submit_complete_modal_content}
        firstSubmitBtnLabel={PAGE_CONTENT.continue_btn_label}
        secondSubmitBtnLabel={PAGE_CONTENT.review_btn_label}
        cancelBtnLabel={PAGE_CONTENT.cancel_btn_label}
        firstSubmitBtnHandler={() => {
          setSelectedFieldId(null);
          setShowFieldInfoForm(false);
          setFarmInfoCurrentTab(1);
          setCurrentFarmLocation(currentFarmId);
          setModalData({ flag: false, id: 0 });
        }}
        secondSubmitBtnHandler={navigateHandler}
        cancelHandler={() => {
          setModalData({ flag: false, id: 0 });
        }}
      />

      <UnsavedChangesModal
        open={
          unsavedChangesModalOpenProps?.isOpen &&
          unsavedChangesModalOpenProps?.triggeredFrom !== 'Tab'
        }
        handleClose={closeUnsavedChangesModal}
        handleLeaveWithoutSave={async () => {
          const shouldNavigate = await shouldNavigateToLandingPage();
          if (shouldNavigate) {
            navigate(pathName.participant.landingPage);
            return;
          }
          handleLeaveWithoutSave();
        }}
        handleSaveAndLeave={async () => {
          const shouldNavigate = await shouldNavigateToLandingPage();
          if (shouldNavigate) {
            navigate(pathName.participant.landingPage);
            return;
          }
          handleSaveAndLeave();
        }}
      />
    </MainWrapper>
  );
};

export default ParticipantDataCollection;
