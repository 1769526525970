import React, { useEffect, useState } from 'react';
import './AddProject.scss';
import {
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  Input,
  Radio,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import {
  PAGE_CONTENT,
  getValueOrDefault,
  getDefultDropdownValue,
  getRegionName,
  CHARACTER_COUNT,
} from './AddProject.content';
import { useForm } from '../../hooks/useForm';
import { useProjectDetails } from '../../hooks/useProjectDetails';
import { useNavigate, useLocation } from 'react-router';
import AddProjectBackButton from './AddProjectBackButton';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { PROJECT_NEW } from 'urls';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '../../components/Chips/CustomChip.react';
import {
  scroll,
  checkTernaryCondition,
  isNotNill,
  isNotEmpty,
  isEmpty,
} from 'utils/helper';
import { pathName } from 'Routes/routes.const';
import { usDateFormatter } from '../../utils/dateFormatter';
import { getMenuItemStyles } from 'theme/GlobalStyles';

const AddProject = () => {
  const navigate = useNavigate();
  const {
    formValue: projectForm,
    formFieldChangeHandler,
    customFieldChangeHandler,
    setFormValue,
  } = useForm({
    registries: '',
    newProjectId: false,
    projectNatureType: '',
    projectId: '',
    projectName: '',
    developerName: '',
    projectdesc: '',
    projectNotes: '',
    projectType: '',
    projectSubType: '',
    impStatus: null,
    methodology: '',
    reviewStatus: '',
    priceAssumptionType: '',
    tags: [],
  });

  const {
    registryData,
    status,
    submitLoading,
    countryList,
    regionList,
    tags,
    priceAssumptionTypes,
    projectTypes,
    setInternalProjectForm,
    projectRegistryIdOptions,
    setProjectRegistryIdOptions,
    projectRegistryHandler,
    developerProjectName,
    setCompleteProjectRegistryValue,
    existingNameError,
    registryProjectIDError,
    SetRegistryProjectIDError,
    setExistingNameError,
    setEditProjectForm,
    fetchAllTags,
    registryHandler,
    methodology,
  } = useProjectDetails();

  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [internalProjectId, setInternalProjectId] = useState();
  const [registryProjectId, setRegistryProjectId] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [noProjectData, setNoProjectData] = useState('');
  const [onLoadNotRegisterd, setonLoadNotRegistered] = useState(false);
  const [tagsInput, setTagsInput] = useState('');
  const [editProjectName, setEditProjectName] = useState('');
  const [onSubmit, setOnSubmit] = useState(true);
  const [selectRegistryFirst, setSelectRegistryFirst] = useState(false);
  const [country, setCountry] = useState([]);
  const [region, setregion] = useState([]);
  const [descCount, setDescCount] = useState(0);
  const [notesCount, setNotesCount] = useState(0);
  const [disableLinked, setDisableLinked] = useState(false);
  const [dirty, setDirty] = useState(false);
  const { state } = useLocation(); // for previous pathname
  //Error states
  const [nameError, setNameError] = useState(false);
  const [implementationError, setImplementationError] = useState(false);
  const [reviewError, setReviewError] = useState(false);
  const [priceAssumptionError, setPriceAssumptionError] = useState(false);
  const [registryError, setRegistryError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [notesError, setNotesError] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [isDeletedRecord, setIsDeleted] = useState();
  const [lastUpdatedOn, setLastUpdatedon] = useState();

  useEffect(() => {
    if (countryList && countryList.length > 0) {
      const modifiedCountry = countryList.map((c) => c['countryName']);
      setCountry(modifiedCountry);
    }
    if (regionList && regionList.length > 0)
      setregion(regionList.map((r) => r['regionName']));
  }, [regionList, countryList]);

  useEffect(() => {
    if (descCount > CHARACTER_COUNT) {
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
  }, [descCount]);

  useEffect(() => {
    if (notesCount > CHARACTER_COUNT) {
      setNotesError(true);
    } else {
      setNotesError(false);
    }
  }, [notesCount]);

  const markFormDirty = () => setDirty(true);

  const fetchEditData = () => {
    if (id) {
      setEditMode(true);
      setEditLoader(true);
      axios
        .get(`${PROJECT_NEW}/${id}`)
        .then((response) => {
          setNoProjectData('No Project data exists');
          if (response.data) {
            if (
              isEmpty(response.data.projectName) &&
              response.data.impStatus &&
              isNotEmpty(response.data.reviewStatus)
            ) {
              setDisableSubmit(true);
            }
            setFormValue({
              ...projectForm,
              projectId: checkTernaryCondition(
                response.data.projectRegistryId !== '-1',
                response.data.projectRegistryId.substring(2) +
                  '-' +
                  response.data.projectName,
                '',
              ),
              newProjectId: checkTernaryCondition(
                response.data.projectRegistryId === '-1',
                true,
                false,
              ),
              registries: getDefultDropdownValue(response.data.registryId),
              projectName: getValueOrDefault(response.data.projectName),
              developerName: getValueOrDefault(response.data.projectDeveloper),
              projectSubType: getValueOrDefault(response.data.projectType),
              projectdesc: getValueOrDefault(response.data.projectDescription),
              projectNotes: getValueOrDefault(response.data.projectNotes),
              impStatus: getValueOrDefault(response.data.projectStatus),
              methodology: getValueOrDefault(response.data.projectMethodology),
              country: getValueOrDefault(response.data.projectCountry),
              region: getValueOrDefault(response.data.projectRegion),
              reviewStatus: getValueOrDefault(
                response.data.internalReviewStatus,
              ),
              tags: checkTernaryCondition(
                response.data.tags,
                [...response.data.tags],
                [],
              ),
              priceAssumptionType: checkTernaryCondition(
                response.data.projectTypePriceAssumptionGroup,
                response.data.projectTypePriceAssumptionGroup,
                '',
              ),
            });
            setLastUpdatedon(response.data.updatedDate);
            setIsDeleted(
              checkTernaryCondition(response.data.isActive == -1, true, false),
            );
            setDescCount(response.data.projectDescription.length);
            setNotesCount(response.data.projectNotes.length);
            setInternalProjectId(id);
            setRegistryProjectId(response.data.registryProjectId);
            setEditProjectName(response.data.projectName);
            setonLoadNotRegistered(
              checkTernaryCondition(
                response.data.projectRegistryId === '-1',
                true,
                false,
              ),
            );

            if (isNotNill(response.data.registryProjectId)) {
              setDisableLinked(true);
            }
            setSelectRegistryData(response.data.registryId);
            setNoProjectData('');
          }
        })
        .catch(() => {
          setNoProjectData('Project does not exist');
        })
        .finally(() => {
          setEditLoader(false);
        });
    }
  };
  const setSelectRegistryData = (registryValue) => {
    if (isNotNill(registryValue)) {
      setSelectRegistryFirst(true);
      registryHandler(registryValue);
    }
  };

  useEffect(() => {
    if (countryList.length > 0) {
      fetchEditData();
    }
    fetchAllTags();
  }, [countryList]);

  //prevent duplicate new values when user types fast
  useEffect(() => {
    if (tags.indexOf(newTag) !== -1) {
      setNewTag('');
    }
  }, [tags]);

  useEffect(() => {
    if (
      projectForm.projectId !== '' &&
      developerProjectName.projectName &&
      developerProjectName.developerName
    ) {
      setFormValue({
        ...projectForm,
        projectName: developerProjectName.projectName,
        developerName: developerProjectName.developerName,
        projectSubType: developerProjectName.projectType,
        impStatus: developerProjectName.projectStatus,
        methodology: developerProjectName.projectMethodology,
        country: developerProjectName.projectCountry,
        region: developerProjectName.projectRegion,
        registries: registryData.filter(
          (data) => data.name === developerProjectName.registry,
        )[0].value,
      });

      if (nameError && developerProjectName.projectName !== '') {
        setNameError(false);
      }
      if (existingNameError.flag && developerProjectName.projectName !== '') {
        setExistingNameError({ flag: false, message: '' });
      }
      if (implementationError && developerProjectName.projectStatus !== '') {
        setImplementationError(false);
      }
    }
  }, [developerProjectName]);

  // to handle disbale of submit button in edit mode
  useEffect(() => {
    if (editMode) {
      if (!nameError && !implementationError && !reviewError) {
        setDisableSubmit(false);
      }
    }
  }, [nameError, implementationError, reviewError]);

  // Review status radio handler
  const reviewStatuscontrolProps = (item) => ({
    checked: projectForm.reviewStatus === item,
    onChange: projectReviewStatusHandler,
    value: item,
    name: 'reviewStatus',
    inputProps: { 'aria-label': item },
  });

  const projectReviewStatusHandler = (e) => {
    formFieldChangeHandler(e);
    setReviewError(false);
  };

  const conformProjectSubmit = () => {
    if (editMode) {
      setEditProjectForm(
        Object.assign(
          {},
          { ...projectForm, internalProjectId, registryProjectId },
        ),
      );
    } else {
      setInternalProjectForm(Object.assign({}, projectForm));
    }
  };

  const addTagshandler = (e) => {
    if (e.target.value.length > 0) {
      if (projectForm.tags.indexOf(e.target.value) === -1) {
        setFormValue({
          ...projectForm,
          tags: [...projectForm.tags, e.target.value],
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case ',':
      case ' ':
        {
          e.preventDefault();
          e.stopPropagation();
          addTagshandler(e);
          setTagsInput('');
        }
        break;
    }
  };

  const clearRegistryData = () => {
    setFormValue({
      ...projectForm,
      projectName: '',
      developerName: '',
      impStatus: '',
      projectId: null,
      projectSubType: null,
      methodology: '',
      country: null,
      region: null,
    });
    setImplementationError(false);
  };

  const changeRegistry = (value) => {
    if (!onLoadNotRegisterd) {
      setFormValue({
        ...projectForm,
        projectName: '',
        developerName: '',
        registries: value,
        impStatus: '',
        projectId: '',
        projectSubType: null,
        methodology: '',
        country: null,
        region: null,
      });
    }
    setSelectRegistryFirst(true);
    setProjectRegistryIdOptions([]);
    if (value !== '') {
      setRegistryError(false);
    }
    setImplementationError(false);
  };

  const registryIdCheckboxHandler = (e) => {
    if (editMode) {
      setonLoadNotRegistered(true);
    }
    if (projectForm.registries !== '') {
      setSelectRegistryFirst(true);
      registryHandler(projectForm.registries);
    }
    setFormValue({
      ...projectForm,
      newProjectId: e.target.checked,
      projectId: '',
      projectName: '',
      developerName: '',
      projectSubType: null,
      impStatus: null,
      methodology: '',
      country: null,
      region: null,
    });
    setCompleteProjectRegistryValue('');
    setImplementationError(false);
  };

  const RegistryIdHandler = (e) => {
    e !== null && projectRegistryHandler(e.target.value);
    if (e !== null) {
      SetRegistryProjectIDError({ flag: false, message: '' });
      setFormValue({
        ...projectForm,
        newProjectId: false,
      });
    }
  };

  //submit handler
  const submitHandler = () => {
    setOnSubmit(!onSubmit);
    if (!projectForm.projectName.trim()) {
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (!projectForm.impStatus || projectForm.impStatus === '') {
      setImplementationError(true);
    } else {
      setImplementationError(false);
    }
    if (projectForm.reviewStatus === '') {
      setReviewError(true);
    } else {
      setReviewError(false);
    }
    if (projectForm.priceAssumptionType === '') {
      setPriceAssumptionError(true);
    } else {
      setPriceAssumptionError(false);
    }
    if (projectForm.registries === '') {
      setRegistryError(true);
    } else {
      setRegistryError(false);
    }
    if (
      projectForm.projectName.trim() &&
      projectForm.impStatus !== '' &&
      projectForm.reviewStatus !== '' &&
      projectForm.impStatus !== null &&
      projectForm.priceAssumptionType !== '' &&
      !descriptionError &&
      !notesError
    ) {
      finalStepSubmitHandler();
    }
  };
  const finalStepSubmitHandler = () => {
    conformProjectSubmit();
  };

  const customformFieldChangeHandler = (value, reason) => {
    if (value !== null) {
      setCompleteProjectRegistryValue(value);
    }
    if (reason !== 'clear') {
      SetRegistryProjectIDError({ flag: false, message: '' });
      setFormValue({
        ...projectForm,
        newProjectId: false,
        projectId: value,
      });
    } else {
      clearRegistryData();
      setCompleteProjectRegistryValue('');
    }
  };

  //to scroll to error field on submit
  useEffect(() => {
    if (
      onSubmit ||
      !onSubmit ||
      existingNameError.flag ||
      registryProjectIDError.flag
    ) {
      scroll('.Mui-error');
    }
  }, [
    onSubmit,
    existingNameError.flag,
    registryProjectIDError.flag,
    descriptionError,
    notesError,
  ]);

  const onChangeCountry = (countryName) => {
    if (countryName !== null) {
      const regionName = getRegionName(countryName, countryList, regionList);
      setFormValue({
        ...projectForm,
        region: regionName,
        country: countryName,
      });
    } else {
      setFormValue({
        ...projectForm,
        region: getValueOrDefault(null),
        country: countryName,
      });
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={editLoader || submitLoading}>
        <CircularProgress color="success" />
      </Backdrop>
      {
        <AddProjectBackButton
          editMode={editMode}
          statePreviousPath={state}
          isdirty={dirty}
        />
      }
      {noProjectData !== '' ? (
        noProjectData
      ) : (
        <div className="main_section" onChange={markFormDirty}>
          <div
            // className="form_section readonly"
            className={checkTernaryCondition(
              isDeletedRecord,
              'form_section readonly',
              'form_section',
            )}>
            {/* Form heading */}
            <div className="add_project_heading">
              {checkTernaryCondition(
                editMode,
                `Edit ${editProjectName}`,
                PAGE_CONTENT.form_heading,
              )}
              {isDeletedRecord && (
                <div className="deletedStatus">{`${PAGE_CONTENT.deletedLabel} 
                  ${
                    lastUpdatedOn !== null && usDateFormatter(lastUpdatedOn)
                  }`}</div>
              )}
            </div>
            <div
              className={checkTernaryCondition(
                disableLinked,
                'form_fields readonly',
                'form_fields',
              )}>
              <p
                className={checkTernaryCondition(
                  registryError,
                  'field_label error',
                  'field_label',
                )}>
                {PAGE_CONTENT.registry_label}
              </p>
              <Select
                value={projectForm.registries}
                onChange={(e) => {
                  SetRegistryProjectIDError({ flag: false, message: '' });
                  setFormValue({
                    ...projectForm,
                    projectId: '',
                    registries: e.target.value,
                  });
                  registryHandler(e.target.value);
                  changeRegistry(e.target.value);
                  markFormDirty();
                }}
                className="registry_dropdown"
                name="registries"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                input={<OutlinedInput />}
                renderValue={
                  projectForm.registries !== ''
                    ? undefined
                    : () => (
                        <div className="registry_select_placeholder">
                          {PAGE_CONTENT.registry_placeholder}
                        </div>
                      )
                }>
                {registryData.map((registry) => (
                  <MenuItem
                    key={registry.value}
                    value={registry.value}
                    sx={getMenuItemStyles}>
                    {registry.label}
                  </MenuItem>
                ))}
              </Select>
              <br></br>
              {registryError && (
                <span className="error_message">
                  {PAGE_CONTENT.project_registry_error}
                </span>
              )}
            </div>
            <div
              className={checkTernaryCondition(
                (projectForm.newProjectId && selectRegistryFirst) ||
                  !selectRegistryFirst ||
                  (editMode && !onLoadNotRegisterd && !selectRegistryFirst) ||
                  disableLinked,
                'form_fields registry_id_field readonly',
                'form_fields registry_id_field',
              )}>
              <p
                className={checkTernaryCondition(
                  registryProjectIDError.flag,
                  'field_label error',
                  'field_label',
                )}>
                {PAGE_CONTENT.projectid_label}
              </p>
              <Autocomplete
                options={projectRegistryIdOptions.map(
                  (data) => data.registryOptions,
                )}
                id="disable-close-on-select"
                value={projectForm.projectId}
                loading={true}
                loadingText="Partially enter your registry ID"
                onChange={(e, value, reason) =>
                  customformFieldChangeHandler(value, reason)
                }
                onInputChange={RegistryIdHandler}
                name="projectId"
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="auto_text"
                    error={registryProjectIDError.flag}
                    placeholder={PAGE_CONTENT.projectid_placeholder}
                    variant="standard"
                    fullWidth
                  />
                )}
              />
              {registryProjectIDError.flag && (
                <span className="error_message">
                  {registryProjectIDError.message}
                </span>
              )}
            </div>
            <div className="projectid_checkfield">
              <Checkbox
                onChange={registryIdCheckboxHandler}
                name="newProjectId"
                inputProps={{ 'aria-label': 'registry_id_checkbox' }}
                color="success"
                className="projectid_checkbox"
                checked={projectForm.newProjectId}
                disabled={disableLinked && editMode}
              />
              <span className="projectcheckbox_label">
                {PAGE_CONTENT.projectid_checkbox_label}
              </span>
            </div>
            <div
              className={checkTernaryCondition(
                !projectForm.newProjectId,
                'form_fields readonly',
                'form_fields',
              )}>
              <p
                className={checkTernaryCondition(
                  nameError || existingNameError.flag,
                  'field_label error',
                  'field_label',
                )}>
                {PAGE_CONTENT.projectname_label}
                {projectForm.newProjectId && (
                  <span
                    className={checkTernaryCondition(
                      nameError,
                      'required_field error',
                      'required_field',
                    )}>
                    {PAGE_CONTENT.project_required_subtext}
                  </span>
                )}
              </p>
              <Input
                value={projectForm.projectName}
                error={nameError || existingNameError.flag}
                placeholder={PAGE_CONTENT.projectname_placeholder}
                name="projectName"
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  e.target.value !== '' && setNameError(false);
                  e.target.value !== '' &&
                    setExistingNameError({ flag: false, message: '' });
                }}
                inputProps={{ 'aria-label': 'Project name' }}
                fullWidth
              />

              {nameError && (
                <span className="error_message">
                  {PAGE_CONTENT.project_name_error_message}
                </span>
              )}
              {existingNameError.flag && (
                <span className="error_message">
                  {existingNameError.message}
                </span>
              )}
            </div>
            <div
              className={checkTernaryCondition(
                !projectForm.newProjectId,
                'form_fields readonly',
                'form_fields',
              )}>
              <p className="field_label">
                {PAGE_CONTENT.projectdeveloper_label}
              </p>
              <Input
                value={projectForm.developerName}
                placeholder={PAGE_CONTENT.projectdeveloper_placeholder}
                name="developerName"
                onChange={formFieldChangeHandler}
                inputProps={{ 'aria-label': 'Developer name' }}
                fullWidth
              />
            </div>
            <div
              className={checkTernaryCondition(
                !projectForm.newProjectId,
                'form_fields readonly',
                'form_fields',
              )}>
              <p className="field_label">{PAGE_CONTENT.projectsub_label}</p>
              <Autocomplete
                options={projectTypes}
                renderOption={(props, option) => (
                  <div
                    {...props}
                    className="option"
                    onMouseEnter={(event) => {
                      event.target.setAttribute('data-hover', true);
                    }}
                    onMouseLeave={(event) => {
                      event.target.removeAttribute('data-hover');
                    }}>
                    {option}
                  </div>
                )}
                id="disable-close-on-select"
                value={projectForm.projectSubType || null}
                onChange={(e, value) => {
                  customFieldChangeHandler('projectSubType', value);
                  markFormDirty();
                }}
                name="projectSubType"
                // freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="auto_text"
                    placeholder={PAGE_CONTENT.projectsub_placeholder}
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </div>
            <div
              className={checkTernaryCondition(
                !projectForm.newProjectId,
                'form_fields readonly',
                'form_fields',
              )}>
              <p
                className={checkTernaryCondition(
                  implementationError,
                  'field_label error',
                  'field_label',
                )}>
                {PAGE_CONTENT.project_imp_status_label}
                <span
                  className={checkTernaryCondition(
                    implementationError,
                    'required_field error',
                    'required_field',
                  )}>
                  {PAGE_CONTENT.project_required_subtext}
                </span>
              </p>
              <Autocomplete
                options={status}
                renderOption={(props, option) => (
                  <div
                    {...props}
                    className="option"
                    onMouseEnter={(event) => {
                      event.target.setAttribute('data-hover', true);
                    }}
                    onMouseLeave={(event) => {
                      event.target.removeAttribute('data-hover');
                    }}>
                    {option}
                  </div>
                )}
                id="disable-close-on-select"
                value={projectForm.impStatus || null}
                isOptionEqualToValue={(options, value) =>
                  options.value === value.value
                }
                name="impStatus"
                onChange={(e, value) => {
                  customFieldChangeHandler('impStatus', value);
                  value !== null && setImplementationError(false);
                  markFormDirty();
                }}
                // freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={implementationError}
                    className="auto_text"
                    placeholder={PAGE_CONTENT.project_imp_status_placeholder}
                    variant="standard"
                    fullWidth
                  />
                )}
              />
              {implementationError && (
                <span className="error_message">
                  {PAGE_CONTENT.project_implementation_errro_message}
                </span>
              )}
            </div>
            <div>
              <div
                className={checkTernaryCondition(
                  !projectForm.newProjectId,
                  'form_fields readonly',
                  'form_fields',
                )}>
                <p className="field_label">
                  {PAGE_CONTENT.project_methodology_label}
                </p>

                <Autocomplete
                  options={methodology}
                  renderOption={(props, option) => (
                    <div
                      {...props}
                      className="option"
                      onMouseEnter={(event) => {
                        event.target.setAttribute('data-hover', true);
                      }}
                      onMouseLeave={(event) => {
                        event.target.removeAttribute('data-hover');
                      }}>
                      {option}
                    </div>
                  )}
                  id="disable-close-on-select"
                  value={projectForm.methodology || null}
                  onChange={(e, value) => {
                    customFieldChangeHandler('methodology', value);
                    markFormDirty();
                  }}
                  name="methodology"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="auto_text"
                      placeholder={PAGE_CONTENT.project_methodology_placeholder}
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </div>

              <div
                className={checkTernaryCondition(
                  !projectForm.newProjectId,
                  'form_fields readonly',
                  'form_fields',
                )}>
                <p className="field_label">
                  {PAGE_CONTENT.project_country_label}
                </p>

                <Autocomplete
                  options={country}
                  renderOption={(props, option) => (
                    <div
                      {...props}
                      className="option"
                      onMouseEnter={(event) => {
                        event.target.setAttribute('data-hover', true);
                      }}
                      onMouseLeave={(event) => {
                        event.target.removeAttribute('data-hover');
                      }}>
                      {option}
                    </div>
                  )}
                  id="disable-close-on-select"
                  value={projectForm.country || null}
                  onChange={(e, value) => {
                    onChangeCountry(value);
                    markFormDirty();
                  }}
                  name="country"
                  // freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="auto_text"
                      placeholder={PAGE_CONTENT.project_country_placeholder}
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </div>

              <div
                className={checkTernaryCondition(
                  !projectForm.newProjectId,
                  'form_fields readonly',
                  'form_fields',
                )}>
                <p className="field_label">
                  {PAGE_CONTENT.project_region_label}
                </p>

                <Autocomplete
                  options={region}
                  renderOption={(props, option) => (
                    <div
                      {...props}
                      className="option"
                      onMouseEnter={(event) => {
                        event.target.setAttribute('data-hover', true);
                      }}
                      onMouseLeave={(event) => {
                        event.target.removeAttribute('data-hover');
                      }}>
                      {option}
                    </div>
                  )}
                  id="disable-close-on-select"
                  value={projectForm.region || null}
                  disabled
                  // onChange={(e, value) =>
                  //   customFieldChangeHandler("region", value)
                  // }
                  name="region"
                  // freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="auto_text"
                      placeholder={PAGE_CONTENT.project_region_placehodler}
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div className="form_fields">
              <p
                className={
                  reviewError ? 'field_label error Mui-error' : 'field_label'
                }>
                {PAGE_CONTENT.internal_review_label}
                <span
                  className={checkTernaryCondition(
                    reviewError,
                    'required_field error',
                    'required_field',
                  )}>
                  {PAGE_CONTENT.project_required_subtext}
                </span>
              </p>
              <div className="flex-wrap">
                <div>
                  <Radio
                    color="success"
                    {...reviewStatuscontrolProps(
                      PAGE_CONTENT.internal_review_1,
                    )}
                    className="projecttype_radio"
                  />
                  <span className="projecttype_label">
                    {PAGE_CONTENT.internal_review_1}
                  </span>
                </div>
                <div>
                  <Radio
                    color="success"
                    {...reviewStatuscontrolProps(
                      PAGE_CONTENT.internal_review_2,
                    )}
                    className="projecttype_radio"
                  />
                  <span className="projecttype_label">
                    {PAGE_CONTENT.internal_review_2}
                  </span>
                </div>
              </div>
              <br></br>
              {reviewError && (
                <span className="error_message">
                  {PAGE_CONTENT.project_review_status_error}
                </span>
              )}
            </div>
            <div className="form_fields">
              <p
                className={checkTernaryCondition(
                  priceAssumptionError,
                  'field_label error',
                  'field_label',
                )}>
                {PAGE_CONTENT.price_assumption_type_label}
                <span
                  className={
                    priceAssumptionError
                      ? 'required_field error'
                      : 'required_field'
                  }>
                  {PAGE_CONTENT.project_required_subtext}
                </span>
              </p>
              <Select
                value={projectForm.priceAssumptionType}
                onChange={(e) => {
                  setFormValue({
                    ...projectForm,
                    priceAssumptionType: e.target.value,
                  });
                  e.target.value !== '' && setPriceAssumptionError(false);
                  markFormDirty();
                }}
                className="priceAssumption_dropdown"
                name="priceAssumptionType"
                displayEmpty
                error={priceAssumptionError}
                inputProps={{ 'aria-label': 'Without label' }}
                input={<OutlinedInput />}
                renderValue={
                  projectForm.priceAssumptionType !== ''
                    ? undefined
                    : () => (
                        <div className="registry_select_placeholder">
                          {PAGE_CONTENT.price_assumption_type_placeholder}
                        </div>
                      )
                }>
                {priceAssumptionTypes.map((registry) => (
                  <MenuItem
                    key={registry.value}
                    value={registry.value}
                    sx={getMenuItemStyles}>
                    {registry.label}
                  </MenuItem>
                ))}
              </Select>
              <br></br>
              {priceAssumptionError && (
                <span className="error_message">
                  {PAGE_CONTENT.price_assumption_type_error}
                </span>
              )}
            </div>
            <div className="form_fields">
              <p
                className={checkTernaryCondition(
                  descriptionError,
                  'field_label error Mui-error',
                  'field_label',
                )}>
                {PAGE_CONTENT.project_desc_label}
              </p>
              <textarea
                placeholder={PAGE_CONTENT.project_desc_placeholder}
                name="projectdesc"
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  setDescCount(e.target.value.length);
                }}
                className={checkTernaryCondition(
                  descriptionError,
                  'text_area error_border',
                  'text_area',
                )}
                value={projectForm.projectdesc}></textarea>
              <br></br>
              <div className="desc_notes_error_msg">
                {descriptionError && (
                  <div className="error_message">
                    {PAGE_CONTENT.project_desc_notes_error}
                  </div>
                )}
                <div
                  className={checkTernaryCondition(
                    descriptionError,
                    'error_message',
                    'non_error_message',
                  )}>
                  {descCount}
                  {PAGE_CONTENT.project_desc_notes_count}
                </div>
              </div>
            </div>
            <div className="form_fields">
              <p
                className={checkTernaryCondition(
                  notesError,
                  'field_label error Mui-error',
                  'field_label',
                )}>
                {PAGE_CONTENT.project_notes_label}
              </p>
              <textarea
                placeholder={PAGE_CONTENT.project_notes_placeholder}
                name="projectNotes"
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  setNotesCount(e.target.value.length);
                }}
                className={checkTernaryCondition(
                  notesError,
                  'text_area error_border',
                  'text_area',
                )}
                value={projectForm.projectNotes}></textarea>
              <br></br>
              <div className="desc_notes_error_msg">
                {notesError && (
                  <div className="error_message">
                    {PAGE_CONTENT.project_desc_notes_error}
                  </div>
                )}
                <div
                  className={checkTernaryCondition(
                    notesError,
                    'error_message',
                    'non_error_message',
                  )}>
                  {notesCount}
                  {PAGE_CONTENT.project_desc_notes_count}
                </div>
              </div>
            </div>
            <div className="form_fields">
              <p className="field_label">
                {PAGE_CONTENT.project_add_tags_label}
              </p>
              <Autocomplete
                multiple
                id="tags-filled"
                inputValue={tagsInput}
                forcePopupIcon
                onInputChange={(e, value) => {
                  if (value !== ' ') {
                    setTagsInput(value);
                    setNewTag('');
                  }
                  if (value !== ',') {
                    setTagsInput(value);
                    setNewTag('');
                  }
                  if (
                    tags.indexOf(value) === -1 &&
                    (value !== ' ' || value !== ',')
                  ) {
                    setNewTag(value);
                  }
                }}
                options={[...tags, newTag].filter((tags) => tags !== '')}
                freeSolo
                clearOnBlur={true}
                renderOption={(props, option) => {
                  if (option === newTag && newTag !== '') {
                    return (
                      <span
                        {...props}
                        style={{ borderTop: '1px solid #D0D0CE' }}>
                        {`${option} (create new tags)`}
                      </span>
                    );
                  } else {
                    return <span {...props}>{option}</span>;
                  }
                }}
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      value={tagsInput}
                      {...params}
                      variant="standard"
                      inputProps={{
                        style: { paddingLeft: '0.625rem' },
                        onKeyDown: handleKeyDown,
                        ...params.inputProps,
                      }}
                      onBlur={addTagshandler}
                      placeholder={checkTernaryCondition(
                        projectForm.tags.length > 0,
                        '',
                        PAGE_CONTENT.project_add_tags_placeholder,
                      )}
                    />
                  );
                }}
                value={projectForm.tags}
                onChange={(event, newValue) => {
                  if (projectForm.tags.indexOf(newValue) === -1) {
                    customFieldChangeHandler('tags', newValue);
                  }
                  markFormDirty();
                }}
              />
            </div>
            <div className="form_buttons">
              <Button
                variant="contained"
                size="medium"
                color="success"
                disabled={disableSubmit}
                disableElevation
                onClick={submitHandler}
                sx={{ textTransform: 'none' }}>
                {PAGE_CONTENT.project_submit_button}
              </Button>
              <Button
                size="medium"
                color="success"
                onClick={() => {
                  navigate(pathName.assets.projects.view, {
                    state: 'edit-project',
                  });
                }}
                sx={{ textTransform: 'none' }}>
                {PAGE_CONTENT.project_cancel_button}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProject;
