import styled from 'styled-components';
import {
  COOL_GRAY,
  CYAN_BLUE,
  DARK_GREEN,
  FOCUS_DARK_GREEN,
  FOCUS_GREEN_BACKGROUND,
  FOCUS_GREEN_BORDER,
  HOVER_DARK_GREEN,
  HOVER_GREEN_BACKGROUND,
  TYPOGRAPHY_GRAY,
  WHITE,
  DARK_CHARCOAL,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const secondaryLabelStyle = {
  fontWeight: 700,
};
export const fieldLabelStyle = {
  fontWeight: 600,
};
export const fieldUnitStyle = {
  fontWeight: 400,
  fontSize: '0.875rem',
  color: COOL_GRAY,
};
export const MainConTainerWrapper = styled.div`
  display: flex;
`;
export const buttonStyleClose = (disabled) => {
  return {
    textTransform: 'none',
    background: WHITE,
    border: `solid 1px ${COOL_GRAY}`,
    padding: '0.344rem 1rem',
    color: checkTernaryCondition(disabled, COOL_GRAY, DARK_GREEN),
    '&:hover': {
      background: WHITE,
      color: checkTernaryCondition(disabled, COOL_GRAY, DARK_GREEN),
    },
    '&.Mui-disabled': {
      border: `1px solid ${TYPOGRAPHY_GRAY}`,
    },
  };
};
export const secondaryButtonStyle = {
  textTransform: 'none',
  color: DARK_GREEN,
  '&:hover': {
    backgroundColor: HOVER_GREEN_BACKGROUND,
  },
  '&:focus': {
    color: WHITE,
    backgroundColor: FOCUS_GREEN_BACKGROUND,
    border: `2px solid ${FOCUS_GREEN_BORDER}`,
    padding: '4.5px 15px',
  },
};
export const primaryButtonStyle = (padding) => {
  return {
    textTransform: 'none',
    color: WHITE,
    backgroundColor: DARK_GREEN,
    '&:hover': {
      backgroundColor: HOVER_DARK_GREEN,
      color: WHITE,
    },
    '&:focus': {
      backgroundColor: FOCUS_DARK_GREEN,
      border: `2px solid ${CYAN_BLUE}`,
      padding: padding || '4.5px 14.5px',
      color: WHITE,
    },
  };
};
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
export const CashCropBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CopyDisclaimer = styled.div`
  color: ${DARK_CHARCOAL};
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
`;

export const smallBtn = {
  height: '1.75rem',
};

export const iconWithBtnStyle = {
  display: 'flex',
  gap: '0.5rem',
};

export const NavigationBtnWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop || '1rem'};
`;

export const MainWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
