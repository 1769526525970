// BaselineReportingMenuItems.js
import {
  InitiateBaselineReportingButton,
  ReviewMenuItem,
} from '../ActivityMenuButton/BaselineButtons.react';
import { useActivityContext } from 'containers/ActivityListContainer';
import { REPORTING_STATUS } from './ActivityMenuItem.constant';
import { ViewPdfButton } from '../ActivityMenuButton/EnrollmentButtons.react';
import { DOWNLOAD_ENROLLMENT_BASELINE_REPORT } from 'urls';

const BaselineReportingMenuItems = () => {
  const { status } = useActivityContext();

  return (
    <>
      {status === REPORTING_STATUS.not_started && (
        <InitiateBaselineReportingButton
          label={'Initiate baseline reporting'}
        />
      )}
      {(status === REPORTING_STATUS.in_progress ||
        status === REPORTING_STATUS.requested) && (
        <>
          <InitiateBaselineReportingButton label={'Continue'} />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_BASELINE_REPORT}
            key={'view-baseline'}
          />
        </>
      )}
      {(status === REPORTING_STATUS.submitted ||
        status === REPORTING_STATUS.in_review) && (
        <>
          <ReviewMenuItem />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_BASELINE_REPORT}
            key={'view-submitted'}
          />
        </>
      )}
      {status === REPORTING_STATUS.approved && (
        <ViewPdfButton
          url={DOWNLOAD_ENROLLMENT_BASELINE_REPORT}
          key={'view-approved'}
        />
      )}
    </>
  );
};

export default BaselineReportingMenuItems;
