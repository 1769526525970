import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router';
import { PAGE_CONTENT } from './Footer.content';
import { landingPageFooterStyles, useStyles } from './Footer.style';
import { pathName } from 'Routes/routes.const';
import {
  baselineAndLandingPages,
  participantEnrolmentRoutes,
} from 'layout/Layout.constants';
import { useContext } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { PARTICIPANT, PARTICIPANT_CONTRIBUTOR } from 'utils/config';

const Footer = ({
  isParticipantLanding,
  isParticipantMenu,
  isInActivityPage,
  isEnrollment,
  isMainAccessDeniedPage,
  isLandingOrAccessDeniedPage = isParticipantLanding || isMainAccessDeniedPage,
}) => {
  const {
    MainContainer,
    FooterLink,
    FooterDiv,
    IconSpan,
    LeftFooterDiv,
    RightFooterDiv,
  } = useStyles();
  const navigate = useNavigate();
  const date = new Date();
  const location = useLocation();
  const { userRoles } = useContext(userAuthRolesContext);
  const isParticipantContributor = userRoles?.includes(PARTICIPANT_CONTRIBUTOR);
  const isParticipantOwner = userRoles?.includes(PARTICIPANT);
  const insideBaselineReportingPage =
    location.pathname === pathName.participant.participantFieldBaseline;

  const isEBACPages =
    baselineAndLandingPages.includes(location?.pathname) ||
    participantEnrolmentRoutes.includes(location?.pathname);

  const reporingPagesPath =
    isParticipantContributor || isParticipantOwner
      ? [
          pathName.participant.activityMenuPage,
          pathName.participant.baselineMenuPage,
          pathName.participant.menuPage,
        ]
      : [];

  const insideLandingPages = [
    pathName.participant.landingPage,
    pathName.participant.baselineLandingPage,
    pathName.participant.activityLandingPage,
    pathName.participant.contributorLandingPage,
    pathName.logout,
    ...reporingPagesPath,
  ].includes(location.pathname);

  /**
   * @description Calculate padding right value based on page
   * @returns {String} padding right value
   */
  const calculatePaddingRight = () => {
    if (isEnrollment) return '1%';
    if (isEBACPages) return '0%';
    return '5%';
  };

  const separatorIcon = <IconSpan> | </IconSpan>;

  return (
    <Box
      sx={
        (insideLandingPages || isMainAccessDeniedPage) &&
        landingPageFooterStyles
      }>
      <MainContainer
        margin={insideBaselineReportingPage && '0px'}
        maxWidth={'100%'}
        isParticipantLanding={isLandingOrAccessDeniedPage}
        isEnrollment={isEnrollment}
        isParticipantMenu={isParticipantMenu}
        isInActivityPage={isInActivityPage}>
        <FooterDiv isParticipantLanding={isLandingOrAccessDeniedPage}>
          <LeftFooterDiv isEBAC={isEBACPages || isMainAccessDeniedPage}>
            <FooterLink
              isParticipantLanding={isLandingOrAccessDeniedPage}
              target={'_blank'}
              onClick={() => {
                navigate(pathName.termsOfUse);
              }}>
              {PAGE_CONTENT.terms_of_use}
            </FooterLink>
            {separatorIcon}
            <FooterLink
              isParticipantLanding={isLandingOrAccessDeniedPage}
              target={'_blank'}
              href={PAGE_CONTENT.privacyPolicyLink}
              rel="noreferrer">
              {PAGE_CONTENT.privacyPolicyText}
            </FooterLink>
            {separatorIcon}
            <FooterLink
              isParticipantLanding={isLandingOrAccessDeniedPage}
              href={PAGE_CONTENT.cookies_link}
              target={''}>
              {PAGE_CONTENT.cookies}
            </FooterLink>
            {separatorIcon}
          </LeftFooterDiv>
          <RightFooterDiv
            data-testid="copyright-test"
            paddingRight={calculatePaddingRight()}>
            <span>
              {' '}
              &copy; {date.getFullYear()} {PAGE_CONTENT.copyright}
            </span>
          </RightFooterDiv>
        </FooterDiv>
      </MainContainer>
    </Box>
  );
};

Footer.propTypes = {
  isParticipantLanding: PropTypes.bool,
  isParticipantMenu: PropTypes.bool,
  isInActivityPage: PropTypes.bool,
  isEnrollment: PropTypes.bool,
  isLandingOrAccessDeniedPage: PropTypes.bool,
  isMainAccessDeniedPage: PropTypes.bool,
};

export default Footer;
