import { SwitchAndLabelWrapper } from 'components/FormComponents/SwitchComponent/SwitchComponent.style';
import styled from 'styled-components';
import { Heading } from '../ProjectSchedule/ProjectSchedule.style';
import { BLACK, COOL_GRAY } from 'theme/GlobalColors';
import { ErroWrapper } from 'components/FormComponents/FormStyles';

export const InputLableWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width || '40rem'};
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
`;

export const LableWrapper = styled.div`
  display: flex;
  font-family: Open Sans;
  font-size: 14px;
  width: ${({ width }) => width || '8.1875rem'};
  font-weight: 600;
  color: ${BLACK};
  line-height: 19.6px;
`;

export const InputInfoIcon = styled.div`
  height: 1.5rem;
  display: flex;
  justify-content: center;
`;

export const SwitchWrapper = styled(SwitchAndLabelWrapper)`
  justify-content: center;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft || '2.8544rem'};
`;

export const LabelAndInfoStyle = styled.div`
  gap: ${({ gap }) => gap || '0.625rem'};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SwitchStyle = {
  width: '32px',
  height: '16px',
  padding: '0px',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '15px',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    cursor: 'not-allowed',
    padding: '2px',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      padding: '2px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgba(208, 208, 206, 1)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    color: 'rgba(0, 0, 0, .25)',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
};

export const ProjectDesignHeader = styled(Heading)`
  font-family: Open Sans;
  font-size: 16px;
  color: ${BLACK};
  font-weight: 700;
  line-height: 22.4px;
  margin-top: 2rem;
`;

export const projectAutoCompleteSx = (isInput) => {
  return {
    '& .Mui-disabled': {
      backgroundColor: '#fff !important',

      '.MuiChip-root': {
        color: COOL_GRAY,
        border: `0.5px solid ${COOL_GRAY}`,
      },
    },

    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& input': {
        fontSize: '0.875rem',
      },
    },
    '& .MuiInput-root': {
      paddingBottom: isInput && '0.188rem',
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      border: `0.6px solid ${COOL_GRAY}`,
      padding: '0.4rem',
      borderRadius: '2px',

      '& #checkboxes-tags-demo, #tags-filled': {
        fontSize: '0.875rem',
        color: COOL_GRAY,
        padding: '0rem 0rem 0rem 0.25rem',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.688rem',
      margin: 0,
    },
  };
};

export const textAreaSx = {
  width: '100%',
  div: {
    color: COOL_GRAY,
    width: '100%',
  },
};

export const WordCount = styled(ErroWrapper)`
  color: ${COOL_GRAY};
`;

export const CustomMethodName = {
  '& .MuiInput-input': {
    padding: '0.375rem 0px 0.375rem 0.5rem',
  },
};

export const DropdownSx = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft};
`;
