import styled from 'styled-components';
import { BRIGHT_GRAY, COOL_GRAY, QUILL_GRAY, WHITE } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const ColumnHeaderWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  margin-left: ${({ marginLeft }) => marginLeft};
  display: ${({ display }) => display};
  gap: ${({ gap }) => gap};
`;
export const TableRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ background }) => background};
  height: ${({ height }) => height};
  border-bottom: 1px solid ${QUILL_GRAY};
  justify-content: space-between;
`;
export const RowValueSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 2.75rem;
`;
export const CCandMTSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) =>
    width ? `calc(19% + ${(width - 1) * 0.3135}rem)` : 'auto'};
`;
export const RowValueWrapper = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  align-self: center;
`;
export const ArrowIconWrapper = styled.div`
  padding-right: 0.5rem;
  width: 2rem;
`;
export const ExpandCalcWrapper = styled.div`
  padding-right: 0.5rem;
  align-self: center;
`;
export const CalculationWrapper = styled.div`
  display: flex;
  width: 62%;
`;
export const ExpandCalcViewWrapper = styled.div`
  margin-left: 1.5rem;
`;
export const CCandMTColumnSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width || '19%'};
  padding-right: 4rem;
`;
export const NestedAccordionWrapper = styled.div`
  height: 82vh;
  overflow: auto;
  background-color: white;
`;
export const AccordionSx = (accordionColor) => {
  return {
    backgroundColor: checkTernaryCondition(
      accordionColor,
      accordionColor,
      WHITE,
    ),
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: '0',
    },
    '& .MuiCollapse-root': {
      backgroundColor: BRIGHT_GRAY,
    },
    '&.MuiAccordion-root:before': {
      opacity: '0',
    },
  };
};
export const AccordionSummarySx = {
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
  '&.Mui-expanded': {
    minHeight: '48px',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};
export const AccordionDetailsSx = (length) => {
  return {
    padding: '0px',
    margin: '0px 0px 0px 1.6rem',
    borderLeft: checkTernaryCondition(
      length !== 0 || length !== undefined,
      `1px solid ${QUILL_GRAY}`,
      'none',
    ),
  };
};
export const TablePaginationBoxSx = {
  backgroundColor: WHITE,
  borderTop: `1px solid ${QUILL_GRAY}`,
};
export const ToolTipIconSx = {
  height: '1.2rem',
  width: '1.2rem',
  cursor: 'pointer',
  color: COOL_GRAY,
};
