import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { MODAL_CONTENT } from './EnrollmentModal.content';
import CloseIcon from '@mui/icons-material/Close';
import {
  ButtonSelectionLabel,
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  InProgressProjectList,
  LinkStyle,
  NewProjectEnrollmentHeading,
  RadioGroupStyle,
  SendRequestBtnStyle,
} from './EnrollmentModal.style';
import { useEffect, useState, useContext } from 'react';
import { checkAccess, checkTernaryCondition, isEmpty } from 'utils/helper';
import BasicAutoComplete from 'components/FormComponents/BasicAutoComplete/BasicAutoComplete.react';
import { pathName } from 'Routes/routes.const';
import axios from 'axios';
import {
  SEND_REQUEST_EMAIL,
  INITIATE_NEW_ENROLLMENT_INSTANCE,
  PARTICIPANT_REPORT_FIRST_PART,
} from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { Link } from 'react-router-dom';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import {
  INACTIVE_EMAIL_TEMLATE_TEXT,
  ORIGINATION_PROJECT_ENROLL_PARTICIPANT_ON_BEHALF,
  USER_STATUS,
} from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ENROLL_ON_BEHALF,
  LifeCycleContext,
} from 'contextAPI/statusLifecycleContext';
import {
  flexSpaceBetween,
  modalActionButtonDefaultStyle,
} from 'theme/GlobalStyles';
import { closeIconSx } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/Modals.style';
import {
  RadioButtonStyle,
  RadioIconStyle,
} from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import { FORM_CONTENT } from 'components/FormComponents/FormComponents.content';
import PropTypes from 'prop-types';
import Loader from 'components/Loader/Loader.react';
import { getActionIdFromActionCode } from 'pages/Origination/Org_Project/Participants/Participants.content';

const EnrollmentModal = ({
  modalData,
  setModalData,
  refreshHandler,
  statusMappingList,
}) => {
  const {
    shouldGenerateNewInstance,
    contractId,
    projectId,
    id: participantId,
    instanceId,
    actionCode,
    projectCycleId,
  } = modalData;
  const [enrollmentProjectList, setEnrollmentProjectList] = useState([]);
  const [intrestedProjectList, setInteresetedProjectList] = useState([]);
  const [projectValue, setProjectValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedEnrollmentOption, setSelectedEnrollmentOption] =
    useState(null);
  const [userStatus, setUserStatus] = useState(null);

  const { permissions } = useContext(userAuthRolesContext);
  const { isActionEnabled, updateParticipantStatus } =
    useContext(LifeCycleContext);
  const hasEnrollOnBehalfPermission = checkAccess(
    permissions,
    ORIGINATION_PROJECT_ENROLL_PARTICIPANT_ON_BEHALF,
  );
  useEffect(() => {
    if (modalData && modalData.flag && modalData.id) {
      axios
        .get(`${PARTICIPANT_REPORT_FIRST_PART}?participantId=${modalData.id}`)
        .then((response) => {
          const userStatusResponse = response.data.userStatus;
          setUserStatus(userStatusResponse);
        });
    }
  }, [modalData, modalData.flag]);
  useEffect(() => {
    if (modalData.id !== 0) {
      setInteresetedProjectList(
        modalData.projectData
          .filter(
            (project) => project.participantProjectStatus === 'Interested',
          )
          .map((project) => ({
            value: project.projectId,
            label: project.projectName,
          })),
      );
      setEnrollmentProjectList(
        modalData.projectData.filter(
          (project) =>
            project.participantProjectStatus === 'Enrollment In Progress',
        ),
      );
    }
  }, [modalData]);

  useEffect(() => {
    getSelectedProjectId();
  }, [modalData, projectValue]);

  const getSelectedProjectId = () => {
    if (modalData.projectId !== undefined) {
      setSelectedProjectId(modalData.projectId);
    } else if (projectValue !== null) {
      setSelectedProjectId(projectValue.value);
    }
  };

  const sendRequestHandler = (internalProjectId, enrollmentInstanceId) => {
    setLoading(true);
    const postData = {
      internalProjectId: internalProjectId,
      participantId: modalData.id,
      enrollmentInstanceId: enrollmentInstanceId,
      projectCycleId: projectCycleId,
    };
    axios
      .post(SEND_REQUEST_EMAIL, postData)
      .then((response) => {
        if (MODAL_CONTENT.SUCCESS.includes(response?.status)) {
          if (response.data?.message !== INACTIVE_EMAIL_TEMLATE_TEXT) {
            displayToast('success', `${MODAL_CONTENT.EMAIL_SUCCESS_MESSAGE}`);
          }
        } else {
          displayToast('error', `${MODAL_CONTENT.EMAIL_ERROR_MESSAGE}`);
        }
        return response;
      })
      .then((response) => {
        response.data?.message !== INACTIVE_EMAIL_TEMLATE_TEXT &&
          actionCode === 'ENROLL-DEFAULT' &&
          updateParticipantStatus({
            contractId: contractId,
            projectId: modalData.projectId,
            participantId: modalData.id,
            instanceId: instanceId,
            actionId: getActionIdFromActionCode(
              'ENROLL-SEND-REQ',
              statusMappingList,
            ),
            projectCycleId: projectCycleId,
          });
      })
      .then(() => {
        modalCloseHandler();
      })
      .catch(() => {
        displayToast('error', `${MODAL_CONTENT.EMAIL_ERROR_MESSAGE}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNewEnrollmentInstanceId = async () => {
    const url = `${INITIATE_NEW_ENROLLMENT_INSTANCE}?contractId=${contractId}&projectCycleId=${projectCycleId}`;
    setLoading(true);
    return axios
      .post(url)
      .then((resp) => {
        const newInstanceId = resp.data.enrollmentInstanceId;
        return newInstanceId;
      })
      .catch(() => {
        /* api error handling */
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const enrollOnBehalfHandler = () => {
    modalCloseHandler();
  };

  const modalCloseHandler = () => {
    setModalData({
      ...modalData,
      flag: false,
    });
    setEnrollmentProjectList([]);
    setInteresetedProjectList([]);
    setProjectValue(null);
    setSelectedEnrollmentOption(null);
  };

  const handleSubmitOnBehalf = () => {
    enrollOnBehalfHandler();
    if (shouldGenerateNewInstance) {
      getNewEnrollmentInstanceId().then((newInstanceId) => {
        refreshHandler();
        if (newInstanceId) {
          const enrollmentHomePageUrl = `${pathName.participant.menuPage}?projectid=${projectId}&participantid=${participantId}&enrollmentInstanceId=${newInstanceId}&projectcycleid=${projectCycleId}`;
          window.open(enrollmentHomePageUrl, '_blank');
        }
      });
    } else {
      window.open(
        `${pathName.participant.menuPage}?projectid=${selectedProjectId}&participantid=${modalData.id}&enrollmentInstanceId=${modalData.instanceId}&projectcycleid=${projectCycleId}`,
        '_blank',
      );
    }
  };

  const handleSendEmailRequest = () => {
    const modalProjectId = checkTernaryCondition(
      !isEmpty(modalData.projectId),
      modalData.projectId,
      projectValue?.value,
    );
    if (modalProjectId) {
      if (shouldGenerateNewInstance) {
        getNewEnrollmentInstanceId().then((newInstanceId) => {
          if (newInstanceId) {
            refreshHandler();
            sendRequestHandler(modalProjectId, newInstanceId);
          }
        });
      } else {
        sendRequestHandler(modalProjectId, instanceId);
      }
    }
  };

  const submitHandler = async () => {
    if (selectedEnrollmentOption === MODAL_CONTENT.ENROLL_BTN) {
      handleSubmitOnBehalf();
    } else {
      handleSendEmailRequest();
    }
  };

  const enrollmentModalContent = checkTernaryCondition(
    !isEmpty(modalData.projectId),
    `${MODAL_CONTENT.BUTTON_SELECTION_LABEL.slice(0, -2)} ${
      modalData.participantName
    }${MODAL_CONTENT.BUTTON_SELECTION_LABEL.slice(-1)}`,
    `${MODAL_CONTENT.BUTTON_SELECTION_LABEL.slice(
      0,
      -2,
    )}${MODAL_CONTENT.BUTTON_SELECTION_LABEL.slice(-1)}`,
  );

  return (
    <>
      <Loader loading={loading} zIndex={99999} />
      <Dialog
        open={modalData.flag}
        onClose={modalCloseHandler}
        sx={DialogStyle}>
        <DialogTitle
          sx={{
            ...DialogTitleStyle,
            ...flexSpaceBetween,
          }}>
          <div>
            {checkTernaryCondition(
              intrestedProjectList.length === modalData.projectData.length ||
                modalData.projectId !== undefined,
              MODAL_CONTENT.ENROLLMENT_HEADING,
              MODAL_CONTENT.CONTINUE_ENROLLMENT_STATUS,
            )}
          </div>
          <CloseIcon onClick={modalCloseHandler} sx={closeIconSx} />
        </DialogTitle>
        <DialogContent sx={DialogContentStyle}>
          {modalData.projectId === undefined && (
            <div>
              {enrollmentProjectList.length > 0 && (
                <>
                  <InProgressProjectList>
                    {enrollmentProjectList.map((project) => {
                      return (
                        <div key={project.projectId}>
                          <Link
                            style={LinkStyle(false)}
                            target="_blank"
                            rel="noreferrer"
                            to={`${pathName.participant.menuPage}?projectid=${project.projectId}&participantid=${modalData.id}`}>
                            {project.projectName}
                          </Link>
                        </div>
                      );
                    })}
                  </InProgressProjectList>
                  <NewProjectEnrollmentHeading>
                    {MODAL_CONTENT.SELECT_NEW_PROJECT_LABEL}
                  </NewProjectEnrollmentHeading>
                </>
              )}
              <BasicAutoComplete
                options={intrestedProjectList}
                id="disable-close-on-select"
                label={`${MODAL_CONTENT.PROJECT_FIELD_LABEL}${modalData.participantName}`}
                value={projectValue}
                loading={true}
                required
                isError={false}
                ErrorMessage={''}
                placeholder={MODAL_CONTENT.PROJECT_FIELD_PLACEHOLDER}
                loadingText={MODAL_CONTENT.PROJECT_FIELD_PLACEHOLDER}
                onChangeHandler={(value) => {
                  setProjectValue(value);
                }}
                inputHandler={() => {
                  /* do nothing here */
                }}
                name="project"
              />
            </div>
          )}
          <ButtonSelectionLabel
            title={checkTernaryCondition(
              enrollmentModalContent?.length > 48,
              enrollmentModalContent.slice(29),
              '',
            )}>
            {enrollmentModalContent}
          </ButtonSelectionLabel>
          <RadioGroup
            sx={RadioGroupStyle}
            row
            aria-labelledby="demo-radio-buttons-group-label"
            onChange={(e) => {
              setSelectedEnrollmentOption(e.target.value);
            }}
            name="radio-buttons-group">
            <FormControlLabel
              sx={RadioButtonStyle('0px')}
              value={MODAL_CONTENT.SEND_REQUEST_BTN}
              control={
                <Radio
                  size={FORM_CONTENT.small}
                  sx={RadioIconStyle(true)}
                  role="enrollment-radio"
                />
              }
              label={MODAL_CONTENT.SEND_REQUEST_BTN}
              disabled={userStatus !== USER_STATUS.STATUS.ACTIVE}
            />
            <FormControlLabel
              sx={RadioButtonStyle(null)}
              value={MODAL_CONTENT.ENROLL_BTN}
              control={
                <Radio
                  size={FORM_CONTENT.small}
                  sx={RadioIconStyle(true)}
                  role="enrollment-radio"
                />
              }
              label={MODAL_CONTENT.ENROLL_BTN}
              disabled={
                !hasEnrollOnBehalfPermission &&
                !isActionEnabled(modalData?.actionId, ENROLL_ON_BEHALF)
              }
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions style={DialogActionStyle}>
          <Button
            onClick={modalCloseHandler}
            sx={[
              CancelBtnStyle,
              tertiaryButtonStyle,
              modalActionButtonDefaultStyle,
            ]}>
            {MODAL_CONTENT.CANCEL_BTN}
          </Button>
          <Button
            disabled={!selectedEnrollmentOption}
            onClick={submitHandler}
            sx={[SendRequestBtnStyle, primaryButtonStyle()]}>
            {MODAL_CONTENT.SUBMIT}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EnrollmentModal.propTypes = {
  modalData: PropTypes.shape({
    flag: PropTypes.bool,
    id: PropTypes.number,
    participantName: PropTypes.string,
    projectId: PropTypes.number,
    projectData: PropTypes.array,
    actionId: PropTypes.number,
    instanceId: PropTypes.number,
    contractId: PropTypes.number,
    shouldGenerateNewInstance: PropTypes.bool,
    projectCycleId: PropTypes.number,
  }),
  setModalData: PropTypes.func,
  refreshHandler: PropTypes.func,
};

EnrollmentModal.defaultProps = {
  refreshHandler: () => null,
};

export default EnrollmentModal;
