import styled from 'styled-components';
import {
  BLACK,
  BLUE,
  COOL_GRAY,
  CYAN_BLUE,
  DARK_CHARCOAL,
  DARK_GREEN,
  FOCUS_DARK_GREEN,
  HOVER_DARK_GREEN,
  QUILL_GRAY,
  WHITE,
} from 'theme/GlobalColors';
import { pointerCursor } from 'theme/GlobalStyles';
import { checkTernaryCondition } from 'utils/helper';

export const OpenInNewTab = styled.div`
  position: absolute !important;
  right: 0.7rem;
  color: ${BLUE};
`;

export const ModalHeading = styled.div`
  color: ${BLACK};
  font-family: 'Open Sans';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '37.5rem',
      padding: '1.5rem',
      position: 'relative',
      '*': {
        fontFamily: 'Open sans, sans-serif',
      },
    },
  },
};

export const DialogContentStyle = {
  padding: '0.5rem',
  overflow: 'auto',

  '&.MuiDialogContent-root': {
    overflowX: 'hidden',
  },
};
export const InfoSx = {
  color: COOL_GRAY,
  fontSize: '1.2rem',
};
export const TooltipHeading = styled.div`
  font-family: Open Sans;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  font-size: 14px;
  gap: 4px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  margin-bottom: 
  color: ${DARK_CHARCOAL};
`;

export const InfoTooltipWrapper = styled.div`
  font-size: 0.75rem;
  width: 350px;
  padding: 0.5rem;
  border-radius: 0rem;
`;

export const IconButtonStyle = {
  ml: 'auto',
  position: 'absolute',
  right: 4,
  top: 4,
};
export const DialogTitleStyle = {
  fontSize: '1.25rem',
  padding: '0rem 0.4rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  color: DARK_CHARCOAL,
  fontWeight: 400,
  lineHeight: '140%',
};
export const DialogActionStyle = (hideButtons) => {
  return {
    display: checkTernaryCondition(hideButtons, 'none', 'flex'),
    justifyContent: 'flex-end',
    marginTop: '0.5rem',
    gap: '0.5rem',
  };
};
export const ModalDialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '31.25rem',
      padding: '1.5rem',
      position: 'relative',

      '*': {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
};
export const ModalIconButtonStyle = {
  ml: 'auto',
  position: 'absolute',
  right: 4,
  top: 4,
};

export const SubmitBtnStyle = {
  textTransform: 'none',
  backgroundColor: DARK_GREEN,
  padding: '0.625rem 1rem',
  color: WHITE,
  '&:hover': {
    backgroundColor: HOVER_DARK_GREEN,
  },
  '&:focus': {
    backgroundColor: FOCUS_DARK_GREEN,
    border: `2px solid ${CYAN_BLUE}`,
  },
  '&.Mui-disabled': {
    color: BLACK,
    backgroundColor: QUILL_GRAY,
  },
};

export const CancelBtnStyle = {
  color: DARK_GREEN,
  textTransform: 'none',
  fontFamily: 'Open Sans, sans-serif',
  padding: '0.625rem 1rem',
};

export const dropdownStyleProps = {
  width: '13.75rem',
  height: '2rem',
};

export const ModalSubmittedContent = styled.div`
  padding-top: 1.5rem;
  color: ${DARK_CHARCOAL};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputSx = {
  fontSize: '0.875rem',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 'normal',
  '& ::placeholder': {
    color: COOL_GRAY,
  },
};

export const closeIconSx = {
  ...pointerCursor,
  alignSelf: 'flex-start',
  color: COOL_GRAY,
};

export const ToolTipAndAlertWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;
