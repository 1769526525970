import React from 'react';
import PropTypes from 'prop-types';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { DIALOG_CONTENT } from './ApproveModal.content';

const ApproveModal = ({ isDialogOpen, projectYear, activityType, onClose }) => {
  return (
    <div>
      <DialogBox
        isOpen={isDialogOpen}
        isApproveModal={true}
        onCancel={onClose}
        title={activityType + DIALOG_CONTENT.heading}
        subtitle={
          activityType +
          DIALOG_CONTENT.sub_heading_part1 +
          projectYear +
          DIALOG_CONTENT.sub_heading_part2
        }></DialogBox>
    </div>
  );
};

ApproveModal.propTypes = {
  isDialogOpen: PropTypes.bool,
  projectYear: PropTypes.string,
  activityType: PropTypes.string,
  onClose: PropTypes.func,
};

export default ApproveModal;
