import axios from 'axios';
import { LANDING_PAGE_CONTENT } from 'pages/ParticipantDataCollection/pages/ParticipantPages.content';
import { formatReportingYearsData } from 'pages/ParticipantDataCollection/ReportingUtilFunctions';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { pathName } from 'Routes/routes.const';
import {
  EDIT_BASELINE_FIELD_INFO,
  FETCH_ACTIVITY_ACREAGE,
  FETCH_BASELINE_FARM_YEARS,
  FETCH_PROJECT_ACTIVITIES,
  FETCH_SOIL_AMENDMENTS_DROPDOWN,
  FETCH_TOTAL_FIELD_AREA,
  FETCH_VIEW_ORIGINATION_PROJECT,
  GET_BASELINE_FIELD_SAMPLE_ID,
  SUBMIT_ACTIVITY_FIELD_INFO,
  SUBMIT_BASELINE_FARM,
  SUBMIT_BASELINE_FIELD_INFO,
} from 'urls';

export const useReportingApis = () => {
  const [loadingCounter, setLoadingCounter] = useState(0);

  const incrementLoader = () => setLoadingCounter((prev) => prev + 1);
  const decrementLoader = () => setLoadingCounter((prev) => prev - 1);
  const loading = loadingCounter > 0;
  const navigate = useNavigate();

  const submitBaselineField = async (body) => {
    incrementLoader();
    return axios
      .post(SUBMIT_BASELINE_FIELD_INFO, body)
      .then((response) => response.data)
      .catch((e) => {
        throw e;
      })
      .finally(decrementLoader);
  };
  const submitActivityField = async (body) => {
    incrementLoader();
    return axios
      .post(SUBMIT_ACTIVITY_FIELD_INFO, body)
      .then((response) => response.data)
      .catch(() => {})
      .finally(decrementLoader);
  };
  const reportingFetchAPI = (url, body) => {
    return axios.get(url, body);
  };

  const editBaselineField = async (params, body) => {
    incrementLoader();
    return axios
      .put(EDIT_BASELINE_FIELD_INFO, body, { params: params })
      .then((response) => response.data)
      .catch((e) => {
        throw e;
      })
      .finally(decrementLoader);
  };

  const submitBaselineFarm = async (body) => {
    incrementLoader();
    return axios
      .put(SUBMIT_BASELINE_FARM, body)
      .then((response) => response.data)
      .catch((e) => {
        throw e;
      })
      .finally(decrementLoader);
  };

  const fetchBaselineFieldDetails = async (url) => {
    incrementLoader();
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        if (
          error?.response?.status === LANDING_PAGE_CONTENT.api_fail_status_401
        ) {
          navigate(pathName.participant.landingPage);
        }
      })
      .finally(decrementLoader);
  };

  const fetchBaselineFarmYears = async (params, type) => {
    incrementLoader();
    return axios
      .get(FETCH_BASELINE_FARM_YEARS, { params: params })
      .then((response) => {
        const data = response.data;
        if (data) {
          return formatReportingYearsData(data, 'farm', type);
        }
        return [];
      })
      .catch(() => {})
      .finally(decrementLoader);
  };

  const fetchBaselineFieldYears = async (params, type) => {
    incrementLoader();
    return axios
      .get(GET_BASELINE_FIELD_SAMPLE_ID, {
        params: params,
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          return formatReportingYearsData(data, 'field', type);
        }
        return [];
      })
      .finally(decrementLoader);
  };

  const fetchFieldArea = async (params) => {
    return axios
      .get(FETCH_TOTAL_FIELD_AREA, { params: params })
      .then((response) => response.data)
      .catch(() => {});
  };

  const fetchProjectData = async (path, errorHandler) => {
    return axios
      .get(`${FETCH_VIEW_ORIGINATION_PROJECT}${path}`)
      .then((response) => response.data)
      .catch(errorHandler);
  };

  const fetchProjectActivities = async (projectId) => {
    const url = FETCH_PROJECT_ACTIVITIES(projectId);

    return axios
      .get(url)
      .then((response) => response.data)
      .catch(() => {});
  };

  const getFormattedAcreageData = (acreageDataList) => {
    return acreageDataList
      .sort((a, b) => new Date(a.updatedDate) - new Date(b.updatedDate)) // sort in order of activity added
      .map((item) => {
        return {
          fieldProjectActivityId: item.fieldProjectActivityId,
          activityId: item.activityId,
          area: item.area,
          activityCategory: item.activityCategory,
          projectActivityType: item.projectActivityType,
          speciesCategory: item.edgeOfFieldDim?.speciesCategoryId,
          stripWidth: item.edgeOfFieldDim?.stripWidth,
          barrierWidth: item.edgeOfFieldDim?.barrierWidth,
          rowsCount: item.edgeOfFieldDim?.numOfRows,
          speciesDensity: item.edgeOfFieldDim?.speciesDensity,
          edgeOfFieldSampleId: item.edgeOfFieldDim?.edgeOfFieldSampleId,
        };
      });
  };

  const fetchActivityAcreage = async (fieldId, sampleId, projectCycleId) => {
    const url = `${FETCH_ACTIVITY_ACREAGE}?fieldId=${fieldId}&sampleId=${sampleId}&projectCycleId=${projectCycleId}`;

    return axios
      .get(url)
      .then((response) => {
        return getFormattedAcreageData(response.data);
      })
      .catch(() => {});
  };

  const saveImplementedActivities = async (payload) => {
    return axios
      .put(FETCH_ACTIVITY_ACREAGE, payload)
      .then((response) => response.data)
      .catch(() => {});
  };

  const fetchSoilAmendmentsDropdownList = async (projectId) => {
    return axios
      .get(FETCH_SOIL_AMENDMENTS_DROPDOWN, { params: { projectId: projectId } })
      .then((response) => response.data)
      .catch(() => {});
  };

  return {
    loading,
    submitBaselineField,
    editBaselineField,
    submitBaselineFarm,
    fetchBaselineFieldDetails,
    fetchBaselineFarmYears,
    fetchBaselineFieldYears,
    fetchFieldArea,
    fetchProjectData,
    reportingFetchAPI,
    submitActivityField,
    fetchProjectActivities,
    fetchActivityAcreage,
    saveImplementedActivities,
    fetchSoilAmendmentsDropdownList,
  };
};
