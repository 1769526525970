import styled from 'styled-components';
import { BLACK, GRAYISH_BLUE, DARK_CHARCOAL } from 'theme/GlobalColors';

export const TermsOfAttestationWrapper = styled.div`
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  border-style: solid;
  border-color: ${GRAYISH_BLUE};
  border-width: 1px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  width: 90%;
  position: relative;
  overflow-y: auto;
`;

export const TermsOfAttestationHeading = styled.div`
  color: ${BLACK};
  text-align: center;
  font: 700 14px 'Open Sans', sans-serif;
  position: relative;
  width: 640px;
`;

export const ContentRestrictionHeading = styled.div`
  color: ${BLACK};
  text-align: left;
  font: 600 14px 'Open Sans', sans-serif;
  text-decoration: underline;
  position: relative;
  width: 640px;
`;

export const AttestationDetails = styled.div`
  color: ${BLACK};
  text-align: left;
  font: 400 14px 'Open Sans', sans-serif;
  position: relative;
`;

export const AttestationSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 100%;
`;

export const AcknowledgementCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
`;

export const AcknowledgementText = styled.div`
  color: ${DARK_CHARCOAL};
  text-align: left;
  font: 400 14px 'Open Sans', sans-serif;
  position: relative;
`;
