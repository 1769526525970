import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MainWrapper,
  BoxWrapper,
  PermissionHeadingWrapper,
  PermissionTextWrapper,
} from 'pages/AccessDeniedPage/AccessDeniedPage.style';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { buttonStyle } from 'components/UserManagementModal/AddUser.style';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import Footer from 'layout/footer/Footer.react';
import {
  logoStyle,
  logoWrapper,
  useStyles as useHeaderStyles,
} from 'layout/header/style';
import { useStyles as useGlobalStyle } from 'theme/GlobalStyles';
import ClearCarbonLogo from '../../assets/Deloitte.png';
import {
  participantLayoutContentPadding,
  ContentWithFooter,
} from 'layout/Layout.style';
import { pathName } from 'Routes/routes.const';
import { useNavigate } from 'react-router';
import { useFirebaseSignIn } from 'security';

export const DisplayContent = ({ strechPage = false, children }) => {
  if (strechPage) {
    return <ContentWithFooter>{children}</ContentWithFooter>;
  }
  return children;
};

DisplayContent.propTypes = {
  strechPage: PropTypes.bool,
  children: PropTypes.node,
};

const Logout = () => {
  const { handleLogin, removeAllEventListeners } = useFirebaseSignIn();

  useEffect(() => {
    removeAllEventListeners();
  }, []);

  const { MainContent, MainContainer } = useGlobalStyle();
  const { HeaderContainer, LogoBox } = useHeaderStyles();

  const navigate = useNavigate();
  const login = async () => {
    handleLogin();
    navigate(pathName.portfolio);
  };

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <HeaderContainer maxWidth="xxl">
          <Box>
            <LogoBox>
              <Toolbar variant="dense" disableGutters sx={logoWrapper}>
                <img
                  src={ClearCarbonLogo}
                  alt="Clear Carbon"
                  style={logoStyle}
                />
              </Toolbar>
            </LogoBox>
          </Box>
        </HeaderContainer>
      </AppBar>
      <DisplayContent strechPage={true}>
        <MainContainer
          style={{ flex: 1 }}
          disableGutters
          maxWidth="xl"
          isParticipantLanding={true}
          isParticipantEnrolled={false}
          isParticipantPortalAccessDenied={false}
          isAccessDenied={false}>
          <MainContent
            marginLeft="0rem"
            padding={participantLayoutContentPadding}
            fullWidth>
            <MainWrapper>
              <BoxWrapper>
                <PermissionHeadingWrapper>
                  Log out successful
                </PermissionHeadingWrapper>
                <PermissionTextWrapper>
                  <Button
                    onClick={login}
                    size="medium"
                    variant="contained"
                    color="success"
                    //   sx={{ textTransform: 'none' }}
                    disableElevation
                    sx={[buttonStyle(), primaryButtonStyle()]}>
                    Sign In
                  </Button>
                </PermissionTextWrapper>
              </BoxWrapper>
            </MainWrapper>
          </MainContent>
        </MainContainer>
        <Footer
          isParticipantLanding={true}
          isParticipantMenu={
            location.pathname === pathName.participant.menuPage
          }
          isInActivityPage={
            location.pathname ===
              pathName.participant.participantFieldActivity ||
            location.pathname ===
              pathName.participant.participantDataCollection ||
            location.pathname === pathName.participant.participantFieldBaseline
          }
        />
      </DisplayContent>
    </>
  );
};
export default Logout;
