import { checkTernaryCondition } from 'utils/helper';
import TillageModalContent from '../TillageModalContent/TillageModalContent.react';
import TillageModal from '../TillageModal.react';
import {
  formFieldNames,
  initialTillageModalData,
  initialTillageModalErrors,
  MODAL_CONTENT,
} from '../TillageModal.content';
import { useForm } from 'hooks/useForm';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as Calendericon } from '../../../../../../../assets/icons/calenderIcon.svg';
import { DARK_GREEN } from 'theme/GlobalColors';
import { DATE_FORMAT } from 'utils/config';
import moment from 'moment';
import PropTypes from 'prop-types';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';

const TillageModalWrapper = ({
  actsAsModal,
  hasTestDefaultDate,
  year,
  id,
  modalOpen,
  setModalOpen,
  submitHandler,
  formDataValue,
  practiceTypeData,
  setIsAddBtnDisabled = () => {
    /* do nothing here */
  },
  makeFormDirty = () => null,
  disableAllFieldActions = false,
}) => {
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    checkTernaryCondition(
      !actsAsModal && formDataValue !== undefined,
      formDataValue,
      initialTillageModalData,
    ),
  );
  const { operationsData } = useContext(
    implementedActivitiesWithOpsDataContext,
  );
  const { tillageTypeDropdown, selectedTillagePractice } = useContext(
    baselineDropdownContext,
  );
  const [errorState, setErrorState] = useState(initialTillageModalErrors);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [openDatePickersIndex, setOpenDatePickersIndex] = useState([]);
  const [onHoverDateApplied, setOnHoverDateApplied] = useState(false);
  const [tillageDateError, setTillageDateError] = useState({
    flag: false,
    indexArray: [],
  });
  const [isCombinationUnique, setIsCombinationUnique] = useState(true);
  const [duplicatedDate, setDuplicateDate] = useState({
    flag: false,
    dupIndexArray: [],
  });
  const [hoveredPickerIndex, setHoveredPickerIndex] = useState(-1);
  const [tillageTypeDeepTillage, setTillageTypeDeepTillage] = useState(false);
  const [noDataOptionSelected, setNoDataOptionSelected] = useState(false);
  const { deleteHandler } = useContext(implementedActivitiesWithOpsDataContext);
  const [checkTillageDepthError, setCheckTillageDepthError] = useState({});
  const [checkTillageSurfaceError, setCheckTillageSurfaceError] = useState({});
  const tillageDatePickerProps = (index) => {
    return {
      label: MODAL_CONTENT.tillageDatePickerLabel,
      inputFormat: DATE_FORMAT,
      icon: Calendericon,
      calendarOpenDate: checkTernaryCondition(
        hasTestDefaultDate,
        new Date(),
        `${year - 1}-09-01`,
      ),
      width: STYLE_CONSTANTS.eight_rem,
      height: STYLE_CONSTANTS.two_rem,
      placeholder: MODAL_CONTENT.tillageDatePickerPlaceholder,
      fontSize: STYLE_CONSTANTS.input_font_size,
      minWidth: STYLE_CONSTANTS.seven_point_five_rem,
      errorMessage: '',
      error:
        errorState.tillageDates.indexes.length > 0 ||
        tillageDateError.indexArray.length > 0 ||
        duplicatedDate.dupIndexArray.length > 0,
      customSelectedDateColor: DARK_GREEN,
      validationError: !isCombinationUnique,
      minDate: `${year - 1}-09-01`,
      maxDate: `${year}-12-31`,
    };
  };

  const setModalErrorState = (value, fieldName, indexVal) => {
    switch (fieldName) {
      case 'tillagePractice':
        setErrorState({ ...errorState, tillagePractice: value === '' });
        break;
      case 'tillageDates':
        setErrorState({
          ...errorState,
          tillageDates: {
            flag:
              [...errorState.tillageDates.indexes].filter(
                (index) => index !== indexVal,
              ).length > 0 || value === '',
            indexes: [...errorState.tillageDates.indexes].filter(
              (index) => index !== indexVal,
            ),
          },
        });
        break;
      case 'surfaceDisturbance':
        setErrorState({
          ...errorState,
          surfaceDisturbance: value > 100,
        });
        break;
      case 'depthOfTillage':
        setErrorState({
          ...errorState,
          depthOfTillage: +value === 0,
        });
        break;
    }
  };

  const handleTillageDateDelete = (index) => {
    const positionOfIndex = errorState.tillageDates.indexes.indexOf(index);
    setErrorState({
      ...errorState,
      tillageDates: {
        flag:
          [...errorState.tillageDates.indexes].filter((item) => item !== index)
            .length > 0,
        indexes: [...errorState.tillageDates.indexes]
          .filter((item) => item !== index)
          .map((dateVal, elementIndex) =>
            dateVal === 0
              ? dateVal
              : checkTernaryCondition(
                  elementIndex >= positionOfIndex,
                  dateVal - 1,
                  dateVal,
                ),
          ),
      },
    });
    const existingDates = formValue?.tillageDates;
    const filteredDates = existingDates.filter(
      (_, dateIndex) => dateIndex !== index,
    );
    customFieldChangeHandler('tillageDates', filteredDates);
    const filteredDuplicateArray = [...duplicatedDate.dupIndexArray].filter(
      (item) => item !== index,
    );
    setDuplicateDate({
      flag: filteredDuplicateArray.length > 0,
      dupIndexArray: filteredDuplicateArray,
    });
  };

  const updateTillageDate = (newDate, dateIndex) => {
    const existingDates = formValue?.tillageDates;
    if (newDate === null) {
      customFieldChangeHandler('tillageDates', [...existingDates, newDate]);
    } else if (newDate === undefined) {
      customFieldChangeHandler(
        'tillageDates',
        existingDates.map((date, index) =>
          checkTernaryCondition(
            index === dateIndex,
            MODAL_CONTENT.tillageDatePickerPlaceholder,
            date,
          ),
        ),
      );
    } else {
      customFieldChangeHandler(
        'tillageDates',
        existingDates.map((date, index) =>
          checkTernaryCondition(index === dateIndex, newDate, date),
        ),
      );
    }
  };
  const setDateErrorValue = () => {
    if (formValue.tillageDates.length > 0) {
      let count = 0;
      let indexArray = [];
      formValue.tillageDates.forEach((date, index) => {
        if (
          (new Date(date) > new Date(`${+year}-12-31`) ||
            new Date(date) < new Date(`${year - 2}-12-31`)) &&
          date !== null &&
          actsAsModal
        ) {
          count = count + 1;
          indexArray.push(index);
        }
      });
      if (count > 0) {
        setTillageDateError({ flag: true, indexArray: indexArray });
      } else {
        setTillageDateError({ flag: false, indexArray: indexArray });
      }
    }
  };

  const datesAreNotFilledOrInvalid = () => {
    return (
      formValue.tillageDates.includes(null) ||
      formValue.tillageDates
        .map((item) => moment(item).format('YYYY-MM-DD'))
        .includes(MODAL_CONTENT.invalid_date)
    );
  };

  useEffect(() => {
    setDateErrorValue();
  }, [formValue.tillageDates]);

  useEffect(() => {
    if (tillageTypeDropdown?.length > 0) {
      const newTillageType = formValue.tillagePractice;
      const value = tillageTypeDropdown.find(
        (tillageType) => tillageType?.value === newTillageType,
      )?.label;
      setTillageTypeDeepTillage(
        value === MODAL_CONTENT.tillage_type_deep_practice,
      );
      if (value === MODAL_CONTENT.noTillageOption) {
        setFormValue({
          ...initialTillageModalData,
          tillagePractice: newTillageType,
        });
        setNoDataOptionSelected(true);
        setErrorState(initialTillageModalErrors);
      } else setNoDataOptionSelected(false);
    }
  }, [formValue.tillagePractice, tillageTypeDropdown]);

  useEffect(() => {
    const isAnyDateInvalid = formValue.tillageDates
      .map((item) => moment(item).format('YYYY-MM-DD'))
      .includes(MODAL_CONTENT.invalid_date);
    if (isSubmitClicked) {
      setIsSubmitDisabled(
        Object.values(errorState).includes(true) ||
          formValue.tillageDates.includes(null) ||
          isAnyDateInvalid ||
          tillageDateError.flag ||
          duplicatedDate.flag,
      );
    } else {
      setIsSubmitDisabled(
        Object.values(errorState).includes(true) ||
          tillageDateError.flag ||
          duplicatedDate.flag ||
          !isCombinationUnique,
      );
    }
  }, [
    errorState,
    tillageDateError,
    isSubmitClicked,
    formValue.tillageDates,
    isCombinationUnique,
    duplicatedDate,
  ]);

  useEffect(() => {
    if (actsAsModal) {
      const checkCombinationUnique = () => {
        const keysToCheck = [
          formFieldNames.tillagePractice,
          formFieldNames.tillageDates,
        ];

        const convertToTargetTime = (date) => {
          const targetTime = new Date(date);
          targetTime.setUTCHours(0, 0, 0, 0); // Reset to 00:00:00 UTC
          targetTime.setHours(
            targetTime.getHours() + 24,
            targetTime.getMinutes(),
          );
          return targetTime;
        };

        const isSameDate = (date1, date2) => {
          const d1 = date1;
          const d2 = convertToTargetTime(date2);
          return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
          );
        };

        const isMatch = (dataItem) => {
          return keysToCheck.every((key) => {
            if (key === formFieldNames.tillageDates) {
              return dataItem[key].some((date) =>
                formValue[key].some((formDate) => isSameDate(date, formDate)),
              );
            }
            return dataItem[key] === formValue[key];
          });
        };

        const isUnique = !practiceTypeData.some(({ data }) => isMatch(data));
        setIsCombinationUnique(isUnique);
      };

      checkCombinationUnique();
    }
  }, [actsAsModal, formValue, practiceTypeData]);

  useEffect(() => {
    const noTillage = tillageTypeDropdown.find(
      (item) => item.label === MODAL_CONTENT.noTillageOption,
    );
    if (noTillage !== undefined) {
      const NO_TILLAGE_TYPE_ID = noTillage.value;
      const hasNoTillageType = practiceTypeData.some(
        (item) => item.data.tillagePractice === NO_TILLAGE_TYPE_ID,
      );
      setIsAddBtnDisabled(hasNoTillageType);
    }
  }, [practiceTypeData, tillageTypeDropdown, actsAsModal]);

  useEffect(() => {
    if (!actsAsModal) {
      submitHandler(formValue, id, BASELINE_MODAL_CONTENT.tillage_list);
    }
  }, [actsAsModal, formValue]);

  useEffect(() => {
    const newDepthErrorState = {};
    const newSurfaceErrorState = {};
    for (const item of operationsData.tillageTypeDtoList) {
      if (item.data.depthOfTillage === 0) {
        newDepthErrorState[item.id] = true;
      }
      if (item.data.surfaceDisturbance > 100) {
        newSurfaceErrorState[item.id] = true;
      }
    }
    setCheckTillageDepthError(newDepthErrorState);
    setCheckTillageSurfaceError(newSurfaceErrorState);
  }, [operationsData]);

  return (
    <>
      {checkTernaryCondition(
        actsAsModal,
        <TillageModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          submitHandler={submitHandler}
          formValue={formValue}
          setFormValue={setFormValue}
          customFieldChangeHandler={customFieldChangeHandler}
          tillageTypeDropdown={tillageTypeDropdown}
          errorState={errorState}
          setErrorState={setErrorState}
          isSubmitDisabled={isSubmitDisabled}
          setIsSubmitDisabled={setIsSubmitDisabled}
          isSubmitClicked={isSubmitClicked}
          setIsSubmitClicked={setIsSubmitClicked}
          openDatePickersIndex={openDatePickersIndex}
          setOpenDatePickersIndex={setOpenDatePickersIndex}
          setOnHoverDateApplied={setOnHoverDateApplied}
          checkTillageDepthError={checkTillageDepthError}
          checkTillageSurfaceError={checkTillageSurfaceError}
          onHoverDateApplied={onHoverDateApplied}
          tillageDateError={tillageDateError}
          setTillageDateError={setTillageDateError}
          setDuplicateDate={setDuplicateDate}
          duplicatedDate={duplicatedDate}
          hoveredPickerIndex={hoveredPickerIndex}
          setHoveredPickerIndex={setHoveredPickerIndex}
          noDataOptionSelected={noDataOptionSelected}
          tillageDatePickerProps={tillageDatePickerProps}
          setModalErrorState={setModalErrorState}
          updateTillageDate={updateTillageDate}
          datesAreNotFilledOrInvalid={datesAreNotFilledOrInvalid}
          selectedTillagePractice={selectedTillagePractice}
          tillageTypeDeepTillage={tillageTypeDeepTillage}
          handleTillageDateDelete={handleTillageDateDelete}
          actsAsModal={actsAsModal}
          isCombinationUnique={isCombinationUnique}
          disableAllFieldActions={disableAllFieldActions}
          makeFormDirty={makeFormDirty}
        />,
        <TillageModalContent
          modalOpen={modalOpen}
          noDataOptionSelected={noDataOptionSelected}
          setIsSubmitClicked={setIsSubmitClicked}
          selectedTillagePractice={selectedTillagePractice}
          formValue={formValue}
          customFieldChangeHandler={customFieldChangeHandler}
          setDuplicateDate={setDuplicateDate}
          duplicatedDate={duplicatedDate}
          setOnHoverDateApplied={setOnHoverDateApplied}
          onHoverDateApplied={onHoverDateApplied}
          checkTillageDepthError={checkTillageDepthError}
          checkTillageSurfaceError={checkTillageSurfaceError}
          errorState={errorState}
          setModalErrorState={setModalErrorState}
          hoveredPickerIndex={hoveredPickerIndex}
          setHoveredPickerIndex={setHoveredPickerIndex}
          hasTestDefaultDate={hasTestDefaultDate}
          openDatePickersIndex={openDatePickersIndex}
          updateTillageDate={updateTillageDate}
          setOpenDatePickersIndex={setOpenDatePickersIndex}
          tillageDatePickerProps={tillageDatePickerProps}
          tillageDateError={tillageDateError}
          isCombinationUnique={isCombinationUnique}
          tillageTypeDeepTillage={tillageTypeDeepTillage}
          tillageTypeDropdown={tillageTypeDropdown}
          disableAllFieldActions={disableAllFieldActions}
          deleteHandler={() =>
            deleteHandler(
              id,
              MODAL_CONTENT.heading,
              tillageTypeDropdown.find(
                (tillageType) =>
                  tillageType?.value === formValue?.tillagePractice,
              )?.label,
            )
          }
          handleTillageDateDelete={handleTillageDateDelete}
          makeFormDirty={makeFormDirty}
        />,
      )}
    </>
  );
};

TillageModalWrapper.propTypes = {
  actsAsModal: PropTypes.bool,
  hasTestDefaultDate: PropTypes.bool,
  id: PropTypes.number,
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
    index: PropTypes.number,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  formValue: PropTypes.object,
  practiceTypeData: PropTypes.array,
  setIsAddBtnDisabled: PropTypes.func,
  makeFormDirty: PropTypes.func,
  year: PropTypes.number,
  checkTillageDepthError: PropTypes.object,
  setCheckTillageDepthError: PropTypes.func,
  checkTillageSurfaceError: PropTypes.object,
  setCheckTillageSurfaceError: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
};

export default TillageModalWrapper;
