import axios from 'axios';
import { getValueOrDefault } from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { checkTernaryCondition, getDefaultValue, isEmpty } from 'utils/helper';
import { FETCH_TILLAGE_SYSTEM_TYPE_BY_ID } from 'urls';
import moment from 'moment';

export const initialDeleteState = {
  id: null,
  isWholeModalDeleted: false,
  modalType: '',
  practiceTypeValue: '',
  deleteModalHeading: '',
  deleteModalContent: '',
  parentId: null,
  cropType: '',
};
export const onClickEmptyState = (year, setterMethod, parentId = null) => {
  if (parentId !== null) {
    setterMethod({
      flag: true,
      id: 0,
      data: { id: uniqueId(), year: year, formData: [] },
      currentRowId: 0,
      parentId: parentId,
    });
  } else {
    setterMethod({
      flag: true,
      id: 0,
      data: { id: uniqueId(), year: year, formData: [] },
      currentRowId: 0,
    });
  }
};

/*export const getIsPracticeModalMandatory = (activityList, cropActivityList) => {
  const practiceModalArray = cropActivityList.map((obj) => {
    return activityList?.find((item) => item.id === obj)?.itemLabel;
  });
  return practiceModalArray;
};*/
export const editStateSetter = (
  setIsInitialLoad,
  year,
  id,
  setterMethod,
  data,
  parentId = null,
) => {
  setIsInitialLoad(false);
  if (parentId !== null) {
    setterMethod({
      flag: true,
      id: id,
      data: { id: id, year: year, formData: [data] },
      currentRowId: id,
      parentId: parentId,
    });
  } else {
    setterMethod({
      flag: true,
      id: id,
      data: { id: id, year: year, formData: [data] },
      currentRowId: id,
    });
  }
};
/*export const findDeleteAndPresentPracticeList = (
  practiceModalsData,
  deletePracticeModalsData,
  modalPracticeArray,
  editDeleteModalTypeMap,
  currentId,
) => {
  let tempPracticeList = {
    ...practiceModalsData,
  };
  let tempDeletePracticeList = {
    ...deletePracticeModalsData,
  };
  modalPracticeArray.forEach((item) => {
    const targetModal = editDeleteModalTypeMap[item.type];
    const modalTypePracticeList = targetModal?.practiceList;
    const modalDeleteList = targetModal?.deletedPracticeList;
    if (modalTypePracticeList) {
      let filterData;
      let removedObjects = tempDeletePracticeList[modalDeleteList];
      filterData = tempPracticeList[modalTypePracticeList].filter((obj) => {
        if (obj.parentId === item.parentId && item.parentId === currentId) {
          removedObjects.push({ id: obj.id, parentId: obj.parentId });
          return false;
        }
        return true;
      });
      tempPracticeList = {
        ...tempPracticeList,
        [modalTypePracticeList]: filterData,
      };
      tempDeletePracticeList = {
        ...tempDeletePracticeList,
        [modalDeleteList]: removedObjects,
      };
    }
  });
  return [tempPracticeList, tempDeletePracticeList];
};*/
/*export const findDataForSingleModalDelete = (
  deleteData,
  practiceModalsData,
  modalTypePracticeList,
) => {
  const removedObjects = [
    {
      id: deleteData?.id,
      parentId: practiceModalsData?.[modalTypePracticeList]?.find(
        (obj) => obj.id === deleteData?.id,
      )?.parentId,
    },
  ];
  const filterData = practiceModalsData?.[modalTypePracticeList].filter(
    (obj) => obj.id !== deleteData?.id,
  );
  return [removedObjects, filterData];
};*/
export const mergeNitrogenRecords = (nitrogenList) => {
  // Create a map to store unique records based on the specified criteria
  const recordMap = new Map();

  nitrogenList.forEach((record) => {
    const data = record.data;
    const key = `${data.fertilizerType}-${data.applicationMethod}-${data.hasInhibitor}-${data.animalType}-${data.manureType}-${data.applicationRate}-${data.unitOfMeasure}-${data.nPercentage}-${data.pPercentage}-${data.kPercentage}`;
    if (recordMap.has(key)) {
      // If the record with the same criteria exists, merge the datesApplied and update the IDs
      const existingRecord = recordMap.get(key);
      existingRecord.id += `_${record.id}`;
      existingRecord.data.id += `_${data.id}`;
      data.datesApplied.forEach((date) => {
        existingRecord.data.datesApplied.push(new Date(date));
      });
    } else {
      // If the record with the same criteria doesn't exist, create a new entry
      const newRecord = JSON.parse(JSON.stringify(record));
      newRecord.data.datesApplied = data.datesApplied.map(
        (date) => new Date(date),
      );
      recordMap.set(key, newRecord);
    }
  });

  // Convert the map values to an array
  return Array.from(recordMap.values());
};

export const mergeTillageRecords = (tillageTypeDtoList) => {
  // Create a map to store unique records based on the specified criteria
  const recordMap = new Map();

  tillageTypeDtoList.forEach((record) => {
    const data = record.data;
    const key = `${data.tillagePractice}-${data.depthOfTillage}-${data.surfaceDisturbance}`;
    if (recordMap.has(key)) {
      // If the record with the same criteria exists, merge the datesApplied and update the IDs
      const existingRecord = recordMap.get(key);
      existingRecord.id += `_${record.id}`;
      existingRecord.data.id += `_${data.id}`;
      data.tillageDates.forEach((date) => {
        existingRecord.data.tillageDates.push(new Date(date));
      });
    } else {
      // If the record with the same criteria doesn't exist, create a new entry
      const newRecord = JSON.parse(JSON.stringify(record));
      newRecord.data.tillageDates = data.tillageDates.map(
        (date) => new Date(date),
      );
      recordMap.set(key, newRecord);
    }
  });

  // Convert the map values to an array
  return Array.from(recordMap.values());
};

// Note: This returns the date by stripping timezone information
const getDateWithoutTimezone = (dateString) => {
  if (!dateString) return '';
  return moment.utc(dateString).format('YYYY-MM-DD');
};

export const extractBaselineFieldData = (
  baselineData,
  animalTypeDropdown,
  fertilizerTypeDropDown,
) => {
  let cashCropList = [];
  let sampleCropList = [];
  let irrigationList = [];
  let soilAmendmentList = [];
  let residueManagementTypeList = [];
  const emptyResidueList = {
    agricultureResiduesManaged: '',
    cropResidueRemoved: '',
  };
  baselineData.baselineFieldCrop.forEach((yearWiseData) => {
    const sampleCrop = yearWiseData.fieldSampleCrops[0];
    sampleCropList.push(sampleCrop);
    const cashCropData = {
      isFetchedFromAPI: true,
      addedFromImplementedActivities: sampleCrop.addedFromImplementedActivities,
      id: sampleCrop.cropSampleId,
      isCopied: sampleCrop.isCopied,
      cropSampleId: sampleCrop.cropSampleId,
      sampleId: sampleCrop.sampleId,
      cropId: checkTernaryCondition(
        sampleCrop.addedFromImplementedActivities &&
          sampleCrop.cropArea === null,
        '',
        sampleCrop.cropId,
      ),
      cropArea: sampleCrop.cropArea,
      cropSeason: sampleCrop.cropSeason,
      cropAreaUom: sampleCrop.cropAreaUom,
      annualYield: sampleCrop.annualYield,
      annualYieldUom: sampleCrop.annualYieldUom,
      plantingDensity: sampleCrop.plantingDensity,
      cropValue: sampleCrop.cropValue,
      plantingDensityUom: sampleCrop.plantingDensityUom,
      cropDepth: sampleCrop.seedingDepth,
      cropDepthUom: sampleCrop.seedingDepthUom,
      cropPlantDate: getDateWithoutTimezone(sampleCrop.cropPlantDate),
      cropTerminationDate: getDateWithoutTimezone(
        sampleCrop.cropTerminationDate,
      ),
      activityList: sampleCrop.cropActivityList?.map(
        (activity) => activity?.projectActivityId,
      ),
      cropRotation: sampleCrop.cropRotationsImplemented,
      productionPurpose:
        sampleCrop.productionPurpose.charAt(0) +
        sampleCrop.productionPurpose.slice(1).toLowerCase(),
      cropPlannedManagementTypeId: checkTernaryCondition(
        sampleCrop.addedFromImplementedActivities &&
          sampleCrop.cropArea === null,
        '',
        sampleCrop.cropPlannedManagementTypeId,
      ),
      tillageSystemOther: checkTernaryCondition(
        sampleCrop.tillageSystemOther === '' ||
          sampleCrop.tillageSystemOther === null,
        '',
        sampleCrop.tillageSystemOther,
      ),
      tillageSystemDisturbanceId: checkTernaryCondition(
        sampleCrop.tillageSystemDisturbanceId === '' ||
          sampleCrop.tillageSystemDisturbanceId === null,
        '',
        sampleCrop.tillageSystemDisturbanceId,
      ),
    };
    irrigationList = sampleCrop.irrigationList.map((obj) => {
      return {
        id: obj.irrigationSampleId || 0,
        irrigationType: getValueOrDefault(obj.irrigationMethodId),
        uom: `${obj.irrigationVolumeUom}`,
        irrigationRate: getValueOrDefault(obj.irrigationVolume),
        isFetchedFromAPI: true,
      };
    });
    soilAmendmentList = sampleCrop.soilAmendmentsList.map((obj) => ({
      addedFromImplementedActivities: obj?.addedFromImplementedActivities,
      id: obj.soilAmendSampleId,
      soilAmendmentsType: getValueOrDefault(obj.soilAmendId),
      amountApplied: getValueOrDefault(obj.soilAmendAmountApplied),
      unitOfMeasure: getValueOrDefault(obj.soilAmendAmountAppliedUom),
      isFetchedFromAPI: true,
    }));
    residueManagementTypeList = sampleCrop.agresidueDimList.map((obj) => ({
      addedFromImplementedActivities: obj?.addedFromImplementedActivities,
      id: obj.agresidueSampleId,
      agresidueSampleId: obj.agresidueSampleId,
      agricultureResiduesManaged:
        obj?.cropResidueManagementRemovalMethod
          ?.cropResidueManagementRemovalMethodId,
      cropResidueManagementRemovalMethod:
        obj?.cropResidueManagementRemovalMethod
          ?.cropResidueManagementRemovalMethod,
      cropResidueRemoved: getValueOrDefault(obj.agresidueRemovalpct),
    }));
    if (residueManagementTypeList.length === 0) {
      residueManagementTypeList.push(emptyResidueList);
    }
    cashCropList.push({
      ...cashCropData,
      ...residueManagementTypeList[0],
      soilAmendmentList: checkTernaryCondition(
        soilAmendmentList.length > 0,
        soilAmendmentList.filter((item) => item.data === undefined),
        [],
      ),
      irrigationList: checkTernaryCondition(
        irrigationList.length > 0,
        irrigationList.filter((item) => item.data === undefined),
        [
          {
            isFetchedFromAPI: true,
            irrigationRate: '',
            irrigationType: '',
            uom: '',
            id: uniqueId(),
          },
        ],
      ),
    });
  });

  let nitrogenList = [];
  let coverCropList = [];
  let tillageTypeDtoList = [];
  let edgeOfFieldList = [];

  if (baselineData.edgeOfFieldDimList) {
    edgeOfFieldList.push(
      ...baselineData.edgeOfFieldDimList.map((obj) => ({
        id: obj.edgeOfFieldSampleId,
        data: {
          addedFromImplementedActivities:
            obj?.addedFromImplementedActivities || false,
          practiceType: getValueOrDefault(obj.edgeOfFieldPracticeId),
          area: getValueOrDefault(obj.edgeOfFieldArea),
          speciesCategory: getValueOrDefault(obj.speciesCategoryId),
          stripWidth: getValueOrDefault(obj.stripWidth),
          barrierWidth: getValueOrDefault(obj.barrierWidth),
          rowCount: getValueOrDefault(obj.numOfRows),
          id: obj.edgeOfFieldSampleId,
          speciesDensity: getValueOrDefault(obj.speciesDensity),
        },
      })),
    );
  }

  if (baselineData.fertilizerList) {
    nitrogenList.push(
      ...baselineData.fertilizerList.map((obj) => ({
        id: obj.fertilizerSampleId,
        data: {
          id: obj.fertilizerSampleId,
          fertilizerType: getValueOrDefault(obj.fertilizerId),
          applicationMethod: getValueOrDefault(
            obj.fertilizerApplicationMethodId,
          ),
          applicationRate: getValueOrDefault(
            obj.fertilizerApplicationRate,
          )?.toString(),
          datesApplied: checkTernaryCondition(
            obj?.applicationDate === null,
            [],
            [moment(obj?.applicationDate?.trim()).toDate()],
          ),
          nPercentage: getValueOrDefault(obj.fertilizerNRate),
          pPercentage: getValueOrDefault(obj.fertilizerPRate),
          kPercentage: getValueOrDefault(obj.fertilizerKRate),
          unitOfMeasure: obj.fertilizerApplicationRateUom,
          hasInhibitor: checkTernaryCondition(
            obj.containsInhibitor,
            'Yes',
            'No',
          ),
          animalType: getDefaultValue(
            animalTypeDropdown?.find(
              (animal) => animal.value === obj.fertilizerId,
            )?.value,
          ),
          manureType: getValueOrDefault(
            checkTernaryCondition(
              obj.manureTypeId === 0 || obj.manureTypeId === null,
              '',
              obj.manureTypeId,
            ),
          ),
          fertilizerCategory: fertilizerTypeDropDown.filter(
            (category) => category.value === obj.fertilizerId,
          )[0]?.category,
        },
      })),
    );
  }
  if (baselineData.tillageDimList) {
    tillageTypeDtoList.push(
      ...baselineData.tillageDimList.map((obj) => ({
        id: obj.tillageSampleId,
        data: {
          addedFromImplementedActivities: obj?.addedFromImplementedActivities,
          id: obj.tillageSampleId,
          tillagePractice: getValueOrDefault(obj.tillagePracticeId),
          customTillagePractice: '',
          tillageDates: checkTernaryCondition(
            obj?.datePerformed === null,
            [],
            [moment(obj?.datePerformed?.trim()).toDate()],
          ),
          surfaceDisturbance: getValueOrDefault(obj.percOfSoilAreaDisturbed),
          depthOfTillage: getValueOrDefault(obj.tillageDepth),
        },
      })),
    );
  }
  sampleCropList.forEach((sampleCrop) => {
    residueManagementTypeList.push(
      ...sampleCrop.agresidueDimList.map((obj) => ({
        id: obj.agresidueSampleId,
        data: {
          addedFromImplementedActivities: obj?.addedFromImplementedActivities,
          id: obj.agresidueSampleId,
          agresidueSampleId: obj.agresidueSampleId,
          agricultureResiduesManaged:
            obj?.cropResidueManagementRemovalMethod
              ?.cropResidueManagementRemovalMethodId,
          cropResidueManagementRemovalMethod:
            obj?.cropResidueManagementRemovalMethod
              ?.cropResidueManagementRemovalMethod,
          cropResidueRemoved: getValueOrDefault(obj.agresidueRemovalpct),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );
    soilAmendmentList.push(
      ...sampleCrop.soilAmendmentsList.map((obj) => ({
        id: obj.soilAmendSampleId,
        data: {
          addedFromImplementedActivities: obj?.addedFromImplementedActivities,
          id: obj.soilAmendSampleId,
          soilAmendmentsType: getValueOrDefault(obj.soilAmendId),
          amountApplied: getValueOrDefault(obj.soilAmendAmountApplied),
          unitOfMeasure: getValueOrDefault(obj.soilAmendAmountAppliedUom),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );
    irrigationList.push(
      ...sampleCrop.irrigationList.map((obj) => ({
        id: obj.irrigationSampleId || 0,
        data: {
          id: obj.irrigationSampleId || 0,
          irrigationType: getValueOrDefault(obj.irrigationMethodId),
          uom: `${obj.irrigationVolumeUom}`,
          irrigationRate: getValueOrDefault(obj.irrigationVolume),
          irrigationArea: getValueOrDefault(obj.irrigationArea)?.toString(),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );
  });
  return {
    practicesData: {
      residueManagementTypeList: residueManagementTypeList,
      edgeOfFieldList: edgeOfFieldList,
      tillageTypeDtoList: tillageTypeDtoList,
      soilAmendmentList: soilAmendmentList,
      irrigationList: checkTernaryCondition(
        irrigationList.length > 0,
        irrigationList,
        [
          {
            isFetchedFromAPI: true,
            irrigationRate: '',
            irrigationType: '',
            uom: '',
            id: uniqueId(),
          },
        ],
      ),
      nitrogenList: nitrogenList,
      coverCropList: coverCropList,
    },
    deletedPracticesData: {
      deletedFertilizerList: [],
      deletedTillageList: [],
      deletedIrrigationList: [],
      deletedSoilAmendmentList: [],
    },
    editMode:
      baselineData?.baselineFieldCrop?.length > 0 ||
      baselineData?.edgeOfFieldDimList?.length > 0 ||
      baselineData?.fertilizerList?.length > 0 ||
      baselineData?.tillageDimList?.length > 0,
    cashCropList: cashCropList,
    nitrogenList: mergeNitrogenRecords(nitrogenList),
    tillageTypeDtoList: mergeTillageRecords(tillageTypeDtoList),
  };
};
export const copyFossilFuelRepeatedItems = (
  newList,
  parentState,
  combinations,
) => {
  let uniqueItems = [],
    repeatedItems = [];
  for (const fossilFuel of newList) {
    const isUnique =
      parentState?.findIndex(
        (fuelData) => fuelData?.fossilFuelType === fossilFuel?.fossilFuelType,
      ) === -1;

    if (isUnique) uniqueItems.push(fossilFuel);
    else {
      const repeatingCombination = combinations.find(
        (combination) =>
          combination.dropdown3Value === fossilFuel.fossilFuelType,
      );
      const modalTitle = `${repeatingCombination?.dropdown1Label} - ${repeatingCombination?.dropdown2Label} - ${repeatingCombination?.dropdown3Label}`;
      repeatedItems.push({
        data: fossilFuel,
        titlePart: modalTitle,
        replaceItemIdKey: 'fossilFuelType',
      });
    }
  }
  return [uniqueItems, repeatedItems];
};
/*export const getPracticeCount = (
  practiceModalsData,
  parentId,
  practiceList,
  noDataOptionKey = null,
  noDataOptionValue = null,
) => {
  return checkTernaryCondition(
    practiceModalsData[practiceList]?.find(
      (item) =>
        item?.parentId === parentId &&
        ((noDataOptionKey === null && noDataOptionValue === null) ||
          item?.data?.[noDataOptionKey] !== noDataOptionValue),
    ),
    1,
    0,
  );
};*/
export const cleanDatesAndIds = (
  practiceModalsData,
  newParentId,
  dateFieldKeys = [],
  dateFieldIsArray = false,
) => {
  return practiceModalsData?.map((practiceData) => {
    const newPracticeId = uniqueId();
    let updatedData = { ...practiceData.data, id: newPracticeId };
    dateFieldKeys.forEach((key) => {
      updatedData = {
        ...updatedData,
        [key]: checkTernaryCondition(dateFieldIsArray, [], ''),
      };
    });
    return {
      ...practiceData,
      parentId: newParentId,
      id: newPracticeId,
      data: updatedData,
    };
  });
};

export const fetchTillageSystemTypeById = async (id) => {
  const response = await axios.get(FETCH_TILLAGE_SYSTEM_TYPE_BY_ID + id);
  const data = response.data;
  return data;
};

/*export const fetchManagementTypeDropdown = async () => {
  const response = await axios.get(FETCH_MANAGEMENT_TYPE_DROPDOWN);
  const data = response.data;
  return data;
};*/
