import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DIM_BLUE_BACKGROUND,
  NEW_DARK_GREEN,
  NEW_FOCUS_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  QUILL_GRAY,
  TEAL_GREEN_HOVER,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapper = styled.div`
  border: 1px solid ${QUILL_GRAY};
  width: ${({ width }) => width};
  border-radius: 2px;
`;
export const AccordionSx = {
  backgroundColor: WHITE,
  boxShadow: 'none',
  '&.Mui-expanded': {
    margin: '0',
  },
  '&.MuiAccordion-root:before': {
    opacity: '0',
  },
  '& .MuiSvgIcon-root': {
    display: 'flex',
    alignItems: 'center',
  },
};
export const ArrowLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: -webkit-fill-available;
`;
export const AccordionSummarySx = {
  padding: '0rem 0.5rem 0rem 0.5rem',
  minHeight: '44px',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
  '&.Mui-expanded': {
    minHeight: '44px',
  },
  '&:hover': {
    backgroundColor: DIM_BLUE_BACKGROUND,
  },
};
export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & .HoverActionsContainer {
    display: none;
  }
  &:hover .HoverActionsContainer {
    display: flex;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  justify-content: end;
  align-items: center;
  min-width: ${({ minWidth }) => minWidth};
`;
export const AccordionDetailsSx = (containsNote) => ({
  padding: '0px',
  margin: `${checkTernaryCondition(
    containsNote,
    '0.5rem',
    '1rem',
  )} 0.5rem 1rem 0.5rem`,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});
export const addBtnSx = (haveRecords, isNone) => ({
  textTransform: 'none',
  border: `1px solid ${NEW_DARK_GREEN}`,
  background: WHITE,
  color: NEW_DARK_GREEN,
  fontWeight: 400,
  marginTop: isNone
    ? '0.5rem'
    : checkTernaryCondition(haveRecords, '1.5rem', '0rem'),
  padding: '0.4rem 0.5rem',
  lineHeight: 'normal',
  height: '1.75rem',
  width: 'fit-content',
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
    padding: '5.5px 7.25px',
  },
  '&.Mui-disabled': {
    color: COOL_GRAY,
    backgroundColor: QUILL_GRAY,
    border: 'none',
  },
});
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  color: ${DARK_CHARCOAL};
`;
export const RowDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;
export const RecordSeparatorLine = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const NoteContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

export const NoteTag = styled.span`
  font-weight: 600;
  font-style: italic;
  margin-left: 5px;
  font-size: 0.75rem;
`;

export const NoteText = styled.span`
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
`;
export const TillageNoteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  margin-left: 1.9rem;
`;
export const NoteWrapper = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  font-style: italic;
  font-family: Open Sans;
`;
export const NoteContent = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  font-family: Open Sans;
`;
