import { pathName } from 'Routes/routes.const';
import PropTypes from 'prop-types';
import DialogBox from 'components/DialogBox/DialogBox.react';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useFirebaseSignIn } from 'security';
import styled from 'styled-components';

function IdleMonitor() {
  const {
    handleLogout,
    addAllEventListener,
    removeAllEventListeners,
    idleModal,
    setIdleModal,
    warningTimeout,
  } = useFirebaseSignIn();

  const location = useLocation();

  useEffect(() => {
    addAllEventListener();
    /* istanbul ignore else */
    if (location.pathname === pathName.logout) {
      removeAllEventListeners();
    }

    return () => {
      removeAllEventListeners();
    };
  }, [location.pathname]);

  const TimerContent = () => {
    return (
      <p>
        This session will expire in{' '}
        <CountDownTimer seconds={warningTimeout / 1000} logOut={handleLogout} />{' '}
        due to inactivity. If you want to extend your session, please select the
        “Continue” button. If you select the “Log out” button or do not respond,
        your session will automatically close.`
      </p>
    );
  };

  return (
    <DialogBox
      dialogActions
      customSx={{ width: '503px', margin: 'auto' }}
      buttonSX={{ border: '1px solid', minWidth: '81px', right: '18.5rem' }}
      isOpen={idleModal}
      onCancel={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleLogout();
      }}
      subtitle={<TimerContent />}
      onConfirm={() => setIdleModal(false)}
      declineCtnLabel={`Log out`}
      acceptCtnLabel={`Continue`}
      isCloseEnabled={false}
      title={`Your session is about to expire`}></DialogBox>
  );
}

export function CountDownTimer({ seconds, logOut }) {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((pre) => {
        if (pre === 0) {
          clearInterval(timer);
          logOut();
          return 0;
        } else return pre - 1;
      });
    }, 1000);
  }, []);

  return (
    <Time>
      {`${Math.floor(time / 60)}`.padStart(2, 0)}:
      {`${time % 60}`.padStart(2, 0)}
    </Time>
  );
}

CountDownTimer.propTypes = {
  seconds: PropTypes.number.isRequired,
  logOut: PropTypes.func.isRequired,
};

const Time = styled.span`
  color: #da291c;
  font-weight: bold;
`;

export default IdleMonitor;
