export const PAGE_CONTENT = {
  addPageTitle: 'Add participant owner',
  editPageTitle: 'Edit participant owner',
  addAndEnrollBtnTitle: 'Add participant and enroll',
  submitBtn: 'Submit',
  saveEditBtn: 'Save',
  cancelBtn: 'Cancel',

  find_name_label: 'Find existing participant by name',
  findByNamePlaceHolder: 'Type and select a participant’s name ',
  firstNamePlaceHolder: 'Type participant’s first name',
  lastNamePlaceHolder: 'Type participant’s last name',
  emailPlaceHolder: 'Type participant’s email address',
  projectPlaceholder: 'Type and select project',
  email: 'Email',
  lastName: 'Last Name',
  firstName: 'First Name',
  project_label: 'Select project to add to',
  firstName_required_error: 'A first name is required to add a participant.',
  lastName_required_error: 'A last name is required to add a participant.',
  email_required_error:
    'A valid email address is required to add a participant.',
  email_inValid_error:
    'An email address must follow a valid format. e.g., name@domain.com ',
  name_not_present_error:
    'Name entered does not exist. Please select a name from the list as you type.',
  duplicate_email_error:
    'The email address {{email}} is already assigned to a different role. Please use a unique email address for each role.',
  project_error: 'A project selection is required to add a participant.',
  project_placeholder: 'Type and select project ',
  project_secondary_message:
    'Participant is already added to the selected project. Please try selecting a different project. ',
  project_primary_message:
    'A project selection is required to add a participant.',
  participant_linked_error_message:
    'Participant is already enrolled in a project and can’t be added to another project until they are removed from the enrolled project.',
  project_life_cycle_label:
    'Please select which project cycle they will start on.',
  project_life_cycle_placeholder: 'Select project cycle',
  two_rem: '2rem',
  project_cycle_required_error: 'Project cycle selection is required.',
};

export const validateEmail = (email) => {
  const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})/;
  return email.match(regex);
};
