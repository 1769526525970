import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormLabels, InputSx, OptionalLabel, borderStyle } from '../FormStyles';
import Chip from 'components/Chips/CustomChip.react';
import { checkTernaryCondition } from 'utils/helper';
import { TAGS_CONTENTS } from './TagComponent.content';
import { TextField, Autocomplete } from '@mui/material';
import { FORM_CONTENT } from '../FormComponents.content';
import {
  CustomPaper,
  chipStyle,
  dropdownArrowSx,
  placeholderSx,
} from '../AutoComplete/AutoComplete.style';

const TagComponent = ({
  label,
  tags,
  handleNewTags,
  customFieldChangeHandler,
  formValue,
  setIsFormEdited,
  marginBottom,
  marginTop,
  flexDirection,
  customSx,
}) => {
  const [newTag, setNewTag] = useState(TAGS_CONTENTS.empty_string);
  const [tagsInput, setTagsInput] = useState(TAGS_CONTENTS.empty_string);
  const handleKeyDown = (e) => {
    switch (e.key) {
      case FORM_CONTENT.comma:
      case FORM_CONTENT.space:
        e.preventDefault();
        e.stopPropagation();
        handleNewTags(e);
        setTagsInput(FORM_CONTENT.empty_string);
        break;
    }
  };
  //Regex for validating tags -> Alphanumeric
  function regexValidate(e) {
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(e.target.value);
  }

  return (
    <>
      <FormLabels
        flexDirection={flexDirection}
        alignItems={'center'}
        marginBottom={marginBottom}
        marginTop={marginTop}>
        {label}
        <OptionalLabel>{TAGS_CONTENTS.optional}</OptionalLabel>
      </FormLabels>
      <Autocomplete
        PaperComponent={CustomPaper}
        multiple
        id="tags-filled"
        inputValue={tagsInput}
        forcePopupIcon
        onInputChange={(e, value) => {
          if (regexValidate(e)) {
            if (value !== FORM_CONTENT.space) {
              setNewTag(FORM_CONTENT.empty_string);
            }
            if (value !== FORM_CONTENT.comma) {
              setTagsInput(value);
              setNewTag(FORM_CONTENT.empty_string);
            }
            if (
              tags.indexOf(value) === -1 &&
              (value !== FORM_CONTENT.space || value !== FORM_CONTENT.comma)
            ) {
              setNewTag(value);
            }
          }
        }}
        options={[...tags, newTag].filter(
          (tags) => tags !== FORM_CONTENT.empty_string,
        )}
        freeSolo
        clearOnBlur={true}
        renderOption={(props, option) => {
          if (option === newTag && newTag !== FORM_CONTENT.empty_string) {
            return (
              <span {...props} style={borderStyle}>
                {`${option} ${TAGS_CONTENTS.create_new_tags}`}
              </span>
            );
          } else {
            return <span {...props}>{option}</span>;
          }
        }}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option}
              variant={FORM_CONTENT.outlined}
              label={option}
              {...getTagProps({ index })}
              sx={chipStyle}
            />
          ))
        }
        sx={InputSx}
        renderInput={(params) => {
          return (
            <TextField
              value={formValue.tags}
              {...params}
              variant={FORM_CONTENT.standard_variant}
              inputProps={{
                onKeyDown: handleKeyDown,
                ...params.inputProps,
              }}
              onBlur={handleNewTags}
              placeholder={checkTernaryCondition(
                formValue.tags.length > 0,
                FORM_CONTENT.empty_string,
                TAGS_CONTENTS.placeholder,
              )}
              sx={(customSx, placeholderSx, dropdownArrowSx)}
            />
          );
        }}
        value={formValue.tags}
        onChange={(event, newValue) => {
          if (formValue.tags.indexOf(newValue) === -1) {
            customFieldChangeHandler(TAGS_CONTENTS.tags, newValue);
          }
          if (setIsFormEdited) {
            setIsFormEdited(true);
          }
        }}
      />
    </>
  );
};

TagComponent.propTypes = {
  label: PropTypes.string,
  tags: PropTypes.array,
  handleNewTags: PropTypes.func,
  customFieldChangeHandler: PropTypes.func,
  formValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setIsFormEdited: PropTypes.func,
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexDirection: PropTypes.string,
  customSx: PropTypes.object,
};

export default TagComponent;
