import { TextField } from '@mui/material';
import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  GLOBAL_BLUE,
  TYPOGRAPHY_GRAY,
  WHITE,
} from 'theme/GlobalColors';

export const SearchBox = styled(TextField)(() => ({
  backgroundColor: WHITE,
  width: '50%',
  maxWidth: '18.75rem',

  '& .MuiTextField-root': {
    marginRight: '0.5rem',
  },

  '& .MuiOutlinedInput-root': {
    padding: '0 0.5rem',
    height: '1.75rem',

    '& input': {
      padding: '0px 2px',
      fontSize: '0.75rem',
    },
    '&.Mui-focused fieldset': {
      borderColor: DARK_GREEN,
    },
  },
}));

export const SearchAndFilterSection = styled.div`
  display: flex;
  display: -webkit-flex;
  gap: 0.25rem;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  flex: 1;
`;

export const AppliedFilters = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 4px;
`;

export const FilterChip = styled.div`
  padding: 0.125rem 0.25rem;
  font-size: 0.688rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${COOL_GRAY};
  border: 0.5px solid ${COOL_GRAY};
  width: fit-content;
  gap: 0.25rem;
  background-color: ${WHITE};
`;

export const ClearLink = styled.div`
  color: ${GLOBAL_BLUE};
  font-size: 0.75rem;
  cursor: pointer;
  align-content: center;
`;

export const clearIconStyle = {
  cursor: 'pointer',
  fontSize: '11px',
};

export const SearchAndFiltersFlex = {
  display: 'flex',
  width: '90%',
  alignItems: 'flex-start',
  gap: '0.5rem',
  flexDirection: 'column',
};

export const SearchIconSx = {
  color: TYPOGRAPHY_GRAY,
};

export const FilterWrapper = styled.div`
  margin-top: 0.2rem;
`;

export const BasicWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: ${({ content }) => content};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  border-radius: ${({ radius }) => radius};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
  height: ${({ height }) => height};
`;

export const TextWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  word-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const DatePickerAndFilterWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: center;
  gap: 0.5rem;
`;
export const OptionListStyle = {
  fontWeight: 'bold',
  whiteSpace: 'pre',
};

export const generatedOnStyle = {
  fontSize: '0.75rem',
  color: DARK_CHARCOAL,
};

export const SearchAndFilterIcon = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
  flex: 1;
  gap: 0.5rem;
`;

export const ReportListAppliedFilters = styled.div`
  display: flex;
  display: -webkit-flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-top: 4px;
  justify-content: flex-end;
  width: 100%;
`;

export const GeneratedOnAndDatePickerWrapper = styled(
  DatePickerAndFilterWrapper,
)`
  width: 100%;
`;
