import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import Tabber from 'components/Tabber/Tabber.react';
import UnsavedChangesModal from '../UnsavedChangesModal/UnsavedChangesModal.react';

const FarmWithMapTabs = ({ currentTab, setCurrentTab, tabList }) => {
  const {
    addFarmClicked,
    setCurrentFarmId,
    setExpandedFarmId,
    setFarmInfoCurrentTab,
    setShowFieldInfoForm,
    farmInfoCurrentTab,
    setAddFarmClicked,
    setIsTabSwitched,
    userActivityDetected,
    unsavedChangesModalOpenProps,
    setUnsavedChangesModalOpenProps,
    closeUnsavedChangesModal,
    setTriggerFarmInfoSubmit,
    setUserActivityDetected,
    currentFarmId,
    setCurrentFarmLocation,
  } = useContext(participantInfoContext);

  useEffect(() => {
    if (farmInfoCurrentTab === 1 && addFarmClicked.flag) {
      setCurrentFarmId(addFarmClicked.id);
      setExpandedFarmId(addFarmClicked.id);
      setFarmInfoCurrentTab(0);
      setShowFieldInfoForm(false);
      setAddFarmClicked({ id: 0, flag: false });
    }
  }, [addFarmClicked]);

  const switchTab = () => {
    setCurrentTab(unsavedChangesModalOpenProps.nextTab);
    closeUnsavedChangesModal();
    setUserActivityDetected(false);
    setCurrentFarmLocation(currentFarmId);
  };

  const handleSaveAndLeave = () => {
    setTriggerFarmInfoSubmit(true);
  };

  const handleFarmWithMapTabChange = (tab) => {
    if (currentTab === tab) return;

    // Note: Open unsave changes modal on tab shift if there are unsaved changes, and the tab is switched from farm to map
    if (userActivityDetected && currentTab === 0) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: tab,
        targetFieldId: null,
        targetFieldName: '',
        targetFarmId: currentFarmId,
        targetFarmName: '',
        navigateNextTo: 'Tab',
        triggeredFrom: 'Tab',
      });
      return;
    }
    setCurrentTab(tab);
    setCurrentFarmLocation(currentFarmId);
  };

  return (
    <>
      <Tabber
        currentTab={currentTab}
        setCurrentTab={handleFarmWithMapTabChange}
        tabList={tabList}
        onTabChange={(tab) => setIsTabSwitched(tab === 1)}
        customTabberStyles={{ paddingTop: '0.813rem' }}
        marginTop={0}
      />
      <UnsavedChangesModal
        open={
          unsavedChangesModalOpenProps?.isOpen &&
          unsavedChangesModalOpenProps?.triggeredFrom === 'Tab'
        }
        handleClose={closeUnsavedChangesModal}
        handleLeaveWithoutSave={switchTab}
        handleSaveAndLeave={handleSaveAndLeave}
      />
    </>
  );
};

FarmWithMapTabs.propTypes = {
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.arrayOf(PropTypes.string),
};
export default FarmWithMapTabs;
