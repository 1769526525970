import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as HelpIcon } from '../../../assets/icons/help_outline.svg';
import EnrollmentHelpModal from './EnrollmentHelpModal/EnrollmentHelpModal.react';
import { useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OpenInNewTab } from './EnrollmentHelpModal/EnrollmentHelpModal.style';
import { HELP_BUTTON_CONTENT } from './EnrollmentHelpModal/EnrollmentHelpModal.content';

import { GET_FAQ_PDF, GET_USER_GUIDE_PDF } from 'urls';
import axios from 'axios';
import { HeaderIconBtnSx } from '../style';

const ITEM_HEIGHT = 48;

const EnrollmentHelpButton = ({ section }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openContactForm = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const openUserGuide = () => {
    let userGuideUrl = '';
    axios.get(GET_USER_GUIDE_PDF).then((response) => {
      userGuideUrl = response.data;
      window.open(userGuideUrl, '_blank');
    });
    setAnchorEl(null);
  };
  const openFaqPdf = () => {
    let faqPdfUrl = '';
    axios.get(GET_FAQ_PDF).then((response) => {
      faqPdfUrl = response.data;
      window.open(faqPdfUrl, '_blank');
    });
    setAnchorEl(null);
  };
  const openVideoTutorialPage = () => {
    window.open(`${window.location.origin}/video-tutorials`, '_blank');
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Help">
        <IconButton
          sx={HeaderIconBtnSx}
          aria-label="help"
          onClick={handleClick}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="contact-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}>
        <MenuItem onClick={openUserGuide}>
          {HELP_BUTTON_CONTENT.USER_GUIDE}
          <OpenInNewTab>
            <OpenInNewIcon />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem onClick={openFaqPdf}>
          {HELP_BUTTON_CONTENT.FAQ}
          <OpenInNewTab>
            <OpenInNewIcon />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem onClick={openVideoTutorialPage}>
          {HELP_BUTTON_CONTENT.VIDEO_TUTORIALS}
          <OpenInNewTab>
            <OpenInNewIcon />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem onClick={openContactForm}>
          {HELP_BUTTON_CONTENT.CONTACT_SUPPORT}
        </MenuItem>
      </Menu>
      <EnrollmentHelpModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        section={section}
      />
    </>
  );
};

export default EnrollmentHelpButton;

EnrollmentHelpButton.propTypes = {
  section: PropTypes.string,
};
