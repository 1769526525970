import React, { useEffect, useState, useContext } from 'react';
import { Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Box } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  GRAYISH_BLUE,
  WHITE,
} from 'theme/GlobalColors';
import {
  PAGE_CONTENT,
  createBasicActivity,
  getActivityId,
  getContractedInterestedRow,
  getRecruitmentInterestedRow,
  getSortedActivities,
  getStatusDateValue,
  getStatusIcon,
  TAB_LIST,
  PAGINATION_INITIAL_STATE,
  PAGE_SIZE_OPTION,
} from './ParticipantActivity.content';
import {
  AcreageWrapper,
  ActivityMainWrapper,
  ProjectActivityDropdownContainer,
  ProjectActivityHeaderContainer,
  highlightStyle,
  StatusDate,
  StatusIcon,
  StatusText,
  StatusWrapper,
  tabStyleSx,
  TabsWrapper,
  dataGridSx,
  dataGridBoxSx,
} from './ParticipantActivity.style';
import {
  FETCH_ACTIVITY_TAB_DATA,
  FETCH_ALL_USER_ACTIONS,
  FETCH_PROJECT_LIFECYCLES,
} from '../../../../../urls';
import { checkTernaryCondition, omit } from 'utils/helper';
import { convertDateFormat } from '../../../../../utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useInterval } from 'hooks/useInterval';
import ViewDetailedAuditLog from '../../../../../components/ViewDetailedAuditLog/ViewDetailedAuditLog.react';
import { TextWrapper } from '../Participants.style';
import { ParticipantActivityProvider } from 'contextAPI/participantActivityContext';
import { getActionIdFromActionCode } from '../Participants.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { ActivityListContainer } from 'containers/ActivityListContainer';
import { DataGrid } from '@mui/x-data-grid';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';

const ParticipantActivity = ({
  participantName = '',
  participantId,
  projectData,
  disableVirtualization = false,
}) => {
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detailedLog, setDetailedLog] = useState({
    projectId: 0,
    projectOpen: false,
    projectName: '',
  });
  const [statusMapList, setStatusMapList] = useState([]);
  const [projectCycleList, setProjectCycleList] = useState([]);
  const [serachParams] = useSearchParams();
  const projectId = serachParams.get('projectid');
  const [projectCycleId, setProjectCycleId] = useState(-1);
  const { areUiActionInProgress } = useContext(ConfigurationContext);
  const [shouldNotRefresh, setShouldNotRefresh] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  /**
   * @description Handles the tab change event and updates the state accordingly.
   * @param {Object} _ - The event object (not used in this function).
   * @param {number} selectedTab - The index of the selected tab.
   * @return {void}
   */
  const handleTabChange = (_, selectedTab) => {
    if (selectedTab === 1) {
      setDetailedLog({
        projectId: projectData?.internalProjectId,
        projectOpen: true,
        projectName: projectData?.projectName,
      });
    }
    setCurrentTab(selectedTab);
  };

  /**
   * @description Generates properties for a tab element based on its index.
   * @param {number} index - The index of the tab.
   * @return {Object} An object containing the id and aria-controls properties for the tab.
   */
  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `participant-page-tab-${index}`,
    };
  };

  const fetchProjectCycles = () => {
    axios
      .get(FETCH_PROJECT_LIFECYCLES, {
        params: {
          internalProjectId: projectId,
          participantId: participantId,
        },
      })
      .then((response) => {
        const projectCycleList = [response.data[0]];
        setProjectCycleList(
          projectCycleList.map((data) => {
            const endYear = new Date(data?.cycleEndDate).getFullYear();
            return {
              label: `${endYear} (${convertDateFormat(
                data?.cycleStartDate,
              )} - ${convertDateFormat(data?.cycleEndDate)})`,
              value: data?.projectCycleId,
            };
          }),
        );
        setProjectCycleId(response.data[0]?.projectCycleId);
      });
  };

  const fetchAllUserActions = () => {
    statusMapList.length === 0 &&
      axios.get(FETCH_ALL_USER_ACTIONS).then((response) => {
        setStatusMapList(response.data);
      });
  };

  const getBaselineActivityId = (
    activityMainData,
    activityId,
    contractStartDate,
  ) => {
    let uniqueBaselineListIds = [];
    activityMainData.forEach((activity) => {
      if (activity.enrollmentInstancesForBaseline) {
        activity.enrollmentInstancesForBaseline.forEach((instance) => {
          !uniqueBaselineListIds.find((ids) => ids === instance) &&
            uniqueBaselineListIds.push({
              id: activity.activityId,
              updatedEnrollmentInstancesForBaseline: instance,
            });
        });
      }
    });
    uniqueBaselineListIds = uniqueBaselineListIds
      .sort(
        (a, b) =>
          a.updatedEnrollmentInstancesForBaseline -
          b.updatedEnrollmentInstancesForBaseline,
      )
      .map((data, index) => ({
        id: data.id,
        updatedEnrollmentInstancesForBaseline: index + 1,
      }));

    const year = moment(contractStartDate, 'YYYY-MM-DD').year();
    uniqueBaselineListIds = uniqueBaselineListIds
      .filter((data) => data.id === activityId)
      .map(
        (instance) =>
          `${year}-${instance.updatedEnrollmentInstancesForBaseline}`,
      );
    return uniqueBaselineListIds.join(', ');
  };

  const createActivityDataObject = (activityMainData) => {
    const enrollmentActivities = getSortedActivities(
      activityMainData.activityTabActivitiesList,
      PAGE_CONTENT.enrollment,
      'activityId',
    );

    const baselineActivities = getSortedActivities(
      activityMainData.activityTabActivitiesList,
      PAGE_CONTENT.BASELINE_REPORTING,
      'activityId',
    );
    const activityActivities = getSortedActivities(
      activityMainData.activityTabActivitiesList,
      PAGE_CONTENT.ACTIVITY_REPORTING,
      'activityId',
    );
    const acitivtiesList = [
      ...enrollmentActivities,
      ...baselineActivities,
      ...activityActivities,
    ];
    let additionalEmptyActivity = [];
    if (
      !acitivtiesList.some(
        (activity) => activity.activityType === PAGE_CONTENT.BASELINE_REPORTING,
      )
    ) {
      const activityId = acitivtiesList.find(
        (activity) => activity.activityType === PAGE_CONTENT.BASELINE_REPORTING,
      )?.activityId;

      additionalEmptyActivity.push(
        createBasicActivity({
          id: uniqueId(),
          activityType: PAGE_CONTENT.BASELINE_REPORTING,
          actionId: 13,
          activityDate: '',
          associatedContractId: activityMainData.contractId,
          enrollmentId: null,
          formId: activityId,
          statusMapList: statusMapList,
        }),
      );
    }
    if (
      !acitivtiesList.some(
        (activity) => activity.activityType === PAGE_CONTENT.ACTIVITY_REPORTING,
      )
    ) {
      const activityId = acitivtiesList.find(
        (activity) => activity.activityType === PAGE_CONTENT.ACTIVITY_REPORTING,
      )?.activityId;

      additionalEmptyActivity.push(
        createBasicActivity({
          id: uniqueId(),
          activityType: PAGE_CONTENT.ACTIVITY_REPORTING,
          actionId: 23,
          activityDate: '',
          statusMapList: statusMapList,
          associatedContractId: activityMainData.contractId,
          enrollmentId: null,
          formId: activityId,
        }),
      );
    }

    return [
      ...acitivtiesList.map((activityData, index) => ({
        id: uniqueId(),
        activityType: activityData.activityType,
        activityId: checkTernaryCondition(
          activityData.activityType === 'Baseline reporting' ||
            activityData.activityType === 'Activity reporting',
          getBaselineActivityId(
            checkTernaryCondition(
              activityData.activityType === 'Baseline reporting',
              activityMainData.activityTabActivitiesList.filter(
                (activity) => activity.activityType === 'Baseline reporting',
              ),
              activityMainData.activityTabActivitiesList.filter(
                (activity) => activity.activityType === 'Activity reporting',
              ),
            ),
            activityData.activityId,
            activityMainData.contractStartDate,
          ),
          getActivityId(
            index + 1,
            activityMainData.contractStartDate,
            activityData.enrollmentInstancesForBaseline,
          ),
        ),
        associatedContractId: activityMainData.contractId,
        enrollmentId: activityData.activityId,
        formId: activityData.activityId,
        area: checkTernaryCondition(
          activityData.area === null,
          '-',
          `${activityData.area} acres`,
        ),
        status: statusMapList.find(
          (status) => status.actionId === activityData.actionId,
        )?.actionStatus,
        actionId: activityData.actionId,
        enrollmentInstancesForBaseline: null,
        activityDate: convertDateFormat(activityData.activityDate),
      })),
      ...additionalEmptyActivity,
    ];
  };

  const fetchActivityData = (projectId, isCalledOverIntervals = false) => {
    !isCalledOverIntervals && setLoading(true);
    areUiActionInProgress === 0 &&
      projectId &&
      axios
        .get(FETCH_ACTIVITY_TAB_DATA, {
          params: {
            internalProjectId: projectId,
            participantId: participantId,
          },
        })
        .then((res) => {
          const data = res.data.activityTabContractLevelList || [];
          let newActivityData = [];
          const isRequestEnrollmentEnabled = data[0]?.activityTabActivitiesList
            ?.filter((data) => data.activityType === PAGE_CONTENT.enrollment)
            .every(
              (activitydata) =>
                activitydata.actionId ===
                getActionIdFromActionCode('ENROLL-APPROVE', statusMapList),
            );
          if (data.length === 0) {
            newActivityData = [
              {
                id: uniqueId(),
                contractId: null,
                contractStartDate: '',
                contractEndDate: '',
                activityList: [
                  getRecruitmentInterestedRow(
                    statusMapList,
                    convertDateFormat(res.data.participantInterestedDate),
                    res.data.totalPlannedAcres,
                    checkTernaryCondition(
                      res.data.totalPlannedAcres !== null &&
                        res.data.totalPlannedAcres >= 0,
                      PAGE_CONTENT.interested,
                      null,
                    ),
                  ),
                ],
              },
              {
                id: uniqueId(),
                contractId: null,
                contractStartDate: '',
                contractEndDate: '',
                activityList: [
                  getContractedInterestedRow(
                    statusMapList,
                    convertDateFormat(res.data.participantInterestedDate),
                  ),
                ],
              },
            ];
          } else {
            newActivityData = data.map((activity) => ({
              contractId: activity.contractId,
              contractStartDate: convertDateFormat(activity.contractStartDate),
              contractEndDate: convertDateFormat(activity.contractEndDate),
              activityList: [
                getRecruitmentInterestedRow(
                  statusMapList,
                  convertDateFormat(res.data.participantInterestedDate),
                  res.data.totalPlannedAcres,
                  checkTernaryCondition(
                    activity.contractAcres,
                    PAGE_CONTENT.contract_added,
                    checkTernaryCondition(
                      res.data.totalPlannedAcres,
                      PAGE_CONTENT.interested,
                      null,
                    ),
                  ),
                ),
                {
                  id: uniqueId(),
                  activityType: PAGE_CONTENT.contracting,
                  activityId: '',
                  area: activity.contractAcres,
                  enrollmentId: null,
                  formId: null,
                  status: statusMapList.find((status) => status.actionId === 2)
                    ?.actionStatus,
                  associatedContractId: activity.contractId,
                  enrollmentInstancesForBaseline: null,
                  activityDate: convertDateFormat(activity.contractCreatedDate),
                  contractPdfUrl: activity.contractPdfUrl,
                  isRequestEnrollmentEnabled: isRequestEnrollmentEnabled,
                  activitySubType: PAGE_CONTENT.contract_added,
                },
                ...createActivityDataObject(activity),
              ],
            }));
          }
          if (isActivityDifferent(newActivityData)) {
            newActivityData.map((activityData) => {
              const activityList = activityData.activityList;
              const recruitmentActivity = activityList.find(
                ({ activityType }) => {
                  return activityType === PAGE_CONTENT.contracting;
                },
              );
              if (recruitmentActivity && isRequestEnrollmentEnabled) {
                recruitmentActivity.isRequestEnrollmentEnabled =
                  isRequestEnrollmentEnabled;
              }
            });
            setActivityData(newActivityData);
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const isActivityDifferent = (newActivityData) => {
    return !_.isEqual(
      omit(_.cloneDeep(activityData), [
        'id',
        'contractPdfUrl',
        'isRequestEnrollmentEnabled',
      ]),
      omit(_.cloneDeep(newActivityData), [
        'id',
        'contractPdfUrl',
        'isRequestEnrollmentEnabled',
      ]),
    );
  };

  const isFetchFunctionCallable = statusMapList.length > 0;

  useEffect(() => {
    if (isFetchFunctionCallable) {
      fetchActivityData(projectData?.internalProjectId);
    }
  }, [statusMapList]);

  useInterval(async () => {
    if (isFetchFunctionCallable && !shouldNotRefresh) {
      fetchActivityData(projectData?.internalProjectId, true);
    }
  }, 5000);

  const columns = [
    { field: 'id', headerName: '', hide: true, flex: 0 },
    {
      field: 'activityType',
      headerName: 'Phase',
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 220,
    },
    {
      field: 'activityId',
      headerName: 'Batch ID',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 140,
    },
    {
      field: 'area',
      headerName: 'Total acres',
      flex: 1,
      minWidth: 240,
      renderCell: (params) => {
        checkTernaryCondition(
          params.row.activitySubType === PAGE_CONTENT.not_started,
          checkTernaryCondition(
            params.row.activityType === PAGE_CONTENT.outreach,
            <AcreageWrapper>{PAGE_CONTENT.interested_acreage}</AcreageWrapper>,
            <AcreageWrapper>{PAGE_CONTENT.contract_acreage}</AcreageWrapper>,
          ),
          params.row.area,
        );
      },
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 340,

      renderCell: (params) => (
        <StatusWrapper>
          <StatusIcon>{getStatusIcon(params.row.status)}</StatusIcon>
          <StatusText>{params.row.status}</StatusText>
          <StatusDate>
            {getStatusDateValue(params.row.activityDate, params.row.status)}
          </StatusDate>
        </StatusWrapper>
      ),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'options',
      headerName: 'Action',
      flex: 0.3,
      minWidth: 70,
      align: 'start',
      renderCell: (params) => (
        <div data-testid={`options-${params.row?.id}`}>
          <ActivityListContainer
            setShouldNotRefresh={setShouldNotRefresh}
            activity={params.row}
            status={params.row.status}
            activityType={params.row.activityType}
            activityDataList={
              activityData[1]
                ? [
                    ...(activityData[0]?.activityList || []),
                    ...(activityData[1]?.activityList || []),
                  ]
                : [...(activityData[0]?.activityList || [])]
            }
            statusMappingList={statusMapList}
            activitySubType={params.row.activitySubType}
            projectCycleId={projectCycleId}
            area={params.row.area}
          />
        </div>
      ),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    fetchAllUserActions();
    fetchProjectCycles();
  }, []);

  const projectCycleDropdownProps = {
    labelMarginTop: '1rem',
    field_key: '',
    label: '',
    value:
      projectCycleId === -1
        ? PAGE_CONTENT.project_cycle_dropdown_placeholder
        : projectCycleId,
    isDisabled: false,
    stateValue: projectCycleId,
    setStateValue: () => {},
    setFormFieldValue: () => {},
    dropDownMinWidth: '13.75rem',
    height: '1.75rem',
    name: '',
    displayEmpty: true,
    showLabelAsValue: true,
    hasNoBottomMargin: true,
    customSx: {
      backgroundColor: WHITE,
      border: `1px ${GRAYISH_BLUE}`,
      ...outlinedDropdownInputSx,
    },
    valueHeight: '1rem',
    ariaLabel: '',
    dropdownlist: projectCycleList,
    dropDownPlaceholder: PAGE_CONTENT.project_cycle_dropdown_placeholder,
    error: false,
    errorMessage: '',
    moduleError: false,
    onUpdate: (e) => {
      setProjectCycleId(e.target.value);
    },
    customPlaceholderColor: COOL_GRAY,
    defaultDropdownColor: GRAYISH_BLUE,
  };

  /**
   * @description Renders the detailed log component wrapped in an ActivityMainWrapper.
   * @return {JSX.Element} The JSX element containing the detailed log component.
   */
  const renderDetailedLog = () => (
    <ActivityMainWrapper>
      <ViewDetailedAuditLog
        statusMapList={statusMapList}
        projectCycleId={projectCycleId}
        participantId={participantId}
        projectId={detailedLog.projectId}
      />
    </ActivityMainWrapper>
  );

  /**
   * @description Renders the project activity component wrapped in an ActivityMainWrapper.
   * @return {JSX.Element} The JSX element containing the project activity component.
   */
  const renderProjectActivity = () => (
    <ActivityMainWrapper>
      <ParticipantActivityProvider
        projectData={{ ...projectData, id: projectData?.internalProjectId }}
        participantName={participantName}
        activityData={activityData}
        participantId={participantId}>
        <div>
          <Box sx={dataGridBoxSx}>
            <DataGrid
              rows={checkTernaryCondition(
                activityData[1],
                [
                  ...(activityData[0]?.activityList || []),
                  ...(activityData[1]?.activityList || []),
                ],
                [...(activityData[0]?.activityList || [])],
              )}
              disableVirtualization={disableVirtualization}
              loading={loading}
              autoHeightPAGE_CONTENT
              columns={columns}
              initialState={PAGINATION_INITIAL_STATE}
              pageSizeOptions={PAGE_SIZE_OPTION}
              disableRowSelectionOnClick
              hideFooter
              sx={dataGridSx}
              disableSelectionOnClick
            />
          </Box>
        </div>
      </ParticipantActivityProvider>
    </ActivityMainWrapper>
  );

  /**
   * @description Renders the content based on the current tab.
   * @return {JSX.Element} The JSX element for the current tab's content.
   */
  const getComponent = () => {
    if (currentTab === 0) {
      return renderProjectActivity();
    }
    return renderDetailedLog();
  };

  return (
    <>
      <ProjectActivityHeaderContainer>
        {currentTab === 0 && (
          <ProjectActivityDropdownContainer>
            <TextWrapper fontSize="0.75rem" color={DARK_CHARCOAL}>
              {PAGE_CONTENT.projectCycleLabel}
            </TextWrapper>
            <DropDown {...projectCycleDropdownProps} />
          </ProjectActivityDropdownContainer>
        )}
        <TabsWrapper>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { highlightStyle },
            }}
            aria-label="page-tabs">
            {TAB_LIST.map((tab, index) => (
              <Tab
                sx={tabStyleSx}
                className={classNames({
                  'active-tab': currentTab === index,
                })}
                key={tab.title}
                label={tab.title}
                {...tabProps(index)}
              />
            ))}
          </Tabs>
        </TabsWrapper>
      </ProjectActivityHeaderContainer>
      {getComponent()}
    </>
  );
};

ParticipantActivity.propTypes = {
  participantName: propTypes.string,
  participantId: propTypes.string,
  projectData: propTypes.object,
  disableVirtualization: propTypes.bool,
};

export default ParticipantActivity;
