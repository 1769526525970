const { Select, OutlinedInput, MenuItem, Input } = require('@mui/material');
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkTernaryCondition } from 'utils/helper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FormLabels,
  FormFields,
  DropDownPlaceholder,
  ErrorWrapper,
  DropdownSx,
  DropdownInputSx,
  OptionalLabel,
  AlertIconSx,
  LabelToolTipWrapper,
  LabelTextWrapper,
  ToolTipAndAlertWrapper,
  DropDownToolTipStyle,
} from '../FormStyles';
import { IconButton } from '@mui/material';
import { FORM_CONTENT } from '../FormComponents.content';
import {
  DARK_CHARCOAL,
  DISABLED_TEXT_COLOR_GRAY,
  GRAYISH_BLUE,
  HOVER_LIGHT_BLUE,
  ROW_FOCUSED_BG,
  BLACK,
} from 'theme/GlobalColors';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import LightTooltip from 'components/LightTooltip';
import { TOOLTIP_PLACEMENTS } from 'utils/config';
import { alertIconStyling as ErrorIconSx } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/NitrogenFertilizerModal/NitrogentFertilizerModal.style';

const DropDown = ({
  label,
  value,
  setFormFieldValue,
  field_key,
  isDisabled,
  onUpdate,
  width,
  height,
  name,
  ariaLabel,
  dropdownlist,
  error,
  errorMessage,
  dropDownPlaceholder,
  isNotOutlined,
  setDropDownValInParent,
  showLabelAsValue,
  setDropDownIdInParent,
  moduleError,
  disableOptionIndex,
  hasNoBottomMargin,
  minWidth,
  menuProps,
  tooltipLabel,
  setModalErrorState,
  fieldName,
  isOptional,
  labelMarginTop,
  labelMarginBottom,
  display,
  direction,
  gap,
  alignItems,
  valueWidth,
  valueHeight,
  labelStyle,
  isAutoWidth,
  dropDownMinWidth,
  dropdownBackgroundColor,
  fieldBottomMargin,
  alertIconStyling,
  tooltipSx,
  headerColor,
  paddingForDropdownSelect,
  paddingForDropdown,
  fontSize = '0.875rem',
  listItemFontSize = '0.875rem',
  setStateValue = () => {
    /* do nothing here */
  },
  disabledIndexes = [],
  customPlaceholderColor = DARK_CHARCOAL,
  borderRadius = '2px',
  errorBorderColor = 'error-border-color',
  customSx = {},
  border = `1px solid ${GRAYISH_BLUE}`,
  dropdownIconColor = DARK_CHARCOAL,
  isMultiple = false,
  customRenderValues = null,
  isRequired = true,
  handleDropdownOpen = () => {
    /* do nothing here */
  },
  handleDropdownClose = () => {
    /* do nothing here */
  },
  defaultDropdownColor = 'inherit',
  dataTestId = 'dropdown-select',
  hasElementAsLabel = false,
}) => {
  const [dropdownVal, setDropdownVal] = useState(value);
  const handleChange = (event) => {
    if (onUpdate) {
      onUpdate(event);
    }
    if (setModalErrorState) {
      setModalErrorState(event.target.value, fieldName);
    }
    setDropdownVal(event.target.value);
    setStateValue({
      value: event.target.value,

      error: false,

      errorMessage: '',
    });

    if (setFormFieldValue) {
      setFormFieldValue(field_key, event.target.value);
    }

    if (setDropDownValInParent) {
      setDropDownValInParent(event.target.value);
    }

    if (setDropDownIdInParent) {
      const item = dropdownlist?.find(
        ({ value: valueItem }) => valueItem === event.target.value,
      );
      if (item) {
        setDropDownIdInParent(item.id);
      } else {
        setDropDownIdInParent(null);
      }
    }
    onUpdate?.(event);
  };

  const selectProps = {
    ...(menuProps && { MenuProps: menuProps }),
  };

  useEffect(() => {
    setDropdownVal(value);
  }, [value]);

  const getRenderValue = (renderValue) => {
    const item = dropdownlist?.find(({ value: v }) => v === renderValue);
    if (dropdownVal === dropDownPlaceholder) {
      return (
        <DropDownPlaceholder
          customPlaceholderColor={customPlaceholderColor}
          data-testid="dropdown-placeholder">
          {dropdownVal}
        </DropDownPlaceholder>
      );
    } else if (!customRenderValues) {
      return (
        <span
          style={{
            display: 'inline-flex',
            width: valueWidth,
            height: valueHeight,
            fontSize: listItemFontSize,
            color: checkTernaryCondition(
              isDisabled,
              BLACK,
              checkTernaryCondition(
                dropdownVal !== '',
                DARK_CHARCOAL,
                customPlaceholderColor,
              ),
            ),
          }}>
          {checkTernaryCondition(showLabelAsValue, item?.label, dropdownVal)}
        </span>
      );
    } else {
      return customRenderValues(renderValue, dropdownlist);
    }
  };

  return (
    <FormFields
      hasNoBottomMargin={hasNoBottomMargin}
      minWidth={minWidth}
      display={display}
      direction={direction}
      gap={gap}
      alignItems={alignItems}
      marginBottom={fieldBottomMargin}>
      <div style={{ display: 'flex', gap: '1rem' }}>
        {label && (
          <FormLabels
            color={headerColor}
            marginTop={labelMarginTop}
            marginBottom={labelMarginBottom}
            iserror={error || moduleError}
            flexDirection={(error || moduleError) && 'row'}
            gap={(error || moduleError) && '0.25rem'}>
            <LabelToolTipWrapper>
              <LabelTextWrapper
                hasElementAsLabel={hasElementAsLabel}
                style={labelStyle}>
                {label}
              </LabelTextWrapper>
              <ToolTipAndAlertWrapper>
                {isOptional && (
                  <OptionalLabel>{FORM_CONTENT.optional}</OptionalLabel>
                )}
                {tooltipLabel && (
                  <LightTooltip
                    title={tooltipLabel}
                    arrow
                    placement={TOOLTIP_PLACEMENTS.TOP}>
                    <IconButton sx={tooltipSx}>
                      <InfoOutlinedIcon style={DropDownToolTipStyle} />
                    </IconButton>
                  </LightTooltip>
                )}
                {(error || moduleError) && (
                  <AlertIcon
                    style={{
                      ...alertIconStyling,
                      ...ErrorIconSx,
                      ...AlertIconSx,
                    }}
                    data-testid="dropdown-error-icon"
                  />
                )}
              </ToolTipAndAlertWrapper>
            </LabelToolTipWrapper>
          </FormLabels>
        )}
      </div>
      <Select
        required={isRequired}
        value={dropdownVal}
        data-testid={dataTestId}
        disabled={isDisabled}
        multiple={isMultiple}
        className={`${errorBorderColor}`}
        onChange={handleChange}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        style={{
          padding: paddingForDropdown,
        }}
        sx={[
          {
            width: checkTernaryCondition(isAutoWidth, 'auto', width),
            minWidth: dropDownMinWidth,
            height: height,
            fontSize: fontSize,
            border: '0px',
            backgroundColor: dropdownBackgroundColor,
            '& .MuiSelect-select': {
              padding: paddingForDropdownSelect,
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: border,
              borderColor: checkTernaryCondition(
                isDisabled,
                DISABLED_TEXT_COLOR_GRAY,
                defaultDropdownColor,
              ),
              borderRadius: borderRadius,
            },
            '& .MuiSvgIcon-root': {
              color: checkTernaryCondition(
                isDisabled,
                DISABLED_TEXT_COLOR_GRAY,
                dropdownIconColor,
              ),
              right: 0,
            },
            '&& .Mui-disabled': {
              WebkitTextFillColor: BLACK,
            },
          },
          checkTernaryCondition(error, {}, DropdownSx),
          checkTernaryCondition(error, {}, DropdownInputSx),
          customSx,
        ]}
        name={name}
        error={error}
        inputProps={{ 'aria-label': ariaLabel }}
        {...selectProps}
        input={checkTernaryCondition(
          isNotOutlined,
          <Input />,
          <OutlinedInput />,
        )}
        renderValue={getRenderValue}>
        {dropdownlist?.map((type, index) => {
          const keyVal = index;
          const hideOption = type.hidden ?? false;
          return (
            !hideOption && (
              <MenuItem
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: ROW_FOCUSED_BG,
                    '&:hover': { backgroundColor: HOVER_LIGHT_BLUE },
                    '&:focus': { backgroundColor: ROW_FOCUSED_BG },
                  },
                  '&:hover': { backgroundColor: HOVER_LIGHT_BLUE },
                  '&:focus': { backgroundColor: ROW_FOCUSED_BG },
                  fontSize: listItemFontSize,
                  color: DARK_CHARCOAL,
                }}
                key={keyVal}
                value={type.value}
                disabled={
                  (disableOptionIndex && index === disableOptionIndex) ||
                  disabledIndexes?.includes(index)
                }>
                {type.label}
              </MenuItem>
            )
          );
        })}
      </Select>
      {error && !moduleError && <ErrorWrapper>{errorMessage}</ErrorWrapper>}
    </FormFields>
  );
};

DropDown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setFormFieldValue: PropTypes.func,
  field_key: PropTypes.string,
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  ariaLabel: PropTypes.string,
  dropdownlist: PropTypes.array,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  dropDownPlaceholder: PropTypes.string,
  isNotOutlined: PropTypes.bool,
  setDropDownValInParent: PropTypes.func,
  showLabelAsValue: PropTypes.bool,
  setDropDownIdInParent: PropTypes.func,
  moduleError: PropTypes.bool,
  disableOptionIndex: PropTypes.number,
  disabledIndexes: PropTypes.arrayOf(PropTypes.number),
  hasNoBottomMargin: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuProps: PropTypes.object,
  tooltipLabel: PropTypes.string,
  setModalErrorState: PropTypes.func,
  fieldName: PropTypes.string,
  isOptional: PropTypes.bool,
  customPlaceholderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  errorBorderColor: PropTypes.string,
  labelMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customSx: PropTypes.object,
  listItemFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  border: PropTypes.string,
  display: PropTypes.string,
  direction: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignItems: PropTypes.string,
  dropdownIconColor: PropTypes.string,
  customRenderValues: PropTypes.func,
  isRequired: PropTypes.bool,
  valueWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dropDownMinWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAutoWidth: PropTypes.bool,
  setStateValue: PropTypes.func,
  isMultiple: PropTypes.bool,
  labelStyle: PropTypes.object,
  handleDropdownOpen: PropTypes.func,
  handleDropdownClose: PropTypes.func,
  defaultDropdownColor: PropTypes.string,
  dropdownBackgroundColor: PropTypes.string,
  fieldBottomMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alertIconStyling: PropTypes.object,
  tooltipSx: PropTypes.object,
  dataTestId: PropTypes.string,
  headerColor: PropTypes.string,
  paddingForDropdownSelect: PropTypes.string,
  paddingForDropdown: PropTypes.string,
  hasElementAsLabel: PropTypes.bool,
};

export default DropDown;
