import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { QUILL_GRAY, WHITE } from 'theme/GlobalColors';

export const TabsWrapper = styled(Tabs)`
  border-bottom: 1px solid ${QUILL_GRAY};
  position: sticky;
  background-color: ${WHITE};
  width: 100%;
  top: 0;
  z-index: 20;
  margin-top: ${({ marginTop }) => marginTop ?? '0.813rem'};
  .MuiTab-root {
    padding: 0 0.5rem 0.5rem;
    line-height: 140%;
  }
`;

export const TabBox = styled(Tab)`
  margin-left: ${({ firstTab }) => (firstTab ? '0.5rem' : '0')};
  text-transform: none;
  &.MuiTab-root {
    padding: 0 0.5rem 0.5rem;
    line-height: 140%;
  }
`;
