import { useState, useContext, useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import { Toolbar, Box } from '@mui/material';
import ProfileSection from './ProfileSection.react';
import {
  useStyles,
  BoxStyle,
  logoStyle,
  logoWrapper,
  logoImgStyle,
} from './style';
import NotificationLog from './NotificationLog.react';
import { checkTernaryCondition } from 'utils/helper';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { ADMIN, CLIENT_LOGO_BASE64_KEY } from 'utils/config';
import { useLocation, useNavigate } from 'react-router';
import {
  landingPath,
  participantActivityPaths,
  participantBaselinePaths,
  participantContributorPaths,
  participantEnrollmentPaths,
} from './Header.content';
import EnrollmentHelpButton from './EnrollmentHelpButton/EnrollmentHelpButton.react';
import ClearCarbonLogo from '../../assets/Deloitte.png';
import { pathName } from 'Routes/routes.const';
import SettingsMenu from './SettingsMenu.react';

const Header = () => {
  const { HeaderContainer, LogoBox } = useStyles();
  const { clientConfigs, userRoles } = useContext(userAuthRolesContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (location?.pathname !== pathName.settings)
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoBase64 = useMemo(() => {
    for (const config of clientConfigs) {
      if (config['key'] === CLIENT_LOGO_BASE64_KEY) return config['value'];
    }
  }, [clientConfigs]);

  const inEnrollmentOrBaselineFlow = useMemo(
    () =>
      participantEnrollmentPaths.includes(location.pathname) ||
      participantBaselinePaths.includes(location.pathname) ||
      participantActivityPaths.includes(location.pathname) ||
      participantContributorPaths.includes(location.pathname) ||
      location.pathname === landingPath,
    [location],
  );

  const isSvg = logoBase64?.startsWith('PHN2Z');
  const logoImageType = isSvg ? 'svg+xml' : 'png';

  const openSettingsPage = () => {
    if (location?.pathname === pathName.settings) return;
    navigate(pathName.settings);
    handleClose();
  };

  return (
    location.pathname !== '/login' && (
      <AppBar position="fixed" elevation={0}>
        <HeaderContainer maxWidth="xxl">
          <Box>
            <LogoBox>
              <Toolbar variant="dense" disableGutters sx={logoWrapper}>
                <img
                  src={ClearCarbonLogo}
                  alt="Clear Carbon"
                  style={logoStyle}
                />
              </Toolbar>
            </LogoBox>
          </Box>
          <Box sx={BoxStyle}>
            <img
              src={`data:image/${logoImageType};base64,${logoBase64}`}
              alt="Client logo"
              style={logoImgStyle}
            />
            {checkTernaryCondition(
              !inEnrollmentOrBaselineFlow,
              <>
                <EnrollmentHelpButton section="global-help-button" />
                <NotificationLog />

                {userRoles[0] === ADMIN && (
                  <SettingsMenu
                    handleClick={handleClick}
                    openSettingsPage={openSettingsPage}
                    handleClose={handleClose}
                    open={open}
                  />
                )}
              </>,
              <>
                <EnrollmentHelpButton section="participant-help-button" />
                <NotificationLog />
              </>,
            )}
            <ProfileSection />
          </Box>
        </HeaderContainer>
      </AppBar>
    )
  );
};

export default Header;
