import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { WhiteChip } from 'components/ActionModal/Components/AddedDeletedTable/AddedDeletedTable.style';
import { checkTernaryCondition } from 'utils/helper';
import {
  MainWrapper,
  ProjectAddOrDeletedWrapper,
  subTitleStyle,
  titleStyle,
} from './ProjectAddedOrDeletedSection.style';

const ProjectAddedOrDeletedSection = ({ action, title, subtitle }) => {
  return (
    <MainWrapper>
      <ProjectAddOrDeletedWrapper>
        <Typography sx={titleStyle(action === 'DELETE')}>{title}</Typography>
        <WhiteChip>
          {checkTernaryCondition(action === 'INSERT', 'Added', 'Deleted')}
        </WhiteChip>
      </ProjectAddOrDeletedWrapper>
      <Typography sx={subTitleStyle}>{subtitle}</Typography>
    </MainWrapper>
  );
};

ProjectAddedOrDeletedSection.propTypes = {
  action: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ProjectAddedOrDeletedSection;
