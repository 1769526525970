import styled from 'styled-components';

export const TransactionListWrapper = styled.div`
  max-height: 6.25rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  flex-warp: wrap;
  margin: 1rem;
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }
`;
export const TransactionListText = styled.span`
  color: #686868;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

export const deleteButtonStyle = {
  fontWeight: '400',
  textTransform: 'none',
  backgroundColor: 'transparent',
  color: '#26890D',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
};

export const editButtonStyle = {
  textTransform: 'none',
  backgroundColor: '#26890D',
  color: 'white',
  minWidth: '50px',
  padding: '0px',
  '&:hover': {
    color: 'white',
    backgroundColor: '#045206',
  },
};
export const ButtonWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction; row;
  gap: 0.4rem;
`;

export const DialogWrapper = styled.div`
  max-height: 6.25rem;
  display: flex;
  display: -webkit-flex;
  margin-top: 1rem;
  flex-direction: column;
  flex-warp: wrap;
  overflow: hidden;
  color: #686868;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;
