export const MODAL_TITLE = 'Update user status';

export const MODAL_INPUTS = {
  UPDATED_USER_STATUS: 'Updated user status',
  CURRENT_USER_STATUS: 'Current user status',
  PARTICIPANT_NAME: 'Participant name',
};

export const CONFIRMATIONAL_MODAL_CONTENT =
  "Are you sure you would like to update participantName's user status to";
