import * as React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
  RadioButtonStyle,
  RadioIconStyle,
  RadioContainer,
} from './RadioButtonGroup.style';
import {
  ErrorWrapper,
  FormLabels,
  LabelToolTipWrapper,
  OptionalLabel,
  ToolTipSx,
  AlertIconSx,
  LabelTextWrapper,
  ToolTipAndAlertWrapper,
} from '../FormStyles';
import { FORM_CONTENT } from '../FormComponents.content';
import { checkTernaryCondition } from 'utils/helper';
import InfoTooltip from '../InfoTooltip/InfoTooltip.react';
import { Container } from '../CheckboxFormGroup/CheckboxFormGroup.style';
import InputField from '../InputField/InputField.react';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';

const RadioButtonsGroup = ({
  list,
  label,
  selectedValue,
  direction,
  handleChange,
  primaryError,
  primaryErrorMessage,
  isDisabled,
  marginRight,
  marginTop,
  marginBottom,
  customSx,
  tooltipContent,
  hasTooltipOnLabel,
  hasTooltipOnItem,
  checkedIsDarkGreen,
  showFreeTextField,
  freeTextProps,
  isOptional,
  hideLabel = false,
  hasCenterTooltip = false,
  radioBtnPadding,
  radioBtnGap,
  formLabelCustomSx,
  formLabelTooltipCustomSx,
  validationError,
  headerColor,
  marginLeft,
  radioBtnOptionGap,
}) => {
  return (
    <div>
      <FormControl
        sx={customSx}
        iserror={primaryError || validationError}
        className={
          (primaryError || validationError) && FORM_CONTENT.error_class
        }>
        <FormLabels
          marginBottom={marginBottom}
          marginTop={marginTop}
          iserror={primaryError || validationError}>
          <LabelToolTipWrapper
            color={headerColor}
            hasCenterTooltip={hasCenterTooltip}>
            <LabelTextWrapper>{label}</LabelTextWrapper>
            <ToolTipAndAlertWrapper>
              {isOptional && (
                <OptionalLabel>{FORM_CONTENT.optional}</OptionalLabel>
              )}
              {hasTooltipOnLabel && (
                <InfoTooltip
                  csafToolTipSx={{
                    ...formLabelTooltipCustomSx,
                    ...ToolTipSx,
                  }}
                  title={FORM_CONTENT.empty_string}
                  content={tooltipContent}
                />
              )}
              {(primaryError || validationError) && (
                <AlertIcon data-testid="radio-alert-icon" style={AlertIconSx} />
              )}
            </ToolTipAndAlertWrapper>
          </LabelToolTipWrapper>
        </FormLabels>
        <RadioGroup
          {...direction}
          value={selectedValue}
          onChange={handleChange}
          aria-labelledby={FORM_CONTENT.radio_label_group}
          name={`${FORM_CONTENT.row_radio_buttons_group}_${label}`}>
          <RadioContainer radioBtnGap={radioBtnGap} direction={direction}>
            {list.map((item, index) => {
              const keyVal = index;
              return (
                <Container className={'disabled-selector'} key={keyVal}>
                  <FormControlLabel
                    value={item.itemLabel}
                    control={
                      <Radio
                        size={FORM_CONTENT.small}
                        sx={RadioIconStyle(checkedIsDarkGreen, radioBtnPadding)}
                      />
                    }
                    label={hideLabel ? '' : item.itemLabel}
                    disabled={checkTernaryCondition(
                      isDisabled,
                      true,
                      item.isDisabled,
                    )}
                    sx={{
                      ...RadioButtonStyle(marginRight),
                      ...formLabelCustomSx,
                      marginLeft: marginLeft,
                      gap: radioBtnOptionGap,
                    }}
                  />

                  {hasTooltipOnItem && item.tooltipContent && (
                    <InfoTooltip
                      title={item.tooltipTitle}
                      content={item.tooltipContent}
                      csafToolTipSx={formLabelTooltipCustomSx}
                    />
                  )}
                </Container>
              );
            })}
          </RadioContainer>
        </RadioGroup>
        {showFreeTextField && <InputField {...freeTextProps} />}
      </FormControl>
      {primaryError && <ErrorWrapper>{primaryErrorMessage}</ErrorWrapper>}
    </div>
  );
};

RadioButtonsGroup.propTypes = {
  list: PropTypes.array,
  label: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  direction: PropTypes.string,
  handleChange: PropTypes.func,
  primaryError: PropTypes.bool,
  primaryErrorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customSx: PropTypes.object,
  tooltipContent: PropTypes.string,
  hasTooltipOnLabel: PropTypes.bool,
  hasTooltipOnItem: PropTypes.bool,
  checkedIsDarkGreen: PropTypes.bool,
  showFreeTextField: PropTypes.bool,
  freeTextProps: PropTypes.object,
  ToolTipSx: PropTypes.object,
  customizedSx: PropTypes.object,
  isOptional: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hasCenterTooltip: PropTypes.bool,
  radioBtnPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  radioBtnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formLabelCustomSx: PropTypes.object,
  formLabelTooltipCustomSx: PropTypes.object,
  validationError: PropTypes.bool,
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  radioBtnOptionGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  headerColor: PropTypes.string,
};

export default RadioButtonsGroup;
