import styled from 'styled-components';
export const VideoCardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 21.438rem;
`;
export const EnlargedVideoContainerSx = {
  '& .MuiDialog-paper': {
    maxWidth: '53.125rem',
    maxHeight: '30.375rem',
  },
};
export const EnlargedVideoDiv = styled.div`
  width: 53.125rem;
  height: 30.375rem;
  position: relative;
  overflow: hidden;
`;

export const PlayIconSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};
export const ThumbnailImageSx = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};
export const MainCardSx = {
  width: '21.438rem',
  height: '12.063rem',
  position: 'relative',
};
export const MainCardSmallThumbnailSx = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

export const CircularProgressSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};
export const CircularProgressSx1 = {
  ...CircularProgressSx,
  top: '45%',
  left: '45%',
};
export const CircularProgressSx2 = {
  ...CircularProgressSx,
  width: '2.5rem',
  height: '2.5rem',
  top: '41%',
  left: '42%',
};
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem 2.438rem;
  align-content: center;
  justify-content: center;
`;
export const GridCellContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
