import styled from 'styled-components';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const StatusWrapper = styled.div`
  font-size: 14px;
`;

export const dialogBoxSx = { width: 690, margin: 'auto', height: 500 };

export const dialogBoxTitleSx = {
  padding: 0,
};

export const inputProps = {
  width: '14rem',
  height: '2rem',
  margin: 0,
};

export const InputSx = {
  ...inputProps,
  width: '17rem',
  '& input': {
    padding: '0.25rem 0 0.25rem 0.5rem',
  },
  '& input::placeholder': {
    color: COOL_GRAY,
  },
};

export const modalContentCustomSx = { height: 240 };

export const confirmationalDialogBoxSx = {
  width: 650,
  margin: 'auto',
};

export const updateStateSx = (hasMarginLeft) => ({
  display: 'flex',
  fontWeight: 700,
  fontSize: '0.875rem',
  color: DARK_CHARCOAL,
  marginLeft: checkTernaryCondition(hasMarginLeft, '0.2rem', 0),
  alignContent: 'center',
  flexWrap: 'wrap',
});

export const ConfirmationalModalContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0;
  font-size: 0.875rem;
  margin-right: 0.75rem;
`;

export const ConfirmationalModalContent = styled.div`
  text-overflow: ellipsis;
  font-size: 0.875rem;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  max-width: 9rem;
`;
