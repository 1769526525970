import React, { useState } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/DownloadLink.svg';
import {
  DARK_CHARCOAL,
  NEW_DARK_GREEN,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';
import PropTypes from 'prop-types';
import { BoxWrapper, downloadIconSx, InfoCardWrapper } from './InfoCard.style';
import {
  CARD_CONTENT,
  TooltipContent,
  findCardIcons,
  findStatusIcons,
  sendStatusToFunc,
} from './CardBox.content';
import { checkTernaryCondition, openPDFFromLink } from 'utils/helper';
import {
  getDownloadHandlerApiUrl,
  LANDING_PAGE_CONTENT,
  MENU_PAGE_CONTENT,
} from 'pages/ParticipantDataCollection/pages/ParticipantPages.content';
import axios from 'axios';
import LightTooltip from 'components/LightTooltip';
import { REQUEST_ADDITIONAL_ENROLLMENT_FROM_BASELINE } from 'urls';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { toastStyle } from 'theme/GlobalStyles';

const InfoCard = ({
  title,
  content,
  status,
  navigateHandler,
  participantId,
  setModuleName,
  enrolmentList,
  projectId,
  instanceId,
  projectCycleId,
  projectName,
  statusList,
  pageDetails,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const isDisabled =
    status === CARD_CONTENT.not_started ||
    status === CARD_CONTENT.interested ||
    status === undefined;
  const shouldNotBeClickable =
    status === CARD_CONTENT.submitted ||
    status === CARD_CONTENT.in_review ||
    status === CARD_CONTENT.approved;

  const handleClick = () => {
    if (!isDisabled && !shouldNotBeClickable) {
      setModuleName(title.toLowerCase().split(' ')[0]);
      setIsClicked(!isClicked);
      navigateHandler(
        title.toLowerCase().split(' ')[0],
        projectId,
        participantId,
        instanceId,
        title.toLowerCase().split(' ')[0],
        projectCycleId,
        projectName,
      );
    }
  };
  const handleHover = (value) => {
    if (!isDisabled) setIsHovered(value);
  };
  const handlePDFDownload = (e, moduleName) => {
    e.stopPropagation();
    e.preventDefault();
    const API_URL = getDownloadHandlerApiUrl(moduleName);
    axios
      .get(
        API_URL.replace(':participnatId', participantId).replace(
          ':projectId',
          projectId,
        ),
      )
      .then((response) => {
        openPDFFromLink(response.data.reportLink);
      });
  };
  const sendEmailForEnrolment = (e) => {
    e.stopPropagation();
    e.preventDefault();
    axios
      .post(REQUEST_ADDITIONAL_ENROLLMENT_FROM_BASELINE, {
        internalProjectId: Number(projectId),
        participantId: Number(participantId),
        enrollmentInstanceId: instanceId,
        projectCycleId: projectCycleId,
      })
      .then(() => {
        toast(
          <CustomSnackbar
            type="success"
            message={CARD_CONTENT.email_sent_success_message}
          />,
          toastStyle,
        );
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={CARD_CONTENT.email_sent_fail_message}
          />,
          toastStyle,
        );
      });
  };
  const getDOwnloadIconDisabled = (
    title,
    status,
    enrollmentStatus,
    baselineEnrollmentStatus,
    activityReportingStatus,
  ) => {
    if (title === MENU_PAGE_CONTENT.ENROLLMENT) {
      return (
        status === LANDING_PAGE_CONTENT.REQUESTED &&
        enrollmentStatus === LANDING_PAGE_CONTENT.INTERESTED
      );
    } else if (title === MENU_PAGE_CONTENT.BASELINE_REPORTING) {
      return (
        status === LANDING_PAGE_CONTENT.REQUESTED &&
        baselineEnrollmentStatus !== LANDING_PAGE_CONTENT.ENROLLMENT_IN_PROGRESS
      );
    } else if (title === MENU_PAGE_CONTENT.ACTIVITY_REPORTING) {
      return (
        status === LANDING_PAGE_CONTENT.REQUESTED &&
        activityReportingStatus !== LANDING_PAGE_CONTENT.ENROLLMENT_IN_PROGRESS
      );
    } else {
      return false;
    }
  };

  const downloadIconDisabled = getDOwnloadIconDisabled(
    title,
    status,
    pageDetails?.enrollmentStatus,
    pageDetails?.baselineEnrollmentStatus,
    pageDetails?.activityReportingStatus,
  );
  return (
    <LightTooltip
      arrow
      title={TooltipContent(
        enrolmentList,
        status,
        isDisabled,
        title,
        statusList,
      )}>
      <InfoCardWrapper
        isDisabled={isDisabled}
        isClicked={isClicked}
        shouldNotBeClickable={shouldNotBeClickable}
        isHovered={isHovered}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        onClick={handleClick}>
        {findCardIcons(
          title,
          sendStatusToFunc(isClicked, isHovered, isDisabled, status),
        )}
        <BoxWrapper
          fontSize="1.125rem"
          fontWeight={600}
          gap="5px"
          color={checkTernaryCondition(
            isDisabled,
            TYPOGRAPHY_GRAY,
            DARK_CHARCOAL,
          )}>
          {title}
          {status !== CARD_CONTENT.not_started && status !== undefined && (
            <div data-testid="download-button">
              <LightTooltip title={CARD_CONTENT.download_pdf} arrow>
                <DownloadIcon
                  data-testid="download-icon"
                  style={downloadIconSx(downloadIconDisabled)}
                  onClick={(e) => {
                    handlePDFDownload(e, title.toLowerCase().split(' ')[0]);
                  }}
                />
              </LightTooltip>
            </div>
          )}
        </BoxWrapper>
        <BoxWrapper
          fontSize=".75rem"
          color={checkTernaryCondition(
            isDisabled,
            TYPOGRAPHY_GRAY,
            DARK_CHARCOAL,
          )}>
          {content}
        </BoxWrapper>
        <BoxWrapper
          gap="4px"
          fontSize="0.875rem"
          color={checkTernaryCondition(
            isDisabled,
            TYPOGRAPHY_GRAY,
            DARK_CHARCOAL,
          )}>
          {findStatusIcons(status)} {status}
        </BoxWrapper>
        {status === CARD_CONTENT.approved &&
          title === CARD_CONTENT.enrollment && (
            <BoxWrapper
              gap="4px"
              fontSize="0.75rem"
              color={NEW_DARK_GREEN}
              onClick={(e) => sendEmailForEnrolment(e)}>
              {CARD_CONTENT.enrolment_link}
            </BoxWrapper>
          )}
      </InfoCardWrapper>
    </LightTooltip>
  );
};
InfoCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  status: PropTypes.string,
  navigateHandler: PropTypes.func.isRequired,
  participantId: PropTypes.number.isRequired,
  setModuleName: PropTypes.func.isRequired,
  enrolmentList: PropTypes.any,
  projectId: PropTypes.number.isRequired,
  instanceId: PropTypes.number.isRequired,
  projectCycleId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string).isRequired,
  pageDetails: PropTypes.object,
};
export default InfoCard;
