import { isEmpty } from 'utils/helper';

export const NO_NOTIFICATIONS = 'No notifications available.';
export const DISMISS_BUTTON = 'Dismiss all';
export const SUCCESS_MSG = 'Successful upload of';
export const ERROR_MSG = 'Error in upload of';

export const NOTIFICATION_LOG_CONTENT = {
  success: 'SUCCESS',
  failed: 'FAILED',
  initiated: 'INITIATED',
  fileName: 'error-file.csv',
  errorFile: 'Error file',
  medium: 'medium',
  dismiss: 'Dismiss',
  of: 'of',
  uploadFailedText:
    'participants failed to meet validation requirements. Please download the CSV file to view the participants that need to be corrected and re-upload your file.',
  uploadInitiatedText:
    'We will notify you when the file upload has finished processing.',
  timestampDot: ' • ',
  uploadFailedTechnicalErrorText:
    'couldn’t be uploaded due to a technical error. Please try uploading again.',
};

const NOTIFICATION_HEADINGS = {
  PARTICICIPANTS_ADDITION_FAILED: 'Participants failed to be added',
  UPLOAD_FAILED: 'File failed to upload',
  PROCESSING: 'File is processing...',
};

export const getNotificationHeading = (
  status,
  totalSubmission,
  successfulSubmission,
) => {
  switch (status) {
    case NOTIFICATION_LOG_CONTENT.success:
      return `${successfulSubmission} of ${totalSubmission} participants were successfully added.`;

    case NOTIFICATION_LOG_CONTENT.failed: {
      if (!isEmpty(totalSubmission) && !isEmpty(successfulSubmission)) {
        return NOTIFICATION_HEADINGS.PARTICICIPANTS_ADDITION_FAILED;
      } else {
        return NOTIFICATION_HEADINGS.UPLOAD_FAILED;
      }
    }
    case NOTIFICATION_LOG_CONTENT.initiated:
      return NOTIFICATION_HEADINGS.PROCESSING;
  }
};

export const getNotificationFailedContent = (
  failedSubmission,
  totalSubmission,
  fileName = '',
) => {
  if (!isEmpty(failedSubmission) && !isEmpty(totalSubmission)) {
    return `${failedSubmission} ${NOTIFICATION_LOG_CONTENT.of} ${totalSubmission} ${NOTIFICATION_LOG_CONTENT.uploadFailedText}`;
  } else {
    return `${fileName} ${NOTIFICATION_LOG_CONTENT.uploadFailedTechnicalErrorText}`;
  }
};
