const { RemoveLabel } = require('./RemoveButton.style');
import PropTypes from 'prop-types';

const RemoveButton = ({ label, clickHandler, labelColor }) => {
  return (
    <RemoveLabel color={labelColor} onClick={clickHandler}>
      {label}
    </RemoveLabel>
  );
};

RemoveButton.propTypes = {
  label: PropTypes.string,
  clickHandler: PropTypes.func,
  labelColor: PropTypes.string,
};

export default RemoveButton;
