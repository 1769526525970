import styled from 'styled-components';
import { COOL_GRAY, DARK_GREEN } from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  margin-bottom: 0.5rem;
`;
export const ViewWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
export const TextWrapper = styled.div`
  color: ${({ color }) => color};
  font-family: 'Open Sans', sans-serif;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-style: normal;
  font-weight: 400;
`;
export const SwitchWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const SwitchAndLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const InfoTooltipWrapper = styled.div`
  font-size: 0.8rem;
  width: 250px;
  padding: 0.3rem;
`;
export const InfoSx = {
  color: COOL_GRAY,
  fontSize: '1.2rem',
};
export const SwitchSx = {
  width: '32px',
  height: '16px',
  padding: '0px',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '15px',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '2px',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      padding: '2px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: DARK_GREEN,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
};
