import styled from 'styled-components';
import { BLACK, BRIGHT_GRAY, COOL_GRAY, QUILL_GRAY } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainTableWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  border-top: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #d0d0ce')};
  border-radius: 4px;
  background-color: ${({ background }) => background};
  padding: ${({ padding }) => padding};
  overflow-y: ${({ overflowY }) => overflowY};
  height: ${({ height }) => height};
`;
export const TableRowWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ background }) => background};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;
export const ColumnHeaderWrapper = styled.div`
  color: ${({ color }) => color || BLACK};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || '0.5rem'};
  border-left: ${({ borderLeft }) =>
    checkTernaryCondition(borderLeft, '1px solid #d0d0ce', '')};
  border-right: ${({ borderRight }) =>
    checkTernaryCondition(borderRight, '1px solid #d0d0ce', '')};
  box-sizing: border-box;
  height: ${({ height }) => height};
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export const LegendWrapper = styled.div`
  font-size: 0.75rem;
  color: ${COOL_GRAY};
  font-weight: 400;
`;

export const TooltipAndHeaderWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || '0.5rem'};
`;

export const InfoTooltipWrapper = styled.div`
  font-size: 0.75rem;
  width: 204px;
  padding: 0.5rem;
  border-radius: 0.125rem;
`;

export const InfoSx = {
  color: COOL_GRAY,
  fontSize: '1.2rem',
  cursor: 'pointer',
};

export const Sublabel = styled.div`
  font-size: 0.625rem;
  font-weight: 400;
  color: ${COOL_GRAY};
  width: max-content;
`;

export const AccordianSummarySx = {
  height: '48px',
  '&.Mui-expanded': {
    backgroundColor: 'rgba(227, 237, 242, 1)',
    minHeight: '48px',
  },
};
export const AccordionSx = {
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    padding: '0 1rem 0 0.5rem',
  },
  '&.MuiAccordion-root:first-of-type': {
    borderRadius: '0px',
    borderTop: `1px solid ${QUILL_GRAY}`,
  },
};
export const AccordionSxWithoutBorder = {
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    padding: '0 1rem 0 0.5rem',
  },
};

export const AccordianDetailsSx = {
  backgroundColor: BRIGHT_GRAY,
  padding: '1rem',
};
export const TableBackground = 'rgba(255, 255, 255, 0.5)';
export const FullWidth = '100%';
export const SummaryColumnHeaderFont = { size: '0.875rem', weight: 400 };
export const DetailsColumnHeaderFont = { size: '0.875rem', weight: 600 };
export const DetailedPanelFont = {
  size: '0.75rem',
  weight: 600,
  color: COOL_GRAY,
};
export const ExpandableRowFont = '1rem';
export const ExpandableRowWrapperStyle = {
  height: '3.125rem',
  margin: '0 2.5rem 0 0.5rem',
};

export const innerTablePadding = '0 1.5rem 0 0';

export const legendIconSx = (color) => {
  return {
    fontSize: '0.65rem',
    color: color,
    borderRadius: '5px',
  };
};

export const TooltipSx = { '& .MuiTooltip-tooltip': { padding: 0 } };
