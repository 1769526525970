import styled from 'styled-components';
import { SortColNames } from './OriginationProjectList.content';
import {
  COOL_GRAY,
  DARK_GREEN,
  GLOBAL_BLUE,
  TYPOGRAPHY_GRAY,
  WHITE,
} from 'theme/GlobalColors';
import { TextField } from '@mui/material';

const FONT_FAMILY = 'Open Sans';

export const MainWrapper = styled.div`
  width: 100%;
  background: #ffff;
  border-radius: 2px;
`;
export const datagridSx = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  borderRight: 0,
  borderLeft: 0,
  borderBottom: 0,
  borderRadius: '2px',
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
    whiteSpace: 'break-spaces',
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: '#333333',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    minHeight: 'fit-content',
  },

  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '2.5rem',
  },
};
export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
`;
export const InfoSx = {
  color: COOL_GRAY,
  fontSize: '1.1rem',
  marginRight: '0.5rem',
  marginLeft: '0.5rem',
};

export const clearAllSx = {
  marginRight: '0.5rem',
};

export const listViewStyle = {
  position: 'relative',
};

export const OptionsWrapper = styled.div`
  padding-right: 0.4rem;
`;
export const FilterHeader = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`;
export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
`;
export const SortArrowsWrapper = styled.div`
  margin-bottom: -0.7rem;
  padding: 0px 0.45rem;
  color: ${WHITE};
  &:hover {
    color: ${COOL_GRAY};
  }
`;
export const HoverableContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${SortArrowsWrapper} {
      color: ${COOL_GRAY};
    }
  }
`;
export const handleArrowColor = (col, sortColumn) => {
  return {
    color: sortColumn === SortColNames[col] ? COOL_GRAY : WHITE,
    '&:hover': {
      color: COOL_GRAY,
    },
  };
};
export const AccessibleLinkStyle = styled.div`
  color: ${GLOBAL_BLUE};
  font-family: ${FONT_FAMILY};
`;
export const CarbonAbated = styled.div`
  margin-right: 1rem;
  font-family: ${FONT_FAMILY};
`;
export const InfoTooltipWrapper = styled.div`
  font-size: 0.8rem;
  width: 200px;
  padding: 0.3rem 0;
`;
export const TruncatedWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${FONT_FAMILY};
`;
export const DefaultFontWrapper = styled.div`
  font-family: ${FONT_FAMILY};
  color: ${({ color }) => color || ''};
`;

export const TimeStampIconWrapper = styled.span`
  color: #63656a;
  font-size: 0.3rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  vertical-align: middle;
`;

export const SearchBox = styled(TextField)(() => ({
  backgroundColor: WHITE,
  width: '100%',
  maxWidth: '18.75rem',

  '& .MuiTextField-root': {
    marginRight: '0.5rem',
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: DARK_GREEN,
    },
  },
  '& .MuiOutlinedInput-root': {
    padding: '0 0.5rem',
    height: '1.75rem',
    borderRadius: 'inherit',

    '& input': {
      padding: '0px 4px',
      fontSize: '0.75rem',
      color: COOL_GRAY,
      '::placeholder': {
        opacity: 1,
      },
    },

    '&.Mui-focused fieldset': {
      borderColor: DARK_GREEN,
    },
  },
}));

export const SearchAndFilterSection = styled.div`
  display: flex;
  gap: 1rem;
  align-items: start;
  margin-top: 1rem;
  justify-content: space-between;
  width: ${({ width }) => width || '100%'};
  flex: 1 1 0%;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0.5rem'};
`;

export const AppliedFilters = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  align-items: center;
`;

export const FilterChip = styled.div`
  padding: 0.156rem 0.25rem;
  font-size: 0.688rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${COOL_GRAY};
  border: 0.5px solid ${COOL_GRAY};
  width: fit-content;
  gap: 0.25rem;
  background-color: ${WHITE};
`;

export const ClearLink = styled.div`
  color: ${GLOBAL_BLUE};
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 1rem;
`;

export const cursorPointerStyle = {
  cursor: 'pointer',
};

export const SearchAndFiltersFlex = {
  display: 'flex',
  width: '90%',
  alignItems: 'center',
  gap: '0.25rem',
};

export const filterClearIconSx = {
  fontSize: '1rem',
  cursor: 'pointer',
  color: TYPOGRAPHY_GRAY,
};
export const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.5rem;
`;
export const modalTitleSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '40rem',
};

export const TooltipSx = {
  '& .MuiTooltip-tooltip': {
    position: 'relative',
    left: '-35px',
    maxWidth: '1200px',
  },
};
export const DialogTooltipSx = {
  '& .MuiTooltip-tooltip': {
    position: 'relative',
    left: '-100px',
    maxWidth: '1000px',
  },
};

export const SettingsSearchFilter = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-end',
  gap: '1rem',
};
