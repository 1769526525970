import styled from 'styled-components';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  background-color: ${WHITE};
  width: 100%;
  height: 70vh;
  position: relative;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
`;
export const ContentWrapper = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 37.5rem;
  text-align: center;
`;
export const TextWrapper = styled.div`
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  line-height: ${({ lineHeight }) => lineHeight ?? '1.3619rem'};
  text-align: center;
  letter-spacing: 0.2px;
  color: ${DARK_CHARCOAL};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '1.5rem'};
  margin-top: ${({ marginTop }) => marginTop ?? '0rem'};
`;
export const btnSx = {
  padding: '0.875rem 1rem',
  height: '2.75rem',
};

export const ImageContainer = styled.div`
  height: 5rem;
`;
export const ImageSx = {
  width: '5rem',
  height: '5rem',
};
