/**
 * @description function to get structured object of name and email list in filter click
 * @param {Object} data
 * @returns {Object}
 */
export const getStructuredObject = (data) => {
  return {
    id: data?.id,
    itemLabel: data?.label,
    checked: false,
  };
};
