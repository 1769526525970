import { useState } from 'react';
import LeftNavigationMenu from './LeftNavigationMenu/LeftNavigationMenu.react';
import ProjectSchedule from './ProjectSchedule/ProjectSchedule.react';
import {
  ProjectSettingsContent,
  ProjectSettingsWrapper,
} from './ProjectSettingsTab.style';
import { ProjectDesignSettingTab } from './ProjectDesignSettingTab/ProjectDesignSettingTab.react';
import { ProjectGeneralSettingTab } from './ProjectGeneralSettingTab/ProjectGeneralSettingTab.react';

const ProjectSettingsTab = () => {
  const [activeTab, setActiveTab] = useState(1);
  const navigationMenuList = [
    {
      id: 1,
      title: 'General',
      component: <ProjectGeneralSettingTab />,
    },
    {
      id: 2,
      title: 'Project design',
      component: <ProjectDesignSettingTab />,
    },
    {
      id: 3,
      title: 'Schedule',
      component: <ProjectSchedule />,
    },
  ];
  return (
    <div>
      <ProjectSettingsWrapper>
        <LeftNavigationMenu
          navigationMenuList={navigationMenuList}
          navSwitchHandler={setActiveTab}
          activeTab={activeTab}
        />
        <ProjectSettingsContent>
          {navigationMenuList.find((menu) => menu.id === activeTab)?.component}
        </ProjectSettingsContent>
      </ProjectSettingsWrapper>
    </div>
  );
};

export default ProjectSettingsTab;
