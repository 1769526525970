import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import {
  AccordionDetailsSx,
  AccordionSummarySx,
  AccordionSx,
  ArrowLabelWrapper,
  ButtonWrapper,
  MainWrapper,
  NoteContent,
  NoteWrapper,
  RecordSeparatorLine,
  RowDataWrapper,
  RowWrapper,
  TextWrapper,
  TillageNoteWrapper,
  TitleWrapper,
  addBtnSx,
} from './ModalLessAccordionDisplayBox.style';
import { QUILL_GRAY } from 'theme/GlobalColors';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import { useContext, useEffect, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CropTypeModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/CropTypeModal/CropTypeModal.react';
import CopyModal from '../CopyModal/CopyModal.react';
import {
  CropDataMissingNote,
  PAGE_CONTENT,
  SoilAmendmentsDataMissingNote,
} from './ModalLessAccordianDisplayBox.content';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import { MODAL_CONTENT } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/TillageModal/TillageModal.content';
import { CopyDisclaimer } from '../TabBodyComponent.style';
import { FETCH_FIELD_COPY_ACROSS_DROPDOWN_DATA } from 'urls';
import axios from 'axios';
import { GLOBAL_EXPAND_COLLAPSE_INITIAL_STATE } from '../DetailedOperationsDataComp/DetailedOperationsDataComp.content';

const ModalLessAccordionDisplayBox = ({
  fieldId,
  mainLabel,
  subText,
  addBtnLabel,
  addNewBtnHandler = () => {},
  data,
  ModalWrapper,
  modalOpen,
  setModalOpen,
  submitHandler,
  cropForm = false,
  cropFormProps,
  setIsDeleteModalOpen,
  cropSubmitHandler,
  copyBtnLabel,
  sampleId,
  copyHandler,
  currentYear,
  jumpClassName,
  disableAllFieldActions = false,
  tillageType = [],
  makeFormDirty = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const projectId = searchParams.get('projectid');
  const { isCoverCropMissingInOpsData, missingSoilAmendmentsInOpsData } =
    useContext(implementedActivitiesWithOpsDataContext);

  const { globalAllExpanded, setGlobalAllExpanded, isFieldDisabled } =
    useContext(implementedActivitiesWithOpsDataContext);
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [isFieldPresent, setIsFieldPresent] = useState(false);
  const [expandedState, setExpandedState] = useState(false);

  useEffect(() => {
    const expandedOrNot = globalAllExpanded[`${fieldId}-${currentYear}`]?.find(
      (item) => item.label === mainLabel,
    )?.value;
    setExpandedState(expandedOrNot);
  }, [globalAllExpanded]);
  const handleArrowIcon = () => {
    return (
      <div>
        {checkTernaryCondition(
          expandedState,
          <KeyboardArrowDownIcon />,
          <KeyboardArrowRightIcon />,
        )}
      </div>
    );
  };

  const handleAccordionToggle = (event) => {
    setGlobalAllExpanded((currentState) => {
      const key = `${fieldId}-${currentYear}`;
      if (isEmpty(currentState[key])) {
        currentState[key] = GLOBAL_EXPAND_COLLAPSE_INITIAL_STATE;
      }
      return {
        ...currentState,
        [key]: currentState[key].map((item) => {
          if (item.label === mainLabel) {
            return {
              ...item,
              value: !item.value,
            };
          }
          return item;
        }),
      };
    });
    event.stopPropagation();
  };
  useEffect(() => {
    if (data?.length === 0) setIsAddBtnDisabled(false);
  }, [data]);
  const haveRecords = data?.length > 0;

  const reducedTillNote = (
    <TillageNoteWrapper>
      <NoteWrapper>{MODAL_CONTENT.tillage_note_title}</NoteWrapper>
      <NoteContent>{MODAL_CONTENT.tillage_reduced_note}</NoteContent>
    </TillageNoteWrapper>
  );
  const noTillNote = (
    <TillageNoteWrapper>
      <NoteWrapper>{MODAL_CONTENT.tillage_note_title}</NoteWrapper>
      <NoteContent>{MODAL_CONTENT.tillage_noTill_note}</NoteContent>
    </TillageNoteWrapper>
  );
  const getTillageActivity = (mainLabel, tillageType) => {
    if (mainLabel === MODAL_CONTENT.heading) {
      switch (tillageType[0]) {
        case MODAL_CONTENT.reducedTill:
          return reducedTillNote;
        case MODAL_CONTENT.noTill:
          return noTillNote;
        default:
          return null;
      }
    }
    return null;
  };

  const checkIsFieldPresent = () => {
    const url = `${FETCH_FIELD_COPY_ACROSS_DROPDOWN_DATA}?ignore=${sampleId}&participantId=${participantId}&projectCycleId=${projectId}`;
    axios.get(url).then((response) => {
      const res = response.data.field.length;
      setIsFieldPresent(res > 0);
    });
  };

  useEffect(() => {
    if (sampleId) checkIsFieldPresent();
  }, [sampleId]);

  const copiedDataPresent = useMemo(
    () => data?.some((item) => item.data?.isCopied),
    [data],
  );
  const containsNote = tillageType?.length > 0;
  return (
    <>
      {/* Note: Using className here to scroll to this view on click from implemented activities */}
      <MainWrapper className={jumpClassName}>
        <Accordion expanded={expandedState} sx={AccordionSx}>
          <AccordionSummary sx={AccordionSummarySx}>
            <RowWrapper>
              <ArrowLabelWrapper onClick={handleAccordionToggle}>
                {handleArrowIcon()}
                <TitleWrapper>
                  <TextWrapper fontSize={'1rem'} fontWeight={600}>
                    {mainLabel && mainLabel.replace('- undefined', '')}
                  </TextWrapper>
                  {subText && (
                    <TextWrapper
                      fontSize={'0.875rem'}
                      fontWeight={400}
                      color={QUILL_GRAY}>
                      {subText}
                    </TextWrapper>
                  )}
                </TitleWrapper>
              </ArrowLabelWrapper>
            </RowWrapper>
          </AccordionSummary>
          <AccordionDetails sx={AccordionDetailsSx(containsNote)}>
            {getTillageActivity(mainLabel, tillageType)}
            <RowDataWrapper>
              <>
                {mainLabel === PAGE_CONTENT.cropDataLabel &&
                  isCoverCropMissingInOpsData && <CropDataMissingNote />}

                {mainLabel === PAGE_CONTENT.cropDataLabel &&
                  missingSoilAmendmentsInOpsData?.length > 0 && (
                    <SoilAmendmentsDataMissingNote
                      soilAmendments={missingSoilAmendmentsInOpsData}
                    />
                  )}
                {copiedDataPresent && (
                  <CopyDisclaimer>
                    {PAGE_CONTENT.cashCropCopyDisclaimer}
                  </CopyDisclaimer>
                )}
              </>

              {data?.map((item, index) => {
                if (cropForm) {
                  const valueToUse = checkTernaryCondition(
                    item.data,
                    item.data,
                    item,
                  );
                  const modalProps = {
                    ...cropFormProps,
                    id: item.id,
                    data: valueToUse,
                    cropYear: item.year || currentYear,
                    submitHandler: submitHandler,
                    setIsDeleteModalOpen: setIsDeleteModalOpen,
                    cropSubmitHandler: cropSubmitHandler,
                    copiedDataPresent: copiedDataPresent,
                    disableAllFieldActions: disableAllFieldActions,
                  };
                  return (
                    <div key={item?.id}>
                      <CropTypeModal
                        {...modalProps}
                        makeFormDirty={makeFormDirty}
                      />
                      {data.length > 1 && index < data.length - 1 && (
                        <RecordSeparatorLine>
                          <hr />
                        </RecordSeparatorLine>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div key={item?.id}>
                      <ModalWrapper
                        actsAsModal={false}
                        practiceTypeData={data}
                        year={item?.year}
                        id={item?.id}
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        submitHandler={submitHandler}
                        formDataValue={item?.data}
                        disableAllFieldActions={disableAllFieldActions}
                        setIsAddBtnDisabled={setIsAddBtnDisabled}
                        makeFormDirty={makeFormDirty}
                      />
                      {data.length > 1 && index < data.length - 1 && (
                        <RecordSeparatorLine>
                          <hr />
                        </RecordSeparatorLine>
                      )}
                    </div>
                  );
                }
              })}
              {!isFieldDisabled && (
                <ButtonWrapper>
                  <Button
                    id="basic-button"
                    aria-haspopup="true"
                    variant={'contained'}
                    size={'medium'}
                    disabled={isAddBtnDisabled || disableAllFieldActions}
                    disableElevation
                    sx={addBtnSx(haveRecords)}
                    onClick={() => {
                      addNewBtnHandler();
                    }}>
                    {addBtnLabel}
                  </Button>
                  {isFieldPresent && copyBtnLabel && (
                    <Button
                      id="basic-button"
                      aria-haspopup="true"
                      variant={'contained'}
                      disabled={disableAllFieldActions}
                      size={'medium'}
                      disableElevation
                      sx={addBtnSx(haveRecords)}
                      onClick={() => {
                        setOpenCopyModal(true);
                      }}>
                      {copyBtnLabel}
                    </Button>
                  )}
                </ButtonWrapper>
              )}
            </RowDataWrapper>
          </AccordionDetails>
        </Accordion>
      </MainWrapper>
      <ModalWrapper
        actsAsModal={true}
        practiceTypeData={data}
        year={modalOpen.data?.year}
        id={modalOpen?.id}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        submitHandler={submitHandler}
        disableAllFieldActions={disableAllFieldActions}
        makeFormDirty={makeFormDirty}
      />
      <CopyModal
        open={openCopyModal}
        modalCloseHandler={() => setOpenCopyModal(false)}
        title={PAGE_CONTENT.CopyFieldCrop}
        apiParams={{ sampleId, participantId, projectCycleId, projectId }}
        copyHandler={copyHandler}
      />
    </>
  );
};

export default ModalLessAccordionDisplayBox;

ModalLessAccordionDisplayBox.propTypes = {
  mainLabel: PropTypes.string,
  subText: PropTypes.string,
  addBtnLabel: PropTypes.string,
  addNewBtnHandler: PropTypes.func,
  data: PropTypes.array,
  ModalWrapper: PropTypes.elementType,
  modalOpen: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape({
      year: PropTypes.string,
    }),
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  cropForm: PropTypes.bool,
  cropFormProps: PropTypes.object,
  setIsDeleteModalOpen: PropTypes.func,
  cropSubmitHandler: PropTypes.func,
  copyBtnLabel: PropTypes.string,
  sampleId: PropTypes.string,
  copyHandler: PropTypes.func,
  currentYear: PropTypes.string,
  jumpClassName: PropTypes.string,
  tillageType: PropTypes.array,
  makeFormDirty: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
  fieldId: PropTypes.number,
};
