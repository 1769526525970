import { TextWrapper } from 'components/FormComponents/FormStyles';
import React, { useEffect, useState } from 'react';
import { PAGE_CONSTANTS } from './VideoTutorialsPage.content';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import { CircularProgress } from '@mui/material';
import {
  CircularProgressSx,
  GridCellContainer,
  GridContainer,
} from './VideoTutorialsPage.style';
import VideoTutorialCard from './VideoTutorialCard.react';
import axios from 'axios';
import { GET_VIDEO_TUTORIALS } from 'urls';
import { checkTernaryCondition } from 'utils/helper';

const VideoTutorialsPage = () => {
  const [videoDetails, setVideoDetails] = useState([]);
  const [videoDataFetching, setVideoDataFetching] = useState(false);
  useEffect(() => {
    setVideoDataFetching(true);
    axios
      .get(GET_VIDEO_TUTORIALS)
      .then((response) => {
        setVideoDetails(response.data);
      })
      .finally(() => {
        setVideoDataFetching(false);
      });
  }, []);
  return (
    <div>
      <TextWrapper
        fontWeight="600"
        fontSize="1.25rem"
        color={DARK_CHARCOAL}
        marginBottom={'1.5rem'}>
        {PAGE_CONSTANTS.PAGE_HEADING}
      </TextWrapper>
      {checkTernaryCondition(
        videoDataFetching,
        <div style={CircularProgressSx}>
          <CircularProgress></CircularProgress>
        </div>,
        <GridContainer>
          {videoDetails?.map(
            ({
              videoId,
              videoLink,
              thumbnailLink,
              videoName,
              videoDescription,
            }) => (
              <GridCellContainer key={videoId}>
                <VideoTutorialCard
                  videoTutorialUrl={videoLink}
                  thumbnailUrl={thumbnailLink}
                  videoTitle={videoName}
                  videoDescription={videoDescription}
                />
              </GridCellContainer>
            ),
          )}
        </GridContainer>,
      )}
    </div>
  );
};

export default VideoTutorialsPage;
