import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  FILE_UPLOAD_CONFIG,
  ISSUE_TYPE_LIST,
  IMPORTANCE_LIST,
  MODAL_CONTENT,
  errorInitialState,
  fieldNames,
  formInitialState,
  describe_issue_tooltip_label_content,
  level_of_importance_tooltip_label_content,
} from './EnrollmentHelpModal.content';
import { TextWrapper } from '../../../../../src/pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/Modals.style';
import { TooltipSx } from '../../../../../src/pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/NitrogenFertilizerModal/NitrogentFertilizerModal.style';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  InfoSx,
  InfoTooltipWrapper,
  InputSx,
  ModalSubmittedContent,
  SubmitBtnStyle,
  ToolTipAndAlertWrapper,
  TooltipHeading,
  closeIconSx,
  dropdownStyleProps,
} from './EnrollmentHelpModal.style';
import CloseIcon from '@mui/icons-material/Close';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import TextAreaField from 'components/FormComponents/TextAreaField/TextAreaField.react';
import MultipleFileUploadInput from 'components/FormComponents/MultipleFileUploadInput/MultipleFileUploadInput.react';
import { useForm } from 'hooks/useForm';
import { useContext, useEffect, useState } from 'react';
import { SUBMIT_SUPPORT_MODAL, SUBMIT_SUPPORT_MODAL_GLOBAL } from 'urls';
import axios from 'axios';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { COOL_GRAY } from 'theme/GlobalColors';
import { useSearchParams } from 'react-router-dom';
import {
  flexSpaceBetween,
  emptyStateCTAButtonDefaultStyle,
} from 'theme/GlobalStyles';
import { FALSE, TOOLTIP_PLACEMENTS } from 'utils/config';
import {
  FocusedInputSx,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import PropTypes from 'prop-types';
import LightTooltip from 'components/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';

const EnrollmentHelpModal = ({ modalOpen, setModalOpen, section }) => {
  const { formValue, setFormValue, customFieldChangeHandler } =
    useForm(formInitialState);
  const [errorState, setErrorState] = useState(errorInitialState);
  const modalCloseHandler = () => {
    setModalOpen(false);
    setFormValue(formInitialState);
    setErrorState(errorInitialState);
    setIsSubmitDisabled(false);
  };
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSupportModalSubmitted, setIsSupportModalSubmitted] = useState(false);
  const { getConfigByKey } = useContext(userAuthRolesContext);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');

  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);

  useEffect(() => {
    if (modalOpen) {
      setIsSupportModalSubmitted(false);
    }
  }, [modalOpen]);

  const updateImageList = (list, isImageErrorPresent) => {
    customFieldChangeHandler(fieldNames.images, list);
    setErrorState((prev) => ({ ...prev, imageError: isImageErrorPresent }));
  };

  const handleSubmit = () => {
    const currentErrorStates = {
      issueTypeError: !formValue.issueType.trim(),
      levelOfImportanceError: !formValue.levelOfImportance.trim(),
      specificIssueTypeError:
        formValue.issueType === ISSUE_TYPE_LIST[6]?.label &&
        !formValue.specificIssueType.trim(),
      specificFeedbackTypeError:
        formValue.issueType === ISSUE_TYPE_LIST[5]?.label &&
        !formValue.specificFeedbackType.trim(),
      descriptionError: !formValue.description.trim(),
    };

    setErrorState((prev) => ({
      ...prev,
      ...currentErrorStates,
    }));
    if (!Object.values(currentErrorStates).includes(true)) {
      const formData = new FormData();
      if (formValue.images.length === 0) {
        formData.append('images', null);
      } else {
        for (const image of formValue.images) {
          formData.append('images', image.file);
        }
      }
      setIsLoading(true);
      const submitUrl =
        section === 'participant-help-button'
          ? SUBMIT_SUPPORT_MODAL
          : SUBMIT_SUPPORT_MODAL_GLOBAL;
      axios
        .post(submitUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            issueType: formValue.issueType,
            specificIssueType: formValue.specificIssueType,
            levelOfImportance: formValue.levelOfImportance,
            feedback: formValue.specificFeedbackType,
            description: formValue.description,
            internalProjectId: projectId || '',
            participantId: participantId || '',
          },
        })
        .then(() => {
          setIsSupportModalSubmitted(true);
        })
        .catch(() => {
          modalCloseHandler();
        })
        .finally(() => {
          setIsLoading(false);
        });
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen}
      onClose={modalCloseHandler}
      sx={DialogStyle}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {checkTernaryCondition(
            isSupportModalSubmitted,
            MODAL_CONTENT.MESSAGE_SENT_LABEL,
            MODAL_CONTENT.modal_heading,
          )}
        </div>
        <CloseIcon onClick={modalCloseHandler} sx={closeIconSx} />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        {checkTernaryCondition(
          isSupportModalSubmitted,
          <ModalSubmittedContent>
            {MODAL_CONTENT.MESSAGE_SENT_CONTENT.replace(
              '{{support_email}}',
              getConfigByKey(MODAL_CONTENT.EMAIL_KEY),
            )}
          </ModalSubmittedContent>,
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}>
              <CircularProgress color="success" />
            </Backdrop>{' '}
            <DropDown
              labelMarginTop="1.5rem"
              labelMarginBottom="0.25rem"
              hasNoBottomMargin={true}
              value={checkTernaryCondition(
                formValue.issueType === '',
                MODAL_CONTENT.ISSUE_TYPE_PLACEHOLDER,
                formValue.issueType,
              )}
              onUpdate={(e) => {
                customFieldChangeHandler(fieldNames.issueType, e.target.value);
                setErrorState((prev) => ({
                  ...prev,
                  issueTypeError: !e.target.value.trim() && FALSE,
                  specificIssueTypeError: FALSE,
                  specificFeedbackTypeError: FALSE,
                }));
              }}
              label={MODAL_CONTENT.ISSUE_TYPE_LABEL}
              error={errorState.issueTypeError}
              customPlaceholderColor={COOL_GRAY}
              errorMessage={''}
              setFormFieldValue={() => {}}
              stateValue={''}
              setStateValue={() => {}}
              name={fieldNames.issueType}
              field_key={fieldNames.issueType}
              dropDownPlaceholder={MODAL_CONTENT.ISSUE_TYPE_PLACEHOLDER}
              dropdownlist={ISSUE_TYPE_LIST}
              setModalErrorState={() => {}}
              fieldName={fieldNames.issueType}
              showLabelAsValue={true}
              {...dropdownStyleProps}
            />
            {formValue.issueType === ISSUE_TYPE_LIST[6]?.label && (
              <InputField
                width="100%"
                label={MODAL_CONTENT.SPECIFIC_ISSUE_TYPE_LABEL}
                required
                name={fieldNames.specificIssueType}
                value={formValue.specificIssueType}
                onUpdate={(e) => {
                  customFieldChangeHandler(
                    fieldNames.specificIssueType,
                    e.target.value,
                  );
                  setErrorState((prev) => ({
                    ...prev,
                    specificIssueTypeError: !e.target.value.trim() && FALSE,
                  }));
                }}
                placeholder={MODAL_CONTENT.SPECIFIC_ISSUE_TYPE_PLACEHOLDER}
                primaryError={errorState.specificIssueTypeError}
                primaryErrorMessage={
                  MODAL_CONTENT.specific_issue_type_required_error
                }
                customInputSx={{ ...InputSx, ...FocusedInputSx }}
                maxLength={100}
                minWidth="10rem"
                labelMarginTop="1.5rem"
                rightSideLabelFormLabelMargin="1.5rem"
                labelMarginBottom="0.25rem"
              />
            )}
            {formValue.issueType === ISSUE_TYPE_LIST[5]?.label && (
              <InputField
                width="100%"
                label={MODAL_CONTENT.SPECIFIC_FEEDBACK_TYPE_LABEL}
                required
                name={fieldNames.specificFeedbackType}
                value={formValue.specificFeedbackType}
                onUpdate={(e) => {
                  customFieldChangeHandler(
                    fieldNames.specificFeedbackType,
                    e.target.value,
                  );
                  setErrorState((prev) => ({
                    ...prev,
                    specificFeedbackTypeError: !e.target.value.trim() && FALSE,
                  }));
                }}
                placeholder={MODAL_CONTENT.SPECIFIC_FEEDBACK_TYPE_PLACEHOLDER}
                primaryError={errorState.specificFeedbackTypeError}
                customInputSx={{ ...InputSx, ...FocusedInputSx }}
                maxLength={100}
                minWidth="10rem"
                labelMarginTop="1.5rem"
                labelMarginBottom="0.25rem"
              />
            )}
            <TextWrapper
              fontSize="0.875rem"
              marginBottom="0.25rem"
              marginTop={checkTernaryCondition(
                formValue.issueType !== ISSUE_TYPE_LIST[6]?.label,
                '1.5rem',
                '0rem',
              )}
              gap="1rem">
              {MODAL_CONTENT.LEVEL_OF_IMPORTANCE_LABEL}
              <ToolTipAndAlertWrapper>
                <LightTooltip
                  placement={TOOLTIP_PLACEMENTS.BOTTOM_END}
                  arrow
                  sx={TooltipSx}
                  title={
                    <InfoTooltipWrapper>
                      <TooltipHeading marginBottom={'1%'}>
                        {MODAL_CONTENT.LEVEL_OF_IMPORTANCE_LABEL_HEADING}
                      </TooltipHeading>
                      {level_of_importance_tooltip_label_content.map(
                        (element) => (
                          <span key={element.data}>
                            <span style={{ fontWeight: 'bold' }}>
                              {element.level}
                              {MODAL_CONTENT.colon}
                            </span>
                            <span>{element.data}</span>
                            <p></p>
                          </span>
                        ),
                      )}
                    </InfoTooltipWrapper>
                  }>
                  <InfoOutlinedIcon sx={InfoSx}></InfoOutlinedIcon>
                </LightTooltip>
                {errorState.levelOfImportanceError && (
                  <AlertIcon data-testId="error-icon" />
                )}
              </ToolTipAndAlertWrapper>
            </TextWrapper>
            <DropDown
              value={checkTernaryCondition(
                formValue.levelOfImportance === '',
                MODAL_CONTENT.LEVEL_OF_IMPORTANCE_PLACEHOLDER,
                formValue.levelOfImportance,
              )}
              onUpdate={(e) => {
                customFieldChangeHandler(
                  fieldNames.levelOfImportance,
                  e.target.value,
                );
                setErrorState((prev) => ({
                  ...prev,
                  levelOfImportanceError: !e.target.value.trim() && FALSE,
                }));
              }}
              customPlaceholderColor={COOL_GRAY}
              setFormFieldValue={() => {}}
              stateValue={''}
              setStateValue={() => {}}
              name={fieldNames.levelOfImportance}
              field_key={fieldNames.levelOfImportance}
              dropDownPlaceholder={
                MODAL_CONTENT.LEVEL_OF_IMPORTANCE_PLACEHOLDER
              }
              dropdownlist={IMPORTANCE_LIST}
              setModalErrorState={() => {}}
              fieldName={fieldNames.levelOfImportance}
              showLabelAsValue={true}
              {...dropdownStyleProps}
              labelMarginTop="1.5rem"
              labelMarginBottom="0.25rem"
              hasNoBottomMargin={true}
            />
            <TextWrapper
              fontSize="0.875rem"
              marginBottom="0.25rem"
              marginTop="1.5rem"
              gap="1rem">
              {MODAL_CONTENT.DESCRIBE_ISSUE_LABEL}
              <ToolTipAndAlertWrapper>
                <LightTooltip
                  placement={TOOLTIP_PLACEMENTS.BOTTOM_END}
                  arrow
                  sx={TooltipSx}
                  title={
                    <InfoTooltipWrapper>
                      <TooltipHeading>
                        {MODAL_CONTENT.DESCRIBE_ISSUE_TOOLTIP_LABEL_HEADING}
                      </TooltipHeading>
                      <ul style={{ marginLeft: '-5%' }}>
                        {describe_issue_tooltip_label_content.map((element) => (
                          <li key={element.data}>{element.data}</li>
                        ))}
                      </ul>
                    </InfoTooltipWrapper>
                  }>
                  <InfoOutlinedIcon sx={InfoSx}></InfoOutlinedIcon>
                </LightTooltip>
                {errorState.descriptionError && (
                  <AlertIcon data-testId="error-icon" />
                )}
              </ToolTipAndAlertWrapper>
            </TextWrapper>
            <TextAreaField
              OptionalLabelText={''}
              placeholder={MODAL_CONTENT.DESCRIBE_ISSUE_PLACEHOLDER}
              name={fieldNames.description}
              value={formValue.description}
              onUpdate={(e) => {
                customFieldChangeHandler(
                  fieldNames.description,
                  e.target.value,
                );
                setErrorState((prev) => ({
                  ...prev,
                  descriptionError: !e.target.value.trim() && FALSE,
                }));
              }}
              viewMode={false}
              primaryError={errorState.descriptionError}
              primaryErrorMessage={''}
              displayCountMessage={''}
              hasNoBottomMargin={true}
              marginTop="0rem"
              marginBottom="0.25rem"
              maxLength={2000}
            />
            <MultipleFileUploadInput
              label={MODAL_CONTENT.SCREENSHOT_UPLOAD_LABEL}
              isOptional={true}
              dragAndDropText={MODAL_CONTENT.SCREENSHOT_DROP_FILE_LABEL}
              acceptedFilesInfo={FILE_UPLOAD_CONFIG}
              updateFileData={updateImageList}
              showRejectedFiles
              marginTop="1.5rem"
              marginBottom="0.25rem"
              hasNoBottomMargin={true}
            />
          </>,
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle(isSupportModalSubmitted)}>
        <Button
          disableElevation
          sx={[
            CancelBtnStyle,
            tertiaryButtonStyle,
            emptyStateCTAButtonDefaultStyle,
          ]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.CANCEL_BTN_LABEL}
        </Button>
        <Button
          disableElevation
          sx={[
            SubmitBtnStyle,
            primaryButtonStyle('0.55rem 0.9rem'),
            emptyStateCTAButtonDefaultStyle,
          ]}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}>
          {MODAL_CONTENT.SUBMIT_BTN_LABEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EnrollmentHelpModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  section: PropTypes.string,
};

export default EnrollmentHelpModal;
