import React, { useState } from 'react';
import { checkTernaryCondition } from 'utils/helper';
import { ReactComponent as GreenDeleteIcon } from '../../../../../../../assets/icons/GreenDeleteIcon.svg';
import { deleteIconSx } from '../../../AddContractModal/AddContractModal.style';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PropTypes from 'prop-types';
import { IconWrapper } from './UploadSoilSamplingModal.style';
const CustomDeleteIcon = ({ deleteFile, index, setFileType }) => {
  const [mouseEnteredDeleteIcon, setMouseEnteredDeleteIcon] = useState(false);
  return (
    <IconWrapper
      mouseEnteredDeleteIcon={mouseEnteredDeleteIcon}
      onMouseEnter={() => {
        setMouseEnteredDeleteIcon(true);
      }}
      onMouseLeave={() => {
        setMouseEnteredDeleteIcon(false);
      }}>
      {checkTernaryCondition(
        mouseEnteredDeleteIcon,
        <GreenDeleteIcon
          data-testid="deleteIcon"
          fontSize="medium"
          sx={deleteIconSx}
          onClick={() => {
            deleteFile(index, setFileType);
            setMouseEnteredDeleteIcon(false);
          }}
        />,
        <DeleteOutlinedIcon
          data-testid="deleteIcon"
          fontSize="medium"
          sx={deleteIconSx}
          onClick={() => {
            deleteFile(index, setFileType);
            setMouseEnteredDeleteIcon(false);
          }}
        />,
      )}
    </IconWrapper>
  );
};

CustomDeleteIcon.propTypes = {
  deleteFile: PropTypes.func,
  index: PropTypes.number,
  setFileType: PropTypes.func,
};
export default CustomDeleteIcon;
