export const listingProjectReportsColumn = {
  reportName: {
    label: 'Name',
    field: 'reportName',
  },
  reportType: {
    label: 'Type',
    field: 'reportType',
  },
  project: {
    label: 'Project',
    field: 'projectName',
  },
  generatedBy: {
    label: 'Generated by',
    field: 'generatedBy',
  },
  generatedOn: {
    label: 'Generated on',
    field: 'generatedOn',
  },
  options: {
    label: 'Action',
    field: 'options',
  },
  projectCycle: {
    label: 'Project cycle',
    field: 'projectCycle',
  },
};
export const SortColNames = {
  generatedOn: 'generatedOn',
};
export const ORIGINATION_PROJECT_REPORTS_LIST_CONTENT = {
  projectEnrollment: 'Project Enrollment',
  generateBtn: 'Generate report',
  genrateReportBtnPadding: '0 0 0.5rem 0',
  column: 'column',
  right: 'right',
  left: 'left',
  center: 'center',
  underscore: '_',
  noDataContent: 'No reports have been generated for this project.',
  addNewUserLabel: 'Generate a new report to get started!',
  error_message: 'failed to be saved. Please try again.',
  success_message: 'was successfully saved to project reports.',
  pdf: '.pdf',
  generate: 'Generate',
  report: 'report',
  cancel: 'Cancel',
  generateReportModalBtn: 'Generate report',
  dialogWidth: true,
  inputFieldLabel: 'Report Name',
  inputFieldWidth: '100%',
  inputFieldPlaceholder: 'Enter report name',
  success: 'success',
  error: 'error',
  downloadPDFLabel: 'Download PDF',
  downloadCSVLabel: 'Download CSV',
  report_name_required_error: 'A report name is required',
  report_name_invalid_error:
    'There are invalid characters in the report name. Please only use letters (a-z)(A-Z), numbers (0-9), @, underscore (_), hyphen (-).',
  searchBoxPlaceholder: 'Search by report name or generated by',
  clearAllLabel: 'Clear All',
  asc: 'ASC',
  desc: 'DESC',
  delete_message: 'Are you sure you want to delete?',
  success_codes: [200, 201, 204],
  success_deletion_message: 'was successfully deleted.',
  error_deletion_message:
    'couldn’t be deleted due to a technical error. Please try again.',
  delete: 'Delete',
  excel_error_message: 'Excel couldn’t be downloaded due to technical error',
  filter_content_width: '75%',
  filter_content: 'We couldn’t find any results for the report',
  search_text_suffix: 'No results found',
  participantProjectSummary: 'Participant Project Summary',
};
export const generateReportButtonOptions = [
  { id: 1, name: 'Project Summary' },
  // { id: 2, name: 'Project Calculations' },
  { id: 3, name: 'Project Enrollment' },
  { id: 4, name: 'Participant Project Summary' },
  { id: 5, name: 'Audit Log' },
];
export const errorInitialState = {
  reportNameRequiredError: false,
  reportNameInvalidError: false,
  projectNameError: true,
  projectCycleError: true,
  participantNameError: false,
};
export const REPORT_TYPE_FILTER = [
  'Project Summary',
  'Project Enrollment',
  'Participant Project Summary',
  'Audit Log',
];
export const FILTER_COLUMNS = ['Type'];
