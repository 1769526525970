export const ORIGINATION_DATA_CONTENT = {
  fertilizer: 'Fertilizer',
  fertilizer_list: 'nitrogenList',
  crop_list: 'cropList',
  fertilizer_deleted_list: 'deletedFertilizerList',
  fieldSampleCrops: 'fieldSampleCrops',
  cover_crop_deleted_list: 'deletedCoverCropList',
  tillage: 'Tillage',
  tillage_list: 'tillageTypeDtoList',
  tillage_deleted_list: 'deletedTillageList',
  crop_section_title: 'Crops & land use',
  required_field: '(Required)',
  crop_button_label: '+ Crop',
  delete_message:
    'Are you sure you want to delete {{year}} - Crop - {{crop_type}}? Any data entered will be lost.',
  delete_heading_first_part: 'Delete',
  delete_content_firt_part: 'Are you sure you want to delete',
  delete_content_second_part: '? Any data entered will be lost.',
  detailed_operations_data: 'Detailed operations data',
  detailed_operations_data_sub_heading:
    'Please provide detailed operations data for each activity implemented on this field. Based on the activities you selected above, you will need to provide additional information within the detailed operations section below.',
  required: '(Required)',
  add_fertilizer_btn_label: '+ Fertilizer',
  cancel_btn_label: 'Cancel',
  delete_btn_label: 'Delete',
  copy_button_label: 'Copy saved field crop',
  fallow: 'Fallow',
  crop_sub_section_title: '- Crop ',
  residue_tillage_management: 'Residue + tillage management',
  IN_PROGRESS: 'In Progress',
  COVER_CROP: 'Cover crop',
};
export const findDataForSingleModalDelete = (
  deleteData,
  operationsData,
  modalTypePracticeList,
) => {
  const removedObjects = [
    {
      id: deleteData?.id,
    },
  ];
  const filterData = operationsData?.[modalTypePracticeList].filter(
    (obj) => obj.id !== deleteData?.id,
  );
  return [removedObjects, filterData];
};
export const deletedModalsInitialData = {
  deletedFertilizerList: [],
  deletedTillageList: [],
  deletedIrrigationList: [],
  deletedSoilAmendmentList: [],
};
export const editDeleteModalTypeMap = {
  Fertilizer: {
    practiceList: ORIGINATION_DATA_CONTENT.fertilizer_list,
    deletedPracticeList: ORIGINATION_DATA_CONTENT.fertilizer_deleted_list,
  },
  Tillage: {
    practiceList: ORIGINATION_DATA_CONTENT.tillage_list,
    deletedPracticeList: ORIGINATION_DATA_CONTENT.tillage_deleted_list,
  },
};
export const initialDeleteState = {
  id: null,
  modalType: '',
  practiceTypeValue: '',
  deleteModalHeading: '',
  deleteModalContent: '',
};
export const GLOBAL_EXPAND_COLLAPSE_INITIAL_STATE = [
  {
    label: ORIGINATION_DATA_CONTENT.fertilizer,
    value: true,
  },
  {
    label: ORIGINATION_DATA_CONTENT.tillage,
    value: true,
  },
  {
    label: ORIGINATION_DATA_CONTENT.crop_section_title,
    value: true,
  },
];
