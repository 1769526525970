import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PAGE_CONTENT, Regex } from './PurchaseVintageSubsection.content';
import { checkTernaryCondition, yearDropdownGenerator } from 'utils/helper';
import {
  Select,
  MenuItem,
  OutlinedInput,
  Input,
  InputAdornment,
} from '@mui/material';

import DeliveryDetailsModal from 'components/DeliveryDetailsModal/DeliveryDetails.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { ReactComponent as AddIcon } from 'assets/icons/addicon.svg';
import { uniqueId } from 'utils/uniqueIdGenerator';

import {
  FormFields,
  FormLabels,
  DropDownPlaceholder,
  FormPriceFields,
  ErroWrapper,
  inputNumberSx,
  VintageWapper,
  DeliveryLabel,
  AddDepositsLabel,
  AddDepositsWrapper,
  VintageSubWrapper,
  VintageDropdownSx,
  vintageMenuSx,
  VintageLabel,
} from './PurchaseVintageSubsection.style';
import SubscriptConverter from './SubscriptConverter.react';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import RemoveButton from 'components/RemoveButton/RemoveButton.react';
import { BLUE, GRAYISH_BLACK } from 'theme/GlobalColors';
import { getMenuItemStyles } from 'theme/GlobalStyles';

const today = new Date();

const PurchaseVintageSubsection = ({
  vintageObj,
  viewMode,
  editMode,
  projectObj,
  onUpdate,
  vintageNo,
  vintageList,
  onDelete,
  setLinkedErrorOpen,
}) => {
  const [vintageYears, setVintageYears] = useState([]);

  const [isDeliveryDetailsOpen, setIsDeliveryDetailsOpen] = useState(false);
  const [isModalWarningDialogOpen, setIsModalWarningDialogOpen] =
    useState(false);
  const [projectDeliveryDetails, setProjectDeliveryDetails] = useState([]);
  const [clearDeliveryDetails, setClearDeliveryDetails] = useState(false);
  const isEditable = true;
  const [pastOccurrenceDetails, setPastOccurrenceDetails] = useState([]);
  const falseValue = false;
  const { markFormDirty } = useContext(EditPurchaseTransactionFormDirtyContext);
  const [editQuantity, setEditQuantity] = useState('');

  const activeDeliveryDetails = vintageObj.purchaseDeliveryRequestDTOS
    ?.filter(
      (item) =>
        new Date(item.deliveryDate) >= new Date(today.toJSON().slice(0, 10)),
    )
    ?.map((item) => {
      return {
        id: uniqueId(),
        scheduleId: item.scheduleId,
        occursOn: item.deliveryDate,
        mtAmount: item.projectedCreditDelivery,
        deliveryStatus: 'Deposited',
        isApiPopulated: item.isApiPopulated,
      };
    });
  const pastDeliveryDetails = vintageObj.purchaseDeliveryRequestDTOS
    ?.filter(
      (item) =>
        new Date(item.deliveryDate) < new Date(today.toJSON().slice(0, 10)),
    )
    ?.map((item) => {
      return {
        id: uniqueId(),
        scheduleId: item.scheduleId,
        occursOn: item.deliveryDate,
        mtAmount: item.projectedCreditDelivery,
        deliveryStatus: 'Deposited',
        isApiPopulated: item.isApiPopulated,
      };
    });

  useEffect(() => {
    onUpdate({
      ...vintageObj,
      purchaseDeliveryRequestDTOS: projectDeliveryDetails,
      deliveryDetailsError: false,
    });
  }, [projectDeliveryDetails]);

  useEffect(() => {
    if (vintageObj.creditDemandDimFlag) {
      setEditQuantity(vintageObj.quantity);
    }
  }, []);

  useEffect(() => {
    onUpdate({
      ...vintageObj,
      purchaseDeliveryRequestDTOS: projectDeliveryDetails.concat(
        pastOccurrenceDetails,
      ),
      deliveryDetailsError: false,
    });
  }, [pastOccurrenceDetails]);

  const handleOpenDeliveryDetailsModal = () => {
    setClearDeliveryDetails(false);
    setIsDeliveryDetailsOpen(true);
  };
  const onDeliveryDetailsClose = () => {
    viewMode
      ? setIsModalWarningDialogOpen(false)
      : setIsModalWarningDialogOpen(true);
    setIsDeliveryDetailsOpen(false);
  };
  const handleModalClose = () => {
    setClearDeliveryDetails(true);
    setIsModalWarningDialogOpen(false);
  };
  const handleGoBackButton = () => {
    setIsDeliveryDetailsOpen(true);
    setIsModalWarningDialogOpen(false);
  };

  const validateEditQuantity = (quantity) => {
    if (editMode && editQuantity !== '') {
      return quantity < editQuantity;
    }
  };

  const quantityHandler = (e) => {
    const value = e.target.value;
    if (value.match(Regex.decimalValues) || value === '') {
      onUpdate({
        ...vintageObj,
        quantity: value,
        quantityError: value !== '' && falseValue,
        decreaseQuantityError: validateEditQuantity(value),
      });
    }
  };

  const priceHandler = (e) => {
    const value = e.target.value;
    if (value.match(Regex.decimalValues) || value === '') {
      onUpdate({
        ...vintageObj,
        price: value,
        priceError: value !== '' && falseValue,
      });
    }
  };

  const vintageStartHandler = (e) => {
    const value = e.target.value;
    onUpdate({
      ...vintageObj,
      vintageStart: value,
      vintageStartError: value !== '' && falseValue,
      vintageYearError:
        parseInt(vintageObj.vintageEnd) < parseInt(value) &&
        value !== '' &&
        vintageObj.vintageEnd !== ''
          ? true
          : false,
      vintageDuplicateError: false,
    });
  };

  const vintageEndHandler = (e) => {
    const value = e.target.value;
    onUpdate({
      ...vintageObj,
      vintageEnd: value,
      vintageEndError: value !== '' && falseValue,
      vintageYearError:
        parseInt(vintageObj.vintageStart) > parseInt(value) &&
        value !== '' &&
        vintageObj.vintageStart !== ''
          ? true
          : false,
      vintageDuplicateError: false,
    });
  };

  useEffect(() => {
    setVintageYears([
      ...yearDropdownGenerator(1990, 2050).map((year) => ({
        label: year,
        value: year,
      })),
    ]);
  }, []);

  return (
    <VintageWapper isFirstChild={vintageNo !== 0}>
      <VintageLabel>
        {`${PAGE_CONTENT.vintage_label} ${vintageNo + 1}`}
        {vintageList.length > 1 && !viewMode && (
          <RemoveButton
            label={PAGE_CONTENT.remove_vintage_label}
            clickHandler={() => {
              if (vintageObj.creditDemandDimFlag) {
                setLinkedErrorOpen({
                  flag: true,
                  list: vintageObj.saleContractNames,
                });
              } else {
                onDelete(vintageObj.id);
                markFormDirty();
              }
            }}
            labelColor={checkTernaryCondition(
              vintageObj.creditDemandDimFlag,
              GRAYISH_BLACK,
              BLUE,
            )}
          />
        )}
      </VintageLabel>
      <VintageSubWrapper>
        <FormFields>
          <FormLabels
            iserror={
              vintageObj.vintageStartError ||
              vintageObj.vintageYearError ||
              vintageObj.vintageDuplicateError
            }>
            {PAGE_CONTENT.vantage_start_label}
          </FormLabels>
          <Select
            disabled={viewMode || vintageObj.isApiPopulated}
            value={vintageObj.vintageStart}
            onChange={(e) => {
              vintageStartHandler(e);
              markFormDirty();
            }}
            sx={VintageDropdownSx}
            MenuProps={vintageMenuSx}
            name="vintageStart_type"
            inputProps={{ 'aria-label': 'vintageStart_type' }}
            error={
              vintageObj.vintageStartError ||
              vintageObj.vintageYearError ||
              vintageObj.vintageDuplicateError
            }
            displayEmpty
            input={<OutlinedInput />}
            renderValue={
              vintageObj.vintageStart !== ''
                ? undefined
                : () => (
                    <DropDownPlaceholder>
                      {PAGE_CONTENT.vantage_start_placeholder}
                    </DropDownPlaceholder>
                  )
            }>
            {vintageYears.map((type) => (
              <MenuItem
                key={type.value}
                value={type.value}
                sx={getMenuItemStyles}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormFields>
        <FormFields>
          <FormLabels
            iserror={
              vintageObj.vintageEndError ||
              vintageObj.vintageYearError ||
              vintageObj.vintageDuplicateError
            }>
            {PAGE_CONTENT.vantage_end_label}
          </FormLabels>
          <Select
            disabled={viewMode || vintageObj.isApiPopulated}
            value={vintageObj.vintageEnd}
            onChange={(e) => {
              vintageEndHandler(e);
              markFormDirty();
            }}
            sx={VintageDropdownSx}
            MenuProps={vintageMenuSx}
            name="vintageEnd_type"
            inputProps={{ 'aria-label': 'vintageEnd_type' }}
            error={
              vintageObj.vintageEndError ||
              vintageObj.vintageYearError ||
              vintageObj.vintageDuplicateError
            }
            displayEmpty
            input={<OutlinedInput />}
            renderValue={
              vintageObj.vintageEnd !== ''
                ? undefined
                : () => (
                    <DropDownPlaceholder>
                      {PAGE_CONTENT.vantage_end_placeholder}
                    </DropDownPlaceholder>
                  )
            }>
            {vintageYears.map((type) => (
              <MenuItem
                key={type.value}
                value={type.value}
                sx={getMenuItemStyles}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormFields>
      </VintageSubWrapper>
      {vintageObj.vintageStartError && (
        <ErroWrapper>{PAGE_CONTENT.vintage_start_error}</ErroWrapper>
      )}
      {vintageObj.vintageEndError && (
        <ErroWrapper>{PAGE_CONTENT.vintage_end_error}</ErroWrapper>
      )}
      {vintageObj.vintageYearError && (
        <ErroWrapper>{PAGE_CONTENT.vintage_year_error}</ErroWrapper>
      )}
      {vintageObj.vintageDuplicateError && (
        <ErroWrapper>{PAGE_CONTENT.vintage_duplicate_error}</ErroWrapper>
      )}
      <VintageSubWrapper>
        <FormFields>
          <FormLabels
            iserror={
              vintageObj.quantityError || vintageObj.decreaseQuantityError
            }>
            <SubscriptConverter
              inputString={PAGE_CONTENT.quantity_label}
              firstPart={3}
              lastPart={2}
            />
          </FormLabels>
          <Input
            disabled={viewMode}
            placeholder={PAGE_CONTENT.quantity_placeholder}
            name="quantity"
            type="text"
            error={vintageObj.quantityError || vintageObj.decreaseQuantityError}
            value={vintageObj.quantity}
            onChange={quantityHandler}
            inputProps={{ 'aria-label': 'quantity' }}
            sx={inputNumberSx}
          />
          {/* <InputSubtext>
            {SubscriptConverter(PAGE_CONTENT.quantity_subtext)}
          </InputSubtext> */}
        </FormFields>
        <FormPriceFields isEmpty={vintageObj.price}>
          <FormLabels iserror={vintageObj.priceError}>
            <SubscriptConverter
              inputString={PAGE_CONTENT.price_label}
              firstPart={2}
              lastPart={1}
            />
          </FormLabels>
          <Input
            disabled={viewMode}
            placeholder={PAGE_CONTENT.price_placeholder}
            name="price"
            type="text"
            value={vintageObj.price}
            error={vintageObj.priceError}
            onChange={priceHandler}
            inputProps={{ 'aria-label': '' }}
            startAdornment={
              vintageObj.price !== '' ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : (
                ''
              )
            }
            sx={inputNumberSx}
          />
          {/* <InputSubtext>
            {SubscriptConverter(PAGE_CONTENT.price_subtext)}
          </InputSubtext> */}
        </FormPriceFields>
      </VintageSubWrapper>
      {vintageObj.quantityError && (
        <ErroWrapper>{PAGE_CONTENT.quantity_error}</ErroWrapper>
      )}
      {vintageObj.priceError && (
        <ErroWrapper>{PAGE_CONTENT.price_error}</ErroWrapper>
      )}
      {vintageObj.decreaseQuantityError && (
        <ErroWrapper>{PAGE_CONTENT.decrease_quantity_error}</ErroWrapper>
      )}
      <DeliveryLabel>
        <FormLabels paddingLeft="0.5rem">
          {PAGE_CONTENT.delivery_details}
        </FormLabels>
        {vintageObj.deliveryDetailsError && (
          <ErroWrapper className="Mui-error">
            {PAGE_CONTENT.delivery_details_error}{' '}
          </ErroWrapper>
        )}
        {editMode && (
          <AddDepositsLabel onClick={() => handleOpenDeliveryDetailsModal()}>
            {PAGE_CONTENT.view_edit_delivery_details}
          </AddDepositsLabel>
        )}
        {viewMode && (
          <AddDepositsLabel onClick={() => handleOpenDeliveryDetailsModal()}>
            {PAGE_CONTENT.view_delivery_details}
          </AddDepositsLabel>
        )}
        {!editMode && !viewMode && (
          <AddDepositsWrapper>
            <AddIcon />
            <AddDepositsLabel onClick={() => handleOpenDeliveryDetailsModal()}>
              {PAGE_CONTENT.add_deposit_label}
            </AddDepositsLabel>
          </AddDepositsWrapper>
        )}
      </DeliveryLabel>
      <DeliveryDetailsModal
        viewMode={viewMode}
        viewModeTitle={`${PAGE_CONTENT.view_delivery_details_label} ${
          projectObj.projectName.value || ''
        }`}
        title={`${PAGE_CONTENT.add_delivery_details_label} ${
          projectObj.projectName.value || ''
        }`}
        isOpen={isDeliveryDetailsOpen}
        acceptCtnLabel="Submit"
        declineCtnLabel="Cancel"
        creditsAvailable={vintageObj.quantity}
        onCancel={() => onDeliveryDetailsClose()}
        deliveryDetailList={vintageObj.purchaseDeliveryRequestDTOS}
        setProjectDeliveryDetails={setProjectDeliveryDetails}
        setIsDeliveryDetailsOpen={setIsDeliveryDetailsOpen}
        isEditable={viewMode ? !isEditable : isEditable}
        clearDeliveryDetails={clearDeliveryDetails}
        pastOccurrences={editMode || viewMode ? pastDeliveryDetails : []}
        activeOccurrencesDetails={
          editMode || viewMode ? activeDeliveryDetails : []
        }
        setProjectPastOccurrenceDetails={setPastOccurrenceDetails}
        deliveryDetailsForSale={false}
        hasPastOccurrences={editMode || viewMode ? true : false}
        editMode={editMode}
        isModalWarningDialogOpen={isModalWarningDialogOpen}
        isLinkedToSale={vintageObj.creditDemandDimFlag}
      />
      <DialogBox
        dialogActions
        isOpen={viewMode ? false : isModalWarningDialogOpen}
        onCancel={() => handleGoBackButton()}
        onConfirm={() => handleModalClose()}
        title={PAGE_CONTENT.add_delivery_details_warning}
        subtitle={PAGE_CONTENT.add_delivery_details_warning_subtitle}
        declineCtnLabel="Go Back"
        acceptCtnLabel="Cancel"
      />
    </VintageWapper>
  );
};

PurchaseVintageSubsection.propTypes = {
  vintageObj: PropTypes.object,
  viewMode: PropTypes.string,
  editMode: PropTypes.bool,
  projectObj: PropTypes.object,
  onUpdate: PropTypes.func,
  vintageNo: PropTypes.number,
  vintageList: PropTypes.array,
  onDelete: PropTypes.func,
  setLinkedErrorOpen: PropTypes.func,
};
export default PurchaseVintageSubsection;
