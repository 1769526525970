export const CONTENT = {
  reports: 'Reports',
  generateBtn: 'Generate report',
  underscore: '_',
};

export const REPORT_BUTTONS = {
  PORJECT_SUMMARY: 'Project Summary',
  PARTICIPANT_PROJECT_SUMMARY: 'Participant Project Summary',
};

export const GENERATE_REPORT_BTN_PADDING = '0 0 0.5rem 0';
