import axios from 'axios';
import { Dialog, DialogContent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FETCH_REVIEW_PDF } from 'urls';
import { ParticipantActivityContext } from 'contextAPI/participantActivityContext';
import {
  PdfDownloadButton,
  ReviewText,
  ReviewPDFSx,
  DialogStyleSx,
} from './Participants.style';
import Loader from '../../../../components/Loader/Loader.react';
import {
  LABELS,
  getActivityDate,
  getActivityId,
  getActivityType,
  getReviewTabName,
} from './Participants.content';
import PdfViewerPage from './PdfViewerPage/pdfViewerPage.react';
import PdfComponent from './PdfViewerPage/components/PdfComponent.react';
import { ReactComponent as PdfLinkIcon } from '../../../../assets/icons/LinktoPdf.svg';
import ActivityReportingPOIReviewComp from './ActivityReportingPOIReviewComp/ActivityReportingPOIReviewComp.react';
import { useActivityContext } from 'containers/ActivityListContainer';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import PropTypes from 'prop-types';
import { checkTernaryCondition, isEmpty } from 'utils/helper';

export const Review = ({
  reviewEnrollmentProps: {
    reviewComponent,
    reviewButtonText,
    reviewButtonText2,
  },
  setShowPdfViewerPage,
  closeEnrollmentModal,
  setCurrentTab,
  setAreUiActionInProgress,
}) => {
  const handlePdfClick = () => {
    closeEnrollmentModal();
    setAreUiActionInProgress((prev) => prev + 1);
    setShowPdfViewerPage(true);
  };

  return (
    <>
      <ReviewText>{reviewComponent}</ReviewText>
      <PdfDownloadButton
        onClick={() => {
          setCurrentTab(0);
          handlePdfClick();
        }}>
        {reviewButtonText}
        <PdfLinkIcon />
      </PdfDownloadButton>
      {!isEmpty(reviewButtonText2) && (
        <PdfDownloadButton
          onClick={() => {
            setCurrentTab(1);
            handlePdfClick();
          }}>
          {reviewButtonText2}
          <PdfLinkIcon />
        </PdfDownloadButton>
      )}
    </>
  );
};

Review.propTypes = {
  reviewEnrollmentProps: PropTypes.shape({
    reviewComponent: PropTypes.node.isRequired,
    reviewButtonText: PropTypes.string.isRequired,
    reviewButtonText2: PropTypes.string,
  }).isRequired,
  setShowPdfViewerPage: PropTypes.func.isRequired,
  closeEnrollmentModal: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  setAreUiActionInProgress: PropTypes.func.isRequired,
};

export const ReviewModal = ({
  reviewEnrollmentProps,
  setReviewEnrollmentProps,
}) => {
  const [pdfCurrentTab, setPdfCurrentTab] = useState(0);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  const participantActivityContext = useContext(ParticipantActivityContext);

  const activityData = participantActivityContext?.activityData;
  const [pdfData, setPdfData] = useState();
  const activityId = getActivityId(
    reviewEnrollmentProps?.formId ?? reviewEnrollmentProps?.instanceId,
    activityData,
  );
  const activityType = getActivityType(
    reviewEnrollmentProps?.formId ?? reviewEnrollmentProps?.instanceId,
    activityData,
  );
  const activityDate = getActivityDate(
    reviewEnrollmentProps?.formId ?? reviewEnrollmentProps?.instanceId,
    activityData,
  );

  const { participantId, projectId, loading, setLoading } =
    useActivityContext();

  const { setAreUiActionInProgress } = useContext(ConfigurationContext);

  useEffect(() => {
    /* istanbul ignore else */
    if (activityId && activityType) {
      setLoading(true);
      axios
        .get(`${FETCH_REVIEW_PDF}/${activityId}/${activityType}`)
        .then((response) => {
          setPdfData(response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [reviewEnrollmentProps?.modalData?.flag]);

  const onClickBackButton = () => {
    reviewEnrollmentProps.modalCloseCb();
    setReviewEnrollmentProps((previous) => ({
      ...previous,
      modalData: { flag: false },
    }));
    setAreUiActionInProgress((prev) => prev - 1);
  };

  return (
    <>
      <Loader loading={loading} zIndex={99999} />
      {reviewEnrollmentProps.modalData.flag && (
        <Dialog
          fullScreen
          open={reviewEnrollmentProps.modalData.flag}
          sx={{ ...DialogStyleSx, top: 50 }}>
          <DialogContent sx={ReviewPDFSx}>
            <PdfViewerPage
              activityType={activityType}
              activityDate={activityDate}
              formId={reviewEnrollmentProps.formId}
              isApproveDisabled={isApproveDisabled}
              setIsApproveDisabled={setIsApproveDisabled}
              heading={pdfData?.activityReviewName}
              subHeadingArray={[
                {
                  title: LABELS.pdf_project_title,
                  response: pdfData?.projectName,
                },
                {
                  title: LABELS.pdf_participant_owner_name,
                  response: pdfData?.participantOwnerName,
                },
              ]}
              currentTab={pdfCurrentTab}
              setCurrentTab={setPdfCurrentTab}
              tabList={[
                {
                  title: getReviewTabName(reviewEnrollmentProps.title),
                  component: <PdfComponent pdfUrl={pdfData?.pdfObjectUrl} />,
                },
                checkTernaryCondition(
                  reviewEnrollmentProps.title ===
                    LABELS.activityReportingReviewTitle,
                  {
                    title: LABELS.activityReviewTab,
                    component: (
                      <ActivityReportingPOIReviewComp
                        setIsApproveDisabled={setIsApproveDisabled}
                        participantId={participantId}
                        projectId={projectId}
                      />
                    ),
                  },
                  {},
                ),
              ]}
              onClickBackButton={onClickBackButton}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

ReviewModal.propTypes = {
  reviewEnrollmentProps: PropTypes.shape({
    formId: PropTypes.string,
    instanceId: PropTypes.string,
    modalData: PropTypes.shape({
      flag: PropTypes.bool.isRequired,
    }).isRequired,
    title: PropTypes.string,
  }).isRequired,
  setReviewEnrollmentProps: PropTypes.func.isRequired,
};
