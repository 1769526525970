import React, { useEffect, useState } from 'react';
import {
  MainWrapper,
  TagWrapper,
  NumbersWrapper,
  TooltipWrapper,
} from './Tags.style';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Tags = ({ tagList }) => {
  const [hasSingleTag, setHasSingleTag] = useState(false);

  useEffect(() => {
    if (tagList?.length === 1) {
      setHasSingleTag(true);
    }
  }, []);
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const customTitle = () => {
    return (
      <MainWrapper flexWrap={'wrap'}>
        {tagList?.map((item) => (
          <TooltipWrapper key={item} width={hasSingleTag ? '70%' : '50%'}>
            {item}
          </TooltipWrapper>
        ))}
      </MainWrapper>
    );
  };

  return (
    <LightTooltip title={customTitle()}>
      <MainWrapper flexWrap={'nowrap'}>
        {tagList?.slice(0, 2)?.map((item) => (
          <TagWrapper key={item} width={hasSingleTag ? '6.25rem' : '4.38rem'}>
            {item}
          </TagWrapper>
        ))}
        {tagList?.length > 2 && (
          <NumbersWrapper>+{tagList?.length - 2}</NumbersWrapper>
        )}
      </MainWrapper>
    </LightTooltip>
  );
};

Tags.propTypes = {
  tagList: PropTypes.array,
};

export default Tags;
