import PropTypes from 'prop-types';
import {
  ContentWrapper,
  ImageContainer,
  ImageSx,
  MainWrapper,
  TextWrapper,
  btnSx,
} from './CalculationEmptyStateComp.style';
import AddButton from 'components/AddButton/AddButton.react';
import { NEW_DARK_GREEN, WHITE } from 'theme/GlobalColors';
import CalculationsImage from 'assets/Calculations.png';

const CalculationEmptyStateComp = ({
  noCalcDataTitle,
  noCalcDataSubTitle,
  buttonLabel,
  disableButton,
}) => {
  return (
    <MainWrapper className="curved-border">
      <ContentWrapper>
        <ImageContainer>
          <img style={ImageSx} src={CalculationsImage} />
        </ImageContainer>
        <TextWrapper marginBottom="0.3125rem" marginTop="1.5rem">
          {noCalcDataTitle}
        </TextWrapper>
        <TextWrapper
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.1919rem">
          {noCalcDataSubTitle}
        </TextWrapper>
        <AddButton
          buttonName={buttonLabel}
          noDropdown={true}
          backgroundColor={NEW_DARK_GREEN}
          textColor={WHITE}
          disableButton={disableButton}
          customBtnSx={btnSx}
        />
      </ContentWrapper>
    </MainWrapper>
  );
};

CalculationEmptyStateComp.propTypes = {
  noCalcDataTitle: PropTypes.string,
  noCalcDataSubTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  disableButton: PropTypes.bool,
};

export default CalculationEmptyStateComp;
