import FiltersPopup from 'pages/OriginationProjectList/components/FiltersPopup/FiltersPopup.react';
import {
  AppliedFilters,
  ClearLink,
  FilterChip,
  SearchAndFilterSection,
  SearchAndFiltersFlex,
  SearchBox,
  clearIconStyle,
  SearchIconSx,
  BasicWrapper,
  TextWrapper,
  DatePickerAndFilterWrapper,
  generatedOnStyle,
  SearchAndFilterIcon,
  ReportListAppliedFilters,
  GeneratedOnAndDatePickerWrapper,
} from './SearchAndFilterComponent.style';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { uniqueId } from 'utils/uniqueIdGenerator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker.react';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calenderIcon.svg';
import { SEARCH_AND_FILTER_COMPONENT_CONTENT } from './SearchAndFilterComponent.content';
import { TYPOGRAPHY_GRAY, WHITE } from 'theme/GlobalColors';
import { pointerCursor } from 'theme/GlobalStyles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SearchAndFilterComponent = ({
  hasSeachBox,
  projectNameSearchText,
  setProjectNameSearchText,
  searchBoxPlaceholder,
  filterSections,
  hasAppliedFilters,
  removeFilter,
  appliedFiltersCount,
  updateFilterStates,
  handleFilterSectionUpdate,
  columnName,
  clearLabel,
  hasDatePicker,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  darkGreenColor,
  isReportsPage = false,
  showGeneratedOn = false,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const filtersPopUp = (
    <FiltersPopup
      filterSections={filterSections}
      updateFilters={handleFilterSectionUpdate}
      isFiltersApplied={appliedFiltersCount > 0}
      darkGreenColor={darkGreenColor}
      applyTextTransform={true}
    />
  );

  const datePicker = (
    <DateRangePicker
      isOpen={isCalendarOpen}
      data-testid={SEARCH_AND_FILTER_COMPONENT_CONTENT.range_picker}
      starDateSetter={setFromDate}
      endDateSetter={setToDate}
      setIsOpen={setIsCalendarOpen}
    />
  );

  const mappedFilterSection = filterSections.map((section, sectionIdx) => {
    return section.filters.map((filter, filterIdx) => {
      return (
        filter.checked && (
          <FilterChip
            key={uniqueId()}
            data-testid={`${section.heading}: ${filter.itemLabel}`}>
            <div>{`${section.heading}: ${filter.itemLabel}`}</div>
            <ClearIcon
              onClick={() =>
                removeFilter({
                  sectionIdx: sectionIdx,
                  filterIdx: filterIdx,
                })
              }
              sx={clearIconStyle}
            />
          </FilterChip>
        )
      );
    });
  });

  const getBasicWrapper = () => {
    return (
      hasDatePicker && (
        <BasicWrapper
          backgroundColor={WHITE}
          gap={SEARCH_AND_FILTER_COMPONENT_CONTENT.gap}
          border={SEARCH_AND_FILTER_COMPONENT_CONTENT.border}
          padding={SEARCH_AND_FILTER_COMPONENT_CONTENT.padding}
          radius={SEARCH_AND_FILTER_COMPONENT_CONTENT.radius}
          cursor={SEARCH_AND_FILTER_COMPONENT_CONTENT.center}
          data-testid={SEARCH_AND_FILTER_COMPONENT_CONTENT.date_filter}
          height="1.75rem"
          onClick={() => {
            setIsCalendarOpen(true);
          }}>
          <TextWrapper
            fontSize={SEARCH_AND_FILTER_COMPONENT_CONTENT.fontSize}
            fontWeight={SEARCH_AND_FILTER_COMPONENT_CONTENT.fontWeight}>
            {fromDate} - {toDate}
          </TextWrapper>
          <CalendarIcon />
        </BasicWrapper>
      )
    );
  };

  const getSearchBox = () => {
    return (
      hasSeachBox && (
        <SearchBox
          placeholder={searchBoxPlaceholder}
          value={projectNameSearchText}
          onChange={(event) => setProjectNameSearchText?.(event.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={SearchIconSx} />,
            endAdornment: projectNameSearchText && (
              <ClearIcon
                sx={{
                  ...pointerCursor,
                  color: TYPOGRAPHY_GRAY,
                  fontSize: '1rem',
                }}
                onClick={() => setProjectNameSearchText('')}
              />
            ),
          }}
        />
      )
    );
  };

  const getClearButton = () => {
    return (
      appliedFiltersCount >= 1 && (
        <ClearLink
          onClick={() => {
            updateFilterStates(columnName[0], []);
          }}>
          {clearLabel}
        </ClearLink>
      )
    );
  };

  if (isReportsPage) {
    return (
      <SearchAndFilterSection>
        <GeneratedOnAndDatePickerWrapper>
          {showGeneratedOn && (
            <Typography variant="body2" sx={generatedOnStyle}>
              {SEARCH_AND_FILTER_COMPONENT_CONTENT.generatedOn}{' '}
            </Typography>
          )}
          {getBasicWrapper()}
        </GeneratedOnAndDatePickerWrapper>
        {datePicker}
        <div style={SearchAndFiltersFlex}>
          <SearchAndFilterIcon>
            {getSearchBox()}
            {filtersPopUp}
          </SearchAndFilterIcon>
          {hasAppliedFilters && (
            <ReportListAppliedFilters>
              {mappedFilterSection}
              {getClearButton()}
            </ReportListAppliedFilters>
          )}
        </div>
      </SearchAndFilterSection>
    );
  }

  return (
    <SearchAndFilterSection>
      <div style={SearchAndFiltersFlex}>
        {getSearchBox()}
        {hasAppliedFilters && (
          <AppliedFilters>
            {mappedFilterSection}
            {getClearButton()}
          </AppliedFilters>
        )}
      </div>
      <DatePickerAndFilterWrapper>
        {getBasicWrapper()}
        {filtersPopUp}
      </DatePickerAndFilterWrapper>
      {datePicker}
    </SearchAndFilterSection>
  );
};

export default SearchAndFilterComponent;

SearchAndFilterComponent.propTypes = {
  hasSeachBox: PropTypes.bool,
  projectNameSearchText: PropTypes.string,
  setProjectNameSearchText: PropTypes.func,
  searchBoxPlaceholder: PropTypes.string,
  filterSections: PropTypes.array,
  hasAppliedFilters: PropTypes.bool,
  removeFilter: PropTypes.func,
  appliedFiltersCount: PropTypes.number,
  updateFilterStates: PropTypes.func,
  handleFilterSectionUpdate: PropTypes.func,
  columnName: PropTypes.array,
  clearLabel: PropTypes.string,
  hasDatePicker: PropTypes.bool,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  setFromDate: PropTypes.func,
  setToDate: PropTypes.func,
  darkGreenColor: PropTypes.string,
  isReportsPage: PropTypes.bool,
  showGeneratedOn: PropTypes.bool,
};
