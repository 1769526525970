import styled from 'styled-components';

export const MainWrapper = styled.span`
  gap: 4px;
  display: flex;
  display: -webkit-flex;
  overflow: hidden;
  flex-wrap: ${({ flexWrap }) => flexWrap};
`;
export const TagWrapper = styled.text`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0.5px solid #63666a;
  border-radius: 4px;
  padding: 2px 4px;
  color: #63666a;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  align-items: center;
  max-width: ${({ width }) => width};
  cursor: default;
`;

export const NumbersWrapper = styled.span`
  diplay: flex;
  display: -webkit-flex;
  color: #0076a8;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
`;
export const TooltipWrapper = styled.div`
  white-space: nowrap;
  border: 0.5px solid #63666a;
  border-radius: 4px;
  padding: 2px 4px;
  color: #63666a;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  align-items: center;
  max-width: ${({ width }) => width};
`;
