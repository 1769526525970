import * as React from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordianDetailsSx,
  AccordianSummarySx,
  AccordianSx,
  ChartsWrapper,
  EmptyDataContent,
  EmptyDataWrapper,
  HeaderWrapper,
  ProjectNameWrapper,
  RegnFarmParentWrapper,
  RegnFarmWrapper,
  SubContainer,
  SubHeaderWrapper,
  TagsContainer,
  TagsWrapper,
  TextWrapper,
  TitleWrapper,
  labelStyle,
  dropDownStyle,
  dropDownSx,
  TextWrapperParentForAcreage,
  NoDataLabelAndChart,
  TextWrapperForNoDataLabel,
  acerageChartCustomStyle,
} from './ParticipantSummary.style';
import {
  ACTIVE_TAG_BG,
  ACTIVE_TAG_TEXT_COLOR,
  LIGHT_CHARCOAL,
  COOL_GRAY,
  DARK_CHARCOAL,
  WHITE,
  EMPTY_STATE_COLOR,
  EMPTY_STATE_TEXT_COLOR,
  NEW_DARK_GREEN,
  CURIOUS_BLUE,
  DARK_MODERATE_BLUE,
  FADED_BLUISH_CYAN,
  SHEPRA_BLUE,
} from 'theme/GlobalColors';
import TableWithExpandableRow from 'components/TableWithExpandableRow/TableWithExpandableRow.react';
import { Backdrop, CircularProgress } from '@mui/material';
import {
  ACREAGE_PROGRESS_TRACKER,
  PARTICIPANT_SUMMARY_CONTENT,
  Status,
} from './ParticipantSummary.content';
import {
  FETCH_INCENTIVES_OF_A_PARTICIPANT,
  FETCH_PARTICIPANT_GHG_INSIGHTS,
  FETCH_PROJECT_PARTICIPANT_ACREAGE,
} from 'urls';
import axios from 'axios';
import {
  checkTernaryCondition,
  getBooleanValueForNullOrUndefined,
  isEmpty,
} from 'utils/helper';
import MapSearchContainer from 'components/MapComponent/MapSearchContainer.react';
import { rangeNumArray } from 'pages/PortfolioOriginationTab/PortfolioOriginationTab.content';
import StakeholderParticipantIncentiveCard from '../StakeholderParticipantIncenticeCard/StakeholderParticipantIncentiveCard.react';
import { useProjectCycleData } from 'hooks/useProjectCycleData';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import MuiBarChart from 'components/Charts/MuiBarChart/MuiBarChart';
import { ghgChartStyles } from 'components/Charts/MuiBarChart/MuiBarChart.style';

const extractFieldDetails = (fieldDetails) => {
  return fieldDetails.map((obj) => {
    const { fieldId, fieldName, farmId, fieldBoundaries } = obj;

    const convertedObject = {
      fieldId: fieldId,
      fieldName,
      farmId: farmId,
      fieldBoundaries: fieldBoundaries.map(
        ({ latitude: lat, longitude: lng }) => ({ lat, lng }),
      ),
    };

    return convertedObject;
  });
};

const ParticipantSummary = ({
  projectList,
  loading,
  participantInfo,
  participantId,
  projectId,
}) => {
  const location = useLocation();
  const { cycleId } = location.state ?? '';
  const [expanded, setExpanded] = useState(['panel1']);
  const [ghgData, setGhgData] = useState(null);
  const [selectedFarmField, setSelectedFarmField] = useState({
    farmId: null,
    fieldId: null,
  });
  const [isLoading, setIsLoading] = useState({
    GHGLoading: false,
  });
  const [incentiveObject, setIncentiveObject] = useState({
    internalProjectId: null,
    averageProjectedIncentive: 0.0,
    averageActualIncentive: 0.0,
    totalProjectedIncentive: 0.0,
    totalActualIncentive: 0.0,
  });
  const [projectCycleId, setProjectCycleId] = useState(cycleId);
  const { projectCycleList, fetchProjectCycleList } = useProjectCycleData();
  const [acreageProgressTracker, setAcreageProgressTracker] = useState([]);
  const [maxAcreage, setMaxAcreage] = useState(null);

  useEffect(() => {
    if (!isEmpty(projectCycleId)) {
      axios
        .get(
          `${FETCH_INCENTIVES_OF_A_PARTICIPANT}/${participantId}/participant-incentives?projectCycleId=${projectCycleId}`,
        )
        .then((res) => {
          setIncentiveObject(res.data);
        });
    }
  }, [projectCycleId]);

  const farmBoundaries = participantInfo?.map((farm) => {
    const boundaries = farm?.fieldDetails?.reduce((accumulator, field) => {
      field?.fieldBoundaries.forEach((boundary) => {
        accumulator.push({ lat: boundary?.latitude, lng: boundary?.longitude });
      });
      return accumulator;
    }, []);
    return boundaries;
  });

  const enrolledStatusCodes = ['5a', '5b', '6', '7', '7a', '7b'];

  const handleChange = (panel) => () => {
    setExpanded(
      expanded.includes(panel)
        ? expanded.filter((item) => item !== panel)
        : [...expanded, panel],
    );
  };
  const setGHGDataFunc = () => {
    setIsLoading((prev) => ({ ...prev, GHGLoading: true }));
    axios
      .get(`${FETCH_PARTICIPANT_GHG_INSIGHTS}/${participantId}`)
      .then((res) => {
        setGhgData(res.data);
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, GHGLoading: false }));
      });
  };
  const mapLabel = ghgData?.ghgInsights?.map((insight) => {
    const year = new Date(insight.abatementDate)
      .getFullYear()
      .toString()
      .slice(-2);
    return parseInt(year, 10);
  });

  const min = !isEmpty(mapLabel) ? Math.min(...mapLabel) : 20;
  const max = !isEmpty(mapLabel) ? Math.max(...mapLabel) : 30;
  const yearRange = rangeNumArray(min, max);

  const actualValues = [];

  yearRange.forEach((year) => {
    if (mapLabel?.length > 0) {
      const index = mapLabel?.indexOf(year);
      if (index !== -1) {
        actualValues.push(ghgData?.ghgInsights[index].actualAbatement);
      } else {
        actualValues.push(null);
      }
    }
  });

  const isDataSetEmpty =
    (isEmpty(ghgData?.ghgInsights) && isEmpty(actualValues)) ||
    isLoading.GHGLoading;

  const actualColour = checkTernaryCondition(
    isDataSetEmpty,
    EMPTY_STATE_COLOR,
    NEW_DARK_GREEN,
  );

  const chartData = {
    labels: checkTernaryCondition(isDataSetEmpty, [], yearRange),
    datasets: [
      {
        label: PARTICIPANT_SUMMARY_CONTENT.actual,
        data: actualValues,
        borderColor: actualColour,
        pointRadius: 1,
        backgroundColor: actualColour,
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            // Format the label to ensure it's in English
            return `${tooltipItem.dataset.label}: ${Intl.NumberFormat(
              'en-US',
            ).format(tooltipItem.raw)}`;
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          display: isDataSetEmpty,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        max: checkTernaryCondition(!isDataSetEmpty, 30, null),
        min: checkTernaryCondition(!isDataSetEmpty, 20, null),
        ticks: {
          padding: 5,
          color: DARK_CHARCOAL,
          stepSize: 1,
          callback: function (value) {
            return "'" + (value + 20);
          },
          locale: 'en-US',
        },
        title: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          stepSize: 150,
          color: DARK_CHARCOAL,
          callback: function (value) {
            return value.toString();
          },
          locale: 'en-US',
        },
        max: 600,
        min: 0,
        title: {
          display: true,
          color: COOL_GRAY,
          text: PARTICIPANT_SUMMARY_CONTENT.CO2e,
        },
        grid: {
          display: !isDataSetEmpty,
        },
      },
    },
  };

  /**
   * check if any value returned from the acreage progress tracker is NaN
   */
  const totalAcreage = acreageProgressTracker?.reduce(
    (acc, curr) => acc + curr.area,
    0,
  );

  const isAcreageEmpty = !acreageProgressTracker?.some(
    (progress) => progress?.area > 0 && !isNaN(progress?.area),
  );

  const isBarChartEmpty =
    isEmpty(acreageProgressTracker) || isNaN(totalAcreage) || isAcreageEmpty;

  const chartLabels = [
    ACREAGE_PROGRESS_TRACKER.ENROLLMENT,
    ACREAGE_PROGRESS_TRACKER.BASELINE,
    ACREAGE_PROGRESS_TRACKER.ACTIVITY,
    ACREAGE_PROGRESS_TRACKER.COMPLETE,
  ];

  const extractedAreas = chartLabels.map((category) => {
    const item = acreageProgressTracker.find(
      (progress) => progress.category === category,
    );
    return item?.area;
  });

  const valueFormatter = (value) =>
    `${checkTernaryCondition(+value === 0.0001, 0, value)} acres`;

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: '',
        valueFormatter,
        data: checkTernaryCondition(
          isBarChartEmpty,
          [0.0001, 0.0001, 0.0001, 0.0001],
          extractedAreas,
        ),
        backgroundColor: checkTernaryCondition(
          isBarChartEmpty,
          [
            EMPTY_STATE_COLOR,
            EMPTY_STATE_COLOR,
            EMPTY_STATE_COLOR,
            EMPTY_STATE_COLOR,
          ],
          [CURIOUS_BLUE, DARK_MODERATE_BLUE, FADED_BLUISH_CYAN, SHEPRA_BLUE],
        ),
        barThickness: 60,
        borderwidth: 0,
      },
    ],
  };

  const reportingOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: Math.max(...data.datasets[0].data) + 0.1,
        grid: {
          display: false,
        },
        display: false,
        ticks: {
          stepSize: checkTernaryCondition(
            maxAcreage !== 0,
            maxAcreage / 2,
            null,
          ),
          max: maxAcreage,
        },
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: () => '',
          label: (context) => {
            const area = extractedAreas[context.dataIndex] ?? 0;
            return checkTernaryCondition(
              area > 0,
              `${area.toFixed(2)} acres`,
              PARTICIPANT_SUMMARY_CONTENT.noDataValueContinous,
            );
          },
        },
        backgroundColor: 'black',
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: 'black',
        formatter: (value) => {
          return checkTernaryCondition(
            isBarChartEmpty || value === 0,
            PARTICIPANT_SUMMARY_CONTENT.noDataValueContinous,
            value,
          );
        },
        align: 'end',
        anchor: 'end',
        clamp: true,
        font: {
          weight: 'bold',
          size: 10,
        },
        padding: { top: 0, bottom: 0 },
      },
    },
    datasets: {},
  };

  useEffect(() => {
    fetchProjectCycleList(projectId);
  }, [projectId]);

  useEffect(() => {
    if (projectCycleList.length > 0) {
      setProjectCycleId(
        checkTernaryCondition(
          projectCycleId === '',
          projectCycleList[0]?.value,
          projectCycleId,
        ),
      );
    }
  }, [projectCycleList]);

  useEffect(() => {
    /* istanbul ignore else */
    if (!isEmpty(projectList)) setGHGDataFunc();
  }, [projectList]);

  useEffect(() => {
    if (!isEmpty(projectCycleId) && !isEmpty(participantId)) {
      axios
        .get(
          `${FETCH_PROJECT_PARTICIPANT_ACREAGE}?participantId=${participantId}&projectCycleId=${projectCycleId}`,
        )
        .then((res) => {
          setAcreageProgressTracker(res.data);
        })
        .catch(() => {});
    }
  }, [participantId, projectCycleId]);

  useEffect(() => {
    if (!isEmpty(acreageProgressTracker)) {
      const maxAcreageArea = acreageProgressTracker?.reduce(
        (acc, curr) => Math.max(acc, curr.area),
        0,
      );
      setMaxAcreage(maxAcreageArea);
    }
  }, [JSON.stringify(acreageProgressTracker)]);

  const zoomCalculator = (farm) => {
    if (farm?.fieldDetails.length > 1) {
      return 9;
    }
    const fieldArea = farm?.fieldDetails[0]?.fieldArea;
    if (fieldArea === undefined) {
      return 0;
    }
    const zoomLevels = [
      { maxArea: 1, zoom: 17 },
      { maxArea: 30, zoom: 16 },
      { maxArea: 100, zoom: 14 },
      { maxArea: 200, zoom: 14 },
      { maxArea: 400, zoom: 13 },
      { maxArea: 800, zoom: 12 },
      { maxArea: 1600, zoom: 11 },
      { maxArea: 3200, zoom: 10 },
      { maxArea: 6400, zoom: 9 },
      { maxArea: 12800, zoom: 8 },
      { maxArea: 17000, zoom: 9 },
      { maxArea: 51200, zoom: 6 },
      { maxArea: 102400, zoom: 5 },
      { maxArea: 204800, zoom: 4 },
    ];
    for (const level of zoomLevels) {
      if (fieldArea < level.maxArea) {
        return level.zoom;
      }
    }
    return 0;
  };

  return (
    <>
      <Backdrop
        open={loading || Object.values(isLoading).includes(true)}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="success" />
      </Backdrop>
      <DropDown
        dropDownMinWidth={'13.75rem'}
        label={PARTICIPANT_SUMMARY_CONTENT.project_life_cycle_label}
        ariaLabel={PARTICIPANT_SUMMARY_CONTENT.project_life_cycle_label}
        labelStyle={labelStyle}
        dropDownStyle={dropDownStyle}
        value={checkTernaryCondition(
          getBooleanValueForNullOrUndefined(projectCycleList.length),
          PARTICIPANT_SUMMARY_CONTENT.project_life_cycle_placeholder,
          projectCycleId,
        )}
        dropdownlist={projectCycleList}
        dropDownPlaceholder={
          PARTICIPANT_SUMMARY_CONTENT.project_life_cycle_placeholder
        }
        setStateValue={() => {}}
        setDropDownValInParent={(newVal) => {
          setProjectCycleId(newVal);
        }}
        customSx={{
          ...dropDownSx,
          ...outlinedDropdownInputSx,
        }}
        setFormFieldValue={() => {}}
        height="1.75rem"
        display="flex"
        gap="4px"
        alignItems="baseline"
        hasNoBottomMargin
        marginTop="-0.5rem"
        direction="row"
        customPlaceholderColor={DARK_CHARCOAL}
        showLabelAsValue={true}
        labelMarginBottom="0rem"
      />
      {projectList
        .filter((project) => project.internalProjectId === Number(projectId))
        .map((project, index) => {
          return (
            <Accordion
              sx={AccordianSx}
              key={`${project.projectName + index}`}
              onChange={handleChange(`panel${index + 1}`)}
              expanded={expanded?.includes(`panel${index + 1}`)}>
              <AccordionSummary
                onClick={() => {
                  setGHGDataFunc();
                }}
                sx={AccordianSummarySx}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <HeaderWrapper padding="0rem">
                  <SubHeaderWrapper gap="0.5rem">
                    <TextWrapper
                      fontWeight="400"
                      fontSize="1.25rem"
                      color={LIGHT_CHARCOAL}>
                      <ProjectNameWrapper title={project.projectName}>
                        {project.projectName}
                      </ProjectNameWrapper>
                    </TextWrapper>
                    <TagsContainer>
                      <TagsWrapper
                        backgroundColor={ACTIVE_TAG_BG}
                        color={ACTIVE_TAG_TEXT_COLOR}>
                        {PARTICIPANT_SUMMARY_CONTENT.activeTag}
                      </TagsWrapper>
                    </TagsContainer>
                  </SubHeaderWrapper>
                </HeaderWrapper>
              </AccordionSummary>
              <AccordionDetails sx={AccordianDetailsSx}>
                {project.participantProjectStatus === Status.interested &&
                  enrolledStatusCodes?.includes(
                    project?.participantEnrolmentStatus?.split(':')[0],
                  ) && (
                    <EmptyDataWrapper className="curved-border">
                      <EmptyDataContent>
                        {PARTICIPANT_SUMMARY_CONTENT.noChartDataLabel}
                      </EmptyDataContent>
                    </EmptyDataWrapper>
                  )}
                <StakeholderParticipantIncentiveCard data={incentiveObject} />

                <ChartsWrapper className="curved-border">
                  <SubContainer
                    width="46%"
                    className="curved-border"
                    backgroundColor={WHITE}
                    padding="1rem"
                    hasBoxShadow={true}>
                    <TextWrapperParentForAcreage
                      alignItems="center"
                      gap="0.5rem"
                      alignContent="center">
                      <TextWrapper
                        color={DARK_CHARCOAL}
                        fontSize="1rem"
                        fontWeight={600}>
                        {PARTICIPANT_SUMMARY_CONTENT.acreageProgressTracker}
                      </TextWrapper>
                    </TextWrapperParentForAcreage>
                    <NoDataLabelAndChart>
                      {isAcreageEmpty && (
                        <TextWrapperForNoDataLabel
                          fontWeight={400}
                          color={COOL_GRAY}
                          fontSize="0.75rem">
                          {PARTICIPANT_SUMMARY_CONTENT.noChartDataLabel}
                        </TextWrapperForNoDataLabel>
                      )}
                      <MuiBarChart
                        data={data}
                        hideLegends
                        options={reportingOptions}
                        showLeftAxis={false}
                        isDataSetEmpty={isBarChartEmpty}
                        style={acerageChartCustomStyle}
                      />
                      <TextWrapper
                        color={DARK_CHARCOAL}
                        fontSize="0.75rem"
                        alignSelf={'center'}
                        marginTop={'1.5rem'}
                        fontWeight={600}>
                        {PARTICIPANT_SUMMARY_CONTENT.reporting}
                      </TextWrapper>
                    </NoDataLabelAndChart>
                  </SubContainer>
                  <SubContainer
                    id="ghg-container"
                    width="49.75%"
                    height="13rem"
                    padding="1rem"
                    className="curved-border"
                    backgroundColor={WHITE}
                    hasBoxShadow={true}>
                    <MuiBarChart
                      options={options}
                      data={chartData}
                      isComparisonChart
                      isDataSetEmpty={isDataSetEmpty}
                      style={ghgChartStyles}
                      hideLegends={false}
                      title={PARTICIPANT_SUMMARY_CONTENT.ghgEmissions}
                      currentValue={{
                        value: checkTernaryCondition(
                          ghgData?.currentYearAbatement,
                          `${ghgData?.currentYearAbatement}K`,
                          PARTICIPANT_SUMMARY_CONTENT.noDataValue,
                        ),
                        label: PARTICIPANT_SUMMARY_CONTENT.actual,
                        color: checkTernaryCondition(
                          isDataSetEmpty,
                          EMPTY_STATE_TEXT_COLOR,
                          NEW_DARK_GREEN,
                        ),
                      }}
                    />
                  </SubContainer>
                </ChartsWrapper>
                {participantInfo.map((farm, index) => (
                  <RegnFarmParentWrapper
                    key={farm?.farmId}
                    className="curved-border">
                    <HeaderWrapper paddingLeft="2rem">
                      <TitleWrapper>{farm?.farmName}</TitleWrapper>
                    </HeaderWrapper>
                    <RegnFarmWrapper>
                      <SubContainer
                        width={'33.4%'}
                        height={'21.25rem'}
                        minWidth={'20.125rem'}
                        noBorder={true}
                        onlyBorderRight={true}>
                        <MapSearchContainer
                          additionalClassName="summary-map"
                          fieldPolygons={extractFieldDetails(
                            farm?.fieldDetails,
                          )}
                          mapCenter={farmBoundaries[index][0]}
                          showSearchBox={false}
                          fieldInfoWindows={[]}
                          markersWithInfoWindows={[]}
                          mapControlOptions={{
                            mapTypeId: 'satellite',
                            zoom: zoomCalculator(farm),
                            labels: true,
                          }}
                          drawMode={null}
                          style={{ height: '25rem', width: '100%' }}
                          selectedFarmField={selectedFarmField}
                          setSelectedFarmField={setSelectedFarmField}
                          hasOnClickFunctionality={true}
                        />
                      </SubContainer>
                      <SubContainer width={'66.6%'} noBorder={true}>
                        <TableWithExpandableRow
                          fieldDetails={farm?.fieldDetails}
                          noBorder={true}
                          selectedRow={selectedFarmField}
                          setSelectedRow={setSelectedFarmField}
                        />
                      </SubContainer>
                    </RegnFarmWrapper>
                  </RegnFarmParentWrapper>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
};

ParticipantSummary.propTypes = {
  projectList: PropTypes.array,
  loading: PropTypes.bool,
  participantInfo: PropTypes.object,
  participantId: PropTypes.number,
  projectId: PropTypes.number,
};
export default ParticipantSummary;
