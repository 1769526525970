import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DIM_BLUE_BACKGROUND,
  NEW_DARK_GREEN,
  NEW_FOCUS_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  QUILL_GRAY,
  TEAL_GREEN_HOVER,
  WHITE,
} from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  border: 1px solid ${QUILL_GRAY};
  width: ${({ width }) => width};
  border-radius: 2px;
`;
export const AccordionSx = {
  backgroundColor: WHITE,
  boxShadow: 'none',
  '&.Mui-expanded': {
    margin: '0',
  },
  '&.MuiAccordion-root:before': {
    opacity: '0',
  },
  '& .MuiSvgIcon-root': {
    display: 'flex',
    alignItems: 'center',
  },
};
export const AccordionSummarySx = {
  padding: '0rem 0.5rem 0rem 0.5rem',
  minHeight: '44px',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
  '&.Mui-expanded': {
    minHeight: '44px',
  },
  '&:hover': {
    backgroundColor: DIM_BLUE_BACKGROUND,
  },
};
export const AccordionDetailsSx = {
  padding: '0px',
  margin: '0.5rem 0.5rem 0.5rem 0.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
};
export const RowData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;
export const DataWrapper = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto;
`;
export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & .HoverActionsContainer {
    display: none;
  }
  &:hover .HoverActionsContainer {
    display: flex;
  }
`;
export const SecondaryLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: inherit;
`;
export const LabelAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PracticesDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const RowDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 2rem;
`;
export const ArrowLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: -webkit-fill-available;
`;
export const TextWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  justify-content: end;
  align-items: center;
  min-width: ${({ minWidth }) => minWidth};
`;
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${({ minWidth }) => minWidth};
  gap: 0.25rem;
`;
export const FieldLabel = styled.div`
  color: ${({ fieldStyle }) => fieldStyle?.color || DARK_CHARCOAL};
  font-weight: ${({ fieldStyle }) => fieldStyle?.fontWeight || 400};
  font-size: ${({ fieldStyle }) => fieldStyle?.fontSize || '14px'};
  line-height: 140%;
  padding: ${({ padding }) => padding};
  width: max-content;
`;
export const FieldValueWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 2rem;
  padding-bottom: 0.25rem;
`;
export const addBtnSx = {
  textTransform: 'none',
  border: `1px solid ${NEW_DARK_GREEN}`,
  background: WHITE,
  color: NEW_DARK_GREEN,
  fontWeight: 400,
  padding: '0.4rem 0.5rem',
  lineHeight: 'normal',
  height: '1.75rem',
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
    padding: '5.5px 7.25px',
  },
  '&.Mui-disabled': {
    color: COOL_GRAY,
    backgroundColor: QUILL_GRAY,
    border: 'none',
  },
};
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  color: ${DARK_CHARCOAL};
`;
export const KeyBoardArrowSx = {
  fontSize: '1rem',
};
