import React, { useState } from 'react';
import {
  DeleteIconSx,
  PlannedProjectActivitiesContainer,
  PlannedProjectActivityAcreInputField,
  PlannedProjectActivityDataContainer,
  PlannedProjectActivityName,
  PlannedProjectActivityTooltipSx,
  TextWrapper,
} from '../ParticipantGeneralFieldInfoForm.style';
import { FOCUS_DARK_GREEN, RED } from 'theme/GlobalColors';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  FIELD_INFO_CONSTANT,
  RESIDUE_TILLAGE_TOOLTIP_FIRST,
  RESIDUE_TILLAGE_TOOLTIP_SECOND,
} from '../ParticipantGeneralFieldInfoForm.content';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import { InputSx } from '../../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { STYLE_CONSTANTS } from '../../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import DialogBox from 'components/DialogBox/DialogBox.react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { alertIconStyling } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/NitrogenFertilizerModal/NitrogentFertilizerModal.style';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';

const PlannedProjectActivityRow = ({
  projectActivity,
  addedProjectActivities,
  setAddedProjectActivities,
  deleteProjectActivityHandler,
  error,
  setError,
  disableProjectActivity,
  setUniqueErrorMessages,
  getProjectActivityError,
  getEdgeOfFieldError,
  labelVal,
}) => {
  const [
    deleteDialogOpenForProjectActivity,
    setDeleteDialogOpenForProjectActivity,
  ] = useState(false);

  const projectActivityAcreHandler = (event) => {
    const updatedProjectActivities = addedProjectActivities.map((activity) => {
      if (activity.id === projectActivity.id) {
        return {
          ...activity,
          totalAcres: event.target.value,
        };
      }
      return activity;
    });

    setError((prevValues) => {
      return {
        ...prevValues,
        noActivityAddedError: false,
        plannedProjectActivityErrorList: getProjectActivityError(
          updatedProjectActivities,
        ),
        edgeOfFieldErrorList: getEdgeOfFieldError(updatedProjectActivities),
        projectActivityDtoList: false,
      };
    });
    setAddedProjectActivities(updatedProjectActivities);

    const errorArrayForRequiredError = getProjectActivityError(
      updatedProjectActivities,
    );
    error.plannedProjectActivityErrorList = errorArrayForRequiredError;
    const messages = new Set();
    const uniqueErrors = errorArrayForRequiredError?.reduce((acc, error) => {
      if (
        error.isError &&
        error.errorMessage &&
        !messages.has(error.errorMessage)
      ) {
        messages.add(error.errorMessage);
        acc.push(error);
      }
      return acc;
    }, []);
    setUniqueErrorMessages(uniqueErrors);
  };

  const isResidueAndTillageManagement =
    projectActivity.category === FIELD_INFO_CONSTANT.RESIDUE_TILLAGE_MANAGEMENT;

  const isEdgeOfFieldPractices =
    projectActivity.category === FIELD_INFO_CONSTANT.EDGE_OF_FIELD_PRACTICES;

  const isValidErrorId = (list) => {
    if (list && list.length > 0) {
      return list.find(
        (error) => +error.id === +projectActivity.id && error.isError,
      );
    } else return false;
  };

  return (
    <>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={deleteDialogOpenForProjectActivity}
        onConfirm={() => {
          deleteProjectActivityHandler(projectActivity.id);
        }}
        subtitle={`${FIELD_INFO_CONSTANT.delete_modal_content_first_half} ${projectActivity.name}${FIELD_INFO_CONSTANT.delete_modal_content_second_half}`}
        onCancel={() => setDeleteDialogOpenForProjectActivity(false)}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Delete"
        title={`${FIELD_INFO_CONSTANT.DELETE_MODAL_HEADING_FIRST_HALF} ${projectActivity.name}`}></DialogBox>
      <PlannedProjectActivitiesContainer key={projectActivity.id}>
        <PlannedProjectActivityName>
          <TextWrapper
            display="flex"
            alignItems="center"
            fontWeight="600"
            fontSize="0.875rem"
            color={FOCUS_DARK_GREEN}
            lineHeight={'1.225rem'}>
            {projectActivity.name}
            {isResidueAndTillageManagement && (
              <InfoTooltip
                content={`${RESIDUE_TILLAGE_TOOLTIP_FIRST}${
                  projectActivity.name + ' '
                }${RESIDUE_TILLAGE_TOOLTIP_SECOND}`}
                customSx={{ ...PlannedProjectActivityTooltipSx }}
              />
            )}
          </TextWrapper>
        </PlannedProjectActivityName>

        <PlannedProjectActivityAcreInputField>
          <PlannedProjectActivityDataContainer>
            <InputField
              isDisabled={checkTernaryCondition(
                isResidueAndTillageManagement,
                true,
                disableProjectActivity,
              )}
              label={labelVal}
              placeholder={FIELD_INFO_CONSTANT.ACRES}
              width="3.75rem"
              value={
                isEmpty(projectActivity.totalAcres)
                  ? ''
                  : projectActivity.totalAcres.toString()
              }
              minWidth={'3.75rem'}
              isRequired
              labelOnRightSide={true}
              rightSideLabelFormLabelMargin="0rem"
              display="flex"
              category="long-numbers-field"
              paddingForInput="0.125rem 0rem 0.125rem 0.5rem"
              leftMarginForRightSideLabel="0.5rem"
              topMarginForRightSideLabel="0.375rem"
              onUpdate={(event) => {
                const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
                if (decimalNumberRegex.test(event.target.value)) {
                  projectActivityAcreHandler(event);
                }
              }}
              customInputSx={InputSx}
              labelMarginTop={STYLE_CONSTANTS.zero_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_rem}></InputField>
            {(isValidErrorId(error.plannedProjectActivityErrorList) ||
              isValidErrorId(error.edgeOfFieldErrorList)) && (
              <AlertIcon
                data-testid="custom-date-error"
                style={alertIconStyling}
              />
            )}
          </PlannedProjectActivityDataContainer>
        </PlannedProjectActivityAcreInputField>
        {!disableProjectActivity && (
          <IconButton
            sx={DeleteIconSx}
            aria-label="delete"
            onClick={() => {
              setDeleteDialogOpenForProjectActivity(true);
            }}>
            <DeleteIcon />
          </IconButton>
        )}
        {isEdgeOfFieldPractices &&
          isValidErrorId(error.edgeOfFieldErrorList) && (
            <TextWrapper
              color={RED}
              fontWeight={400}
              fontSize={'0.688rem'}
              minWidth={'384px'}>
              {
                error.edgeOfFieldErrorList.find(
                  (error) => +error.id === +projectActivity.id && error.isError,
                ).errorMessage
              }
            </TextWrapper>
          )}
      </PlannedProjectActivitiesContainer>
    </>
  );
};
PlannedProjectActivityRow.propTypes = {
  projectActivity: PropTypes.object,
  addedProjectActivities: PropTypes.array,
  setAddedProjectActivities: PropTypes.func,
  deleteProjectActivityHandler: PropTypes.func,
  error: PropTypes.object,
  setError: PropTypes.func,
  disableProjectActivity: PropTypes.bool,
  setUniqueErrorMessages: PropTypes.func,
  getProjectActivityError: PropTypes.func,
  getEdgeOfFieldError: PropTypes.func,
  labelVal: PropTypes.string,
};
export default PlannedProjectActivityRow;
