import * as React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  TableRowWrapper,
  ColumnHeaderWrapper,
  MainTableWrapper,
  AccordianDetailsSx,
  TableBackground,
  FullWidth,
  SummaryColumnHeaderFont,
  AccordianSummarySx,
  innerTablePadding,
  AccordionSx,
  AccordionSxWithoutBorder,
  DetailedPanelFont,
  TableHeaderWrapper,
  LegendWrapper,
} from './TableWithExpandableRow.style';
import { useState, useEffect } from 'react';
import { TABLE_CONTENT } from './TableWithExpandableRow.content';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { Typography } from '@mui/material';
import OverFlowTooltip from 'components/OverFLowTooltip/OverFLowTooltip.react';

const InnerRows = (activityName, plannedArea, actualArea) => (
  <TableRowWrapper>
    <ColumnHeaderWrapper fontWeight={400} width="60%" fontSize="0.875rem">
      <OverFlowTooltip value={activityName}></OverFlowTooltip>
    </ColumnHeaderWrapper>
    <ColumnHeaderWrapper
      fontWeight={400}
      height="2rem"
      width="20%"
      justifyContent="center"
      fontSize="0.875rem">
      <Typography>{plannedArea}</Typography>
    </ColumnHeaderWrapper>
    <ColumnHeaderWrapper
      fontWeight={400}
      height="2rem"
      width="20%"
      fontSize="0.875rem">
      <Typography>{actualArea}</Typography>
    </ColumnHeaderWrapper>
  </TableRowWrapper>
);

const TableRowComponent = ({
  isExpanded,
  field,
  selectedRow,
  setSelectedRow,
  index,
}) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);
  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={checkTernaryCondition(
          index === 0,
          AccordionSx,
          AccordionSxWithoutBorder,
        )}>
        <AccordionSummary
          sx={AccordianSummarySx}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => {
            if (selectedRow?.fieldId === field?.fieldId) {
              setSelectedRow({
                farmId: null,
                fieldId: null,
              });
            } else {
              setSelectedRow({
                farmId: field?.farmId,
                fieldId: field?.fieldId,
              });
            }
          }}>
          <TableRowWrapper width={FullWidth}>
            <ColumnHeaderWrapper
              fontWeight={SummaryColumnHeaderFont.weight}
              width="40%"
              color={DARK_CHARCOAL}
              fontSize={SummaryColumnHeaderFont.size}>
              {field?.fieldName}
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper
              fontWeight={SummaryColumnHeaderFont.weight}
              width="15%"
              color={DARK_CHARCOAL}
              fontSize={SummaryColumnHeaderFont.size}>
              {field?.fieldArea}
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper
              fontWeight={SummaryColumnHeaderFont.weight}
              width="15%"
              color={DARK_CHARCOAL}
              fontSize={SummaryColumnHeaderFont.size}>
              {checkTernaryCondition(
                field?.delta,
                field?.delta,
                TABLE_CONTENT.emptyNotation,
              )}
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper
              fontWeight={SummaryColumnHeaderFont.weight}
              width="15%"
              color={DARK_CHARCOAL}
              fontSize={SummaryColumnHeaderFont.size}>
              {checkTernaryCondition(
                field?.baseline,
                field?.baseline,
                TABLE_CONTENT.emptyNotation,
              )}
            </ColumnHeaderWrapper>
            <ColumnHeaderWrapper
              fontWeight={SummaryColumnHeaderFont.weight}
              width="15%"
              color={DARK_CHARCOAL}
              fontSize={SummaryColumnHeaderFont.size}>
              {checkTernaryCondition(
                field?.activity,
                field?.activity,
                TABLE_CONTENT.emptyNotation,
              )}
            </ColumnHeaderWrapper>
          </TableRowWrapper>
        </AccordionSummary>
        <AccordionDetails expanded={true} sx={AccordianDetailsSx}>
          <MainTableWrapper
            noBorder={true}
            background={TableBackground}
            padding={innerTablePadding}>
            <TableRowWrapper>
              <ColumnHeaderWrapper
                color={DetailedPanelFont.color}
                fontWeight={DetailedPanelFont.weight}
                width="60%"
                fontSize={DetailedPanelFont.size}>
                {TABLE_CONTENT.activities}
              </ColumnHeaderWrapper>
              <ColumnHeaderWrapper
                color={DetailedPanelFont.color}
                fontWeight={DetailedPanelFont.weight}
                width="20%"
                fontSize={DetailedPanelFont.size}>
                <TableHeaderWrapper>
                  <LegendWrapper>{TABLE_CONTENT.plannedAcres}</LegendWrapper>
                </TableHeaderWrapper>
              </ColumnHeaderWrapper>
              <ColumnHeaderWrapper
                color={DetailedPanelFont.color}
                fontWeight={DetailedPanelFont.weight}
                width="20%"
                fontSize={DetailedPanelFont.size}>
                <TableHeaderWrapper>
                  <LegendWrapper>{TABLE_CONTENT.actualAcres}</LegendWrapper>
                </TableHeaderWrapper>
              </ColumnHeaderWrapper>
            </TableRowWrapper>
            {field?.activities.map((item) => {
              return InnerRows(
                item.activityName,
                item.plannedArea,
                item.actualArea,
              );
            })}
          </MainTableWrapper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

TableRowComponent.propTypes = {
  isExpanded: PropTypes.bool,
  field: PropTypes.string,
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  index: PropTypes.number,
};

export default TableRowComponent;
