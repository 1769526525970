import styled from 'styled-components';
import { ReactComponent as CalenderIcon } from '../../../../../assets/icons/calenderIcon.svg';
import {
  BLUISH_CYAN,
  NEW_FOCUS_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  WHITE,
} from 'theme/GlobalColors';
export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 32.625rem;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const ButtonWrapper = styled.div`
  display: inline-flex;
  width: fit-content;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;
`;
export const StyledCalenderIcon = styled(CalenderIcon)`
  height: 0.894rem;
`;

export const addButtonSx = {
  border: `0.0625rem solid ${BLUISH_CYAN}`,
  borderRadius: '0.25rem',
  padding: '0.375rem 0.5rem',
  backgroundColor: WHITE,
  color: BLUISH_CYAN,
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.192rem',
  textAlign: 'left',
  height: '1.75rem',
  '&:focus': {
    border: `0.0625rem solid ${BLUISH_CYAN}`,
  },
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
    border: `0.0625rem solid ${BLUISH_CYAN}`,
  },
};
