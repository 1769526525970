import React, { useEffect, useState } from 'react';
import {
  LOADING,
  UPLOAD_FAILED,
  RUN_CALS,
  SUCCESS,
  CALS_FAILED,
  RUNNING,
  UPLOADING,
  NOT_UPLOADED,
  GRAY_TWENTY,
  LIGHT_GREEN,
  DARK_RED,
  DEEP_YELLOW,
  GREEN,
  EMPTY_STRING,
} from '../../utils/config';
import { STATUS_CONTENT } from './Status.content';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RefreshIcon from '@mui/icons-material/Refresh';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Icon,
  Text,
  TooltipWrapper,
  TooltipMessageWrapper,
  Box,
} from './Status.style';
import PropTypes from 'prop-types';

const Status = ({ status, onRunCalClick, error }) => {
  const [icon, setIcon] = useState();
  const [text, setText] = useState();
  const [textColor, setTextColor] = useState(GRAY_TWENTY);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const customTitle = (title, message) => {
    return (
      <TooltipWrapper flexWrap={'wrap'}>
        {title}
        <TooltipMessageWrapper>{message}</TooltipMessageWrapper>
      </TooltipWrapper>
    );
  };

  useEffect(() => {
    switch (status) {
      case LOADING:
        setIcon(<CircularProgress size="1.5rem" sx={{ color: LIGHT_GREEN }} />);
        setText(STATUS_CONTENT.loading);
        setTextColor(GRAY_TWENTY);
        setErrorTitle(null);
        break;
      case UPLOAD_FAILED:
        setIcon(
          <ReportProblemIcon
            sx={{ color: DARK_RED, height: '1.5rem', width: '1.5rem' }}
          />,
        );
        setText(STATUS_CONTENT.upload_failed);
        setTextColor(DARK_RED);
        setErrorTitle(STATUS_CONTENT.upload_failed_error_title);
        setErrorMessage(error === null ? '--' : error);
        break;
      case RUN_CALS:
        setIcon(
          <RefreshIcon
            sx={{ color: DEEP_YELLOW, height: '1.5rem', width: '1.5rem' }}
          />,
        );
        setText(STATUS_CONTENT.run_cals);
        setTextColor(GRAY_TWENTY);
        setErrorTitle(null);
        break;
      case SUCCESS:
        setIcon(
          <FiberManualRecordIcon
            sx={{ color: GREEN, height: '1.5rem', width: '1.5rem' }}
          />,
        );
        setText(STATUS_CONTENT.success);
        setTextColor(GRAY_TWENTY);
        setErrorTitle(null);
        break;
      case CALS_FAILED:
        setIcon(
          <ReportProblemIcon
            sx={{ color: DARK_RED, height: '1.5rem', width: '1.5rem' }}
          />,
        );
        setText(STATUS_CONTENT.cals_failed);
        setTextColor(DARK_RED);
        setErrorTitle(STATUS_CONTENT.run_calcs_error_title);
        setErrorMessage(STATUS_CONTENT.run_calcs_error_message);
        break;
      case RUNNING:
        setIcon(<CircularProgress size="1.5rem" sx={{ color: LIGHT_GREEN }} />);
        setText(STATUS_CONTENT.running);
        setTextColor(GRAY_TWENTY);
        setErrorTitle(null);
        break;
      case UPLOADING:
        setIcon(<CircularProgress size="1.5rem" sx={{ color: LIGHT_GREEN }} />);
        setText(STATUS_CONTENT.uploading);
        setTextColor(GRAY_TWENTY);
        setErrorTitle(null);
        break;
      case NOT_UPLOADED:
        setIcon(
          <ReportProblemIcon
            sx={{ color: DEEP_YELLOW, height: '1.5rem', width: '1.5rem' }}
          />,
        );
        setText(STATUS_CONTENT.not_uploaded);
        setTextColor(GRAY_TWENTY);
        setErrorTitle(null);
        break;
      default:
        setText(EMPTY_STRING);
    }
  }, [status]);

  return (
    <LightTooltip
      title={errorTitle !== null ? customTitle(errorTitle, errorMessage) : ''}>
      <Box
        onClick={() => {
          status === RUN_CALS && onRunCalClick();
        }}
        cursor={status === RUN_CALS ? 'pointer' : ''}>
        <Icon>{icon}</Icon>
        <Text textColor={textColor}>{text}</Text>
      </Box>
    </LightTooltip>
  );
};

Status.propTypes = {
  status: PropTypes.string,
  onRunCalClick: PropTypes.func,
  error: PropTypes.string,
};

export default Status;
