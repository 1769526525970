import { FocusedInputSx } from 'components/FormComponents/FormStyles';
import { pipelineInputSx } from '../AddContractModal/AddContractModal.style';

export const PAGE_CONTENT = {
  title: 'Add pipeline acreage',
  editTitle: 'Edit pipeline acreage',
  cancel: 'Cancel',
  submit: 'Submit',
  toast_success_message: 'Pipeline acreage added successfully',
  toast_error_message: 'Failed to add pipeline acreage',
  edit_toast_success_message: 'Pipeline acreage updated successfully',
  edit_toast_error_message: 'Failed to update pipeline acreage',
  numberFieldPlaceholder: '0.00',
  activities_table_heading:
    'Provide the corresponding extent of implementation in acres for each project activity.',
  total_enrolled_acres_label:
    'Provide the total enrolled acres that project activities will be implemented on for project cycle',
  total_enrolled_acres_placeholder: 'acres',
  enrolled_acres_required_error: 'Total acres for project cycle is required. ',
  enrolled_acres_min_limit_error: 'Acre amount must be greater than 0.',
  pipelineAcresSumLimit:
    'Pipeline acres for each activity cannot exceed total enrolled acres.',
  pipelineUnits: 'pipelineUnits',
};

export const inputStyleProps = {
  textAlignment: 'end',
  maxLength: 100,
  minWidth: '3.75rem',
  width: '3.75rem',
  padding: '0.5rem',
  lineHeight: '1.225rem',
  fontSize: '0.875rem',
  height: '1.75rem',
  noLabel: true,
  marginBottom: '0',
  rightSideLabelFormLabelMargin: '0',
  customStyles: { width: '3.75rem', marginBottom: '0' },
  customInputSx: { ...pipelineInputSx, ...FocusedInputSx },
  display: 'flex',
  alignItems: 'center',
};

export const totalAcresInputProps = (predefinedUnit) => {
  return {
    minWidth: '3.75rem',
    width: '3.75rem',
    labelWidth: '28.25rem',
    placeholder: PAGE_CONTENT.total_enrolled_acres_placeholder,
    hasPredefinedUnit: true,
    predefinedUnit: `total ${predefinedUnit}`,
    customStyles: { width: '28.25rem' },
  };
};

export const initialPipelineAcerageModalValues = {
  pipelineUnits: [],
};
export const contracting = [
  {
    PIPELINE_CONTRACTED_UNIT: [
      {
        dataElementId: 13,
        dataElementName: 'Pipeline contracted units',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Contracting',
      },
    ],
  },
  {
    INCENTIVE_RATE: [
      {
        dataElementId: 12,
        dataElementName: 'Incentive rate',
        uomId: 25,
        numeratorUomId: 17,
        denominatorUomId: 16,
        uomName: '$ per ac',
        uomNameFull: 'US dollars per acre',
        uomNameDisplay: '$ per ac',
        uomType: 'ratio',
        uiModal: 'Contracting',
      },
    ],
  },
  {
    TOTAL_CONTRACTED_AREA: [
      {
        dataElementId: 14,
        dataElementName: 'Total contracted area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Contracting',
      },
    ],
  },
];
