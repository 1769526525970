export const MODAL_CONTENT = {
  ENROLLMENT_HEADING: 'Initiate enrollment',
  CONTINUE_ENROLLMENT_STATUS: 'Continue Participant Enrollment for',
  PROJECT_FIELD_LABEL: 'Select project to initiate enrollment process for ',
  PROJECT_FIELD_PLACEHOLDER: 'Type and select project ',
  SELECT_NEW_PROJECT_LABEL: 'Enroll Participant in New Project',
  BUTTON_SELECTION_LABEL: 'How would you like to enroll ?',
  CANCEL_BTN: 'Cancel',
  ENROLL_BTN: 'Enroll on behalf',
  SEND_REQUEST_BTN: 'Send request',
  SUCCESS: [200, 201, 204],
  EMAIL_SUCCESS_MESSAGE: 'Email sent successfully',
  EMAIL_ERROR_MESSAGE: 'Unable to send email due to technical error',
  SUBMIT: 'Submit',
  PROJECT_NAME: 'Project name',
  PROJECT_NAME_PLACEHOLDER: 'Type and select project name',
  PROJECT_LIFE_CYCLE_LABEL: 'Select project cycle.',
  PROJECT_LIFE_CYCLE_PLACEHOLDER: 'Select project cycle',
  TWO_REM: '2rem',
  PROJECT_CYCLE_REQUIRED_ERROR: 'Project cycle selection is required.',
  PROJECT_NOT_PRESENT_ERROR:
    'Project entered does not exist. Please select a project from the list as you type.',
  PARTICIPANT_NAME: 'Participant name',
  PARTICIPANT_NAME_PLACEHOLDER: 'Type and select participant name',
  PARTICIPANT_NOT_PRESENT_ERROR:
    'Participant entered does not exist. Please select a participant from the list as you type.',
};
