import { PROJECT_SCHEDULE_CONTENT } from './ProjectSchedule.content';
import {
  Heading,
  IconWrapper,
  InfoContent,
  InfoWrapper,
  ProjectScheduleWrapper,
  SubHeading,
} from './ProjectSchedule.style';
import { useEffect, useState } from 'react';
import ProjectCycleTable from '../ProjectCycleTable/ProjectCycleTable.react';
import axios from 'axios';
import { FETCH_PROJECT_LIFE_CYCLE, GET_PROJECT_LIFE_CYCLE_LIST } from 'urls';
import { useParams } from 'react-router-dom';
import { constructCycleData } from './ProjectSchedule.functions';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/infoIcon.svg';
import { checkTernaryCondition } from 'utils/helper';
import moment from 'moment';
import Loader from 'components/Loader/Loader.react';

const ProjectSchedule = () => {
  const [cycleData, setCycleData] = useState([]);
  const { id } = useParams();
  const [durationData, setDurationData] = useState({
    noOfCycles: 0,
    period: '__________',
    startMonth: '__________',
  });
  const [loaderCount, setLoaderCount] = useState(0);

  const fetchProjectLifeCycle = () => {
    setLoaderCount((prev) => prev + 1);
    axios
      .get(`${GET_PROJECT_LIFE_CYCLE_LIST}/${id}`)
      .then((response) => {
        if (response.data.length > 0) {
          const getCycleData = constructCycleData(response.data);
          setCycleData(getCycleData);
        }
      })
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const fetchNoOfCycles = () => {
    setLoaderCount((prev) => prev + 1);
    axios
      .get(`${FETCH_PROJECT_LIFE_CYCLE}/${id}`)
      .then((response) => {
        if (response.data) {
          const noOfCycles = response.data.totalProjectCycles;
          const cycleDuration = response.data.cycleDuration;
          const getMonthNumber = response.data.cycleStartMonth;
          const getMonth = moment()
            .month(getMonthNumber - 1)
            .format('MMMM');
          setDurationData({
            noOfCycles: noOfCycles,
            period: checkTernaryCondition(
              cycleDuration === 12,
              'annually',
              `every ${cycleDuration} months`,
            ),
            startMonth: `${getMonth} ${response.data.projectStartYear}`,
          });
        }
      })
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  useEffect(() => {
    fetchProjectLifeCycle();
    fetchNoOfCycles();
  }, []);
  return (
    <ProjectScheduleWrapper>
      <Loader loading={loaderCount > 0} zIndex={999999} />
      <Heading>{PROJECT_SCHEDULE_CONTENT.heading}</Heading>
      <SubHeading>
        {PROJECT_SCHEDULE_CONTENT.subHeading(durationData)}
      </SubHeading>
      <ProjectCycleTable rowData={cycleData} />
      <InfoWrapper>
        <IconWrapper>
          <InfoIcon />
        </IconWrapper>
        <InfoContent>{PROJECT_SCHEDULE_CONTENT.info_content}</InfoContent>
      </InfoWrapper>
    </ProjectScheduleWrapper>
  );
};

export default ProjectSchedule;
