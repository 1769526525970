import styled from 'styled-components';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  display: flex;
  padding: 1rem;
  background-color: ${WHITE};
  margin: 1rem;
  gap: 1rem;
`;
export const Field = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  min-width: ${({ minWidth }) => minWidth};
  gap: 1rem;
`;
export const FieldTitle = styled.div`
  color: ${({ color }) => color || '#63666A'};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding: ${({ padding }) => padding};
`;
export const FieldValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${DARK_CHARCOAL};
  word-wrap: break-word;
  max-width: 20rem;
`;
