import { useContext, useState, useEffect } from 'react';
import TabPanel from 'components/Tabber/TabPanel.react';
import { PORTFOLIO_TABS } from './PortfolioOverview.content';
import PortfolioOriginationTab from 'pages/PortfolioOriginationTab/PortfolioOriginationTab.react';
import { ModulesEnableMapper, getIsModuleEnabled } from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import InsetPage from 'pages/InsetsPage/SettingsPage.react';
import { sidenavContext } from 'contextAPI/SideNavContext';

const PortfolioOverview = () => {
  const { modulesListData } = useContext(userAuthRolesContext);
  const isOriginationModuleEnabled = getIsModuleEnabled(
    ModulesEnableMapper['PORTFOLIO_ORIGINATION'],
    modulesListData,
  );
  const [currentTab, setCurrentTab] = useState(0);
  const { tab } = useContext(sidenavContext);

  const portfolioTabs = [
    {
      title: PORTFOLIO_TABS.insetting,
      component: <InsetPage />,
      isModuleEnabled: getIsModuleEnabled(
        ModulesEnableMapper['PORTFOLIO_INSETTING'],
        modulesListData,
      ),
    },
    {
      title: PORTFOLIO_TABS.offsetting,
      component: <></>,
      isModuleEnabled: getIsModuleEnabled(
        ModulesEnableMapper['PORTFOLIO_OFFSETTING'],
        modulesListData,
      ),
    },
    {
      title: PORTFOLIO_TABS.origination,
      component: <PortfolioOriginationTab />,
      isModuleEnabled: isOriginationModuleEnabled,
    },
  ];

  const enabledTabs = portfolioTabs.filter((tab) => tab.isModuleEnabled);

  useEffect(() => {
    const originationTabIndex = enabledTabs.findIndex(
      (tab) => tab.title === PORTFOLIO_TABS.origination,
    );

    // Note: If origination module is enabled, then it should be the default tab, otherwise we show portfolio overview tab
    if (originationTabIndex >= 0 && isOriginationModuleEnabled)
      setCurrentTab(originationTabIndex);
    else setCurrentTab(0);
  }, []);
  useEffect(() => {
    if (tab?.module === 'Portfolio') {
      const tabIndex = enabledTabs.findIndex((tabs) => tabs.title === tab.tab);
      setCurrentTab(tabIndex);
    }
  }, [tab]);

  return (
    <div>
      {enabledTabs.map((tab, index) => (
        <TabPanel key={tab.title} value={currentTab} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default PortfolioOverview;
