import { DOWNLOAD_ENROLLMENT_REPORT, SEND_ENROLMENT_REOPEN_EMAIL } from 'urls';
import axios from 'axios';
import { pathName } from 'Routes/routes.const';
import { checkTernaryCondition } from 'utils/helper';

export const enrollmentReview = (participantId, projectId) => ({
  participantId,
  projectId,
  title: 'Review enrollment',
  contentText:
    'Please access the enrollment responses to review and make your approval decision once complete.',
  firstSubmitBtnLabel: 'Approve enrollment',
  secondSubmitBtnLabel: 'Reopen enrollment',
  cancelBtnLabel: 'Cancel',
  firstSubmitBtnCb: () => {},
  secondSubmitBtnCb: () => {},
  cancelCb: () => {},
  modalCloseCb: () => {},
  modalData: { flag: false },
  reviewComponent:
    'Please access the enrollment responses to review and make your approval decision once complete.',
  reviewButtonText: 'Review enrollment',
  fetchInitialDataCb: () =>
    axios
      .get(DOWNLOAD_ENROLLMENT_REPORT.replace(':participnatId', participantId))
      .then((response) => response.data.reportLink),
  reOpenProp: enrollmentReOpen(participantId, projectId),
});

export const enrollmentReOpen = (participantId, projectId) => ({
  participantId,
  projectId,
  title: 'Reopen enrollment',
  contentText: (participantName) =>
    `How would you like to reopen ${checkTernaryCondition(
      participantName,
      `${participantName}'s`,
      '',
    )} enrollment?`,
  emailPlaceholder: (projectName, projectCycleYear) =>
    `Your enrollment for the ${projectName} ${projectCycleYear} has been reopened. Someone will be in contact with you to discuss next steps. Please access the enrollment request linked below if you would like to proceed.`,
  enrollmentCta: 'Reopen enrollment request link',
  customEmailMessage: '',
  isOnBehalf: false,
  onReopenSubmit: (
    customEmailMessage,
    isOnBehalf,
    setIsLoading,
    closeModal,
    instanceId,
    actionId,
    contractId,
    updateParticipantStatus,
    projectCycleId,
  ) => {
    setIsLoading(true);
    axios
      .post(`${SEND_ENROLMENT_REOPEN_EMAIL}?&radioSelection=${isOnBehalf}`, {
        internalProjectId: projectId,
        participantId: participantId,
        approvalDescription: customEmailMessage,
        enrollmentInstanceId: instanceId,
        projectCycleId: projectCycleId,
      })
      .then(() => {
        updateParticipantStatus({
          contractId: contractId,
          projectId: projectId,
          participantId: participantId,
          instanceId: instanceId,
          actionId: actionId,
          projectCycleId: projectCycleId,
        });
        if (!isOnBehalf)
          window.open(
            `${pathName.participant.menuPage}?projectid=${projectId}&participantid=${participantId}&enrollmentInstanceId=${instanceId}&projectcycleid=${projectCycleId}`,
            '_blank',
          );
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  },
});
