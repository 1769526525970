import styled from 'styled-components';
import {
  BLACK,
  FOCUS_DARK_GREEN,
  NEW_DARK_GREEN,
  RED,
} from 'theme/GlobalColors';
import { containedBtnSx } from 'theme/GlobalStyles';

export const Title = styled.div`
  font-size: 1rem;
  font-weight: ${({ fontWeight }) => fontWeight ?? '600'};
  line-height: 22.4px;
  margin-bottom: 0.5rem;
  color: ${BLACK};
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: ${({ gap }) => gap};
  align-items: center;
`;

export const Subtitle = styled.div`
  font-size: 0.875rem;
  margin-bottom: 1rem;
  line-height: 21px;
`;

export const ActivityItemData = styled.div`
  margin-bottom: 1.5rem;
`;

export const ActivityFlexItems = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const ActivityTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 24px;
  height: 2rem;
  display: flex;
  align-items: center;
  color: ${FOCUS_DARK_GREEN};
`;

export const InputWithDeleteWrapper = styled.div`
  display: flex;
  position: relative;
  max-width: 40%;
  margin-top: ${({ marginTop }) => marginTop ?? '1rem'};
`;

export const inputWrapperStyles = {
  width: '10%',
};

export const inputStyleProps = {
  minWidth: '5rem',
  width: '5rem',
  hasPredefinedUnit: true,
  noLabel: true,
  rightSideLabelFormLabelMargin: '0',
  height: '2rem',
};

export const NoteSection = styled.div`
  font-size: 0.75rem;
  line-height: 19.07px;
  margin-top: 0.25rem;
  font-weight: 400;
  font-style: italic;
`;

export const NoteTag = styled.span`
  font-weight: 600;
  font-style: italic;
  font-size: 0.75rem;
`;

export const PredefinedUnit = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

export const deleteIconStyles = {
  marginLeft: '1.5rem',
  alignSelf: 'center',
};

export const JumpLink = styled.div`
  cursor: pointer;
  color: ${NEW_DARK_GREEN};
  font-size: 0.875rem;
  width: max-content;
  margin-left: 2rem;
`;

export const EdgeOfFieldItems = styled.div`
  display: flex;
  gap: 1rem;
`;

export const speciesCategoryStyleProps = {
  minWidth: '8.75rem',
  width: '8.75rem',
  height: '2rem',
  showLabelAsValue: true,
  hasElementAsLabel: true,
};

export const edgeOfFieldCommonInputStyles = {
  minWidth: '5rem',
  width: '5rem',
  hasElementAsLabel: true,
};

export const stripWidthInputStyleProps = {
  ...edgeOfFieldCommonInputStyles,
  hasPredefinedUnit: true,
  predefinedUnit: 'ft',
};

export const barrierWidthInputStyleProps = {
  ...edgeOfFieldCommonInputStyles,
  hasPredefinedUnit: true,
  predefinedUnit: 'ft',
};

export const rowsCountInputStyleProps = {
  ...edgeOfFieldCommonInputStyles,
  hasPredefinedUnit: false,
};

export const speciesDensityInputStyleProps = {
  ...edgeOfFieldCommonInputStyles,
  hasPredefinedUnit: true,
  predefinedUnit: 'trees planted per acre',
};

export const LabelWithAlerts = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const AcresError = styled.div`
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${RED};
  width: 27rem;
`;
export const EdgeOfFieldError = styled.div`
  position: absolute;
  left: 0;
  top: 2rem;
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${RED};
  width: 26rem;
`;

export const saveBtnSx = {
  ...containedBtnSx,
  height: '1.75rem',
  marginTop: '1rem',
  marginBottom: '0rem',
};

export const ItalicsStyleText = styled.span`
  font-style: italic;
`;
