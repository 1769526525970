import styled from 'styled-components';
import {
  DARK_GREEN,
  COOL_GRAY,
  GRAYISH_BLUE,
  BLACK,
  NEUTRAL_BACKGROUND_COLOR,
} from 'theme/GlobalColors';

export const AutocompleteInput = styled.input`
  padding: 0.25rem 0 0.25rem 0.5rem;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  border: none;
  border-bottom: ${({ isDisabled }) => getBottomBorder(isDisabled)};
  &:focus {
    border-bottom: ${`2px solid ${DARK_GREEN}`};
  }
  outline: none;
  opacity: 1;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : '')};
  height: 2rem;
  color: ${({ isDisabled }) => isDisabled && BLACK};
  background-color: ${({ isDisabled }) =>
    isDisabled && NEUTRAL_BACKGROUND_COLOR};
  ::placeholder {
    color: ${COOL_GRAY};
    opacity: 0.7;
    visibility:hidden;
  },
`;

export const getBottomBorder = (isDisabled) => {
  if (isDisabled) return `1px dotted ${DARK_GREEN}`;
  return `1px solid ${GRAYISH_BLUE}`;
};
