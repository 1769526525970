import {
  MainWrapper,
  datagridSx,
  FilterHeader,
  TransactionListWrapper,
  TransactionListText,
  DataGridWrapper,
  SortWrapper,
  handleArrowColor,
  MTCO2EWrapper,
  MTCO2EHeading1,
  MTCO2EHeading2,
  CarbonContent,
} from './ProjectCatalog.style';
import { CATALOG_CONTENT, SortColValues } from './ProjectCatalog.content';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import SimpleOptionsButton from 'components/SimpleOptionsButton/SimpleOptionsButton.react';
import axios from 'axios';
import DialogBox from 'components/DialogBox/DialogBox.react';
import {
  PROJECT_CATALOG,
  COUNTRY_COLUMN,
  PROJECT_TYPE_COLUMN,
  PROJECT_DEVELOPERS_COLUMN,
  PROJECT_NAME_COLUMN,
  INTERNAL_REVIEW_STATUS_COLUMN,
  TAGS_COLUMN,
  DELETE_PROJECT,
} from 'urls';
import { useNavigate, useLocation } from 'react-router';
import FilterComponent from 'components/FilterComponent/FilterComponent.react';
import Tags from 'components/Tags/Tags.react';
import { dateFormatter, dateTimeFormatter } from 'utils/dateFormatter';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { EN_US } from 'constants/localization';
import { uniqueId } from 'utils/uniqueIdGenerator';
import NodataComponent from 'components/NoDataComp/NodataContent.react';
import { covertDateToLocalTimeZone } from 'utils/helper';
import CustomCell from 'components/CustomCell/CustomCell.react';
import { pathName } from 'Routes/routes.const';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';

const ProjectCatalog = ({ disableVirtualization }) => {
  const location = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setErrorIsDialogOpen] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [currentRow, setCurrentRow] = useState();
  const gridRows = true;
  const [filterData, setFilterData] = useState({
    COUNTRY: [],
    PROJECT_TYPE: [],
    PROJECT_NAME: [],
    PROJECT_DEVELOPER: [],
    INTERNAL_REVIEW_STATUS: [],
    TAGS: [],
  });
  const [modifiedList, setModifiedList] = useState({
    COUNTRY: [],
    PROJECT_NAME: [],
    PROJECT_DEVELOPER: [],
    PROJECT_TYPE: [],
    INTERNAL_REVIEW_STATUS: [],
    TAGS: [],
  });
  const [countrySelectedFilter, setCountrySelectedFilter] = useState([]);
  const [projectNameSelectedFilter, setProjectNameSelectedFilter] = useState(
    [],
  );
  const [projectDeveloperSelectedFilter, setProjectDeveloperSelectedFilter] =
    useState([]);
  const [projectTypeSelectedFilter, setProjectTypeSelectedFilter] = useState(
    [],
  );
  const [internalReviewSelectedFilter, setInternalReviewSelectedFilter] =
    useState([]);
  const [tagsSelectedFilter, setTagsSelectedFilter] = useState([]);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogSubtitle, setDialogSubtitle] = useState();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState({
    nameFlag: false,
    developerFlag: false,
    typeFlag: false,
    countryFlag: false,
    statusFlag: false,
    dateFlag: false,
  });
  const [order, setOrder] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    fetchProjectList();
  }, [
    countrySelectedFilter,
    projectTypeSelectedFilter,
    projectDeveloperSelectedFilter,
    projectNameSelectedFilter,
    internalReviewSelectedFilter,
    tagsSelectedFilter,
  ]);

  const navigate = useNavigate();

  const editHandler = (id) => {
    //navigate to the edit project page
    navigate(`${pathName.assets.projects.edit}/${id}`, {
      state: { previousPath: location.pathname },
    });
  };

  const rowClickHandler = (params) => {
    if (params.field !== 'options') {
      //navigate to project details page
      navigate(`${pathName.assets.projects.view}/${params.row.id}`, {
        state: { previousPath: location.pathname },
      });
    }
  };

  const currentRowSetter = (row) => {
    setCurrentRow(row);
  };

  const deleteClickHandler = () => {
    setIsDialogOpen(true);
  };

  const onErrorDialogBoxClose = () => {
    setTransactionList([]);
    setErrorIsDialogOpen(false);
  };

  const apiCall = () => {
    axios
      .all(
        [
          COUNTRY_COLUMN,
          PROJECT_TYPE_COLUMN,
          PROJECT_DEVELOPERS_COLUMN,
          PROJECT_NAME_COLUMN,
          INTERNAL_REVIEW_STATUS_COLUMN,
          TAGS_COLUMN,
        ].map((promise) => axios.get(promise)),
      )
      .then(
        axios.spread((...responses) => {
          setFilterData({
            ...filterData,
            COUNTRY: responses[0].data,
            PROJECT_TYPE: responses[1].data,
            PROJECT_DEVELOPER: responses[2].data,
            PROJECT_NAME: responses[3].data,
            INTERNAL_REVIEW_STATUS: responses[4].data,
            TAGS: responses[5].data,
          });
        }),
      )
      .catch(() => {
        // react on errors.
      });
  };

  const deleteProject = () => {
    setIsDialogOpen(false);
    axios
      .delete(DELETE_PROJECT, {
        params: { internalProjectId: currentRow?.internalProjectId },
      })
      .then((response) => {
        if (response?.data?.status === CATALOG_CONTENT.success) {
          toast(
            <CustomSnackbar
              type="success"
              message={`${currentRow?.projectName} ${CATALOG_CONTENT.success_message}`}
            />,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          fetchProjectList();
          apiCall();
        } else if (response?.data?.status === CATALOG_CONTENT.failed) {
          setDialogTitle(`${currentRow?.projectName} couldn't be deleted`);
          setDialogTitle(
            `${currentRow?.projectName} ${CATALOG_CONTENT.delete_message}`,
          );
          setErrorIsDialogOpen(true);
        } else {
          setDialogTitle(`${currentRow?.projectName} can't be deleted`);
          if (response?.data?.transactionNames.length === 1) {
            setDialogSubtitle(
              `${currentRow?.projectName} is linked in ${response?.data?.transactionNames[0]} ${CATALOG_CONTENT.denied_message_single_transaction}`,
            );
          } else {
            setDialogSubtitle(
              `${currentRow?.projectName} ${CATALOG_CONTENT.denied_message_multiple_transaction}`,
            );
            setTransactionList(response?.data?.transactionNames);
          }
          setErrorIsDialogOpen(true);
        }
      })
      .catch(() => {
        setDialogTitle(`${currentRow?.projectName} couldn't be deleted`);
        setDialogSubtitle(
          `${currentRow?.projectName} ${CATALOG_CONTENT.failed_message}`,
        );
        setErrorIsDialogOpen(true);
      });
  };

  const handelFilterClick = () => {
    let countriesList = filterData.COUNTRY?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let projectTypeList = filterData.PROJECT_TYPE?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let projectNameList = filterData.PROJECT_NAME?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let projectDeveloper = filterData.PROJECT_DEVELOPER?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let internalReviewList = filterData.INTERNAL_REVIEW_STATUS?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let tagsList = filterData.TAGS?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    setModifiedList(() => ({
      ...modifiedList,
      COUNTRY: [...countriesList],
      PROJECT_TYPE: [...projectTypeList],
      PROJECT_NAME: [...projectNameList],
      PROJECT_DEVELOPER: [...projectDeveloper],
      INTERNAL_REVIEW_STATUS: [...internalReviewList],
      TAGS: [...tagsList],
    }));
  };

  const columnsSort = {
    nameFlag: false,
    developerFlag: false,
    typeFlag: false,
    countryFlag: false,
    statusFlag: false,
    dateFlag: false,
  };

  const handleSorting = (flagValue, flagName) => {
    let obj = { ...columnsSort };
    obj[flagName] = !flagValue;
    setSortOrder(obj);
    setOrder(!flagValue);
  };

  const handleSortClick = (params) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    setSortColumn(SortColValues[params.field]);
    switch (SortColValues[params.field]) {
      case SortColValues.projectName:
        handleSorting(sortOrder.nameFlag, 'nameFlag');
        break;
      case SortColValues.projectDeveloper:
        handleSorting(sortOrder.developerFlag, 'developerFlag');
        break;
      case SortColValues.projectType:
        handleSorting(sortOrder.typeFlag, 'typeFlag');
        break;
      case SortColValues.country:
        handleSorting(sortOrder.countryFlag, 'countryFlag');
        break;
      case SortColValues.internalReviewStatus:
        handleSorting(sortOrder.statusFlag, 'statusFlag');
        break;
      case SortColValues.addedOn:
        handleSorting(sortOrder.dateFlag, 'dateFlag');
        break;
    }
  };

  const columns = [
    {
      field: 'projectName',
      headerName: 'Project Name',
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Project Name</b>
          <FilterComponent
            isFilterApplied={
              projectNameSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={modifiedList.PROJECT_NAME.length > 4 ? true : false}
            isSearchEnabled={
              modifiedList.PROJECT_NAME.length > 9 ? true : false
            }
            list={modifiedList.PROJECT_NAME}
            setFilterList={setProjectNameSelectedFilter}
          />
          <SortWrapper
            data-testid="sort-project-name"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.nameFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      disableColumnMenu: true,
      width: 170,
    },
    {
      field: 'projectDeveloper',
      headerName: 'Project Developer',
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Project Developer</b>
          <FilterComponent
            isFilterApplied={
              projectDeveloperSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={
              modifiedList.PROJECT_DEVELOPER.length > 4 ? true : false
            }
            isSearchEnabled={
              modifiedList.PROJECT_DEVELOPER.length > 9 ? true : false
            }
            list={modifiedList.PROJECT_DEVELOPER}
            setFilterList={setProjectDeveloperSelectedFilter}
          />
          <SortWrapper
            data-testid="sort-project-developer"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.developerFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      disableColumnMenu: true,
      width: 200,
    },
    {
      field: 'projectType',
      headerName: 'Project Type',
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Project Type</b>
          <FilterComponent
            isFilterApplied={
              projectTypeSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={modifiedList.PROJECT_TYPE.length > 4 ? true : false}
            isSearchEnabled={
              modifiedList.PROJECT_TYPE.length > 9 ? true : false
            }
            list={modifiedList.PROJECT_TYPE}
            setFilterList={setProjectTypeSelectedFilter}
          />
          <SortWrapper
            data-testid="sort-project-type"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.typeFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      disableColumnMenu: true,
      width: 170,
    },
    {
      field: 'country',
      headerName: 'Country',
      disableColumnMenu: true,
      width: 130,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Country</b>
          <FilterComponent
            isFilterApplied={countrySelectedFilter?.length > 0 ? true : false}
            isMultiSelect={modifiedList.COUNTRY.length > 4 ? true : false}
            isSearchEnabled={modifiedList.COUNTRY.length > 9 ? true : false}
            list={modifiedList.COUNTRY}
            setFilterList={setCountrySelectedFilter}
          />
          <SortWrapper
            data-testid="sort-country"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.countryFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
    },
    {
      field: 'internalReviewStatus',
      headerName: 'Internal Review Status',
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Internal Review Status</b>
          <FilterComponent
            isFilterApplied={
              internalReviewSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={
              modifiedList.INTERNAL_REVIEW_STATUS.length > 4 ? true : false
            }
            isSearchEnabled={
              modifiedList.INTERNAL_REVIEW_STATUS.length > 9 ? true : false
            }
            list={modifiedList.INTERNAL_REVIEW_STATUS}
            setFilterList={setInternalReviewSelectedFilter}
          />
          <SortWrapper
            data-testid="sort-project-status"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.statusFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      disableColumnMenu: true,
      width: 230,
    },
    {
      field: 'mtco2e',
      headerName: 'Available Credit Supply (MT CO2e)',
      disableColumnMenu: true,
      width: 200,
      sortable: false,
      renderHeader: () => (
        <MTCO2EWrapper onClick={(e) => e.stopPropagation()}>
          <MTCO2EHeading1>
            {CATALOG_CONTENT.available_credit_suply}
          </MTCO2EHeading1>
          <MTCO2EHeading2>{CATALOG_CONTENT.MTCO2_label}</MTCO2EHeading2>
        </MTCO2EWrapper>
      ),
      renderCell: (params) => (
        <CarbonContent>
          {params.value !== null && params.value !== 0
            ? params.value?.toLocaleString(EN_US)
            : '--'}
        </CarbonContent>
      ),
    },
    {
      field: 'tags',
      headerName: 'Tags',
      disableColumnMenu: true,
      width: 120,
      sortable: false,
      renderHeader: () => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Tags</b>
          <FilterComponent
            isFilterApplied={tagsSelectedFilter?.length > 0 ? true : false}
            isMultiSelect={modifiedList.TAGS.length > 4 ? true : false}
            isSearchEnabled={modifiedList.TAGS.length > 9 ? true : false}
            list={modifiedList.TAGS}
            setFilterList={setTagsSelectedFilter}
          />
        </FilterHeader>
      ),
      renderCell: (params) => <Tags tagList={params.row.tags} />,
    },
    {
      field: 'addedOn',
      headerName: 'Added on',
      disableColumnMenu: true,
      width: 130,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Added On</b>
          <SortWrapper
            data-testid="sort-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.dateFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <div>
          {params.value !== null ? (
            <CustomCell
              value1={dateFormatter(params.value)}
              value2={dateTimeFormatter(
                covertDateToLocalTimeZone(params.value),
              )}
              color={CATALOG_CONTENT.cool_gray}
            />
          ) : (
            '--'
          )}
        </div>
      ),
    },
    {
      field: 'options',
      headerName: '',
      disableColumnMenu: true,
      width: 30,
      sortable: false,
      renderCell: (params) => (
        <div
          onClick={() => currentRowSetter(params?.row)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              currentRowSetter(params?.row);
            }
          }}
          data-testid={`options-${params.row?.id}`}>
          <SimpleOptionsButton
            editId={params.row.id}
            onEdit={editHandler}
            onDelete={() => deleteClickHandler()}
          />
        </div>
      ),
    },
  ];

  const fetchProjectList = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    // to fetch data with pagesize, pagebody  and filter
    axios
      .post(PROJECT_CATALOG, {
        filter: {
          projectNameSelections: projectNameSelectedFilter,
          projectDeveloperSelections: projectDeveloperSelectedFilter,
          projectCountrySelections: countrySelectedFilter,
          projectNatureVsNonnatureSelections: projectTypeSelectedFilter,
          internalReviewStatusSelections: internalReviewSelectedFilter,
          tags: tagsSelectedFilter,
        },
        pageNumber: pageState.page - 1,
        pageSize: pageState.pageSize,
        projectSortFilterDto: {
          newestToOldest: order,
          sortType: sortColumn,
        },
      })
      .then((response) => {
        let projects = [];
        response.data.content.forEach((project) => {
          projects.push({
            id: project.internalProjectId,
            ...project,
          });
        });
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: projects,
          total: response.data.totalElements,
        }));
        setIsDataFetched(true);
      });
  };

  // onPage load fetch project list
  useEffect(() => {
    fetchProjectList();
  }, []);

  //Pagination fetch data
  useEffect(() => {
    fetchProjectList();
  }, [pageState.pageSize, pageState.page, sortColumn, sortOrder]);

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    handelFilterClick();
  }, [filterData]);
  if (isDataFetched && pageState.data.length == 0) {
    return (
      <NodataComponent
        nodata={CATALOG_CONTENT.noDataContent}
        addNewLabel={CATALOG_CONTENT.addNewProjectLabel}
        addbuttonLabel={{
          name: CATALOG_CONTENT.addButton,
          link: '/assets/projects/add',
        }}
      />
    );
  } else {
    return (
      <MainWrapper>
        {gridRows && (
          <DataGridWrapper>
            <DataGrid
              disableVirtualization={disableVirtualization}
              rows={pageState.data || []}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[25, 50, 75]}
              disableSelectionOnClick
              onCellClick={rowClickHandler}
              pagination
              componentsProps={{
                pagination: {
                  labelRowsPerPage: 'Items per page',
                  SelectProps: TablePaginationMenuListStyle,
                },
              }}
              sx={datagridSx}
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              paginationMode="server"
              onPageChange={(newPage) =>
                setPageState((old) => ({ ...old, page: newPage + 1 }))
              }
              onPageSizeChange={(newPageSize) =>
                setPageState((old) => ({ ...old, pageSize: newPageSize }))
              }
              columns={columns}
            />
          </DataGridWrapper>
        )}
        <DialogBox
          dialogActions
          deleteOperation
          isOpen={isDialogOpen}
          onConfirm={() => deleteProject()}
          subtitle={`${CATALOG_CONTENT.delete_message?.slice(0, 32)}${
            currentRow?.projectName
          }${CATALOG_CONTENT.delete_message?.slice(31)}`}
          onCancel={() => setIsDialogOpen(false)}
          declineCtnLabel="Cancel"
          acceptCtnLabel="Delete"
          title={`Delete ${currentRow?.projectName}`}></DialogBox>
        <DialogBox
          deleteOperation
          isOpen={isErrorDialogOpen}
          subtitle={dialogSubtitle}
          onCancel={() => onErrorDialogBoxClose()}
          title={dialogTitle}>
          <TransactionListWrapper>
            {transactionList.map((item) => (
              <TransactionListText key={uniqueId()}>{item}</TransactionListText>
            ))}
          </TransactionListWrapper>
        </DialogBox>
      </MainWrapper>
    );
  }
};

ProjectCatalog.propTypes = {
  disableVirtualization: PropTypes.bool,
};
export default ProjectCatalog;
