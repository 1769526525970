import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const sidenavContext = createContext({});
export const SideNavProvider = ({ children }) => {
  const [tab, setTab] = useState({ module: '', tab: '' });

  const value = useMemo(
    () => ({
      tab,
      setTab,
    }),
    [tab],
  );
  return (
    <sidenavContext.Provider value={value}>{children}</sidenavContext.Provider>
  );
};

SideNavProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
