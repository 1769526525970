import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as DownloadIcon } from '../../../../../../assets/icons/downloadiconblue.svg';
import { PAGE_CONTENT } from '../../PdfViewerPage/PdfViewerPage.content';
import {
  FooterWrapper,
  HeaderStyle,
  PdfDownloadButtonStyle,
  PDFViewerSx,
} from './PdfComponent.style';
import { secondaryButtonStyle } from '../PdfViewerPage.style';
import Footer from 'layout/footer/Footer.react';

const PdfComponent = ({ pdfUrl }) => {
  const handleDownloadClick = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <HeaderStyle>
        <Button sx={[secondaryButtonStyle]} onClick={handleDownloadClick}>
          <DownloadIcon style={PdfDownloadButtonStyle} />
          {PAGE_CONTENT.downloadPdf}
        </Button>
      </HeaderStyle>
      <PDFViewerSx>
        {pdfUrl && (
          <iframe
            src={`${pdfUrl}#toolbar=0`}
            width="40%"
            style={{
              border: 'none',
            }}
          />
        )}
      </PDFViewerSx>
      <FooterWrapper>
        <Footer
          isParticipantLanding={false}
          isParticipantMenu={false}
          isInActivityPage={false}
        />
      </FooterWrapper>
    </>
  );
};

PdfComponent.propTypes = {
  pdfUrl: PropTypes.string,
};

export default PdfComponent;
