import axios from 'axios';
import { getFormattedFarmData } from '../ReportingUtilFunctions';
import { SUBMIT_POI_TAB_DATA } from 'urls';
import {
  formatSubmitData,
  getActivityCategory,
} from './components/ProofOfImplementationMainComp/ProofOfImplementationMainComp.content';

export const getOperationsDataBody = (
  parentState,
  sampleId,
  farmId,
  projectId,
  participantId,
  projectCycleId,
) => {
  const { yearStatus, fossiFuelFormatted } = getFormattedFarmData(parentState);
  return {
    activityReportingStatus: 'Enrollment In Progress',
    enrolmentType: 'Activity',
    farmSampleId: sampleId,
    farmId: farmId,
    farmOperationsDataStatus: yearStatus,
    projectId: projectId,
    participantId: participantId,
    participantBaselineStatus: 'Enrollment In Progress',
    yearStatus: yearStatus,
    fossilFuels: fossiFuelFormatted,
    projectCycleId: Number(projectCycleId),
  };
};

export const submitPoiTabData = async (
  projectActivities,
  currentTab,
  currentTabData,
  projectId,
  farmId,
  currentTabStatus,
  participantId,
  annualImplementationCost,
) => {
  const currentTabTitle = projectActivities[currentTab]?.title;
  const activityIds = projectActivities
    .find((item) => item.title === currentTabTitle)
    ?.practiceTypeList.map((practiceType) => practiceType.value);

  const submitData = formatSubmitData(
    currentTabData,
    projectId,
    farmId,
    activityIds,
    getActivityCategory(projectActivities[currentTab]?.title),
    currentTabStatus,
    participantId,
    annualImplementationCost,
  );

  return axios
    .post(
      `${SUBMIT_POI_TAB_DATA}?internalProjectId=${projectId}&participantId=${participantId}&farmId=${farmId}`,
      submitData,
    )
    .then(() => {})
    .catch(() => {});
};

export const getFarmById = (farms, farmId) => {
  return farms.find((farm) => farm.farmId === farmId);
};
