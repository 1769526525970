export const ACTIVITY_TYPE = {
  recruitment: 'Recruitment',
  outreach: 'Outreach',
  contracting: 'Contracting',
  enrollment: 'Enrollment',
  baseline_reporting: 'Baseline reporting',
  activity_reporting: 'Activity reporting',
  delete: 'Delete',
  remove: 'Remove',
  set_inactive: 'Set inactive',
};

export const getActivityPracticeMapping = (activitiesList) => {
  const activityPracticeMapping = {};

  activitiesList.forEach((activity) => {
    const activityWithData = {
      projectActivityId: activity.id,
      activityCategory: activity.activityCategory,
      projectActivityType: activity.value,
      contractedUnits: '',
      incentiveRate: activity.incentiveRate,
      error: null,
    };

    if (activityPracticeMapping[activity.activityCategory]) {
      activityPracticeMapping[activity.activityCategory].push(activityWithData);
    } else {
      activityPracticeMapping[activity.activityCategory] = [activityWithData];
    }
  });

  return activityPracticeMapping;
};

export const ACTIVITY_SUBTYPE = {
  not_started: 'Not started',
  interested: 'Interested',
  contract_added: 'Contract added',
};
