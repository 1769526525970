import styled from 'styled-components';
import {
  BERLIN_BLUE,
  BLUE,
  COOL_GRAY,
  CYAN_BLUE,
  GLOBAL_BLUE,
  HOVER_LIGHT_BLUE,
  NEW_DARK_GREEN,
  NEW_HOVER_DARK_GREEN,
  QUILL_GRAY,
  RED,
  ROW_FOCUSED_BG,
  TEAL_GREEN_FOCUS,
  WHITE,
} from 'theme/GlobalColors';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as ExpandButton } from 'assets/icons/ExpandButton.svg';
import { checkTernaryCondition } from 'utils/helper';
export const MainWrapper = styled.div`
  display: flex;
  padding: ${({ padding }) => (padding ? padding : '0.5rem 0.5rem')};
  margin: ${({ margin }) => (margin ? margin : '0')};
  border: ${({ border }) => (border ? border : 'none')};
  grid-column-gap: 0.5rem;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-right: 0.5rem;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'inherit'};
  &:hover {
    background-color: ${({ isHovered }) => isHovered && HOVER_LIGHT_BLUE};
  }
  border-left: ${({ borderLeft }) => borderLeft};
`;

export const ViewMapLink = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-left: auto;
  padding-bottom: 0.5rem;
  color: ${NEW_DARK_GREEN};
  width: fit-content;
  font-size: 0.875rem;
  &:hover {
    color: ${NEW_HOVER_DARK_GREEN};
  }
  &:focus {
    color: ${TEAL_GREEN_FOCUS};
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'inherit'};
  cursor: pointer;
  padding-left: ${({ paddingLeft }) =>
    checkTernaryCondition(paddingLeft, '4.1rem', '2.6rem')};
`;

export const FieldWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ color }) => color};
  padding: 0.4rem 0.5rem;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '')};
  &:hover {
    color: #00587d;
  }
  cursor: ${({ cursor }) => cursor};
`;

export const TextInput = styled.input`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-style: ${(props) => (props.dimmed ? 'italic' : 'normal')};
  font-size: 14px;
  line-height: 19px;
  color: ${({ selected }) => (selected ? BERLIN_BLUE : GLOBAL_BLUE)};
  border: none;
  outline: none;
  height: 1.5rem;
  padding: 6px 4px;
  background-color: ${({ isHovered }) => (isHovered ? WHITE : 'transparent')};
  cursor: pointer;
  width: 95%;
  ::placeholder {
    color: ${BLUE};
  }
  &:hover {
    color: #00587d;
  }
`;

export const InputWithAdornment = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: ${WHITE};
  background-color: ${({ isFocused }) => (isFocused ? WHITE : 'transparent')};
  border: ${({ isFocused }) => (isFocused ? `1px solid ${CYAN_BLUE}` : 'none')};
  border-radius: 2px;
  box-sizing: border-box;
  min-width: 3.8rem;
  max-width: 18.75rem;
  flex: 1;
`;

export const ControlledInputWrapper = styled.div`
  flex: 1;
  box-sizing: border-box;
  min-width: 3.8rem;
  max-width: 18.75rem;
`;

export const FieldErrorWrapper = styled.span`
  font-size: 0.75rem;
  color: ${RED};
`;

export const AccordianSummaryWithMapViewLink = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const FieldLeftBorderDiv = styled.div`
  width: ${({ lastField }) =>
    checkTernaryCondition(lastField, '0.7rem', '1.6rem')};
  height: ${({ height }) => height || '100%'};
  position: absolute;
  left: -1px;
  top: ${({ farmTab }) => !farmTab && '0'};
  bottom: ${({ farmTab }) => farmTab && '0'};
  border-right: ${({ lastField }) => !lastField && `2px solid ${QUILL_GRAY}`};
  margin-left: ${({ lastField }) => lastField && '1.6rem'};
  border-left: ${({ lastField }) => lastField && `2px solid ${QUILL_GRAY}`};
  border-bottom: ${({ lastField }) => lastField && `2px solid ${QUILL_GRAY}`};
`;

export const FarmsAndFieldsWrapperPadding = '1.125rem 0.5rem 0.5rem 0.5rem';
export const arrowSx = {
  cursor: 'pointer',
};

export const iconStyles = {
  width: '1.5rem',
  height: '1.5rem',
};

export const tickIconStyle = {
  width: '1.25rem',
  height: '1.25rem',
};
export const editPenIconStyle = {
  width: '1rem',
  height: '1rem',
};

export const fullWidth = '100%';
export const fieldBorder = `1px solid ${QUILL_GRAY}`;
export const getFieldPadding = (isHovered) =>
  `0.375rem ${isHovered ? '0.8rem' : '2.8rem'} 0.375rem 2.55rem`;
export const getFieldPaddingWhenSelected = (isHovered) =>
  `0.375rem ${isHovered ? '0.8rem' : '2.8rem'} 0.375rem 2.237rem`;
//when field is not selected padding is setting here
export const getFieldBackgroundColor = (
  isHovered,
  haSelectedFieldId,
  isFarmSelected,
) => {
  if (isHovered) {
    return HOVER_LIGHT_BLUE;
  } else if (haSelectedFieldId && !isFarmSelected) {
    return ROW_FOCUSED_BG;
  } else {
    return '';
  }
};
export const getFarmBackgroundColor = (isHovered) =>
  isHovered && HOVER_LIGHT_BLUE;

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.collapsable && (
        <ExpandButton
          onClick={props.toggleAccordion}
          data-testid={'KeyboardArrowRightIcon'}
        />
      )
    }
    onClick={(event) => {
      event.stopPropagation();
      event.preventDefault();
    }}
    {...props}
  />
))((props) => ({
  flexDirection: 'row-reverse',
  backgroundColor: props.backgroundColor,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
    marginBottom: props.marginBottom || '0rem',
  },

  '&.MuiAccordionSummary-root': {
    padding: 0,
    paddingLeft: '0.875rem',
    backgroundColor: props.backgroundColor,
  },

  '.MuiAccordionSummary-content': {
    margin: 0,
  },

  '.MuiAccordionSummary-expandIconWrapper': {
    marginTop: props.iconMarginTop,
    marginBottom: props.marginBottom || '0rem',
    transform: 'rotate(-90deg)',
    marginLeft: '0.25rem',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  '&.MuiAccordionDetails-root': {
    padding: '0',
    maxHeight: 'auto',

    '::-webkit-scrollbar': {
      width: '4px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: QUILL_GRAY,
      borderRadius: '3px',
    },
  },
}));

export const viewMapIconStyle = {
  width: '1.25rem',
  height: '1.25rem',
};

export const deleteIconSx = {
  color: QUILL_GRAY,
  cursor: 'pointer',
};

export const deleteIcon = {
  position: 'absolute',
  right: '0.65rem',
  color: COOL_GRAY,
  '&:hover': {
    fill: NEW_DARK_GREEN,
  },
};

export const deleteIconHoverStyle = {
  '&.MuiSvgIcon-root': {
    ':hover': { fill: NEW_DARK_GREEN },
  },
};

export const getFarmAndFieldsPadding = (isHovered) =>
  `0.5rem ${isHovered ? '0.8rem' : '2.8rem'} 0.5rem 0.5rem`;

export const FarmHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StatusHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 12px;
  font-weight: 400;
  padding-left: 1rem;
  align-items: baseline;
  padding-bottom: 0.5rem;
  color: ${COOL_GRAY};
`;
export const HorizontalLine = styled.hr`
  color: ${QUILL_GRAY};
  background-color: ${QUILL_GRAY};
  height: 0.1rem;
  width: ${({ isField }) => checkTernaryCondition(isField, '1rem', '0.563rem')};
  margin: 0;
  position: absolute;
  left: 25px;
  border: none;
`;
export const addFieldsOnMapPadding = (isHovered) =>
  `0.51rem ${isHovered ? '0.8rem' : '2.8rem'} 0.51rem 2.5rem`;
export const getAddFieldsOnMapBackgroundColor = (isFocused) => {
  return checkTernaryCondition(isFocused, ROW_FOCUSED_BG, '');
};

export const AddFieldButtonWrapper = styled.div`
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
`;
export const projectNameStyle = (value) => ({
  width: checkTernaryCondition(value === 1, '12rem', '7rem'),
  fontWeight: '400',
  fontSize: '0.875rem',
});
