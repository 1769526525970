import React from 'react';
import PropTypes from 'prop-types';
import {
  FormLabels,
  FormFields,
  ValueWrapper,
  ListWrapper,
} from '../FormStyles';

const FieldValueComponent = ({
  label,
  value,
  isDeleted,
  isListNeeded,
  minWidth,
  fontWeight,
  color,
  labelMarginTop,
  labelMarginBottom,
  labelFontSize,
  hasNoBottomMargin,
}) => {
  return (
    <FormFields minWidth={minWidth} hasNoBottomMargin={hasNoBottomMargin}>
      <FormLabels
        fontWeight={fontWeight}
        color={color}
        marginTop={labelMarginTop}
        marginBottom={labelMarginBottom}
        fontSize={labelFontSize}>
        {label}
      </FormLabels>
      {isListNeeded ? (
        <ListWrapper isDeleted={isDeleted}>
          {value?.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ListWrapper>
      ) : (
        <ValueWrapper isDeleted={isDeleted}>{value}</ValueWrapper>
      )}
    </FormFields>
  );
};

FieldValueComponent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDeleted: PropTypes.bool,
  isListNeeded: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  labelMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNoBottomMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FieldValueComponent;
