export const ADD_USER_CONTENT = {
  first_name: 'First Name',
  first_name_placeholder: "Type user's first name",
  last_name: 'Last Name',
  last_name_placeholder: "Type user's last name",
  email: 'Email',
  email_placeholder: "Type user's email address",
  userType: 'User Type: ',
  company: 'Company',
  company_value: 'Green foods CO',
  roles_label: 'Select Role(s)',
  origination_role_label: 'Select Role',
  assets_role_label: 'Assets Role',
  column: 'column',
  roles_subtypes: [
    { value: 'Viewer', label: 'Viewer' },
    { value: 'Project Manager', label: 'Project Manager' },
  ],
  select_roles: 'Select Role',
  user_first_name: 'userFirstName',
  user_last_name: 'userLastName',
  user_email: 'userEmail',
  user_roles: 'userRoles',
  origination_roles: 'originationRoles',
  asset_roles: 'assetRoles',
  error_class_name: '.Mui-error',
  user_first_name_error: 'A first name is required to add a user.',
  user_last_name_error: 'A last name is required to add a user.',
  user_email_valid_error: 'A valid email address is required to add a user.',
  user_email_format_error:
    'An email address must follow a valid format. e.g., name@domain.com',
  duplicate_email_error:
    'Email entered already exists. Please try using a different email.',
  select_role_error: 'A role selection is required to add a user.',
  select_role_dropdown_error: 'A role selection is required to add a user.',
  add_user: 'Add user',
  outlined: 'outlined',
  primary: 'primary',
  secondary: 'secondary',
  submit_button: 'submit-button',
  contained: 'contained',
  cancel: 'Cancel',
  close_icon: 'close-icon',
  empty_string: '',
  admin: 'Admin',
  success_toast_message: ' was successfully added.',
  error_toast_message: ' failed to be added. Please try again.',
  success_toast_message_edit: ' was successfully updated.',
  error_toast_message_edit: ' failed to be updated. Please try again.',
  success: 'success',
  error: 'error',
  empty_array: [],
  edit_user: 'Edit User',
  save: 'Save',
  role_error: 'Please select at least one module role',
  none_error: 'noneError',
  none: 'None',
};
export const rolesTypes = [
  {
    itemLabel: 'Admin',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'Modules',
    selected: false,
    isDisabled: false,
  },
];
const roleNames = {
  VIEWER: 'Viewer',
  ORIGINATION_MANAGER: 'Origination Manager',
  PARTICIPANT_MANAGER: 'Participant Manager',
  ADMIN: 'Admin',
  NONE: 'None',
};

const roleKeys = {
  VIEWER: 'VIEWER',
  ORIGINATION_MANAGER: 'ORIGINATION_MANAGER',
  PARTICIPANT_MANAGER: 'PARTICIPANT_MANAGER',
  ADMIN: 'ADMIN',
};
/**
 * Converts a role key to its corresponding role name.
 *
 * @param {string} roleName - The key representing the role.
 * @returns {string} - The human-readable name of the role.
 */
export const changeRoleNames = (roleName) => {
  switch (roleName) {
    case roleKeys.VIEWER:
      return roleNames.VIEWER;
    case roleKeys.ORIGINATION_MANAGER:
      return roleNames.ORIGINATION_MANAGER;
    case roleKeys.PARTICIPANT_MANAGER:
      return roleNames.PARTICIPANT_MANAGER;
    case roleKeys.ADMIN:
      return roleNames.ADMIN;
    default:
      return roleNames.NONE;
  }
};
