import React from 'react';
import { TextWrapper } from '../OriginationSummaryTab/OriginationSummaryTab.style';
import { Container, tooltipSx } from './ParticipantIncettivesCard.style';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  NEW_DARK_GREEN,
  QUILL_GRAY,
} from 'theme/GlobalColors';
import { ACTUAL, PROJECTED, TOOLTIP_PLACEMENTS } from 'utils/config';
import PropTypes from 'prop-types';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import { CARD_CONTENT } from './ParticipantIncentivesCard.content';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import { TextAndTooltipWrapper } from '../Org_Project/Participants/StakeholderParticipantIncenticeCard/StakeholderParticipantIncentiveCard.style';

/**
 * @description This component returns the projected and actual values
 * @param {Number | undefined} value
 * @param {String} type
 * @returns {Element} - Returns the projected and actual values
 */
const ProjectedAndActualValues = ({ value, type = ACTUAL }) => {
  return (
    <Container alignItems="center" gap="0.125rem" direction="column">
      <TextWrapper
        color={checkTernaryCondition(
          type === ACTUAL,
          NEW_DARK_GREEN,
          COOL_GRAY,
        )}
        fontFamily="Open Sans"
        fontSize="1.25rem"
        fontWeight={600}>
        {`$${checkTernaryCondition(
          value === 0 || isEmpty(value),
          '--',
          value?.toFixed(2),
        )}`}
      </TextWrapper>
      <TextAndTooltipWrapper>
        <TextWrapper
          color={checkTernaryCondition(
            type === ACTUAL,
            NEW_DARK_GREEN,
            DARK_CHARCOAL,
          )}
          fontFamily="Open Sans"
          fontSize="0.75rem"
          fontWeight={400}>
          {type}
        </TextWrapper>

        <InfoTooltip
          content={checkTernaryCondition(
            type === ACTUAL,
            CARD_CONTENT.actualIncentivePayments,
            CARD_CONTENT.projectedIncentivePayments,
          )}
          placement={TOOLTIP_PLACEMENTS.TOP_START}
          customSx={tooltipSx}
        />
      </TextAndTooltipWrapper>
    </Container>
  );
};

const ParticipantIncentivesCard = ({ data }) => {
  return (
    <Container
      gap="0.5rem"
      padding="1rem"
      className="curved-border"
      backgroundColor="white"
      direction="column">
      <Container direction="row" width="100%" justifyContent="space-between">
        <Container>
          <TextWrapper color={DARK_CHARCOAL} fontSize="1rem" fontWeight={600}>
            {CARD_CONTENT.cardHeader}
          </TextWrapper>
        </Container>
      </Container>
      {/* The cost section */}
      <Container gap="0.5rem" width="100%" justifyContent="stretch">
        <Container
          width="49.13%"
          gap="0.5rem"
          padding="0rem 0.5rem 0rem 0rem"
          direction="column"
          borderRight={`0.063rem solid ${QUILL_GRAY}`}>
          <Container alignItems="center" gap="0.125rem">
            <TextWrapper
              color={DARK_CHARCOAL}
              fontFamily="Open Sans"
              fontSize="0.875rem"
              lineHeight="1.225rem"
              fontWeight={600}>
              {CARD_CONTENT.averageSubHeading}
            </TextWrapper>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.875rem"
              lineHeight="1.225rem"
              fontWeight={400}>
              {CARD_CONTENT.perAcre}
            </TextWrapper>
          </Container>
          <Container gap="0.75rem" width="100%" direction="row">
            <ProjectedAndActualValues
              value={data.averageProjectedIncentive}
              type={PROJECTED}
            />
            <ProjectedAndActualValues value={data.averageActualIncentive} />
          </Container>
        </Container>
        {/* The Second part section */}
        <Container
          width="49.13%"
          direction="column"
          gap="0.5rem"
          alignItems="flex-start">
          <Container alignItems="center" gap="0.125rem">
            <TextWrapper
              color={DARK_CHARCOAL}
              fontFamily="Open Sans"
              fontSize="0.875rem"
              lineHeight="1.225rem"
              fontWeight={600}>
              {CARD_CONTENT.totalSubHeading}
            </TextWrapper>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.875rem"
              lineHeight="1.225rem"
              fontWeight={400}>
              {CARD_CONTENT.dollar}
            </TextWrapper>
          </Container>
          <Container gap="0.75rem" width="100%" direction="row">
            <ProjectedAndActualValues
              value={data.totalProjectedIncentive}
              type={PROJECTED}
            />
            <ProjectedAndActualValues value={data.totalActualIncentive} />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

ParticipantIncentivesCard.propTypes = {
  data: PropTypes.object,
};

ProjectedAndActualValues.propTypes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default ParticipantIncentivesCard;
