import {
  DOWNLOAD_ENROLLMENT_BASELINE_REPORT,
  SEND_BASELINE_REOPEN_EMAIL,
} from 'urls';

import axios from 'axios';
import { checkTernaryCondition } from 'utils/helper';
import { pathName } from 'Routes/routes.const';

export const baselineReview = (participantId, projectId) => ({
  participantId,
  projectId,
  title: 'Review baseline reporting',
  contentText:
    'Please access the baseline reporting responses to review and make your approval decision once complete.',
  firstSubmitBtnLabel: 'Approve baseline reporting',
  secondSubmitBtnLabel: 'Reopen baseline reporting',
  cancelBtnLabel: 'Cancel',
  firstSubmitBtnCb: () => {},
  secondSubmitBtnCb: () => {},
  cancelCb: () => {},
  modalCloseCb: () => {},
  modalData: { flag: false },
  reviewComponent:
    'Please access the baseline reporting responses to review and make your approval decision once complete.',
  reviewButtonText: 'Review baseline reporting',
  fetchInitialDataCb: () =>
    axios
      .get(
        DOWNLOAD_ENROLLMENT_BASELINE_REPORT.replace(
          ':participnatId',
          participantId,
        ).replace(':projectId', projectId),
      )
      .then((response) => response.data.reportLink),
  reOpenProp: baselineReOpen(participantId, projectId),
});

export const baselineReOpen = (participantId, projectId) => ({
  participantId,
  projectId,
  title: 'Reopen baseline',
  contentText: (participantName) =>
    `How would you like to reopen ${checkTernaryCondition(
      participantName,
      `${participantName}'s`,
      ' ',
    )} baseline reporting?`,
  emailPlaceholder: (projectName, projectCycleYear) =>
    `Your baseline reporting for the ${projectName} ${projectCycleYear} has been reopened. Someone will be in contact with you to discuss next steps. Please access the baseline reporting request linked below to proceed.`,
  enrollmentCta: 'Reporting request link',
  customEmailMessage: '',
  isOnBehalf: false,
  onReopenSubmit: (
    customEmailMessage,
    isOnBehalf,
    setIsLoading,
    closeModal,
    instanceId,
    actionId,
    contractId,
    updateParticipantStatus,
    // TODO: Use this while integrating project cycle for baseline
    projectCycleId,
    updateParticipantBaselineStatus,
    baselineActionId,
    formId,
    activityId,
    ishandleApprove = false,
    handleApprove = () => {},
  ) => {
    setIsLoading(true);
    axios
      .post(
        `${SEND_BASELINE_REOPEN_EMAIL}?radioSelection=${isOnBehalf}&baselineEdgeCase=${ishandleApprove}`,
        {
          internalProjectId: projectId,
          participantId: participantId,
          approvalDescription: customEmailMessage,
          projectCycleId: projectCycleId,
        },
      )
      .then(() => {
        updateParticipantBaselineStatus({
          actionId: baselineActionId,
          formId: formId,
          projectCycleId,
        }).then(() => {
          if (ishandleApprove) {
            handleApprove();
          }
        });
        if (!isOnBehalf)
          window.open(
            `${pathName.participant.baselineMenuPage}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`,
            '_blank',
          );
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  },
});
