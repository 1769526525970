export const SIDENAV_CONTENT = {
  project_button: 'Add Project',
  project_form_route: '/projects/add',
  reports_route: '/reports',
  portfolio_route: '/portfolio',
};
export const menuList = ['Insetting', 'Origination'];
export const menuItemType = [
  'Portfolio',
  'Reports',
  'Projects',
  'NotPortfolio',
  'NotProjects',
  'isCollapsed',
];
export const projectMenus = {
  summary: 'Summary',
  participants: 'Participants',
  calculations: 'Calculations',
  soilSampling: 'Soil sampling',
  settings: 'Settings',
};
export const isOfParticularType = (header, type) => {
  return header.toLowerCase() === type.toLowerCase();
};
export const pathVariables = () => {
  return window.location.pathname.split('/');
};
