import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_GREEN,
  FOCUSED_BORDER,
  NEW_DARK_GREEN,
  NEW_FOCUS_DARK_GREEN,
  NEW_HOVER_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  QUILL_GRAY,
  TEAL_GREEN_FOCUS,
  TEAL_GREEN_HOVER,
  WHITE,
} from 'theme/GlobalColors';

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const cancelBtnSx = {
  height: '2.25rem',
  textTransform: 'none',
  backgroundColor: 'transparent',
  color: NEW_DARK_GREEN,
  fontWeight: 400,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: NEW_HOVER_DARK_GREEN,
  },

  '&:focus': {
    color: TEAL_GREEN_FOCUS,
  },
};

export const saveBtnSx = {
  height: '2.25rem',
  textTransform: 'none',
  border: `1px solid ${NEW_DARK_GREEN}`,
  background: WHITE,
  color: NEW_DARK_GREEN,
  padding: '0.344rem 1rem',
  fontWeight: 400,

  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
    padding: '4.5px 15px',
  },
  '&.Mui-disabled': {
    color: COOL_GRAY,
    backgroundColor: QUILL_GRAY,
    border: 'none',
  },
};

export const continueBtnSx = {
  textTransform: 'none',
  backgroundColor: NEW_DARK_GREEN,
  height: '2.25rem',
  color: WHITE,
  fontWeight: 400,

  '&:hover': {
    backgroundColor: DARK_GREEN,
    color: WHITE,
  },
  '&:focus': {
    backgroundColor: NEW_FOCUS_DARK_GREEN,
    border: `2px solid ${FOCUSED_BORDER}`,
    padding: '4.5px 14.5px',
    color: WHITE,
  },
  '&.Mui-disabled': {
    color: COOL_GRAY,
    backgroundColor: QUILL_GRAY,
    border: 'none',
  },
};
