import styled from 'styled-components';
import {
  COOL_GRAY,
  QUILL_GRAY,
  DARK_GREEN,
  DARK_CHARCOAL,
} from 'theme/GlobalColors';

export const buttonStyle = (formHasError) => {
  return {
    textTransform: 'none',
    height: '1.75rem',
    backgroundColor: formHasError ? QUILL_GRAY : DARK_GREEN,
    color: formHasError ? COOL_GRAY : 'white',
    '&:hover': {
      backgroundColor: formHasError ? QUILL_GRAY : DARK_GREEN,
      color: formHasError ? COOL_GRAY : 'white',
    },
  };
};

export const AddUserButtonStyle = {
  position: 'absolute',
  top: '1rem',
  right: '2.125rem',
};
export const UserTypeWrapper = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  font-family: 'Open Sans';
  font-style: 'normal';
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
`;

export const ErrorWrapper = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  font-family: 'Open Sans';
  font-style: 'normal';
`;

export const DialogTitleSx = {
  fontSize: '20px',
  color: DARK_CHARCOAL,
  fontWeight: 400,
  padding: 0,
};

export const DialogContentSx = {
  padding: 0,
  marginTop: '1.5rem',
  gap: '0.5rem',
  overflowY: 'hidden',
};

export const paperRootSx = {
  '& .MuiPaper-root': {
    padding: '1.5rem',
    borderRadius: '2px',
    height: 'auto',
    width: '36rem',
  },
};

export const cancelButtonStyle = {
  padding: '0rem',
};
