import styled from 'styled-components';
import { DARK_CHARCOAL, DARK_GREEN } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '37.5rem',
      padding: '1.5rem 1.5rem 1rem 1.5rem',
      maxHeight: '31.25rem',
      overflowY: 'auto',
    },
  },
};
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.75rem;
`;
export const AddParticipantWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 30rem;
  width: 28rem;
  text-overflow: ellipsis;
  height: 1.75rem;
`;
export const IconButtonStyle = { padding: 0 };
export const DialogTitleStyle = {
  fontSize: '20px',
  padding: '0rem',
  color: DARK_CHARCOAL,
  fontWeight: 400,
};
export const DialogContentStyle = {
  marginBottom: '0rem',
  padding: '0rem',
  overflowX: 'hidden',
};
export const DialogActionStyle = {
  justifyContent: 'flex-end',
  gap: '0.5rem',
  padding: '0 0.5rem 0.5rem 0.5rem',
  marginTop: '1.5rem',
};
export const EnterDataLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin-top: 1.5rem;

  /* Grays/Cool Gray 10 */

  color: #63666a;
`;

export const NameOptionStyle = {
  display: 'flex',
  flexDirection: 'row',
};
export const LastNamestyle = {
  paddingLeft: '0.2rem',
};
export const ButtonStyle = {
  textTransform: 'none',
  color: 'white',
  background: DARK_GREEN,
  '&:hover': {
    background: DARK_GREEN,
    color: 'white',
  },
  padding: '0.625rem 1rem',
  height: '2.25rem',
};
export const saveButtonStyle = (editMode) => {
  return {
    textTransform: 'none',
    padding: checkTernaryCondition(editMode, '6px 16px', '5.5px 16px'),
    color: checkTernaryCondition(editMode, 'white', DARK_GREEN),
    border: checkTernaryCondition(editMode, 'none', `1px solid ${DARK_GREEN}`),
    background: checkTernaryCondition(editMode, DARK_GREEN, 'white'),
    '&:hover': {
      color: checkTernaryCondition(editMode, 'white', DARK_GREEN),
      background: checkTernaryCondition(editMode, DARK_GREEN, 'white'),
    },
  };
};

export const InputWrapper = styled.div`
  display: flex;
  gap: 0rem;
`;

export const OptionItemStyle = {
  fontWeight: 'bold',
  whiteSpace: 'pre',
};
