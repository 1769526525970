import styled from 'styled-components';
import {
  COOL_GRAY,
  BLUISH_CYAN,
  WHITE,
  QUILL_GRAY,
  DARK_CHARCOAL,
  NEW_HOVER_GREEN_BACKGROUND,
  NEW_FOCUS_DARK_GREEN,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
export const RowCellWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
`;
export const CellValueWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20rem;
`;

export const acceptAllBtnStyle = {
  fontSize: '0.875rem',
  textTransform: 'none',
  maxheight: '1rem',
  border: `1px solid ${BLUISH_CYAN}`,
  background: WHITE,
  color: BLUISH_CYAN,
  '&.MuiButton-root.Mui-disabled': {
    color: COOL_GRAY,
    background: QUILL_GRAY,
    border: 'none',
  },
  '&.MuiButton-root.reopen': {
    width: '4.125rem',
  },
  '&.MuiButton-root.approve': {
    width: '4.5rem',
  },
  '&:hover': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
  },
};

export const TextWrapper = styled.div`
  display: ${({ display }) => display || 'flex'};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const countStyle = {
  display: 'inline',
  color: DARK_CHARCOAL,
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Open Sans',
  lineHeight: '19.6px',
  textAlign: 'left',
};

export const statusStyle = {
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-end',
  alignItems: 'center',
};
export const StatusTextWrapper = styled.div`
  display: inline;
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  text-align: left;
  color: ${COOL_GRAY};
`;

export const DataGridWrapper = styled.div`
  width: 100%;
  height: 82vh;
`;

export const datagridSx = (hasNoScroll) => {
  const scrollSx = checkTernaryCondition(hasNoScroll, { display: 'none' }, {});
  return {
    height: '64vh',
    backgroundColor: '#FFFFFF',
    width: 'auto',
    marginTop: '0.375rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
    '& .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '0.875rem',
      lineHeight: '140%',
      color: '#333333',
    },
    '& .MuiDataGrid-cellContent': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '1.188rem',
      color: '#333333',
    },
    borderBottom: 0,
    borderRadius: 0,
    borderRight: 0,
    borderLeft: 0,
    '& .MuiDataGrid-virtualScrollerContent': {
      borderBottom: '1px solid #D0D0CE',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      marginLeft: '2px',
      visibility: 'visible',
      width: 'auto',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': scrollSx,
  };
};
export const ImgAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  height: 100px;
  box-sizing: border-box;
  gap: 8px;
`;

export const ImgTag = styled.img`
  display: block;
  width: 63.36px;
  height: 32px;
  border-bottom-left-radius: 2px;
`;

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: center;
  justify-content: flex-start;
`;
export const FarmFieldWrapper = styled.div`
  align-items: start;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: center;
`;

export const ImplemnetedOnBoxSx = {
  width: 'auto',
  display: 'flex',
  height: '1.75rem',
  alignItems: 'center',
  padding: '0rem 0.5rem 0rem 1rem',
  borderBottom: `1px solid ${QUILL_GRAY}`,
};

export const ImplementedOnTypographySx = {
  color: COOL_GRAY,
  fontSize: '0.875rem',
  lineHeight: '19.6px',
};

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const poiCheckBoxStyle = { display: 'flex', alignItems: 'center' };
