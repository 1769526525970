import styled from 'styled-components';
import {
  PAGE_BACKGROUND_COLOR,
  DARK_CHARCOAL,
  WHITE,
  QUILL_GRAY,
  GLOBAL_BLUE,
  COOL_GRAY,
} from '../../theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapper = styled.div`
  background-color: ${PAGE_BACKGROUND_COLOR};
  display: flex;
`;
export const FormWrapper = styled.div`
  background-color: ${WHITE};
  width: 100%;
  box-shadow: 0px 0.125rem 0.188rem rgba(0, 0, 0, 0.1);
`;
export const FormSection = styled.div`
  width: auto;
  max-width: 34.5rem;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  height: fit-content;
  position: relative;
`;
export const FormHeading = styled.div`
  margin-bottom: 1rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
  display: flex;
  justify-content: space-between;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 1.5rem;

  & > :first-child {
    margin-right: 1rem;
    padding: 0.625rem 1rem;
  }

  & > :nth-child(2) {
    padding: 0.625rem 1rem;
  }
`;
export const scrollStyle = { behavior: 'smooth', block: 'center' };
export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const ProjectHeadingWrapper = styled.div`
  margin: 0px;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: 'nowrap';
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.00938em;
  margin-bottom: 1rem;
  color: #333333;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  gap: 4px;
  height: 19px;
  background: #d0d0ce;
  border-radius: 4px;
  font-size: 11px;
  position: absolute;
  top: -3rem;
  right: 0;
`;

export const dropdownProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

export const instructionTextStyles = {
  maxWidth: '25rem',
};

export const breadcrumbStyles = {
  fontSize: '0.875rem',
  color: DARK_CHARCOAL,
  fontWeight: '400',
};

export const linkStyles = {
  textDecoration: 'none',
  color: GLOBAL_BLUE,
  fontSize: '0.875rem',
};

export const projectNameStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '15rem',
};

export const breadcrumbSx = {
  '& .MuiBreadcrumbs-separator': {
    margin: 0,
  },
};

export const dividerSx = { marginBottom: '1rem', borderColor: QUILL_GRAY };

export const checkboxPadding = '0rem 0.5rem 0rem 0.563rem';

export const autoCompleteSx = (isInput) => {
  return {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& input': {
        fontSize: '0.875rem',
      },
    },
    '& .MuiInput-root': {
      paddingBottom: isInput && '0.188rem',
    },
    '& .MuiInputBase-root': {
      '& #checkboxes-tags-demo, #tags-filled': {
        fontSize: '0.875rem',
        color: COOL_GRAY,
        padding: checkTernaryCondition(
          isInput,
          '0rem 0rem 0rem 0.25rem',
          '0.25rem 0rem 0.25rem 0.5rem',
        ),
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.688rem',
      margin: 0,
    },
  };
};
export const formLabelCustomSx = {
  marginRight: '0rem',
};
export const formLabelTooltipCustomSx = {
  paddingTop: '0rem',
};
