import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import ProTypes from 'prop-types';
import { FORM_CONTENT } from 'components/FormComponents/FormComponents.content';
import {
  RadioButtonStyle,
  RadioIconStyle,
} from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import { useContext, useEffect, useState } from 'react';

import {
  cancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogTitleStyle,
  IconButtonStyle,
  secondSubmitBtnStyle,
} from 'pages/ParticipantDataCollection/components/EnrollmentCompleteModal/EnrollmentCompleteModal.style';
import CloseIcon from '@mui/icons-material/Close';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import axios from 'axios';
import { PARTICIPANT_EMAIL_DETAILS, PARTICIPANT_REPORT_FIRST_PART } from 'urls';
import {
  EmailTextArea,
  EmailText,
  EmailLink,
  EditableTextArea,
  ReviewText,
  RadioBtnGroupSx,
  reopenDialogTitleStyle,
  reopenDialogContentStyle,
  reopenDialogActionStyle,
} from './Participants.style';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { closeIconSx } from 'theme/GlobalStyles';
import { loaderContext } from 'contextAPI/loaderContext';
import { checkTernaryCondition } from '../../../../utils/helper';
import { getActionIdFromActionCode, LABELS } from './Participants.content';
import { Link } from 'react-router-dom';
import { USER_STATUS } from 'utils/config';
import { ConfigurationContext } from 'contextAPI/configurationContext';

export const ReOpenTemplate = ({ reopenProps, setReopenProps }) => {
  const [isRequestSent, setRequestSent] = useState(false);
  const [formattedContext, setFormattedContext] = useState('');
  const [additionalContext, setAdditionalContext] = useState('');
  const [formattedEmailPlaceholder, setFormattedEmailPlaceholder] =
    useState('');
  const [participantEmailInfo, setParticipantEmailInfo] = useState(null);
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${PARTICIPANT_EMAIL_DETAILS}?participantId=${reopenProps.participantId}&projectId=${reopenProps.projectId}`,
      )
      .then((res) => res.data)
      .then((res) => setParticipantEmailInfo(res));
  }, []);

  useEffect(() => {
    setFormattedContext(
      reopenProps.contentText(participantEmailInfo?.participantName),
    );
    setAdditionalContext(reopenProps.additionalContextText || null);
    setFormattedEmailPlaceholder(
      reopenProps.emailPlaceholder(
        participantEmailInfo?.projectName,
        participantEmailInfo?.reportingYear,
      ),
    );
  }, [participantEmailInfo]);

  useEffect(() => {
    if (reopenProps?.modalData?.flag && reopenProps?.participantId) {
      axios
        .get(
          `${PARTICIPANT_REPORT_FIRST_PART}?participantId=${reopenProps.participantId}`,
        )
        .then((response) => {
          const userStatus = response.data.userStatus;
          setUserStatus(userStatus);
        });
    }
  }, [reopenProps?.modalData?.flag]);

  const changeReopenSelection = (e) => {
    setRequestSent(e.target.value === 'true');
    setReopenProps((previous) => ({
      ...previous,
      isOnBehalf: e.target.value === 'true',
      isValueSelected: true,
    }));
  };

  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    setReopenProps((previous) => ({
      ...previous,
      customEmailMessage: e.target.value,
    }));
  };

  return (
    <>
      {additionalContext && <ReviewText>{additionalContext}</ReviewText>}
      <ReviewText>{formattedContext}</ReviewText>
      <FormControl>
        <RadioGroup
          sx={RadioBtnGroupSx}
          row
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={changeReopenSelection}
          name="radio-buttons-group">
          <FormControlLabel
            sx={RadioButtonStyle(null)}
            value={true}
            control={
              <Radio size={FORM_CONTENT.small} sx={RadioIconStyle(true)} />
            }
            label={LABELS.SEND_REQUEST_WITH_MESSAGE}
            disabled={userStatus !== USER_STATUS.STATUS.ACTIVE}
          />
          <FormControlLabel
            sx={RadioButtonStyle(null)}
            value={false}
            control={
              <Radio size={FORM_CONTENT.small} sx={RadioIconStyle(true)} />
            }
            label={
              reopenProps.customReopenRadioLabel ?? LABELS.REOPEN_ON_BEHALF
            }
          />
        </RadioGroup>
      </FormControl>
      {isRequestSent && (
        <>
          <ReviewText margin="1rem 0rem 0.25rem 0rem">
            {LABELS.APPROVAL_TEXT}
          </ReviewText>

          <EmailTextArea>
            <EmailText>
              {participantEmailInfo?.participantName},
              <br />
              <br />
              {formattedEmailPlaceholder}
              <br />
              <br />
              <EditableTextArea
                placeholder={LABELS.DESCRIPTION_CONTENT}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyDown}></EditableTextArea>
              <br />
            </EmailText>
            <Link to="/" target="_blank" rel="noopener noreferrer">
              <EmailLink>{reopenProps?.enrollmentCta}</EmailLink>
            </Link>
            <EmailText>
              {LABELS.CONTACT_SUPPORT}
              <br />
              {LABELS.THANK_YOU_TEXT}
              <br />
              {participantEmailInfo?.projectDeveloperName}
              <br />
              {participantEmailInfo?.companyName}
            </EmailText>
          </EmailTextArea>
        </>
      )}
    </>
  );
};

ReOpenTemplate.propTypes = {
  reopenProps: ProTypes.shape({
    contentText: ProTypes.func,
    additionalContextText: ProTypes.string,
    emailPlaceholder: ProTypes.func,
    participantId: ProTypes.string,
    projectId: ProTypes.string,
    customReopenRadioLabel: ProTypes.string,
    enrollmentCta: ProTypes.string,
    modalData: ProTypes.object,
    isOnBehalf: ProTypes.bool,
    isValueSelected: ProTypes.bool,
    customEmailMessage: ProTypes.string,
    onReopenSubmit: ProTypes.func,
    instanceId: ProTypes.string,
    contractId: ProTypes.string,
    projectCycleId: ProTypes.string,
    formId: ProTypes.string,
    ishandleApprove: ProTypes.bool,
    handleApprove: ProTypes.func,
  }),
  setReopenProps: ProTypes.func,
};

export const ReopenCustomModal = ({
  reopenProps,
  setReopenProps,
  statusMappingList,
  onClose = () => {},
}) => {
  const {
    reOpenStatus,
    updateParticipantStatus,
    updateParticipantBaselineStatus,
  } = useContext(LifeCycleContext);
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);
  const { setIsLoading } = useContext(loaderContext);

  const closeModal = () => {
    setReopenProps((previous) => ({
      ...previous,
      modalData: { flag: false },
    }));
    setAreUiActionInProgress((prev) => prev - 1);
  };
  return (
    <Dialog open={reopenProps.modalData.flag} onClose={() => {}} maxWidth="md">
      <IconButton
        sx={IconButtonStyle}
        onClick={() => {
          setReopenProps((previous) => ({
            ...previous,
            modalData: { flag: false },
          }));
          setAreUiActionInProgress((prev) => prev - 1);
        }}
        data-testid="close-icon">
        <CloseIcon sx={closeIconSx} />
      </IconButton>

      <DialogTitle sx={{ ...DialogTitleStyle, ...reopenDialogTitleStyle }}>
        {reopenProps.title}
      </DialogTitle>
      <DialogContent
        sx={{ ...DialogContentStyle, ...reopenDialogContentStyle }}>
        <ReOpenTemplate
          reopenProps={reopenProps}
          setReopenProps={setReopenProps}
        />
      </DialogContent>
      <DialogActions
        style={{ ...DialogActionStyle, ...reopenDialogActionStyle }}>
        <Button
          sx={[cancelBtnStyle, tertiaryButtonStyle]}
          onClick={() => {
            setReopenProps((previous) => ({
              ...previous,
              modalData: { flag: false },
            }));
            setAreUiActionInProgress((prev) => prev - 1);
          }}>
          Cancel
        </Button>
        <Button
          disabled={!reopenProps?.isValueSelected}
          sx={[secondSubmitBtnStyle, primaryButtonStyle()]}
          onClick={() => {
            const baselineActionId = checkTernaryCondition(
              !reopenProps.isOnBehalf,
              getActionIdFromActionCode(
                'BASELINE-REOPEN-ON-BEHALF',
                statusMappingList,
              ),
              getActionIdFromActionCode(
                'BASELINE-REOPEN-SEND-REQ-PRTCPNT',
                statusMappingList,
              ),
            );
            const actionId = checkTernaryCondition(
              !reopenProps.isOnBehalf,
              getActionIdFromActionCode(
                'ENROLL-REOPEN-ON-BEHALF',
                statusMappingList,
              ),
              getActionIdFromActionCode(
                'ENROLL-REOPEN-SEND-REQ-PRTCPNT',
                statusMappingList,
              ),
            );
            const activityId = checkTernaryCondition(
              !reopenProps.isOnBehalf,
              getActionIdFromActionCode(
                'ACTIVITY-REOPEN-ON-BEHALF',
                statusMappingList,
              ),
              getActionIdFromActionCode(
                'ACTIVITY-REOPEN-SEND-REQ-PRTCPNT',
                statusMappingList,
              ),
            );
            reOpenStatus(reopenProps);
            reopenProps.onReopenSubmit(
              reopenProps.customEmailMessage,
              reopenProps.isOnBehalf,
              setIsLoading,
              () => {
                closeModal();
                onClose();
              },
              // Note: instanceId is only used in enrollment reopen, not used in baseline reopen
              reopenProps.instanceId,
              actionId,
              reopenProps.contractId,
              updateParticipantStatus,
              reopenProps.projectCycleId,
              updateParticipantBaselineStatus,
              baselineActionId,
              reopenProps.formId,
              activityId,
              reopenProps.ishandleApprove,
              reopenProps.handleApprove,
            );
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReopenCustomModal.propTypes = {
  reopenProps: ProTypes.shape({
    title: ProTypes.string,
    contentText: ProTypes.func,
    additionalContextText: ProTypes.string,
    emailPlaceholder: ProTypes.func,
    participantId: ProTypes.string,
    projectId: ProTypes.string,
    customReopenRadioLabel: ProTypes.string,
    enrollmentCta: ProTypes.string,
    modalData: ProTypes.object,
    isOnBehalf: ProTypes.bool,
    isValueSelected: ProTypes.bool,
    customEmailMessage: ProTypes.string,
    onReopenSubmit: ProTypes.func,
    instanceId: ProTypes.string,
    contractId: ProTypes.string,
    projectCycleId: ProTypes.string,
    formId: ProTypes.string,
    ishandleApprove: ProTypes.bool,
    handleApprove: ProTypes.func,
  }),
  setReopenProps: ProTypes.func,
  statusMappingList: ProTypes.array,
  onClose: ProTypes.func,
};
