import moment from 'moment';

export const constructCycleData = (projectCycleList) => {
  const cycleData = projectCycleList.map((cycle, index) => {
    return {
      id: cycle.projectCycleId,
      startDate: moment(cycle.cycleStartDate).format('MM/DD/YYYY'),
      endDate: moment(cycle.cycleEndDate).format('MM/DD/YYYY'),
      cycleYear: cycle.reportingYear.toString(),
    };
  });
  return cycleData;
};
