import axios from 'axios';
import { SettingsContext } from 'contextAPI/settings';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  SAMLAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { SESSION_LOGOUT } from 'urls';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const useFirebaseSignIn = () => {
  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  let idleTimeout = 1000 * 60 * 28; //28 minute
  let warningTimeout = 1000 * 60 * 2; //2 minute
  let idleLogout = idleTimeout + warningTimeout; //Ideltime + warning time (2 Minutes)
  let idleEvent = null;
  let idleLogoutEvent = null;

  const [idleModal, setIdleModal] = useState(false);
  const navigation = useNavigate();

  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = () => {
    if (idleEvent) clearTimeout(idleEvent);
    if (idleLogoutEvent) clearTimeout(idleLogoutEvent);

    idleEvent = setTimeout(() => setIdleModal(true), idleTimeout); //show session warning modal.
    idleLogoutEvent = setTimeout(() => handleLogout(), idleLogout); //Call logged out on session expire.
  };

  function removeAllEventListeners() {
    for (let e in events) {
      window.removeEventListener(events[e], sessionTimeout);
      clearTimeout(idleEvent);
      clearTimeout(idleLogoutEvent);
    }
  }

  function addAllEventListener() {
    for (let e in events) {
      window.addEventListener(events[e], sessionTimeout);
    }
  }

  const settings = useContext(SettingsContext);

  const firebaseConfig = {
    apiKey: settings.firebaseApiKey,
    authDomain: settings.firebaseAuthDomain,
    authProvider: settings.authProvider,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new SAMLAuthProvider(`saml.${firebaseConfig.authProvider}`);

  const handleLogin = () => {
    if (settings.isDevEnvironment) {
      return signInWithPopup(auth, provider);
    } else {
      signInWithRedirect(auth, provider);
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
    removeAllEventListeners();
    if (!settings.isDevEnvironment) {
      await axios.post(SESSION_LOGOUT, {});
    }
    sessionStorage.clear();
    navigation('/logout', { replace: true });
    setIdleModal(false);
  };

  return {
    handleLogin,
    app,
    handleLogout,
    addAllEventListener,
    removeAllEventListeners,
    idleModal,
    setIdleModal,
    warningTimeout,
  };
};
