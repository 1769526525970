export const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const transformOrigin = { vertical: 'top', horizontal: 'right' };

export const MENUITEM = {
  users: 'Users',
  configuration: 'Configuration',
  billing: 'Billing',
};
