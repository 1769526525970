import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import {
  BasicWrapper,
  TextWrapper,
  inputStyle,
} from '../DateRangePicker.style';
import CustomDay from './CustomDatePicker.react';
import { PAGE_CONTENT } from '../DateRangePicker.content';
import dayjs from 'dayjs';
import { textFieldStyle } from './CustomDatePicker.style';

const CalendarElement = ({
  date,
  text,
  border,
  otherDate,
  isFromDate,
  setFromDate,
  setToDate,
  fromDate,
  toDate,
}) => {
  const [formattedDate, setFormattedDate] = useState(
    dayjs(date).format('MM/DD/YYYY'),
  );
  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setFormattedDate(dateValue);

    const year = dateValue.split('/')[2];
    if (!dayjs(dateValue).isValid() || year?.length !== 4) return;

    const formattedDateString = dayjs(dateValue).format('YYYY-MM-DD');
    if (isFromDate) {
      setFromDate(formattedDateString);
    } else {
      setToDate(formattedDateString);
    }
  };

  const updateFormattedDate = (dateValue) => {
    setFormattedDate(dateValue);
  };

  return (
    <BasicWrapper direction={PAGE_CONTENT.column} gap="1rem">
      <BasicWrapper alignment="center" gap="0.5rem">
        <TextWrapper fontSize="0.9rem" fontWeight="400">
          {text}
        </TextWrapper>
        <TextField
          data-testid="date-field"
          variant={PAGE_CONTENT.outlined}
          id="input-with-icon-adornment"
          value={formattedDate}
          InputProps={{
            sx: inputStyle,
          }}
          onChange={handleDateChange}
          sx={textFieldStyle}
        />
      </BasicWrapper>
      <div style={{ borderRight: border }}>
        <CustomDay
          data-testid="calendar"
          date={dayjs(otherDate)}
          isFromDate={isFromDate}
          dateSetter={isFromDate ? setFromDate : setToDate}
          fromDate={fromDate}
          toDate={toDate}
          updateFormattedDate={updateFormattedDate}
        />
      </div>
    </BasicWrapper>
  );
};

CalendarElement.propTypes = {
  date: PropTypes.instanceOf(Date),
  text: PropTypes.string,
  border: PropTypes.bool,
  otherDate: PropTypes.instanceOf(Date),
  isFromDate: PropTypes.bool,
  setFromDate: PropTypes.func,
  setToDate: PropTypes.func,
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
};

export default CalendarElement;
