import { Title } from './ActivityDeleteModal.style';
import PropTypes from 'prop-types';

export const ModalTitle = ({ activityTitle }) => (
  <Title>{`Do you want to delete ${activityTitle} data below?`}</Title>
);

export const ModalContent = ({ activityTitle }) => (
  <>{`You've entered data for ${activityTitle} below that may not be needed anymore if the activity was not implemented. `}</>
);

ModalTitle.propTypes = {
  activityTitle: PropTypes.string.isRequired,
};

ModalContent.propTypes = {
  activityTitle: PropTypes.string.isRequired,
};
