import React from 'react';
import EnrollmentModal from 'pages/OriginationParticipantsList/EnrollmentModal/EnrollmentModal.react';
import { ReviewModal } from 'pages/Origination/Org_Project/Participants/ReviewTemplate';
import { useActivityContext } from 'containers/ActivityListContainer';
import { isEmpty } from 'utils/helper';

export const EnrollmentActivityAction = () => {
  const {
    enrollModalData,
    setEnrollModalData,
    statusMappingList,
    reviewEnrollmentProps,
    setReviewEnrollmentProps,
  } = useActivityContext();

  if (isEmpty(statusMappingList)) return null;

  return (
    <>
      <EnrollmentModal
        modalData={enrollModalData}
        setModalData={setEnrollModalData}
        refreshHandler={() => {
          /* do nothing here */
        }}
        statusMappingList={statusMappingList}
        data-testid="enrollment-modal"
      />
      <ReviewModal
        reviewEnrollmentProps={reviewEnrollmentProps}
        setReviewEnrollmentProps={setReviewEnrollmentProps}
      />
    </>
  );
};
