export const MODAL_CONTENT = {
  heading: 'Soil amendments',
  soil_amendments_type: 'Soil amendments type',
  select_type: 'Select type',
  amount_applied: 'Amount applied',
  type_amount: 'Type amount',
  unit_of_measure: 'Unit of measure',
  amount_applied_error: 'Amount applied is required',
  soil_amendments_type_error: 'Soil amendments type selection is required.',
  unit_of_measure_error: 'Unit of measure selection is required.',
  submit: 'Submit',
  cancel: 'Cancel',
  thirteenPointSevenFiveRem: '13.75rem',
  eightPointSevenFiveRem: '8.75rem',
  sixPointTwoFiveRem: '6.25rem',
  tenRem: '10rem',
  hundred_precent: '100%',
  dropdownHeight: '2rem',
  zeroPointFiveRem: '0.5rem',
  onePointFiveRem: '1.5rem',
  zeroPointTwoFiveRem: '0.25rem',
  zeroPointSevenFiveRem: '0.75rem',
  zeroRem: '0rem',
  oneRem: '1rem',
  UOM_NAME: 'unitOfMeasure',
};

export const initialSoilAmendmentsTypeData = {
  sampleId: 0,
  soilAmendmentsType: '',
  amountApplied: '',
  unitOfMeasure: '',
  id: '',
};

export const soilAmendmentsTypeDropdownList = [
  { label: 'Limestone', value: 'Limestone' },
  { label: 'Dolomite', value: 'Dolomite' },
];

export const initialSoilAmendmentsErrorState = {
  soilAmendmentsTypeError: false,
  amountAppliedError: false,
  uomError: false,
};

export const unitOfMeasureDropdownList = (list) => {
  return list.map((item) => ({
    itemLabel: item.uomNameDisplay,
    value: item.uomId,
    uomId: item.uomId,
    uomNameFull: item.uomNameFull,
  }));
};

export const errorSetter = (formValue) => {
  if (
    formValue.soilAmendmentsType !== '' ||
    formValue.amountApplied !== '' ||
    formValue.unitOfMeasure !== ''
  ) {
    const errorObj = {
      soilAmendmentsTypeError: formValue.soilAmendmentsType === '',
      amountAppliedError: formValue.amountApplied === '',
      uomError: formValue.unitOfMeasure === '',
    };
    return errorObj;
  }
};
