import { Container } from '@mui/material';
import { SETTINGS_CONTENT } from './SettingsPage.content';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';
import UserList from 'pages/UserManagement/UserList.react';
import { listViewStyle } from 'pages/OriginationProjectList/OriginationProjectList.style';

const SettingsPage = () => {
  return (
    <Container maxWidth={false}>
      <ListViewHeader
        title={`${SETTINGS_CONTENT.setting} | ${SETTINGS_CONTENT.users}`}
        noButton
        style={listViewStyle}
      />

      <UserList />
    </Container>
  );
};

export default SettingsPage;
