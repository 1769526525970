import styled from 'styled-components';
import { DARK_CHARCOAL, DARK_GREEN } from 'theme/GlobalColors';

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '37.5rem',
      padding: '1.5rem 1.5rem 1rem 1.5rem',
      overflowY: 'auto',
    },
  },
};
export const DialogActionStyle = {
  justifyContent: 'flex-end',
  padding: 0,
};

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.75rem;
`;
export const DialogTitleStyle = {
  fontSize: '20px',
  padding: '0rem',
  color: DARK_CHARCOAL,
  fontWeight: 400,
};

export const IconButtonStyle = { padding: 0 };

export const ButtonStyle = {
  textTransform: 'none',
  color: 'white',
  background: DARK_GREEN,
  '&:hover': {
    background: DARK_GREEN,
    color: 'white',
  },
  padding: '0.625rem 1rem',
  height: '2.25rem',
};

export const DialogContentStyle = {
  marginBottom: '0rem',
  padding: '0rem',
  overflowX: 'hidden',
};

export const ParticipantNameSection = styled.div`
  display: flex;
  width: 100%;
  grid-column-gap: 1rem;
  margin-top: 1.5rem;
`;

export const LocationFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-bottom: 1rem;
`;

export const InputWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  & * {
    flex: 1;
  }
`;

export const borderSx = {
  '&.Mui-disabled:before': { borderBottomStyle: 'solid' },
};

export const communicationCheckboxPadding = '0rem 0.5rem 0rem 0.563rem';
