import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { pathName } from 'Routes/routes.const';
import { HeaderIconBtnSx, settingsIconStyles } from './style';
import { useLocation } from 'react-router';
import {
  MENUITEM,
  transformOrigin,
  anchorOrigin,
} from './SettingsMenu.constant';

const ITEM_HEIGHT = 48;

const SettingsMenu = ({ handleClick, handleClose, openSettingsPage, open }) => {
  const location = useLocation();

  return (
    <>
      <IconButton
        sx={{ ...settingsIconStyles, ...HeaderIconBtnSx }}
        onClick={handleClick}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true">
        <SettingsOutlinedIcon />
      </IconButton>

      {location?.pathname !== pathName.settings && (
        <Menu
          id="settings-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '9rem',
            },
          }}
          open={open}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={handleClose}>
          <MenuItem onClick={openSettingsPage} data-testid="settings-users">
            {MENUITEM.users}
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
SettingsMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  openSettingsPage: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
export default SettingsMenu;
