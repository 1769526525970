import axios from 'axios';
import {
  FETCH_ADD_CONTRIBUTOR_FARM_AND_PROJECT,
  FETCH_ALL_CONTRIBUTORS,
  FETCH_PROFILE_PARTICIPANT_CONTRIBUTOR,
  SEND_PROFILE_PARTICIPANT_CONTRIBUTOR,
} from 'urls';
import { formatPhoneNumber } from 'utils/helper';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { errorToastMessage } from './ParticipantContributorsForm.style';

export const addFarmContributor = async ({
  participantForm,
  participantOwnerId,
  selectedCategories,
  selectedSubItems,
  participantId = null,
  refreshHandler,
  isFormEdited = false,
  projectCycleId,
}) => {
  const filteredPrimaryCommunicationList = participantForm.primaryCommunication
    .filter((filteredList) => filteredList.checked === true)
    .map((item) => item.itemLabel);
  const successMessage = `${participantForm.participantFirstName} ${participantForm.participantLastName} was successfully added.`;
  const successMessageForEdit = `${participantForm.participantFirstName} ${participantForm.participantLastName} was successfully updated.`;
  const errorMessage = `${participantForm.participantFirstName} ${participantForm.participantLastName} failed to be added. Please try again.`;
  const errorMessageForEdit = `${participantForm.participantFirstName} ${participantForm.participantLastName} failed to be updated. Please try again.`;
  try {
    const response = await axios.post(
      `${SEND_PROFILE_PARTICIPANT_CONTRIBUTOR}?projectCycleId=${projectCycleId}`,
      {
        participantOwnerId: Number(participantOwnerId),
        participantFirstName: participantForm.participantFirstName,
        participantLastName: participantForm.participantLastName,
        participantEmail: participantForm.participantEmail,
        participantId: participantId ? participantId : null,
        phone: formatPhoneNumber(participantForm.participantPhone),
        primaryCommunication: filteredPrimaryCommunicationList.toString(),
        farmProjectAssignment: selectedCategories.map((farmId) => {
          const projectId = Number(selectedSubItems[farmId].toString());
          return { farmId: farmId.toString(), projects: [{ projectId }] };
        }),
      },
    );
    toast(
      <CustomSnackbar
        type="success"
        message={!isFormEdited ? successMessage : successMessageForEdit}
      />,
      errorToastMessage,
      refreshHandler(),
    );
    return response.data;
  } catch {
    toast(
      <CustomSnackbar
        type="error"
        message={!isFormEdited ? errorMessage : errorMessageForEdit}
      />,
      errorToastMessage,
    );
  }
};
export const fetchAddContributorFarmAndProject = async (participantOwnerId) => {
  return axios
    .get(FETCH_ADD_CONTRIBUTOR_FARM_AND_PROJECT(participantOwnerId))
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};
export const fetchProfileParticipantContributor = async (
  participantId,
  participantOwnerId,
) => {
  return axios
    .get(
      FETCH_PROFILE_PARTICIPANT_CONTRIBUTOR(participantId, participantOwnerId),
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};

export const fetchAllParticipantContributors = () => {
  return axios.get(FETCH_ALL_CONTRIBUTORS).catch(() => {});
};
