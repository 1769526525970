import { ReactComponent as PortfolioIcon } from '../../assets/icons/portfolioIcon.svg';
import { ReactComponent as PortfolioFocusedIcon } from '../../assets/icons/portfolioIconFocused.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/library_books.svg';
import { ReactComponent as ProjectFocusedIcon } from '../../assets/icons/library_books_selected.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/report.svg';
import { ReactComponent as ReportIconFocused } from '../../assets/icons/reportFocused.svg';

export const navLinks = {
  originationModuleLinks: [
    {
      title: 'Portfolio',
      defaultIcon: PortfolioIcon,
      focusedIcon: PortfolioFocusedIcon,
      path: '/portfolio',
      moduleKey: 'PORTFOLIO',
    },
    {
      title: 'Projects',
      defaultIcon: ProjectIcon,
      focusedIcon: ProjectFocusedIcon,
      path: '/projects',
      additionalPath: '/stakeholders',
      moduleKey: 'PROJECTS',
    },
    // {
    //   title: 'Stakeholders',
    //   defaultIcon: StakeholderIcon,
    //   focusedIcon: StakeholderFocusedIcon,
    //   path: '/stakeholders',
    //   moduleKey: 'STAKEHOLDERS',
    // },
    // {
    //   title: 'Audit Log',
    //   defaultIcon: AuditIcon,
    //   focusedIcon: AuditIconFocused,
    //   path: '/audit-log',
    //   moduleKey: 'AUDIT_LOG',
    // },
    {
      title: 'Reports',
      defaultIcon: ReportIcon,
      focusedIcon: ReportIconFocused,
      path: '/reports',
      moduleKey: 'REPORTS',
    },
    /*     { title: 'Insights', path: '/origination/insights' }, */
  ],
  assetsModuleLinks: [
    // { title: 'Transactions', path: '/transactions', moduleKey: 'TRANSACTIONS' },
    /* { title: 'Stakeholders', path: '/assets/stakeholders' },*/
  ],
};
