import {
  ErroWrapper,
  FormFields,
  FormLabels,
  PaymentWapper,
} from './ProjectSubsection.style';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { PAGE_CONTENT } from './ProjectSubsection.content';
import PaymentSubsection from 'pages/AddPurchaseTransaction/components/PaymentSubsection/PaymentSubsection.react';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useContext, useState } from 'react';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import RemoveButton from 'components/RemoveButton/RemoveButton.react';
import { BLUE, GRAYISH_BLACK } from 'theme/GlobalColors';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { checkTernaryCondition } from 'utils/helper';

const ProjectSubsection = ({
  projectObj,
  onUpdate,
  onDelete,
  paymentTypes,
  costStructure,
  editMode,
  viewMode,
  paymentObj,
  vintageObj,
  projectList,
}) => {
  const { projectNames, fetchProjectNames } = useTransactionDetails();
  const falseValue = false;
  const { markFormDirty } = useContext(EditPurchaseTransactionFormDirtyContext);
  const [linkedErrorOpen, setLinkedErrorOpen] = useState({
    flag: false,
    list: [],
  });
  const projectNameChangeHandler = (e, newValue, reason) => {
    if (reason !== 'clear') {
      onUpdate({
        ...projectObj,
        projectName: newValue,
        projectNameError: newValue !== '' && falseValue,
      });
    } else {
      onUpdate({
        ...projectObj,
        projectName: '',
        projectNameError: newValue !== '' && falseValue,
        detailsError: false,
      });
    }
    markFormDirty();
  };

  const paymentUpdateHandler = (paymentObj) => {
    onUpdate({
      ...projectObj,
      paymentDetails: projectObj.paymentDetails.map((payment) =>
        payment.id === paymentObj.id ? paymentObj : payment,
      ),
    });
  };

  const addSectionHandler = () => {
    onUpdate({
      ...projectObj,
      paymentDetails: [
        ...projectObj.paymentDetails,
        {
          ...paymentObj,
          id: uniqueId(),
          vintageDetails: [{ ...vintageObj, id: uniqueId() }],
        },
      ],
    });
    markFormDirty();
  };

  const deleteVariableCostHandler = (id) => {
    onUpdate({
      ...projectObj,
      paymentDetails: projectObj.paymentDetails.filter(
        (payment) => payment.id !== id,
      ),
    });
  };

  return (
    <div>
      <FormFields>
        <FormLabels
          iserror={projectObj.projectNameError || projectObj.detailsError}>
          {PAGE_CONTENT.project_name_label}
          {projectList.length > 1 && !viewMode && (
            <RemoveButton
              label={PAGE_CONTENT.remove_project_text}
              clickHandler={() => {
                if (projectObj.saleContractNames.length > 0) {
                  setLinkedErrorOpen({
                    flag: true,
                    list: projectObj.saleContractNames,
                  });
                } else {
                  onDelete(projectObj.id);
                  markFormDirty();
                }
              }}
              labelColor={checkTernaryCondition(
                projectObj.saleContractNames.length > 0,
                GRAYISH_BLACK,
                BLUE,
              )}
            />
          )}
        </FormLabels>
        <Autocomplete
          disabled={viewMode || projectObj.isApiPopulated}
          options={projectNames}
          onChange={projectNameChangeHandler}
          value={projectObj.projectName || null}
          onInputChange={(e, newValue) => {
            fetchProjectNames(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              error={projectObj.projectNameError || projectObj.detailsError}
              inputProps={{
                style: { paddingLeft: '0.625rem' },
                ...params.inputProps,
              }}
              placeholder={PAGE_CONTENT.project_name_placeholder}
            />
          )}
        />
        {projectObj.projectNameError && (
          <ErroWrapper>{PAGE_CONTENT.project_name_error}</ErroWrapper>
        )}
        {projectObj.detailsError && (
          <ErroWrapper>
            {PAGE_CONTENT.existing_project_details_error}
          </ErroWrapper>
        )}
      </FormFields>

      <PaymentWapper>
        {projectObj.paymentDetails.map((paymentDetail, index) => {
          const id = index;
          return (
            <PaymentSubsection
              viewMode={viewMode}
              paymentTypes={paymentTypes}
              costStructure={costStructure}
              key={id}
              paymentNo={id}
              onUpdate={paymentUpdateHandler}
              paymentObj={paymentDetail}
              projectObj={projectObj}
              onProjectUpdate={onUpdate}
              editMode={editMode}
              vintageObj={vintageObj}
              onDelete={deleteVariableCostHandler}
              paymentList={projectObj.paymentDetails}
              setLinkedErrorOpen={setLinkedErrorOpen}
            />
          );
        })}
        <AddSectionButton
          onClickHanlder={addSectionHandler}
          hiddenFlag={viewMode}
          label={PAGE_CONTENT.add_variable_cost_structure}
        />
        <DialogBox
          dialogActions
          deleteOperation
          isOpen={linkedErrorOpen.flag}
          title={PAGE_CONTENT.inventory_modal_error_title}
          transactionList={linkedErrorOpen.list}
          onConfirm={() => {
            setLinkedErrorOpen({ flag: false, list: [] });
          }}
          onCancel={() => {
            setLinkedErrorOpen({ flag: false, list: [] });
          }}
          acceptCtnLabel="Close">
          {PAGE_CONTENT.inventory_modal_error_message}
        </DialogBox>
      </PaymentWapper>
    </div>
  );
};

ProjectSubsection.propTypes = {
  projectObj: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  paymentTypes: PropTypes.array,
  costStructure: PropTypes.object,
  editMode: PropTypes.bool,
  viewMode: PropTypes.string,
  paymentObj: PropTypes.object,
  vintageObj: PropTypes.object,
  projectList: PropTypes.array,
};
export default ProjectSubsection;
