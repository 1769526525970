import { COOL_GRAY } from 'theme/GlobalColors';
import {
  InfoSx,
  InfoTooltipWrapper,
  MainWrapper,
  SwitchWrapper,
  TextWrapper,
  ViewWrapper,
} from './ToggleViewCalculations.style';
import LightTooltip from 'components/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SwitchComponent from 'components/FormComponents/SwitchComponent/SwitchComponent.react';
import { useContext } from 'react';
import { projectCalculationContext } from 'contextAPI/projectCalculationContext';
import PropTypes from 'prop-types';

const ToggleViewCalculations = ({ toogleViewCalculationsTooltip }) => {
  const { calculationToggle, handleChange } = useContext(
    projectCalculationContext,
  );
  return (
    <MainWrapper>
      <ViewWrapper>
        <TextWrapper color={COOL_GRAY}>View:</TextWrapper>
        <SwitchWrapper>
          <SwitchComponent
            name={'baseline'}
            isChecked={calculationToggle.baseline}
            handleChange={handleChange}
            label={'Baseline'}
            fontSize={'12px'}
          />
          <SwitchComponent
            name={'project'}
            isChecked={calculationToggle.project}
            handleChange={handleChange}
            label={'Project'}
            fontSize={'12px'}
          />
          {toogleViewCalculationsTooltip && (
            <LightTooltip
              placement="bottom-start"
              arrow
              title={
                <InfoTooltipWrapper>
                  {toogleViewCalculationsTooltip}
                </InfoTooltipWrapper>
              }>
              <InfoOutlinedIcon sx={InfoSx}></InfoOutlinedIcon>
            </LightTooltip>
          )}
        </SwitchWrapper>
      </ViewWrapper>
    </MainWrapper>
  );
};

ToggleViewCalculations.propTypes = {
  toogleViewCalculationsTooltip: PropTypes.string,
};

export default ToggleViewCalculations;
