import axios from 'axios';
import { loaderContext } from 'contextAPI/loaderContext';
import { useContext, useEffect, useState } from 'react';
import { GENERAL_SETTING_PAGE } from 'urls';

export const useProjectGeneralSettingTab = (id) => {
  const { setIsLoading } = useContext(loaderContext);
  const [generalSettings, setGeneralSettings] = useState({
    projectName: '',
    location: '',
    sectoralScope: '',
    afolusectoralSubtypes: 'None',
    commercialization: '',
    originationEngine: true,
    creditIssuance: true,
    projectActivities: [],
  });

  const [projectId] = useState(id);

  const callProjectGeneralSettingApi = async () => {
    setIsLoading(true);
    const { data } = await axios.get(`${GENERAL_SETTING_PAGE}${projectId}`);
    setIsLoading(false);
    setGeneralSettings(data);
  };

  useEffect(() => {
    callProjectGeneralSettingApi();
  }, []);

  return {
    ...generalSettings,
  };
};
