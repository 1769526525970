import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as DisabledDownloadIcon } from '../../assets/icons/DisabledDownloadIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as GreenDownloadIcon } from '../../assets/icons/GreenDownloadIcon.svg';
import { ReactComponent as GreenDeleteIcon } from '../../assets/icons/GreenDeleteIcon.svg';
import { MainWrapper, OptionsWrapper } from './NewOptionsButton.style';
import { checkTernaryCondition } from 'utils/helper';
import { COOL_GRAY } from 'theme/GlobalColors';
import { PropTypes } from 'prop-types';

const NewOptionsButton = ({
  deleteHandler,
  firstMenuItemClickHandler,
  secondMenuItemClickHandler,
  thirdMenuItemClickHandler,
  isDownloadDisabled,
  deleteNotDisabled,
  reportType,
  reportOptions,
  id,
  hoveredButton,
  updateHoveredButton,
  downloadDataTestId = 'download-button',
  deleteDataTestId = 'delete-button',
}) => {
  const isDownloadIconHovered = hoveredButton?.download === id;
  const isDeleteIconHovered = hoveredButton?.delete === id;

  const handlerAttributeOptions = {
    download: 'download',
    delete: 'delete',
  };

  const deleteClickHandler = () => {
    deleteHandler();
  };

  /**
   * @description used to handle hover event on download and delete buttons
   * @param {String} [attribute='']
   * @param {String} [id='']
   */
  const onHoverHandler = (attribute = '', id = '') => {
    switch (attribute) {
      case handlerAttributeOptions.download:
        updateHoveredButton((prev) => ({
          ...prev,
          download: id,
        }));
        break;
      case handlerAttributeOptions.delete:
        updateHoveredButton((prev) => ({
          ...prev,
          delete: id,
        }));
        break;
      default:
        updateHoveredButton({
          download: '',
          delete: '',
        });
        break;
    }
  };

  const handlers = {
    [reportOptions[0]?.name]: firstMenuItemClickHandler,
    [reportOptions[1]?.name]: secondMenuItemClickHandler,
    [reportOptions[2]?.name]: thirdMenuItemClickHandler,
  };

  const downloadClickHandler = () => {
    const handler = handlers[reportType];
    if (handler) {
      handler();
    }
  };

  const getDownloadButton = () => {
    if (isDownloadDisabled) {
      return (
        <DisabledDownloadIcon data-testid={`disabled-${downloadDataTestId}`} />
      );
    }
    return checkTernaryCondition(
      isDownloadIconHovered,
      <GreenDownloadIcon
        onClick={downloadClickHandler}
        data-testid={`hovered-${downloadDataTestId}`}
      />,
      <DownloadIcon
        onClick={downloadClickHandler}
        data-testid={downloadDataTestId}
      />,
    );
  };

  const getDeleteIcon = () => {
    if (deleteNotDisabled) {
      return checkTernaryCondition(
        isDeleteIconHovered,
        <GreenDeleteIcon
          onClick={deleteClickHandler}
          data-testid={`hovered-${deleteDataTestId}`}
        />,
        <DeleteIcon
          aria-label="deleteFile"
          id="delete-button"
          onClick={deleteClickHandler}
          sx={{ color: COOL_GRAY }}
          data-testid={deleteDataTestId}
        />,
      );
    }
    return (
      <DeleteIcon
        sx={{ color: COOL_GRAY, opacity: 0.5 }}
        data-testid={`disabled-${deleteDataTestId}`}
      />
    );
  };

  return (
    <MainWrapper>
      <OptionsWrapper
        onMouseEnter={() =>
          onHoverHandler(handlerAttributeOptions.download, id)
        }
        onMouseLeave={() => onHoverHandler()}
        deletenotdisabled={!isDownloadDisabled}>
        {getDownloadButton()}
      </OptionsWrapper>
      <OptionsWrapper
        onMouseEnter={() => onHoverHandler(handlerAttributeOptions.delete, id)}
        onMouseLeave={() => onHoverHandler()}
        deletenotdisabled={deleteNotDisabled}>
        {getDeleteIcon()}
      </OptionsWrapper>
    </MainWrapper>
  );
};

export default NewOptionsButton;

NewOptionsButton.propTypes = {
  deleteHandler: PropTypes.func,
  firstMenuItemClickHandler: PropTypes.func,
  secondMenuItemClickHandler: PropTypes.func,
  thirdMenuItemClickHandler: PropTypes.func,
  isDownloadDisabled: PropTypes.bool,
  deleteNotDisabled: PropTypes.bool,
  reportType: PropTypes.string,
  reportOptions: PropTypes.array,
  id: PropTypes.string,
  hoveredButton: PropTypes.object,
  updateHoveredButton: PropTypes.func.isRequired,
  downloadDataTestId: PropTypes.string,
  deleteDataTestId: PropTypes.string,
};
