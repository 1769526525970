import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { ADD_USER_CONTENT } from './AddUser.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { useForm } from 'hooks/useForm';
import {
  formModal,
  useUserDetails,
  fieldInitialState,
} from 'hooks/useUserDetails';
import { scroll, validateEmail, checkTernaryCondition } from 'utils/helper';
import {
  buttonStyle,
  DialogTitleSx,
  DialogContentSx,
  paperRootSx,
  cancelButtonStyle,
  AddUserButtonStyle,
} from './AddUser.style';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import {
  ErroWrapper,
  FocusedInputSx,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { closeIconSx } from 'theme/GlobalStyles';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';

const AddUserForm = ({ isUserDataEmpty, isOpen, setIsOpen, fetchUserList }) => {
  const [open, setOpen] = useState(false);
  const [emailSecondaryError, setEmailSecondaryError] = useState(
    ADD_USER_CONTENT.user_email_format_error,
  );
  const [isFormEdited, setIsFormEdited] = useState(false);
  const { formValue, customFieldChangeHandler, setFormValue } =
    useForm(formModal);
  const [hasError, setHasError] = useState(false);
  const [isEmailBlurred, setIsEmailBlurred] = useState(false);

  const {
    userFirstName,
    setUserFirstName,
    userLastName,
    setUserLastName,
    userEmail,
    setUserEmail,
    userRoles,
    setUserRoles,
    originationRoles,
    setOriginationRoles,
    handleTextFieldValueChange,
    handleFormSubmit,
    formHasError,
    fetchModuleRoles,
    moduleRoles,
    checkUserEmail,
    isDuplicateEmail,
    postUserData,
    fetchUserDetails,
    fetchedUserDetails,
    setIsDuplicateEmail,
    moduleError,
    setModuleError,
    setFormHasError,
    handleEmailChange,
    checkingEmail,
  } = useUserDetails();

  const formContainsError = () => {
    const isUserFirstNameEmpty = userFirstName.value === '';
    const isUserLastNameEmpty = userLastName.value === '';
    const isUserEmailEmpty = userEmail.value === '';
    const validEmail = userEmail.error;
    const isOriginationRolesEmpty = originationRoles.value === '';
    const isEmptyArray = [
      isOriginationRolesEmpty,
      isUserFirstNameEmpty,
      isUserLastNameEmpty,
      isUserEmailEmpty,
      validEmail,
    ];

    setHasError(isEmptyArray.includes(true));
  };

  useEffect(() => {
    if (isOpen.flag) {
      setOpen(isOpen.flag);
      setHasError(true);
    }
  }, [isOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const isAdminSelected = userRoles.value === ADD_USER_CONTENT.admin;
  const isButtonDisabled =
    isDuplicateEmail ||
    (!isFormEdited && isOpen.id !== 0) ||
    moduleError ||
    hasError ||
    checkingEmail;

  const originationDropdownProps = {
    labelMarginTop: '1rem',
    field_key: ADD_USER_CONTENT.origination_roles,
    label: ADD_USER_CONTENT.origination_role_label,
    value:
      originationRoles.value === ADD_USER_CONTENT.empty_string
        ? ADD_USER_CONTENT.select_roles
        : originationRoles.value,
    isDisabled: false,
    stateValue: originationRoles,
    setStateValue: setOriginationRoles,
    setFormFieldValue: customFieldChangeHandler,
    width: '14rem',
    height: '2rem',
    name: ADD_USER_CONTENT.origination_role_label,
    displayEmpty: true,
    ariaLabel: ADD_USER_CONTENT.origination_role_label,
    dropdownlist: moduleRoles || ADD_USER_CONTENT.empty_array,
    dropDownPlaceholder: ADD_USER_CONTENT.select_roles,
    error: originationRoles.error,
    errorMessage: ADD_USER_CONTENT.select_role_dropdown_error,
    moduleError: originationRoles.errorType === ADD_USER_CONTENT.none_error,
    onUpdate: () => {
      setIsFormEdited(true);
    },
    customSx: outlinedDropdownInputSx,
  };

  const clearFormData = () => {
    setUserEmail(fieldInitialState);
    setUserRoles(fieldInitialState);
    setOriginationRoles(fieldInitialState);
    setUserFirstName(fieldInitialState);
    setUserLastName(fieldInitialState);
    setIsFormEdited(false);
  };
  const setInvalidEmailError = () => {
    setUserEmail({
      ...userEmail,
      error: true,
      errorType: ADD_USER_CONTENT.secondary,
    });
    setIsDuplicateEmail(false);
    setEmailSecondaryError(ADD_USER_CONTENT.user_email_format_error);
  };
  const handleClose = () => {
    setIsEmailBlurred(false);
    setOpen(false);
    clearFormData();
    setIsOpen({ id: 0, flag: false });
    setHasError(true);
    setIsDuplicateEmail(false);
  };

  useEffect(() => {
    fetchModuleRoles();
  }, []);
  useEffect(() => {
    setOpen(isOpen.flag);
    isOpen.flag && fetchUserDetails(isOpen.id);
  }, [isOpen]);
  useEffect(() => {
    fetchedUserDetails !== ADD_USER_CONTENT.empty_string &&
      setFormValue(fetchedUserDetails);
  }, [fetchedUserDetails]);
  useEffect(() => {
    if (isDuplicateEmail) {
      setEmailSecondaryError(ADD_USER_CONTENT.duplicate_email_error);
      setUserEmail({
        ...userEmail,
        error: true,
        errorType: ADD_USER_CONTENT.secondary,
      });

      setIsDuplicateEmail(false);
    }
  }, [isDuplicateEmail]);
  useEffect(() => {
    scroll(ADD_USER_CONTENT.error_class_name);
    if (originationRoles.value !== 'None') {
      setModuleError(false);
    }
    formContainsError();
  }, [formValue]);

  useEffect(() => {
    if (open) {
      setUserEmail(fieldInitialState);
    }
  }, [open]);

  useEffect(() => {
    formContainsError();
  }, [userEmail.error]);

  useEffect(() => {
    if (formHasError === false && isDuplicateEmail === false) {
      postUserData(formValue, isOpen.flag, isOpen.id, fetchUserList);
      setOpen(false);
      clearFormData();
      setIsOpen({ id: 0, flag: false });
    }
  }, [formHasError]);

  useEffect(() => {
    if (originationRoles.value === ADD_USER_CONTENT.none) {
      setFormHasError(true);
      setOriginationRoles({
        ...originationRoles,
        error: true,
        errorType: ADD_USER_CONTENT.none_error,
      });
      setModuleError(true);
    } else {
      setModuleError(false);
      originationRoles.value !== ADD_USER_CONTENT.empty_string &&
        setOriginationRoles({
          ...originationRoles,
          error: false,
          errorType: null,
        });
    }
  }, [originationRoles.value]);

  useEffect(() => {
    setOriginationRoles(fieldInitialState);
  }, [isAdminSelected]);

  return (
    <div>
      {!isUserDataEmpty && (
        <Button
          onClick={handleClickOpen}
          variant={ADD_USER_CONTENT.contained}
          style={AddUserButtonStyle}
          sx={[buttonStyle(), primaryButtonStyle()]}>
          {ADD_USER_CONTENT.add_user}
        </Button>
      )}
      <Dialog
        disableRestoreFocus
        open={open}
        onClose={handleClose}
        fullWidth
        sx={paperRootSx}>
        <Box display="flex" justifyContent={'space-between'}>
          <DialogTitle sx={DialogTitleSx}>
            {checkTernaryCondition(
              isOpen.flag,
              ADD_USER_CONTENT.edit_user,
              ADD_USER_CONTENT.add_user,
            )}
          </DialogTitle>
          <CloseIcon
            data-testid={ADD_USER_CONTENT.close_icon}
            onClick={() => handleClose()}
            sx={closeIconSx}
          />
        </Box>
        <DialogContent sx={DialogContentSx}>
          <InputField
            label={ADD_USER_CONTENT.first_name}
            value={userFirstName.value}
            placeholder={ADD_USER_CONTENT.first_name_placeholder}
            name={ADD_USER_CONTENT.first_name}
            width="100%"
            isRequired
            maxLength={100}
            category="name-field"
            primaryError={userFirstName.error}
            onUpdate={(event) => {
              handleTextFieldValueChange(
                userFirstName,
                setUserFirstName,
                customFieldChangeHandler,
                event,
                ADD_USER_CONTENT.user_first_name,
              );
              setIsFormEdited(true);
            }}
            labelMarginTop="0rem"
            labelMarginBottom="0.25rem"
            rightSideLabelFormLabelMargin="0rem"
            customInputSx={FocusedInputSx}
          />
          <InputField
            label={ADD_USER_CONTENT.last_name}
            value={userLastName.value}
            placeholder={ADD_USER_CONTENT.last_name_placeholder}
            name={ADD_USER_CONTENT.last_name}
            width="100%"
            maxLength={100}
            isRequired
            category="name-field"
            primaryError={userLastName.error}
            onUpdate={(event) => {
              handleTextFieldValueChange(
                userLastName,
                setUserLastName,
                customFieldChangeHandler,
                event,
                ADD_USER_CONTENT.user_last_name,
              );
              setIsFormEdited(true);
            }}
            labelMarginTop="1.5rem"
            labelMarginBottom="0.25rem"
            rightSideLabelFormLabelMargin="0rem"
            customInputSx={FocusedInputSx}
          />
          <InputField
            label={ADD_USER_CONTENT.email}
            value={userEmail.value}
            placeholder={ADD_USER_CONTENT.email_placeholder}
            name={ADD_USER_CONTENT.email}
            isDisabled={isOpen.flag}
            category="email-field"
            width="100%"
            isRequired
            maxLength={100}
            primaryError={
              userEmail.error &&
              userEmail.errorType === ADD_USER_CONTENT.primary
            }
            primaryErrorMessage={ADD_USER_CONTENT.user_email_valid_error}
            secondaryError={
              userEmail.error &&
              userEmail.errorType === ADD_USER_CONTENT.secondary
            }
            secondaryErrorMessage={emailSecondaryError}
            onUpdate={(event) => {
              handleEmailChange(
                setUserEmail,
                customFieldChangeHandler,
                event,
                ADD_USER_CONTENT.user_email,
              );
              if (event.target.value.trim().length === 0) {
                setUserEmail({
                  value: event.target.value.trim(),
                  error: true,
                  errorType: ADD_USER_CONTENT.primary,
                });
              } else if (!validateEmail(event.target.value.trim())) {
                setUserEmail({
                  value: event.target.value.trim(),
                  error: true,
                  errorType: ADD_USER_CONTENT.secondary,
                });
              } else {
                setUserEmail({
                  value: event.target.value.trim(),
                  error: false,
                  errorType: '',
                });
                checkUserEmail(event.target.value.trim());
              }
            }}
            labelMarginTop="1.5rem"
            labelMarginBottom="0.25rem"
            rightSideLabelFormLabelMargin="0rem"
            customInputSx={FocusedInputSx}
          />
          <DropDown
            {...originationDropdownProps}
            labelMarginTop="1.5rem"
            labelMarginBottom="0.25rem"
            hasNoBottomMargin={true}
          />
          {moduleError && (
            <ErroWrapper>{ADD_USER_CONTENT.role_error}</ErroWrapper>
          )}
        </DialogContent>
        <DialogActions sx={DialogContentSx}>
          <Button
            onClick={handleClose}
            sx={{ ...tertiaryButtonStyle, ...cancelButtonStyle }}>
            {ADD_USER_CONTENT.cancel}
          </Button>
          <Button
            disabled={isButtonDisabled}
            data-testid={ADD_USER_CONTENT.submit_button}
            onClick={() => {
              handleFormSubmit(!isUserDataEmpty);
              formContainsError();
              setHasError(true);
              setIsEmailBlurred(false);
            }}
            variant={ADD_USER_CONTENT.contained}
            sx={[buttonStyle(isButtonDisabled), primaryButtonStyle()]}>
            {checkTernaryCondition(
              isOpen.flag,
              ADD_USER_CONTENT.save,
              ADD_USER_CONTENT.add_user,
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddUserForm;

AddUserForm.propTypes = {
  isUserDataEmpty: PropTypes.bool.isRequired,
  isOpen: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  fetchUserList: PropTypes.func.isRequired,
};
