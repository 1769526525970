export const PARTICIPANT_CONTENT = {
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  project: 'Project',
  action: 'Action',
  projectName: 'projectName',
  emptyDataContent: 'No participant contributors added',
  addNewParticipantLabel: 'Add at least one individual to get started.',
  contributorEmptyDataContent:
    'Participant contributor is not assigned to any farm yet.',
  addParticipantContributorBtn: 'Add participant contributor',
  contributorList: 'contributorList',
  ownerList: 'ownerList',
  farmName: 'farmName',
  fsaId: 'fsaId',
  right: 'right',
  farm_and_fsa_id: 'Farm and FSA ID',
  contributor: 'Contributor',
  contributorName: 'Contributor',
  participantContributor: 'Participant contributor',
  owner: 'Participant Owner',
  actionField: 'options',
  actionLabel: 'Action',
  ownerName: 'Owner',
  last_updated: 'Last updated',
  updatedDate: 'updatedDate',
  mailing_address_1: 'Mailing Address',
  farm_assigments_title: 'Farm assignments',
  preferred_communication_Method: 'Preferred Communication Method',
  race: 'Race/Ethnicity',
  gender: 'Gender',
  historically_underserved_status: 'Historically Underserved Status',
  disabled_veteran_owned: 'Disabled Veteran-Owned',
  disability_owned: 'Disability-Owned',
  small_business: 'Small Business',
  farm_name: 'Farm name',
  fsa_id: 'FSA ID',
  lgbtqia: 'LGBTQIA+',
  contact: 'Contact',
  additional_info: 'Additional Info',
  women_owned: 'Women-Owned',
  veteran_owned: 'Veteran-Owned',
  profile_tab: 'Profile',
  city: 'City',
  zip_code: 'Zip Code',
  state: 'State',
  minWidth: '19.375rem',
  labelFontSize: '0.75rem',
  labelMarginTop: '0rem',
  labelMarginBottom: '0.5rem',
  fieldMarginBottom: '0rem',
  cityWidth: '12.5rem',
  stateWidth: '2.5rem',
  default_pagination_options: [10, 20, 30],
  default_page_size: 10,
  pincodeWidth: '3.125rem',
  participant_owner: 'PARTICIPANT_OWNER',
  participant_contributor: 'PARTICIPANT_CONTRIBUTOR',
  NO_FARM: 'Participant owner has no projects with enrolled farms.',
  owner_label: 'owner',
  contributor_label: 'contributor',
  NO_FARMS_ADDED: 'No farms added',
};

export const PARTICIPANT_INFO_INITIAL_STATE = {
  projectId: '',
  participantId: '',
  participantName: '',
  farmId: '',
  farmName: '',
  projectName: '',
};
