import { Fragment, useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { HeaderIconBtnSx, useStyles } from './style';
import { getCurrentUserData } from 'security/components/Profile.react';
import { useFirebaseSignIn } from 'security';

const ProfileSection = () => {
  const currentUser = getCurrentUserData();

  const [avatarProps, setAvatarProps] = useState({ children: true });
  const { AvatarIcon, UsernameDiv, UsernameTooltip } = useStyles();
  const { handleLogout } = useFirebaseSignIn();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    currentUser &&
      setAvatarProps({ children: currentUser.email[0].toUpperCase() || '' });
  }, []);
  return (
    <Fragment>
      {avatarProps.children && (
        <>
          <UsernameTooltip
            data-testid="profile-icon"
            arrow
            title={<UsernameDiv>{currentUser.email}</UsernameDiv>}>
            <IconButton
              sx={HeaderIconBtnSx}
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <AvatarIcon {...avatarProps} />
            </IconButton>
          </UsernameTooltip>

          <Menu
            sx={{ top: '1rem', width: '10rem', left: '89.5vw' }}
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem
              sx={{ width: '144px', height: '28px' }}
              onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </>
      )}
    </Fragment>
  );
};

export default ProfileSection;
