export const PARTICIPANT_SUMMARY_CONTENT = {
  leftBarChartTitle: 'GHG Emissions',
  leftBarChartXAxisLabel: (
    <span>
      MT CO<sub>2</sub>e
    </span>
  ),
  rightBarChartTitle: 'GHG emissions by source / sink ',
  rightBarChartXAxisLabel: (
    <spa>
      MT CO<sub>2</sub>e
    </spa>
  ),
  fullHeight: '100%',
  ninetyPercent: '90%',

  enrollmentAndReporting: 'Enrollment and reporting',
  enrollment: 'Enrollment: ',
  Enrollment: 'Enrollment',
  Activity: 'Activity',
  InProgress: 'In progress',
  Complete: 'Complete',
  NotStarted: 'Not started',
  acres: 'acres',
  baselineReporting: 'Baseline reporting: ',
  activityReporting: 'Activity reporting: ',
  activityDelta: 'Activity + delta',
  noDataLabel: 'Not reported yet',
  baseline: 'Baseline',
  activeTag: 'Active',
  noDataValue: '- -',
  noDataValueContinous: '--',
  ghgEmissions: 'GHG reductions & removals to date',
  ghg_impact_tooltip: 'Represents reductions and removals to date.',
  actual: 'Actual',
  CO2e: 'CO₂e (K MT)',
  project_life_cycle_label: 'Project cycle:',
  project_life_cycle_placeholder: 'Select project cycle',
  acreageProgressTracker: 'Acreage progress tracker',
  noChartDataLabel: 'No data to report yet.',
  reporting: 'Reporting',
};

export const Status = {
  interested: 'Interested',
  inProgress: 'Enrollment In Progress',
  enrolled: 'Enrolled',
  approved: 'Approved',
  submitted: 'Submitted',
};

export const ACREAGE_PROGRESS_TRACKER = {
  ENROLLMENT: 'Enrollment',
  BASELINE: 'Baseline',
  ACTIVITY: 'Activity',
  COMPLETE: 'Complete',
};
