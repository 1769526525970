import { NewColorThemeProvider } from 'contextAPI/newColorThemeContext';

import {
  CONTENT,
  GENERATE_REPORT_BTN_PADDING,
  REPORT_BUTTONS,
} from './Reports.content';
import { HeaderWrapper, ReportsWrapper } from './Reports.styles';
import OriginationProjectReportsList from 'pages/Origination/OriginationProjectReportsList/OriginationProjectReportsList.react';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';
import { DARK_GREEN } from 'theme/GlobalColors';
import { checkAccess, getCurrentDate } from 'utils/helper';
import {
  ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE,
  ORIGINATION_PROJECT_SUMMARY_REPORT_CREATE,
} from 'utils/config';
import { useContext, useEffect, useState } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';

const Reports = () => {
  const { permissions } = useContext(userAuthRolesContext);
  const [selectedReportOption, setSelectedReportOption] = useState('');
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] =
    useState(false);
  const [reportName, setReportName] = useState('');

  // generate report button options
  const generateReportButtonOptions = [
    {
      id: 1,
      name: REPORT_BUTTONS.PORJECT_SUMMARY,
      isDisabled: !checkAccess(
        permissions,
        ORIGINATION_PROJECT_SUMMARY_REPORT_CREATE,
      ),
    },
    {
      id: 2,
      name: REPORT_BUTTONS.PARTICIPANT_PROJECT_SUMMARY,
      isDisabled: !checkAccess(
        permissions,
        ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE,
      ),
    },
  ];

  /**
   * @description method to update generate report modal state
   * @param {Boolean} [value=false]
   */
  const updateGenerateModalState = (value = false) => {
    setIsGenerateReportModalOpen(value);
  };

  /**
   * @description used to update the report name
   * @param {String} value
   */
  const updateReportName = (value = '') => {
    setReportName(value.trim());
  };

  /**
   * @description method to set default report name
   */
  const setDefaultReportName = () => {
    updateReportName(
      `${selectedReportOption?.replace?.(
        / /g,
        CONTENT.underscore,
      )}-${getCurrentDate()}`,
    );
  };

  // set default report name on selected report option change
  useEffect(() => {
    setDefaultReportName();
  }, [selectedReportOption]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <ReportsWrapper>
        <HeaderWrapper>{CONTENT.reports}</HeaderWrapper>
        <ListViewHeader
          buttonName={CONTENT.generateBtn}
          buttonBackgroundColor={DARK_GREEN}
          noDropdown={false}
          options={generateReportButtonOptions}
          setSelectedOption={setSelectedReportOption}
          toggleModal={setIsGenerateReportModalOpen}
          isModalPresent={true}
          containerPadding={GENERATE_REPORT_BTN_PADDING}
        />
      </ReportsWrapper>
      <NewColorThemeProvider>
        <OriginationProjectReportsList
          generateReportButtonOptions={generateReportButtonOptions}
          selectedReportOption={selectedReportOption}
          reportName={reportName}
          setDefaultReportName={setDefaultReportName}
          setSelectedReportOption={setSelectedReportOption}
          updateReportName={updateReportName}
          isGenerateReportModalOpen={isGenerateReportModalOpen}
          updateGenerateModalState={updateGenerateModalState}
        />
      </NewColorThemeProvider>
    </div>
  );
};

export default Reports;
