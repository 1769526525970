import {
  InputAdornment,
  Select,
  MenuItem,
  OutlinedInput,
  Input,
} from '@mui/material';
import {
  PAGE_CONTENT,
  SubscriptConverter,
} from './SaleVintageSubsection.content';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import DeliveryDetailsModal from 'components/DeliveryDetailsModal/DeliveryDetails.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { ReactComponent as AddIcon } from 'assets/icons/addicon.svg';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  SubWrapper,
  VintageWrapper,
  ErroWrapper,
  FormFields,
  FormLabels,
  DropDownPlaceholder,
  inputNumberSx,
  FormPriceFields,
  AddDepositsLabel,
  AddDepositsWrapper,
  AvailableQuantityLabel,
  VintageLabelWrapper,
} from './SaleVintageSubsection.style';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import { convertToDate } from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { checkTernaryCondition } from 'utils/helper';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import { BLUE } from 'theme/GlobalColors';
import RemoveButton from 'components/RemoveButton/RemoveButton.react';
import { getMenuItemStyles } from 'theme/GlobalStyles';

const today = new Date();

const SaleVintageSubsection = ({
  paymentObj,
  isEdit,
  viewMode,
  onUpdate,
  projectObj,
  vintageNo,
  vintageYears,
  indexNumber,
  onDelete,
}) => {
  const { fetchAvailableQty, availableQty, minDeliveryDate } =
    useTransactionDetails();
  const [isDeliveryDetailsOpen, setIsDeliveryDetailsOpen] = useState(false);
  const [isModalWarningDialogOpen, setIsModalWarningDialogOpen] =
    useState(false);
  const isEditable = true;
  const [clearDeliveryDetails, setClearDeliveryDetails] = useState(false);
  const [pastOccurrenceDetails, setPastOccurrenceDetails] = useState();
  const [projectDeliveryDetails, setProjectDeliveryDetails] = useState([]);
  const [vintages, setVintages] = useState({});

  const [editQuantity, setEditQuantity] = useState('');
  const [editCreditBatchId, setEditCreditBatchId] = useState('');
  const falseValue = false;
  const { markFormDirty } = useContext(EditPurchaseTransactionFormDirtyContext);

  useEffect(() => {
    if (isEdit || viewMode) {
      setEditQuantity(parseFloat(paymentObj.quantity));
      setEditCreditBatchId(paymentObj.vintage);
    }
  }, []);

  const availableQuantityHandler = () => {
    return {
      ...paymentObj,
      AvailableQuantityError:
        paymentObj.quantity <= availableQty ? false : true,
    };
  };

  const availableQuantityDisplayHandler = () => {
    if (isEdit) {
      return paymentObj.vintage !== editCreditBatchId
        ? availableQty
        : availableQty + editQuantity;
    } else {
      return availableQty;
    }
  };

  const handleEditedCreditBatch = (updateObj) => {
    if (editCreditBatchId !== paymentObj.vintage) {
      onUpdate(updateObj);
    } else {
      onUpdate({
        ...paymentObj,
        AvailableQuantityError: validateAvailableQty(
          parseFloat(
            checkTernaryCondition(
              paymentObj.quantity === '',
              0,
              paymentObj.quantity,
            ),
          ),
        )
          ? false
          : true,
      });
    }
  };

  useEffect(() => {
    if (availableQty !== '') {
      let updateObj = availableQuantityHandler();
      if (editCreditBatchId === '') {
        onUpdate(updateObj);
      } else {
        handleEditedCreditBatch(updateObj);
      }
    }
  }, [availableQty, paymentObj.quantity]);

  const activeDeliveryDetails = paymentObj.deliveryDetails
    ?.filter(
      (item) =>
        new Date(item.deliveryDate) >= new Date(today.toJSON().slice(0, 10)),
    )
    .map((item) => {
      return {
        id: uniqueId(),
        scheduleId: item.scheduleId,
        occursOn: item.deliveryDate,
        mtAmount: item.allocatedSupply,
        deliveryStatus: item.deliveryStatus,
        isApiPopulated: item.isApiPopulated,
        rowValid: true,
      };
    });

  const pastDeliveryDetails = paymentObj.deliveryDetails
    ?.filter(
      (item) =>
        new Date(item.deliveryDate) < new Date(today.toJSON().slice(0, 10)),
    )
    ?.map((item) => {
      return {
        id: uniqueId(),
        scheduleId: item.scheduleId,
        occursOn: item.deliveryDate,
        mtAmount: item.allocatedSupply,
        deliveryStatus: item.deliveryStatus,
        isApiPopulated: item.isApiPopulated,
        rowValid: true,
      };
    });

  const validateAvailableQty = (quantity) => {
    if (!paymentObj.isQtyFromApi) {
      return quantity <= availableQty;
    } else if (quantity > editQuantity) {
      return quantity - editQuantity <= availableQty;
    } else {
      return true;
    }
  };

  const quantityHandler = (e) => {
    const value = e.target.value;
    if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
      onUpdate({
        ...paymentObj,
        quantity: value,
        quantityError: value !== '' && falseValue,
      });
    }
  };
  const priceHandler = (e) => {
    if (e.target.value.match(/^\d*(\.\d{0,2})?$/) || e.target.value === '') {
      onUpdate({
        ...paymentObj,
        price: e.target.value,
        priceError: e.target.value !== '' && falseValue,
      });
    }
  };

  const vintageHandler = (e) => {
    const value = e.target.value;
    const [vintageStart, vintageEnd] = vintageYears
      .filter((year) => year.value === value)[0]
      .label.split('-');
    const internalProjectId = projectObj.projectName.internalProjectId;
    setVintages({ vintageStart: vintageStart, vintageEnd: vintageEnd });
    fetchAvailableQty({ internalProjectId, vintageStart, vintageEnd });
    onUpdate({
      ...paymentObj,
      vintage: value,
      vintageError: value !== '' && falseValue,
      vintageDuplicateError: false,
    });
  };

  const handleModalOpenClose = (
    clearState,
    warningDialogState,
    deliveryDetailsState,
  ) => {
    setClearDeliveryDetails(clearState);
    viewMode
      ? setIsModalWarningDialogOpen(false)
      : setIsModalWarningDialogOpen(warningDialogState);
    setIsDeliveryDetailsOpen(deliveryDetailsState);
  };

  useEffect(() => {
    if (projectDeliveryDetails.length > 0) {
      onUpdate({
        ...paymentObj,
        deliveryDetails: projectDeliveryDetails,
        deliveryDetailsError: false,
      });
    }
  }, [projectDeliveryDetails]);

  useEffect(() => {
    if (pastOccurrenceDetails) {
      onUpdate({
        ...paymentObj,
        deliveryDetails: projectDeliveryDetails?.concat(pastOccurrenceDetails),
        deliveryDetailsError: false,
      });
    }
  }, [pastOccurrenceDetails]);

  useEffect(() => {
    if (
      (isEdit || viewMode) &&
      vintageYears.length > 0 &&
      paymentObj.vintage > 0
    ) {
      const [vintageStart, vintageEnd] = vintageYears
        .filter((year) => year.value === paymentObj.vintage)[0]
        .label.split('-');
      const internalProjectId = projectObj.projectName.internalProjectId;
      setVintages({ vintageStart: vintageStart, vintageEnd: vintageEnd });
      fetchAvailableQty({ internalProjectId, vintageStart, vintageEnd });
    }
  }, [vintageYears]);

  return (
    <VintageWrapper isFirstChild={vintageNo !== 0}>
      {!viewMode && (
        <VintageLabelWrapper data-testid="vintage-labels">
          {`${PAGE_CONTENT.vintage_label} ${indexNumber + 1}`}
          {projectObj.paymentDetails?.length > 1 && (
            <RemoveButton
              label={PAGE_CONTENT.remove_vintage}
              clickHandler={() => {
                onDelete(paymentObj.id);
                markFormDirty();
              }}
              labelColor={BLUE}
            />
          )}
        </VintageLabelWrapper>
      )}
      <FormFields>
        <FormLabels
          iserror={paymentObj.vintageError || paymentObj.vintageDuplicateError}>
          {PAGE_CONTENT.vintage_label}
        </FormLabels>
        <Select
          data-testid="vintage-year-value"
          disabled={viewMode || paymentObj.isApiPopulated}
          value={paymentObj.vintage}
          error={paymentObj.vintageError || paymentObj.vintageDuplicateError}
          onChange={(e) => {
            vintageHandler(e);
            markFormDirty();
          }}
          sx={{ width: '8.125rem', height: '2rem', backgroundColor: 'white' }}
          name="vantageStart_type"
          inputProps={{ 'aria-label': 'vantageStart_type' }}
          displayEmpty
          defaultValue=""
          input={<OutlinedInput />}
          renderValue={
            paymentObj.vintage !== ''
              ? undefined
              : () => (
                  <DropDownPlaceholder>
                    {PAGE_CONTENT.vintage_placeholder}
                  </DropDownPlaceholder>
                )
          }>
          {vintageYears.map((type) => (
            <MenuItem
              key={type.value}
              value={type.value}
              sx={getMenuItemStyles}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
        {paymentObj.vintageError && (
          <ErroWrapper>{PAGE_CONTENT.vintage_error}</ErroWrapper>
        )}
        {paymentObj.vintageDuplicateError && (
          <ErroWrapper>{PAGE_CONTENT.vintage_duplicate_error}</ErroWrapper>
        )}
      </FormFields>
      <SubWrapper>
        <FormFields>
          <FormLabels iserror={paymentObj.quantityError}>
            {SubscriptConverter(PAGE_CONTENT.quantity_label, 3, 2)}
          </FormLabels>
          <Input
            disabled={viewMode}
            placeholder={PAGE_CONTENT.quantity_placeholder}
            name="quantity"
            type="text"
            error={paymentObj.quantityError}
            value={paymentObj.quantity}
            onChange={quantityHandler}
            inputProps={{ 'aria-label': 'quantity' }}
            sx={inputNumberSx}
          />
          {/* <InputSubtext>
          {SubscriptConverter(PAGE_CONTENT.quantity_subtext)}
        </InputSubtext> */}
          {!paymentObj.AvailableQuantityError && (
            <AvailableQuantityLabel>
              {availableQty === '' ? 0 : availableQty}{' '}
              {PAGE_CONTENT.available_label}
            </AvailableQuantityLabel>
          )}
        </FormFields>
        <FormPriceFields isEmpty={paymentObj.price !== ''}>
          <FormLabels iserror={paymentObj.priceError}>
            {SubscriptConverter(PAGE_CONTENT.price_label, 2, 1)}
          </FormLabels>
          <Input
            disabled={viewMode}
            placeholder={PAGE_CONTENT.price_placeholder}
            name="price"
            type="text"
            value={paymentObj.price}
            error={paymentObj.priceError}
            onChange={priceHandler}
            inputProps={{ 'aria-label': '' }}
            startAdornment={
              paymentObj.price !== '' ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : (
                ''
              )
            }
            sx={inputNumberSx}
          />
          {/* <InputSubtext>
          {SubscriptConverter(PAGE_CONTENT.price_subtext)}
        </InputSubtext> */}
        </FormPriceFields>
      </SubWrapper>
      {paymentObj.priceError && (
        <ErroWrapper>{PAGE_CONTENT.price_error}</ErroWrapper>
      )}
      {paymentObj.quantityError && (
        <ErroWrapper>{PAGE_CONTENT.quantity_error}</ErroWrapper>
      )}
      {paymentObj.AvailableQuantityError && (
        <ErroWrapper className="Mui-error">
          {PAGE_CONTENT.available_quantity_error_label}
          {availableQty === '' ? 0 : availableQuantityDisplayHandler()}.
        </ErroWrapper>
      )}
      <FormLabels>{PAGE_CONTENT.delivery_details}</FormLabels>
      {paymentObj.deliveryDetailsError && (
        <ErroWrapper className="Mui-error">
          {PAGE_CONTENT.delivery_details_error}{' '}
        </ErroWrapper>
      )}
      {isEdit && (
        <AddDepositsLabel
          onClick={() =>
            handleModalOpenClose(false, isModalWarningDialogOpen, true)
          }>
          {PAGE_CONTENT.view_edit_delivery_details}
        </AddDepositsLabel>
      )}
      {viewMode && (
        <AddDepositsLabel
          onClick={() =>
            handleModalOpenClose(false, isModalWarningDialogOpen, true)
          }>
          {PAGE_CONTENT.view_delivery_details}
        </AddDepositsLabel>
      )}
      {!isEdit && !viewMode && (
        <AddDepositsWrapper
          onClick={() =>
            handleModalOpenClose(false, isModalWarningDialogOpen, true)
          }>
          <AddIcon />
          <AddDepositsLabel>{PAGE_CONTENT.add_deposit_label}</AddDepositsLabel>
        </AddDepositsWrapper>
      )}

      <DeliveryDetailsModal
        viewMode={viewMode}
        viewModeTitle={`${PAGE_CONTENT.view_delivery_details_label} ${
          projectObj.projectName.value || ''
        }`}
        title={`${PAGE_CONTENT.add_delivery_details_label} ${
          projectObj.projectName.value || ''
        }`}
        minDeliveryDate={
          minDeliveryDate !== null ? convertToDate(minDeliveryDate) : ''
        }
        isOpen={isDeliveryDetailsOpen}
        acceptCtnLabel="Submit"
        declineCtnLabel="Cancel"
        creditsAvailable={paymentObj?.quantity}
        onCancel={() => handleModalOpenClose(clearDeliveryDetails, true, false)}
        setProjectDeliveryDetails={setProjectDeliveryDetails}
        hasRadioButton
        setIsDeliveryDetailsOpen={setIsDeliveryDetailsOpen}
        isEditable={viewMode ? !isEditable : isEditable}
        clearDeliveryDetails={clearDeliveryDetails}
        pastOccurrences={isEdit || viewMode ? pastDeliveryDetails : []}
        activeOccurrencesDetails={
          isEdit || viewMode ? activeDeliveryDetails : []
        }
        setProjectPastOccurrenceDetails={setPastOccurrenceDetails}
        deliveryDetailsForSale
        hasPastOccurrences={isEdit || viewMode ? true : false}
        deliveryDetailList={paymentObj.deliveryDetails}
        projectObj={projectObj}
        vintages={vintages}
        editMode={isEdit}
        isModalWarningDialogOpen={isModalWarningDialogOpen}
        isLinkedToSale={false}
      />
      <DialogBox
        dialogActions
        isOpen={viewMode ? false : isModalWarningDialogOpen}
        onCancel={() => handleModalOpenClose(clearDeliveryDetails, false, true)}
        onConfirm={() =>
          handleModalOpenClose(true, false, isDeliveryDetailsOpen)
        }
        title={PAGE_CONTENT.add_delivery_details_warning}
        subtitle={PAGE_CONTENT.add_delivery_details_warning_subtitle}
        declineCtnLabel="Go Back"
        acceptCtnLabel="Cancel"
      />
    </VintageWrapper>
  );
};

SaleVintageSubsection.propTypes = {
  paymentObj: PropTypes.object,
  isEdit: PropTypes.bool,
  viewMode: PropTypes.string,
  onUpdate: PropTypes.func,
  projectObj: PropTypes.object,
  vintageNo: PropTypes.number,
  vintageYears: PropTypes.array,
  indexNumber: PropTypes.number,
  onDelete: PropTypes.func,
};
export default SaleVintageSubsection;
