import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: ${({ cursor }) => cursor};
`;
export const Icon = styled.div`
  fill: ${({ fillColor }) => fillColor};
`;
export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  color: ${({ textColor }) => textColor};
`;

export const TooltipWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  color: #da291c;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  gap: 0.5rem;
`;
export const TooltipMessageWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  color: #333333;
`;
