import styled from 'styled-components';
import { checkTernaryCondition } from 'utils/helper';

export const Container = styled.div`
  border-right: ${({ borderRight }) => borderRight};
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding};
  justify-content: ${({ justifyContent }) => justifyContent};
  display: flex;
  display: -webkit-flex;
  flex-direction: ${({ direction }) => direction};
  min-width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap || '1rem'};
  box-shadow: ${({ hasBoxShadow }) =>
    checkTernaryCondition(
      hasBoxShadow,
      '0px 2px 3px 0px rgba(0, 0, 0, 0.1)',
      'none',
    )};
  border-radius: ${({ borderRadius }) => borderRadius};
  min-height: ${({ minHeight }) => minHeight};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const tooltipSx = {
  marginLeft: '0.5rem',
  fontSize: '1rem',
};
