import React, { useState, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AddIcon from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import {
  SIDENAV_CONTENT,
  isOfParticularType,
  menuItemType,
  menuList,
  projectMenus,
} from './CollapsableBox.content';
import { sidenavContext } from 'contextAPI/SideNavContext';
import { NavLink } from '../NavModule.style';
import {
  TitleSx,
  accordionSx,
  projectAccordionStyle,
  accordionSummarySx,
  addIconSx,
  addIconStyle,
  projectMenuSx,
  projectMenuSummaryStyle,
  ProjectListBox,
  ProjectButtonWrapper,
  projectNameStyle,
} from './CollapsableBox.style';
import {
  ModulesEnableMapper,
  ORIGINATION_PROJECT_CREATE,
  getEnvironmentEnabledFeatures,
  getIsModuleEnabled,
} from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as ExpandButton } from 'assets/icons/ExpandButton.svg';
import { checkTernaryCondition } from 'utils/helper';
import OverFlowTooltip from 'components/OverFLowTooltip/OverFLowTooltip.react';

const CollapsableBox = ({ header, projectList, linkProps, link }) => {
  const { pathname } = useLocation();
  const pathParts = pathname.split('/');
  const isReport = isOfParticularType(header, menuItemType[1]);
  const isProject = isOfParticularType(header, menuItemType[2]);
  const isPortFolio = isOfParticularType(header, menuItemType[0]);
  const [searchParams] = useSearchParams();
  const stakeholderProjectId = searchParams.get('projectid');
  const isInStakeholderPage = pathParts[1] === 'stakeholders';
  const navigate = useNavigate();

  const { setTab, tab } = useContext(sidenavContext);
  const [projectIdSelected, setProjectIdSelected] = useState('');
  const [moduleClicked, setModuleClicked] = useState('');
  const projectId = pathParts[2];
  const { modulesListData, sublet } = useContext(userAuthRolesContext);
  const isProjectCreateEnabled = getEnvironmentEnabledFeatures(
    sublet,
    ORIGINATION_PROJECT_CREATE,
  );

  const isOriginationModuleEnabled = getIsModuleEnabled(
    ModulesEnableMapper.PORTFOLIO_ORIGINATION,
    modulesListData,
  );
  const isInsettingEnabled = getIsModuleEnabled(
    ModulesEnableMapper.PORTFOLIO_INSETTING,
    modulesListData,
  );

  const disableNavLinkClassname = checkTernaryCondition(
    link.isDisabled,
    'disabled',
    '',
  );

  /**
   * @description Function to show NavLink Highlight
   * @param {String} menu
   * @param {Object} project
   * @returns {Boolean} showNavLinklighLight
   */
  const showNavLinkHighlight = (menu, project) => {
    const isTabMenu = menu === tab?.tab;
    const isProjectIdSame = +project.id === +projectId;
    return (
      (isTabMenu && isProjectIdSame) ||
      (menu === projectMenus.summary &&
        tab.tab === '' &&
        pathParts[1] === menuItemType[2].toLowerCase() &&
        isProjectIdSame) ||
      (stakeholderProjectId &&
        +stakeholderProjectId === +project.id &&
        isTabMenu)
    );
  };

  /**
   * @description function to update Element Scroll Info
   * @param {Element} elementRef
   * @param {Object} project
   * @param {String} menu
   */
  const updateElementScrollInfo = (elementRef, project, menu) => {
    if (
      (elementRef && menu === tab?.tab && +project.id === +projectId) ||
      (menu === projectMenus.summary &&
        tab === '' &&
        pathParts[1] === menuItemType[2].toLowerCase() &&
        +project.id === +projectId)
    ) {
      const elementsWithSameClassName = document.getElementsByClassName(
        'MuiAccordionSummary-content Mui-expanded MuiAccordionSummary-contentGutters',
      );
      if (elementsWithSameClassName.length === 2) {
        elementRef?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  return (
    <>
      {isPortFolio && (
        <>
          {(isOriginationModuleEnabled && isInsettingEnabled && (
            <Accordion
              sx={{
                gap: '0px',
                maxWidth: '13.75rem',
              }}
              expanded={
                (moduleClicked === menuItemType[0] ||
                  pathParts[1] === menuItemType[0].toLowerCase()) &&
                moduleClicked !== menuItemType[3]
              }
              onChange={() => {
                if (moduleClicked === menuItemType[0]) {
                  setModuleClicked(menuItemType[3]);
                } else {
                  setModuleClicked(menuItemType[0]);
                }
              }}>
              <AccordionSummary
                sx={TitleSx}
                expandIcon={<ExpandButton />}
                aria-controls="panel1-content"
                id="panel1-header">
                {header}
              </AccordionSummary>
              {menuList.map((menu, index) => {
                const keyVal = { index };
                const shouldRenderAccordionDetails =
                  (index === 0 && isInsettingEnabled) ||
                  (index === 1 && isOriginationModuleEnabled);
                if (shouldRenderAccordionDetails)
                  return (
                    <AccordionDetails
                      sx={accordionSx}
                      key={keyVal}
                      onClick={() =>
                        setTab({ module: menuItemType[0], tab: menu })
                      }>
                      <NavLink
                        data-testid="portfolio-tab"
                        showHighlight={
                          menu === tab?.tab ||
                          (pathParts[1] === menuItemType[0].toLowerCase() &&
                            tab?.tab === '' &&
                            menu === menuList[1])
                        }
                        className={`${link.isDisabled ? 'disabled' : ''} `}
                        {...linkProps}>
                        <div>{menu}</div>
                      </NavLink>
                    </AccordionDetails>
                  );
              })}
            </Accordion>
          )) || (
            <NavLink
              isPortfolio
              style={TitleSx}
              to={SIDENAV_CONTENT.portfolio_route}
              showHighlight={pathParts[1] === menuItemType[0].toLowerCase()}
              className={disableNavLinkClassname}
              onClick={() => {
                setModuleClicked(menuItemType[1]);
                setTab({ module: menuItemType[0], tab: menuList[1] });
              }}>
              <div>{menuItemType[0]}</div>
            </NavLink>
          )}
        </>
      )}
      {isProject && (
        <Accordion
          sx={projectAccordionStyle}
          expanded={
            ((moduleClicked === menuItemType[2] ||
              pathParts[1] === menuItemType[2].toLowerCase()) &&
              moduleClicked !== menuItemType[4]) ||
            isInStakeholderPage
          }
          onChange={() => {
            if (moduleClicked === menuItemType[2]) {
              setModuleClicked(menuItemType[4]);
            } else {
              setModuleClicked(menuItemType[2]);
            }
          }}>
          <AccordionSummary
            sx={{
              ...TitleSx,
              ...accordionSummarySx,
            }}
            expandIcon={!!projectList?.data?.length && <ExpandButton />}
            aria-controls="panel1-content"
            id="panel1-header">
            <ProjectButtonWrapper>
              {header}

              {isProjectCreateEnabled && (
                <AddIcon
                  sx={{
                    ...addIconSx,
                    ...addIconStyle,
                  }}
                  onClick={() => navigate(SIDENAV_CONTENT.project_form_route)}
                />
              )}
            </ProjectButtonWrapper>
          </AccordionSummary>

          <ProjectListBox>
            {projectList?.data?.map((project) => (
              <Accordion
                elevation={0}
                key={project.id}
                sx={projectMenuSx}
                expanded={
                  ((+projectId === +project.id ||
                    +projectIdSelected === +project.id) &&
                    projectIdSelected !== menuItemType[5]) ||
                  (stakeholderProjectId &&
                    +stakeholderProjectId === +project.id)
                }
                onChange={() => {
                  if (projectIdSelected === project.id) {
                    setProjectIdSelected(menuItemType[5]);
                  } else {
                    setProjectIdSelected(project.id);
                  }
                }}>
                <AccordionSummary
                  sx={projectMenuSummaryStyle}
                  expandIcon={<ExpandButton />}
                  aria-controls="panel1-content"
                  id="panel1-header">
                  <OverFlowTooltip
                    value={project.projectName}
                    stylesSx={{
                      ...projectNameStyle,
                      fontWeight: checkTernaryCondition(
                        (+projectId === +project.id &&
                          projectIdSelected !== menuItemType[5]) ||
                          (stakeholderProjectId &&
                            +stakeholderProjectId === +project.id),
                        700,
                        'inherit',
                      ),
                    }}
                  />
                </AccordionSummary>
                {Object.values(projectMenus).map((menu) => (
                  <AccordionDetails
                    key={`${project.id}-${menu}`}
                    sx={accordionSx}
                    onClick={() => {
                      setTab({ module: menuItemType[2], tab: menu });
                    }}>
                    <NavLink
                      isProject
                      showHighlight={showNavLinkHighlight(menu, project)}
                      className={checkTernaryCondition(
                        link.isDisabled,
                        'disabled',
                        '',
                      )}
                      {...{
                        ...linkProps,
                        to: `${linkProps.to}/${project.id}`,
                        ref: (elementRef) => {
                          updateElementScrollInfo(elementRef, project, menu);
                        },
                      }}>
                      <>{menu}</>
                    </NavLink>
                  </AccordionDetails>
                ))}
              </Accordion>
            ))}
          </ProjectListBox>
        </Accordion>
      )}
      {isReport && (
        <NavLink
          to={SIDENAV_CONTENT.reports_route}
          style={TitleSx}
          isReport
          showHighlight={
            moduleClicked === menuItemType[1] ||
            pathParts[1] === menuItemType[1].toLowerCase()
          }
          className={disableNavLinkClassname}
          onClick={() => {
            setModuleClicked(menuItemType[1]);
            setTab(menuItemType[1]);
          }}>
          <div>{menuItemType[1]}</div>
        </NavLink>
      )}
    </>
  );
};
CollapsableBox.propTypes = {
  header: PropTypes.string.isRequired,
  projectList: PropTypes.array.isRequired,
  linkProps: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};
export default CollapsableBox;
