export const TAB_CONTENT = {
  uploadedOnHeading: 'Uploaded on',
  uploadedByHeading: 'Uploaded by',
  fileNameHeading: 'File name',
  emptyStateHeading: 'No soil sampling',
  emptyStateSubtitle: 'Add at least one file to get started.',
  btnText: 'Soil sampling',
  projectCycleHeading: 'Project cycle',
  btnText2: 'Add soil sampling',
};
