import { TooltipHyperLink } from './FileUploadCompPOI.style';
import PdfIcon from 'assets/icons/PDF.svg';
import DocIcon from 'assets/icons/DOC.svg';
import XlsIcon from 'assets/icons/XLS.svg';

export const PAGE_CONTENT = {
  browse: ' browse',
  max_file_info_text: 'Max. file size: ',
  fieldDropdownPlaceholder: 'Tag field',
  practiceDropdownPlaceholder: 'Tag practice',
  submitted: 'Submitted',
  incorrect_geo_reference_error:
    'Georeference does not match any existing fields. Please select a field tag.',
  field_tag_required_error: 'Field tag required.',
  missing_geo_reference_error:
    'Georeference missing. Please select a field tag.',
  practice_tag_required_error: 'Practice tag required.',
  multipleGeoReferenceError:
    'Georeference matches multiple fields. Please review suggested matches and keep only one field tag.',
  FULL_STOP: '.',
};
export const formatBytes = (bytes) => {
  const megabyte = 1024 * 1024;
  return bytes / megabyte + ' MB';
};
export const maxLimitError = (maxLimit) =>
  `File size exceeds maximum of ${maxLimit} allowed.`;
export const getFileUploadError = (
  errorCode,
  maxLimit,
  fileNotSupportedText,
) => {
  switch (errorCode) {
    case 'file-invalid-type':
      return fileNotSupportedText;
    case 'file-too-large':
      return maxLimitError(formatBytes(maxLimit));
    default:
      return 'Error';
  }
};

export const dropdownProps = {
  isMultiple: true,
  isNotOutlined: false,
  showLabelAsValue: true,
  hasNoBottomMargin: true,
  width: '182px',
  minWidth: '182px',
  customSx: {
    '& .MuiInputBase-input': {
      padding: '6px 8px',
    },
  },
};

export const GeoReferenceTooltip = () => {
  return (
    <div
      style={{ width: '15.5rem', fontSize: '0.75rem', lineHeight: '1.05rem' }}>
      Geo-reference data is required to be attached to images submitted. When
      taking photos, ensure that the following settings are active. Check your
      phones settings here:{' '}
      <TooltipHyperLink
        href="https://support.apple.com/en-us/102647"
        rel="noreferrer"
        target="_blank">
        Apple
      </TooltipHyperLink>{' '}
      or{' '}
      <TooltipHyperLink
        href="https://support.google.com/accounts/answer/3467281?hl=en#zippy="
        target="_blank"
        rel="noreferrer">
        Android
      </TooltipHyperLink>
    </div>
  );
};

export const getImgSrc = (file, fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return PdfIcon;
    case 'doc':
      return DocIcon;
    case 'xls':
      return XlsIcon;
    default:
      return `data:image/png;base64,${file}`;
  }
};
