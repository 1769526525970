import axios from 'axios';
import { loaderContext } from 'contextAPI/loaderContext';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DESIGN_SETTING_PAGE } from 'urls';

export const useProjectDesignSettingTab = () => {
  const { id } = useParams();
  const { setIsLoading } = useContext(loaderContext);
  const [designSetting, setDesignSetting] = useState({
    customMethodology: false,
    customMethodologyName: '',
    referenceMethodologyId: 1,
    modelingProvider: '',
  });

  const callDesignSettingApi = async () => {
    setIsLoading(true);
    const { data } = await axios.get(`${DESIGN_SETTING_PAGE}${id}`);
    setIsLoading(false);
    setDesignSetting(data);
  };

  useEffect(() => {
    callDesignSettingApi();
  }, []);

  return {
    ...designSetting,
  };
};
