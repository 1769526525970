import {
  COOL_GRAY,
  DARK_CHARCOAL,
  ROW_FOCUSED_BG,
  ROW_HOVER_BG,
} from 'theme/GlobalColors';
import { Paper } from '@mui/material';

export const chipStyle = {
  color: COOL_GRAY,
  border: `0.5px solid ${COOL_GRAY}`,
  background: '#FFFFFF ',
  borderRadius: '4px',
  height: '1.25rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 4px',
  gap: '4px',
  '&.MuiAutocomplete-tag': {
    margin: '0rem 0.25rem 0.25rem 0rem',
  },
  '& .MuiChip-label': {
    padding: '0rem',
  },
  '& .MuiChip-deleteIcon': {
    margin: 0,
  },
};
export const slotProps = {
  popper: {
    sx: {
      zIndex: 0,
    },
  },
};
export const placeholderSx = {
  '& .MuiInputBase-input::placeholder': {
    visibility: 'hidden',
  },
};
export const dropdownArrowSx = {
  '.MuiAutocomplete-popupIndicator': {
    color: DARK_CHARCOAL,
  },
};
export const CustomPaper = ({ children }) => {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-listbox': {
          '& .MuiAutocomplete-option': {
            fontSize: '14px',
            padding: '0.25rem 0.5rem',
            lineHeight: '20px',
          },
          "& .MuiAutocomplete-option[aria-selected='true']": {
            backgroundColor: ROW_FOCUSED_BG,
            fontWeight: 600,
            '&.Mui-focused': {
              backgroundColor: ROW_FOCUSED_BG,
            },
          },
        },
        '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
          backgroundColor: ROW_FOCUSED_BG,
          '&:hover': {
            background: ROW_HOVER_BG,
          },
        },
      }}>
      {children}
    </Paper>
  );
};
