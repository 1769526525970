import styled from 'styled-components';

export const TextWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  justify-content: start;
  align-items: center;
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;
export const OperationsDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
