import PropTypes from 'prop-types';
import {
  MainWrapper,
  AccessDeniedWrapper,
  AccessDeniedHeadingNormal,
} from './AccessDeniedPage.style';
import { ACCESS_DENIED_PAGE_CONTENT } from './AccessDeniedPage.content';

const AccessDeniedPageForParticipantContributor = ({ emailSupport }) => {
  return (
    <MainWrapper>
      <AccessDeniedWrapper>
        <AccessDeniedHeadingNormal>
          {ACCESS_DENIED_PAGE_CONTENT.access_denied_heading}
        </AccessDeniedHeadingNormal>
        <div>{`${ACCESS_DENIED_PAGE_CONTENT.permission_body} ${emailSupport} ${ACCESS_DENIED_PAGE_CONTENT.for_support}`}</div>
      </AccessDeniedWrapper>
    </MainWrapper>
  );
};

export default AccessDeniedPageForParticipantContributor;

AccessDeniedPageForParticipantContributor.propTypes = {
  emailSupport: PropTypes.string.isRequired,
};
