import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { GET_PROJECT_LIFE_CYCLE_LIST } from 'urls';

export const useProjectCycleData = () => {
  const [projectCycleList, setProjectCycleList] = useState([]);
  const fetchProjectCycleList = (projectId) => {
    return axios
      .get(`${GET_PROJECT_LIFE_CYCLE_LIST}/${projectId}`)
      .then((response) => {
        setProjectCycleList(
          response.data.map((item) => ({
            label: `${moment(item.cycleEndDate).format('YYYY')} (${moment(
              item.cycleStartDate,
            ).format('MM/DD/YYYY')} - ${moment(item.cycleEndDate).format(
              'MM/DD/YYYY',
            )})`,
            value: item.projectCycleId,
          })),
        );
      });
  };
  return {
    projectCycleList,
    fetchProjectCycleList,
    setProjectCycleList,
  };
};
