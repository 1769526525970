import { useParams, useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  MainWrapper,
  datagridSx,
  FilterHeader,
  DataGridWrapper,
} from '../../../ProjectCatalog/ProjectCatalog.style';
import {
  ParticipantButtonWrapper,
  TableWrapper,
  LinkWrapper,
  LinkItem,
  ContributorStatusWrapper,
  SearchFilterWrapper,
  AccountStatusFilterWrapper,
  ActivityStatusWrapper,
  filterIconSx,
  ActivityStatusFlex,
  ParticipantNameCellWrapper,
  participantNameSx,
  participantEmailSx,
} from './Participants.style';
import { DataGrid } from '@mui/x-data-grid';
import { useInterval } from 'hooks/useInterval';
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SimpleOptionsButton from 'components/SimpleOptionsButton/SimpleOptionsButton.react';
import {
  listingParticipantsColumn,
  LABELS,
  baselineModalPropsInit,
  initialReviewEnrollmentProp,
  getActionIdFromActionCode,
  getParticipantStatus,
  getParticipantUserStatus,
  getParticipantUserStatusCode,
  PARTICIPANT_TYPES,
  viewDetailsLink,
  getDynamicRowHeight,
  statusOrder,
} from './Participants.content';
import {
  DELETE_PARTICIPANT,
  FETCH_ALL_USER_ACTIONS,
  FETCH_PARTICIPANTS,
  FETCH_POSSIBLE_ACTIVITY_STATUS,
  FETCH_FILTER_ACCOUNT_STATUS,
  PARTICIPANTS_LIST,
} from 'urls';
import NodataComponent from 'components/NoDataComp/NodataContent.react';
import AddParticipants from './AddParticipants/AddParticipants.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import {
  checkAccess,
  checkTernaryCondition,
  getLatestDate,
  debounceFunc,
  getBooleanValueForNullOrUndefined,
} from 'utils/helper';
import { pathName } from 'Routes/routes.const';
import EditParticipantProfile from './EditParticipantProfile/EditParticipantProfile.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ADMIN,
  ORIGINATION_PARTICIPANT_CREATE,
  ORIGINATION_PARTICIPANT_DELETE,
  ORIGINATION_PARTICIPANT_UPDATE,
  ORIGINATION_MANAGER,
  TOOLTIP_PLACEMENTS,
} from 'utils/config';
import {
  DARK_CHARCOAL,
  DIM_GRAY,
  GRAYISH_BLUE,
  TYPOGRAPHY_GRAY,
  WHITE,
} from 'theme/GlobalColors';
import {
  TablePaginationMenuListStyle,
  pointerCursor,
} from 'theme/GlobalStyles';
import { Tooltip, Typography } from '@mui/material';
import { enrollmentReview } from './EnrollmentReview';
import { baselineReview } from './BaselineReview';
import { ReopenCustomModal } from './ReOpenTemplate';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import {
  ClearLink,
  FilterChip,
  SearchBox,
} from 'pages/OriginationProjectList/OriginationProjectList.style';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { LoaderProvider } from 'contextAPI/loaderContext';
import AddParticipantsViaFileUpload from './AddParticipantsViaFileUpload/AddParticipantsViaFileUpload.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { NotificationLogProvider } from 'containers/NotificationLogContext.react';
import { UpdateAccount } from './ParticipantAccount/UpdateAccountModal';
import FiltersPopup from 'pages/OriginationProjectList/components/FiltersPopup/FiltersPopup.react';
import { PAGE_CONTENT } from 'components/FilterComponent/FilterComponent.content';
import { clearIconStyle } from 'components/SearchAndFilterComponent/SearchAndFilterComponent.style';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import { useProjectCycleData } from 'hooks/useProjectCycleData';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { ORIGINATION_SUMMARY_TAB_CONTENT } from 'pages/Origination/OriginationSummaryTab/OriginationSummaryTab.content';
import {
  dropDownStyle,
  labelStyle,
} from 'pages/Origination/OriginationSummaryTab/OriginationSummaryTab.style';
import OverFlowTooltip from 'components/OverFLowTooltip/OverFLowTooltip.react';
import LightTooltip from 'components/LightTooltip';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { getStructuredObject } from './ListParticipants.functions';
import ParticipantsImage from 'assets/Participants.png';
import { customBtnSx } from './ParticipantSummary/ParticipantProfile.style';

const LIST_REFRESH_INTERVAL = 5000;

const ListParticipants = ({
  disableVirtualization,
  projectName,
  participantDialogOpen,
  setParticipantDialogOpen,
  multipleParticipantsFileUploadOpen,
  setMultipleParticipantsFileUploadOpen,
  setHasNoParticipants,
  refreshHandler = () => {
    /* do nothing here */
  },
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userRoles } = useContext(userAuthRolesContext);
  const { projectCycleList, fetchProjectCycleList } = useProjectCycleData();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });
  const [payload, setPayload] = useState({
    filter: {},
    projectId: null,
    pageNumber: null,
    pageSize: null,
    newestToOldest: null,
  });
  const [newDataSet, setNewDataSet] = useState([]);
  const [currentRow, setCurrentRow] = useState();
  const [modifiedList, setModifiedList] = useState({
    PARTICIPANT_NAME: [],
    PARTICIPANT_EMAIL: [],
  });
  const [participantsNameSelectedFilter, setParticipantsNameSelectedFilter] =
    useState([]);
  const [
    participantsNameIdSelectedFilter,
    setParticipantsNameIdSelectedFilter,
  ] = useState([]);
  const [participantsEmailSelectedFilter, setParticipantsEmailSelectedFilter] =
    useState([]);

  const order = false;
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [filterData, setFilterData] = useState({
    participantsList: [],
    contactList: [],
    contractsList: [],
  });
  const [participantNameIdFilter, setParticipantNameIdFilters] = useState([]);

  const [isUpdateAccountModalOpen, setUpdateAccountModalOpen] = useState({
    open: false,
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [enrollModalData, setEnrollModalData] = useState({
    flag: false,
    id: 0,
    projectData: [],
    projectId: 0,
    participantName: '',
    instanceId: -1,
    shouldGenerateNewInstance: false,
  });
  const [baselineModalProps, setBaselineModalProps] = useState(
    baselineModalPropsInit,
  );
  const [reviewEnrollmentProps, setReviewEnrollmentProps] = useState(
    initialReviewEnrollmentProp,
  );
  const [reopenProps, setReopenProps] = useState(initialReviewEnrollmentProp);
  const [addContractOpen, setAddContractOpen] = useState({
    isOpen: false,
    participantId: null,
    projectId: null,
  });
  const { permissions } = useContext(userAuthRolesContext);
  const [participantSearchText, setParticipantSearchText] = useState('');

  const [hasNoParticipant, setHasNoParticipant] = useState(false);
  const debounceTimerRef = useRef(null);
  const [statusMappingList, setStatusMappingList] = useState([]);
  const [participantAccount, setParticipantAccount] = useState({});
  const [accountStatusFilterList, setAccountStatusFilterList] = useState([]);
  const [participantActivity, setParticipantActivity] = useState([]);
  const [activityStatusFilterList, setActivityStatusFilterList] = useState([]);
  const [cycleValue, setCycleValue] = useState('');
  const { areUiActionInProgress } = useContext(ConfigurationContext);
  const location = useLocation();

  const getLatestEnrollmentInstanceId = (enrollmentInstances = []) => {
    let latestDate = getLatestDate(
      enrollmentInstances?.map((instance) => instance.createdDate),
    );
    return (
      enrollmentInstances.find(
        (instance) => instance.createdDate === latestDate,
      )?.enrollmentInstanceId ?? null
    );
  };

  const currentRowSetter = (row) => {
    setCurrentRow(row);
  };

  // Note: This function cleans up the applied "Account status" filter in case any filter is applied which has no participant/contributor, upon updating account status of a participant, applied filter might have redundant data, we are cleaning the filter states here
  const removeUnwantedFilters = (accountStatusList) => {
    const statusCodesList = accountStatusList?.map((status) =>
      getParticipantUserStatusCode(status),
    );
    setAccountStatusFilterList((prev) => {
      return prev.filter((filterLabel) =>
        statusCodesList.includes(filterLabel),
      );
    });
  };

  const fetchAccountStatus = () => {
    axios
      .get(FETCH_FILTER_ACCOUNT_STATUS, {
        params: { projectId: id, projectCycleId: cycleValue },
      })
      .then((response) => {
        const accountStatus = response.data?.map(getParticipantUserStatus);
        removeUnwantedFilters(accountStatus);
        setParticipantAccount(accountStatus);
      })
      .catch(() => {
        /* do api error handling here */
      });
  };

  const fetchActivityStatus = () => {
    axios
      .get(FETCH_POSSIBLE_ACTIVITY_STATUS, {
        params: { projectId: id, projectCycleId: cycleValue },
      })
      .then((response) => {
        const statusObject = response.data;
        const activityMapping = Object.entries(statusObject).flatMap(
          ([status, actionIds]) => {
            if (status == LABELS.enrollment_approved) {
              status = LABELS.baseline_not_started;
            } else if (status == LABELS.baseline_approved) {
              status = LABELS.activity_not_started;
            }
            return {
              actionId: actionIds,
              status: status,
            };
          },
        );
        // Sort the activityMapping based on the statusOrder
        activityMapping.sort((a, b) => {
          const aIndex = statusOrder.findIndex((orderStatus) =>
            a.status.startsWith(orderStatus),
          );
          const bIndex = statusOrder.findIndex((orderStatus) =>
            b.status.startsWith(orderStatus),
          );

          if (aIndex === bIndex) {
            // If statuses are the same, sort by actionIds
            return a.actionId[0] - b.actionId[0];
          }
          return aIndex - bIndex;
        });
        setParticipantActivity(activityMapping);
      });
  };

  const fetchAllUserActions = () => {
    axios.get(FETCH_ALL_USER_ACTIONS).then((response) => {
      setStatusMappingList(response.data);
    });
  };

  useEffect(() => {
    cycleValue !== '' && fetchAccountStatus();
    cycleValue !== '' && fetchActivityStatus();
  }, [cycleValue]);

  useEffect(() => {
    fetchFilterData();
    fetchAllUserActions();
    fetchProjectCycleList(id).then(() => {
      fetchParticipantsList(!!participantSearchText, true, false);
    });
    return () => {
      setParticipantsNameSelectedFilter([]);
      setParticipantsEmailSelectedFilter([]);
    };
  }, []);

  useEffect(() => {
    cycleValue !== '' &&
      payload.projectId !== null &&
      debouncedFetchParticipantsList(!!participantSearchText);
  }, [participantSearchText, cycleValue]);

  useEffect(() => {
    handelFilterClick();
  }, [filterData]);

  useEffect(() => {
    payload.projectId !== null &&
      debouncedFetchParticipantsList(!!participantSearchText);
  }, [
    participantsNameSelectedFilter,
    participantsNameIdSelectedFilter,
    participantsEmailSelectedFilter,
    order,
    pageState.pageSize,
    pageState.page,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      cycleValue !== '' &&
        payload.projectId !== null &&
        fetchParticipantsList(!!participantSearchText, true, false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    baselineModalProps.open,
    reviewEnrollmentProps.modalData.flag,
    reopenProps.modalData.flag,
    enrollModalData.flag,
    addContractOpen.isOpen,
    accountStatusFilterList,
    activityStatusFilterList,
    cycleValue,
  ]);

  useEffect(() => {
    let participantIds = [];
    participantNameIdFilter.forEach((data) => {
      if (participantsNameSelectedFilter?.includes(data?.label)) {
        participantIds.push(data?.id);
      }
    });
    setParticipantsNameIdSelectedFilter(participantIds);
  }, [participantsNameSelectedFilter]);

  const {
    updateParticipantStatus,
    generateBaselineId,
    updateParticipantBaselineStatus,
  } = useContext(LifeCycleContext);

  const fetchParticipantsList = (
    isSearch = false,
    isInitialCall = false,
    enableLoader = true, // Property mainly used to avoid loading spinner on polling request to fetch data and change status frequently. ESGCC-14539 & ESGCC-14602
  ) => {
    const filterObject = {
      participant: participantsNameIdSelectedFilter,
      contact: participantsEmailSelectedFilter,
      participantNameOrEmailSearch: participantSearchText,
      participantAccountStatuses: accountStatusFilterList,
      participantAccountActivity: activityStatusFilterList?.flatMap(
        (item) => item.actionId,
      ),
      projectCycleId: [cycleValue],
    };
    const projectIdToNewestToOldest = {
      projectId: id,
      pageNumber: isSearch ? 0 : pageState.page - 1,
      pageSize: pageState.pageSize,
      newestToOldest: order,
    };
    const newPayload = {
      filter: filterObject,
      ...projectIdToNewestToOldest,
    };
    const payloadOthersProps = { ...payload };
    delete payloadOthersProps.filter;
    // isDataFetched should not set during polling request to avoid flickering of data.
    enableLoader && setIsDataFetched(false);
    setPageState((old) => ({ ...old, isLoading: enableLoader }));
    // to fetch data with pagesize, pagebody  and filter
    axios
      .post(PARTICIPANTS_LIST, newPayload)
      .then((response) => {
        if (isInitialCall && response.data?.content?.length === 0) {
          setHasNoParticipant(true);
        }
        if (response.data?.content?.length > 0) setHasNoParticipant(false);
        let participants = [];
        response.data?.content?.forEach((participant) => {
          participants.push({
            id: participant.participantId,
            type: checkTernaryCondition(
              participant.participantType === PARTICIPANT_TYPES.owner,
              LABELS.Owner,
              LABELS.Contributor,
            ),
            participantfullname:
              participant.participantFirstName +
              ' ' +
              participant.participantLastName,
            email: participant.participantEmail,
            actionId: participant?.actionId,
            baselineActionId: participant?.baselineActionId,
            isBaselineInititated:
              participant?.actionId === 12 &&
              participant?.baselineActionId !== null,
            formId: participant?.formId,
            ...participant,
            userStatus: getParticipantUserStatus(participant?.userStatus),
          });
        });
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: participants,
          total: response.data.totalElements,
        }));
        cycleValue !== '' && setIsDataFetched(true);
      })
      .finally(() => {
        setPayload(newPayload);
      });
  };

  useInterval(async () => {
    cycleValue !== '' &&
      payload.projectId !== null &&
      areUiActionInProgress === 0 &&
      fetchListAndFilterData();
  }, LIST_REFRESH_INTERVAL);

  const fetchListAndFilterData = () => {
    fetchParticipantsList(!!participantSearchText, false, false);
    fetchFilterData();
  };

  const debouncedFetchParticipantsList = debounceFunc(
    fetchParticipantsList,
    500,
    debounceTimerRef,
  );

  // Need to change based on Project ID
  const fetchFilterData = () => {
    axios
      .get(FETCH_PARTICIPANTS, {
        params: {
          projectId: id,
          name: '',
          isLinked: true,
        },
      })
      .then((response) => {
        let participants = [];
        let contacts = [];
        let participantName_Id = [];
        let contractsList = [];
        response.data.forEach((participant) => {
          participants.push({
            id:
              participant?.participantFirstName +
              ' ' +
              participant?.participantLastName,
            label:
              participant?.participantFirstName +
              ' ' +
              participant?.participantLastName,
          });
          participantName_Id.push({
            id: participant?.participantId,
            label:
              participant?.participantFirstName +
              ' ' +
              participant?.participantLastName,
          });
          contacts.push({
            id: participant?.participantEmail,
            label: participant?.participantEmail,
          });
          contractsList.push({
            id: participant?.participantId,
            contracts: participant?.contracts,
          });
        });
        setFilterData({
          ...filterData,
          participantsList: participants,
          contactList: contacts,
          contractsList: contractsList,
        });
        setParticipantNameIdFilters(participantName_Id);
      })
      .catch(() => {
        // react on errors.
      });
  };

  const addEditParticipantsHandler = (participantId) => {
    if (participantId === 0) {
      setParticipantDialogOpen({ flag: true, id: 0 });
    } else {
      setParticipantDialogOpen({ flag: true, id: participantId });
    }
  };

  const deleteParticipant = () => {
    setIsDeleteDialogOpen(false);
    const successMessage = `${currentRow?.participantFirstName} ${currentRow?.participantLastName} was successfully removed from ${projectName}.`;
    const errorMessage = `${currentRow?.participantFirstName} ${currentRow?.participantLastName} failed to be removed from ${projectName}. Please try again.`;
    axios
      .delete(
        `${DELETE_PARTICIPANT}/${currentRow?.participantId}?internalProjectId=${id}`,
      )
      .then(() => {
        toast(<CustomSnackbar type="success" message={successMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchParticipantsList(!!participantSearchText);
        refreshHandler();
      })
      .catch(() => {
        toast(<CustomSnackbar type="error" message={errorMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handelFilterClick = () => {
    const participantNameList = filterData.participantsList?.map((data) =>
      getStructuredObject(data),
    );
    const participantEmailList = filterData.contactList?.map((data) =>
      getStructuredObject(data),
    );

    setModifiedList(() => ({
      ...modifiedList,
      PARTICIPANT_NAME: [...participantNameList],
      PARTICIPANT_EMAIL: [...participantEmailList],
    }));
  };

  const transformActivityMapToList = (activityStatus) => {
    return activityStatus?.map(({ actionId, status }, index) => ({
      id: index,
      itemLabel: status,
      checked: activityStatusFilterList.some((filter) => filter.id === index),
      actionId: actionId,
    }));
  };

  const transformAccountMapToList = (map) => {
    return Object.entries(map)
      .filter(([, itemLabel]) => itemLabel !== '') // Ignored the first parameter
      .map(([labelId, itemLabel]) => ({
        id: labelId,
        itemLabel,
        checked: accountStatusFilterList.includes(
          getParticipantUserStatusCode(itemLabel),
        ),
      }));
  };

  let filterSections = [
    ...[
      {
        heading: LABELS.activity_status,
        filters: transformActivityMapToList(participantActivity),
      },
    ],
    {
      heading: LABELS.account_status,
      filters: transformAccountMapToList(participantAccount),
    },
  ];

  const updateFilterStates = (sectionHeading, checkedFields) => {
    switch (sectionHeading) {
      case LABELS.account_status: {
        setAccountStatusFilterList(checkedFields);
        break;
      }
      case LABELS.activity_status: {
        setActivityStatusFilterList(checkedFields);
      }
    }
  };

  const handleFilterSectionUpdate = (sections) => {
    sections.forEach((section) => {
      const checkedFields = section.filters
        .filter((filter) => filter.checked === true)
        ?.map((filter) => {
          if (section.heading === LABELS.activity_status) {
            return {
              id: filter.id,
              actionId: filter.actionId,
              itemLabel: filter.itemLabel,
            };
          } else {
            return getParticipantUserStatusCode(filter.itemLabel);
          }
        });
      updateFilterStates(section.heading, checkedFields);
    });
  };

  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    filterSections.forEach((section) => {
      section.filters?.map((filter) => {
        if (filter.checked) count++;
      });
    });

    return count;
  }, [filterSections]);

  const generateBaselineInstanceAndUpdateStatus = async (
    participantId,
    projectId,
  ) => {
    return generateBaselineId({
      projectId: projectId,
      participantId: participantId,
      enrollmentType: 'Baseline',
    }).then((response) => {
      const formId = response.data;
      return updateParticipantBaselineStatus({
        formId: response.data,
        actionId: getActionIdFromActionCode(
          'BASELINE_DEFAULT',
          statusMappingList,
        ),
      }).then(() => formId);
    });
  };

  const columns = [
    {
      field: listingParticipantsColumn.participantId.field,
      hide: true,
    },
    {
      field: listingParticipantsColumn.participant.field,
      headerName: listingParticipantsColumn.participant.label,
      sortable: false,
      renderHeader: () => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <span className="bold-600">
            {listingParticipantsColumn.participant.label}
          </span>
        </FilterHeader>
      ),
      renderCell: ({ value, row: { email } }) => (
        <ParticipantNameCellWrapper>
          <OverFlowTooltip value={value} stylesSx={participantNameSx} />
          <OverFlowTooltip value={email} stylesSx={participantEmailSx} />
        </ParticipantNameCellWrapper>
      ),
      disableColumnMenu: true,
      minWidth: 216,
      flex: 1,
    },
    {
      field: listingParticipantsColumn.type.field,
      headerName: listingParticipantsColumn.type.label,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 123,
      flex: 1,
    },
    {
      field: listingParticipantsColumn.status.field,
      headerName: listingParticipantsColumn.status.label,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 350,
      renderCell: (params) => {
        return checkTernaryCondition(
          params.row.type === LABELS.Contributor,

          <ContributorStatusWrapper>
            {LABELS.not_applicable_label}
          </ContributorStatusWrapper>,
          <Typography>
            {params.row.enrollmentInstanceStatus?.map((instanceData) => (
              <LightTooltip
                key={instanceData.enrollmentInstanceId}
                title={getParticipantStatus(statusMappingList, instanceData)}>
                {getParticipantStatus(statusMappingList, instanceData)}
              </LightTooltip>
            ))}
          </Typography>,
        );
      },
      flex: 1,
    },
    {
      field: listingParticipantsColumn.viewDetails.field,
      headerName: listingParticipantsColumn.viewDetails.label,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 218,
      renderCell: (params) => {
        const linkList = checkTernaryCondition(
          params.row?.type === LABELS.Owner,
          viewDetailsLink(params.row.participantId, id),
          viewDetailsLink(params.row.participantId, id).slice(0, 1),
        );
        return (
          <LinkWrapper>
            {linkList.map((link, index) => (
              <div
                key={link.name}
                onClick={() => {
                  navigate(link.url, {
                    state: { ...link.state, cycleId: cycleValue },
                  });
                }}
                onKeyDown={() => {}}>
                <LinkItem>{link.name}</LinkItem>{' '}
                <span>{index < linkList.length - 1 && '|'}</span>
              </div>
            ))}
          </LinkWrapper>
        );
      },
      flex: 1,
    },
    {
      field: listingParticipantsColumn.userStatus.field,
      headerName: listingParticipantsColumn.userStatus.label,
      sortable: false,
      renderHeader: () => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <span className="bold-600">
            {listingParticipantsColumn.userStatus.label}
          </span>
        </FilterHeader>
      ),
      disableColumnMenu: true,
      minWidth: 111,
      flex: 1,
    },
    {
      field: listingParticipantsColumn.actions.field,
      headerName: listingParticipantsColumn.actions.label,
      disableColumnMenu: true,
      minWidth: 80,
      sortable: false,
      renderCell: (params) => (
        <div
          onClick={() => {
            currentRowSetter(params?.row);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              currentRowSetter(params?.row);
            }
          }}
          data-testid={`options-${params.row?.id}`}>
          <SimpleOptionsButton
            disableIconButton={
              !(
                checkAccess(userRoles, ADMIN) ||
                checkAccess(userRoles, ORIGINATION_MANAGER)
              )
            }
            hasDeleteOption={false}
            hasEditOption={false}
            updateStatusAccountOptionDisabled={
              !(
                checkAccess(userRoles, ADMIN) ||
                checkAccess(userRoles, ORIGINATION_MANAGER)
              )
            }
            hasContract
            horizontalIcon={true}
            addContactText={LABELS.addContact}
            onContractClick={() => {
              setAddContractOpen({
                isOpen: true,
                participantId: params.row.participantId,
                projectId: id,
              });
            }}
            simpleOptionsSx={{ paddingLeft: '0rem', marginLeft: '0.8rem' }}
            editId={params.row.participantId}
            editOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE)
            }
            onEdit={addEditParticipantsHandler}
            onDelete={() => {
              setIsDeleteDialogOpen(true);
            }}
            deleteOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_DELETE) ||
              params.row.participantBaselineStatus === 'Enrolled'
            }
            showEnrollOption={true}
            enrollOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE)
            }
            onEnroll={() => {
              setEnrollModalData({
                ...params.row,
                flag: true,
                id: params.row.participantId,
                projectData: [],
                projectId: id,
                participantName: `${params.row.participantfullname}`,
                contractId: params.row.contracts[0].contractId,
                instanceId: getLatestEnrollmentInstanceId(
                  params.row.contracts[0]?.enrolmentInstances,
                ),
                shouldGenerateNewInstance: false,
                actionCode: statusMappingList.find(
                  (data) => data.actionId === params.row.actionId,
                ).actionCode,
              });
            }}
            showEnterBaselineOption={true}
            // Note: Currently participant manager will be blocked to enter baseline, this check is temporary and will be removed later.
            enterBaselineOptionDisabled={
              userRoles.includes('PARTICIPANT_MANAGER') ||
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE)
            }
            reviewBaselineDisabled={userRoles.includes('PARTICIPANT_MANAGER')}
            onEnterBaselineClick={async () => {
              let formId = params.row.formId;
              if (
                getActionIdFromActionCode(
                  'ENROLL-APPROVE',
                  statusMappingList,
                ) === params.row.actionId &&
                params.row.baselineActionId === null
              ) {
                formId = await generateBaselineInstanceAndUpdateStatus(
                  params.row.participantId,
                  id,
                );
              }
              setBaselineModalProps({
                open: true,
                actionId: params.row.actionId,
                formId: formId,
                participantName: `${params.row.participantFirstName} ${params.row.participantLastName}`,
                navigateLink: `${pathName.participant.baselineMenuPage}?projectid=${id}&participantid=${params.row.participantId}`,
                navigateState: {
                  projectId: id,
                  projectName: projectName,
                  participantId: params.row.participantId,
                  participantFirstName: params.row.participantFirstName,
                  participantLastName: params.row.participantLastName,
                  participantEmail: params.row.participantEmail,
                },
                actionCode: statusMappingList.find(
                  (data) => data.actionId === params.row.baselineActionId,
                )?.actionCode,
              });
            }}
            enterBaselineLabel={LABELS.enterBaselineLabel}
            reviewEnrollmentLabel={LABELS.reviewEnrollmentLabel}
            onClickReviewEnrollment={() => {
              Promise.resolve(
                setReviewEnrollmentProps({
                  ...enrollmentReview(params.row.participantId, id),
                  modalData: { flag: true },
                  instanceId: getLatestEnrollmentInstanceId(
                    params.row.contracts[0].enrolmentInstances,
                  ),
                  actionCode: statusMappingList.find(
                    (data) => data.actionId === params.row.actionId,
                  )?.actionCode,
                  contractId: params.row.contracts[0].contractId,
                }),
              ).then(
                getActionIdFromActionCode(
                  'ENROLL-SUBMIT',
                  statusMappingList,
                ) === params.row.actionId &&
                  updateParticipantStatus({
                    contractId: params.row.contracts[0].contractId,
                    projectId: id,
                    participantId: params.row.participantId,
                    instanceId: getLatestEnrollmentInstanceId(
                      params.row.contracts[0]?.enrolmentInstances,
                    ),
                    actionId: getActionIdFromActionCode(
                      'ENROLL-REVIEW',
                      statusMappingList,
                    ),
                  }),
              );
            }}
            reviewBaselineLabel={LABELS.reviewBaselineLabel}
            onClickReviewBaseline={() => {
              setReviewEnrollmentProps({
                ...baselineReview(params.row.participantId, id),
                modalData: { flag: true },
                formId: params.row.formId,
                baselineActionId: params.row.baselineActionId,
              });
              getActionIdFromActionCode(
                'BASELINE-SUBMIT',
                statusMappingList,
              ) === params.row.baselineActionId &&
                updateParticipantBaselineStatus({
                  formId: params.row.formId,
                  actionId: getActionIdFromActionCode(
                    'BASELINE-REVIEW',
                    statusMappingList,
                  ),
                });
            }}
            hasUpdateStatusAccount={true}
            onUpdateAccountStatus={() => {
              setUpdateAccountModalOpen({ params, open: true });
            }}
          />
        </div>
      ),
      flex: 0.75,
    },
  ];

  const addButtonOptions = [
    {
      id: 1,
      name: 'Add participant owner',
      modalHandler: () => addEditParticipantsHandler(0),
      hasDivider: false,
    },
    {
      id: 2,
      name: 'Add multiple participant owners',
      modalHandler: () => setMultipleParticipantsFileUploadOpen(true),
      hasDivider: false,
    },
  ];

  useEffect(() => {
    const newData = pageState.data?.map((obj) => {
      if (
        obj.type === LABELS.Owner &&
        filterData?.contractsList.find((item) => item.id === obj.participantId)
      ) {
        return {
          ...obj,
          contracts: filterData?.contractsList.find(
            (item) => item.id === obj.participantId,
          ).contracts,
        };
      } else {
        return obj;
      }
    });
    setNewDataSet(newData);
  }, [filterData, pageState]);

  useEffect(() => {
    if (location.state?.cycleId) {
      setCycleValue(location.state?.cycleId);
    }
  }, [location.state]);

  useEffect(() => {
    if (projectCycleList.length > 0) {
      setCycleValue(
        checkTernaryCondition(
          cycleValue === '',
          projectCycleList[0]?.value,
          cycleValue,
        ),
      );
    }
    setHasNoParticipants(hasNoParticipant);
  }, [projectCycleList, hasNoParticipant]);

  const getDataComponent = () => {
    if (
      cycleValue !== '' &&
      isDataFetched &&
      pageState.data?.length === 0 &&
      participantSearchText === '' &&
      hasNoParticipant
    )
      return (
        <NodataComponent
          nodata={LABELS.noDataContent}
          addNewLabel={LABELS.addNewProjectLabel}
          imageSource={ParticipantsImage}
          addNewLabelFontSize="0.875rem"
          width={'25.5625rem'}
          componentMarginBottom={'0.3125rem'}
          addNewLabelLineHeight={'1.1919rem'}
          addNewLabelFontWeight={'400'}
          addNewLabelMarginBottom={'0rem'}
          buttonMarginTop={'1.5rem'}
          customBtnSx={customBtnSx}
          addNewLabelColor={DIM_GRAY}
          addbuttonLabel={{
            name: LABELS.addNewParticipant,
            link: '',
          }}
          hasMultipleModals={true}
          addNewButtonOptions={addButtonOptions}
          disableButton={
            !checkAccess(permissions, ORIGINATION_PARTICIPANT_CREATE)
          }
          isModalPresent={true}
        />
      );

    if (
      cycleValue !== '' &&
      isDataFetched &&
      pageState.data?.length === 0 &&
      participantSearchText?.trim()?.length > 0
    )
      return (
        <NodataComponent
          filterData={true}
          searchTermSuffix={LABELS.search_text_suffix}
          width={LABELS.filter_content_width}
        />
      );

    return (
      <MainWrapper className="curved-border">
        <DataGridWrapper>
          <DataGrid
            getRowHeight={({ model }) => getDynamicRowHeight(model)}
            headerHeight={40}
            disableVirtualization={disableVirtualization}
            rows={newDataSet || []}
            rowsPerPageOptions={[25, 50, 75]}
            getRowId={(row) =>
              checkTernaryCondition(
                row?.participantId === undefined,
                uniqueId(),
                row?.participantId,
              )
            }
            disableSelectionOnClick
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Items per page',
                SelectProps: TablePaginationMenuListStyle,
              },
            }}
            paginationMode="server"
            pagination
            rowCount={pageState.total}
            pageSize={pageState.pageSize}
            page={pageState.page - 1}
            onPageChange={(newPage) =>
              setPageState((old) => ({ ...old, page: newPage + 1 }))
            }
            loading={pageState.isLoading || !isDataFetched || cycleValue === ''}
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({ ...old, pageSize: newPageSize }))
            }
            columns={columns}
            sx={datagridSx}
          />
        </DataGridWrapper>
      </MainWrapper>
    );
  };

  return (
    <>
      <ParticipantButtonWrapper>
        <DropDown
          dropDownMinWidth={'13.75rem'}
          label={ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_label}
          ariaLabel={ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_label}
          labelStyle={labelStyle}
          dropDownStyle={dropDownStyle}
          value={checkTernaryCondition(
            getBooleanValueForNullOrUndefined(projectCycleList.length),
            ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_placeholder,
            cycleValue,
          )}
          dropdownlist={projectCycleList}
          dropDownPlaceholder={
            ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_placeholder
          }
          setStateValue={() => {}}
          setDropDownValInParent={(newVal) => {
            setCycleValue(newVal);
          }}
          customSx={{
            backgroundColor: WHITE,
            border: `1px ${GRAYISH_BLUE}`,
            ...outlinedDropdownInputSx,
          }}
          setFormFieldValue={() => {
            /* do nothing */
          }}
          minWidth="13.75rem"
          height="1.75rem"
          display="flex"
          direction="row"
          gap="4px"
          alignItems="baseline"
          hasNoBottomMargin
          marginTop="-0.5rem"
          customPlaceholderColor={DARK_CHARCOAL}
          showLabelAsValue={true}
        />

        <SearchFilterWrapper>
          <SearchBox
            style={{ width: '18.75rem' }}
            variant="outlined"
            placeholder={LABELS.searchBoxPlaceholder}
            value={participantSearchText}
            onChange={(event) => {
              const searchText = event.target.value.replace(
                /[^a-zA-Z0-9@._-\s]/g,
                '',
              );
              setParticipantSearchText(searchText);
            }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: TYPOGRAPHY_GRAY }} />,
              endAdornment: participantSearchText?.length > 0 && (
                <ClearIcon
                  sx={{
                    ...pointerCursor,
                    color: TYPOGRAPHY_GRAY,
                    fontSize: '1rem',
                  }}
                  onClick={() => {
                    setIsDataFetched(false);
                    setParticipantSearchText('');
                  }}
                />
              ),
            }}
          />
          {!hasNoParticipant && (
            <FiltersPopup
              filterSections={filterSections}
              updateFilters={handleFilterSectionUpdate}
              isFiltersApplied={appliedFiltersCount > 0}
            />
          )}
        </SearchFilterWrapper>
      </ParticipantButtonWrapper>
      <AccountStatusFilterWrapper>
        <div style={filterIconSx}>
          {accountStatusFilterList?.map((status, id) => {
            return (
              <FilterChip
                key={id}
                data-testid={`${
                  LABELS.account_status
                }: ${getParticipantUserStatus(status)}`}>
                <div>{`${LABELS.account_status}: ${getParticipantUserStatus(
                  status,
                )}`}</div>
                <ClearIcon
                  onClick={() => {
                    setAccountStatusFilterList(
                      accountStatusFilterList.filter((item) => item !== status),
                    );
                  }}
                  sx={clearIconStyle}
                />
              </FilterChip>
            );
          })}
        </div>
        <ActivityStatusWrapper>
          <ActivityStatusFlex>
            {activityStatusFilterList?.map((status, id) => {
              return (
                <div key={id}>
                  <FilterChip
                    data-testid={`${LABELS.activity_status}: ${status.itemLabel}`}>
                    <div>{`${LABELS.activity_status}: ${status.itemLabel}`}</div>
                    <ClearIcon
                      onClick={() => {
                        setActivityStatusFilterList(
                          activityStatusFilterList.filter(
                            (item) => item.id !== status.id,
                          ),
                        );
                      }}
                      sx={clearIconStyle}
                    />
                  </FilterChip>
                </div>
              );
            })}
            {appliedFiltersCount > 0 && (
              <ClearLink
                onClick={() => {
                  setAccountStatusFilterList([]);
                  setActivityStatusFilterList([]);
                }}>
                {PAGE_CONTENT.clear_all}
              </ClearLink>
            )}
          </ActivityStatusFlex>
        </ActivityStatusWrapper>
      </AccountStatusFilterWrapper>
      <TableWrapper>{getDataComponent()}</TableWrapper>

      {checkTernaryCondition(
        participantDialogOpen.id === 0,
        <AddParticipants
          refreshHandler={() => {
            payload.projectId !== null &&
              debouncedFetchParticipantsList(!!participantSearchText);
            refreshHandler();
            cycleValue !== '' && fetchAccountStatus();
            cycleValue !== '' && fetchActivityStatus();
          }}
          isModalOpen={participantDialogOpen.flag}
          setIsModalOpen={setParticipantDialogOpen}
          projectId={id}
          projectName={projectName}
          particpantId={participantDialogOpen.id}
          projectLifeCycleList={projectCycleList}
        />,
        <EditParticipantProfile
          isModalOpen={participantDialogOpen.flag}
          modalCloseHandler={() =>
            setParticipantDialogOpen({ flag: false, id: 0 })
          }
          participantId={participantDialogOpen.id}
          refreshHandler={() => {
            payload.projectId !== null &&
              debouncedFetchParticipantsList(!!participantSearchText);
            refreshHandler();
          }}
        />,
      )}

      <LoaderProvider>
        <UpdateAccount
          isUpdateAccountModalOpen={isUpdateAccountModalOpen}
          setUpdateAccountModalOpen={setUpdateAccountModalOpen}
          refreshHandler={() => {
            fetchAccountStatus();
          }}
        />
      </LoaderProvider>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDeleteDialogOpen}
        onConfirm={deleteParticipant}
        subtitle={`${LABELS.delete_message?.slice(0, 31)} ${
          currentRow?.participantFirstName
        } ${currentRow?.participantLastName} ${LABELS.delete_message?.slice(
          32,
          36,
        )} ${projectName}${LABELS.delete_message?.slice(36)}`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Remove"
        title={`Remove ${currentRow?.participantFirstName} ${currentRow?.participantLastName}`}></DialogBox>
      <LoaderProvider>
        <ReopenCustomModal
          reopenProps={reopenProps}
          setReopenProps={setReopenProps}
          statusMappingList={statusMappingList}
        />
      </LoaderProvider>
      <NotificationLogProvider>
        <AddParticipantsViaFileUpload
          addParticipantsFileUploadOpen={multipleParticipantsFileUploadOpen}
          setAddParticipantsFileUploadOpen={
            setMultipleParticipantsFileUploadOpen
          }
          refreshHandler={() => {
            refreshHandler();
            fetchFilterData();
          }}
          projectName={{ value: id, label: projectName }}
          isNotProjectParticipantsPage={false}
          projectLifeCycleList={projectCycleList}
        />
      </NotificationLogProvider>
    </>
  );
};

ListParticipants.propTypes = {
  refreshHandler: PropTypes.func,
  disableVirtualization: PropTypes.bool,
  projectName: PropTypes.string,
  participantDialogOpen: PropTypes.bool,
  setParticipantDialogOpen: PropTypes.func,
  multipleParticipantsFileUploadOpen: PropTypes.bool,
  setMultipleParticipantsFileUploadOpen: PropTypes.func,
  setHasNoParticipants: PropTypes.func,
};
export default ListParticipants;
