import styled from 'styled-components';
import {
  BLUE,
  DARK_GREEN,
  NEW_DARK_GREEN,
  QUILL_GRAY,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const DialogTitleStyle = {
  fontSize: '20px',
  padding: '0rem',
  marginBottom: '1rem',
};
export const DialogActionStyle = {
  justifyContent: 'flex-end',
  gridColumnGap: '0.5rem',
};
export const IconButtonStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme) => theme.palette.grey[500],
  zIndex: 950,
};
export const DialogContentStyle = {
  marginBottom: '1rem',
  padding: '0rem',
};
export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '60%',
      maxWidth: '34%',
      padding: '24px',
      '& .MuiDialogContent-root': {
        overflow: 'visible',
      },
    },
  },
};

export const InProgressProjectList = styled.div`
  display: 'flex';
  flex-direction: 'column';
  grid-column-gap: 0.5rem;
  font-size: 0.875rem;
  color: ${BLUE};
  margin-bottom: 1.5rem;
  cursor: pointer;
`;

export const NewProjectEnrollmentHeading = styled.div`
  font-size: 1.25rem;
  padding: 0.5rem 0rem;
`;

export const ButtonSelectionLabel = styled.div`
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  max-width: 25rem;
  line-height: normal;
`;

export const CancelBtnStyle = {
  textTransform: 'none',
  color: DARK_GREEN,
  padding: '0.625rem 1rem',
};

export const EnrollBtnStyle = {
  textTransform: 'none',
  color: DARK_GREEN,
  border: `1px solid ${DARK_GREEN}`,
  padding: '0.625rem 1rem',
  fontFamily: 'Open sans, sans serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  height: '2.25rem',
  borderRadius: '4px',
};

export const EnrollBtnDisabledStyle = {
  color: QUILL_GRAY,
  border: `1px solid ${QUILL_GRAY}`,
};

export const SendRequestBtnStyle = {
  color: WHITE,
  backgroundColor: DARK_GREEN,
  borderRadius: '4px',
  textTransform: 'none',
  padding: '0.625rem 1rem',
  fontFamily: 'Open sans, sans serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  height: '2.25rem',
  '&:hover': {
    color: WHITE,
    backgroundColor: DARK_GREEN,
  },
};

export const LinkStyle = (disabled) => ({
  textDecoration: 'none',
  color: checkTernaryCondition(disabled, QUILL_GRAY, NEW_DARK_GREEN),
});

export const RadioGroupStyle = {
  gap: '16px',
  '& .MuiFormControlLabel-root': {
    flex: 1,
    marginLeft: '-10px',
  },
};
