export const CARD_CONTENT = {
  cardHeader: 'Participant incentives',
  projected: 'Projected',
  actual: 'Actual',
  averageSubHeading: 'Averages ',
  perAcre: '(per acre)',
  totalSubHeading: 'Total ',
  dollar: '($)',
  projectedIncentivePayments:
    'Projected incentive payments will be populated after enrollment data has been entered.',
  actualIncentivePayments:
    'Actual incentive payments will be populated after activity reporting data has been entered.',
};
