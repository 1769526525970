export const MODAL_CONTENT = {
  title: 'Add multiple participant owners',
  to: 'to',
  cancel: 'Cancel',
  submit: 'Add participant owners',
  upload_title: 'Please enter the participant contact information.',
  project_page_upload_title:
    'Please upload the participant’s contact information.​',
  uploadSubText:
    'You may also upload multiple participants’ info below through a CSV file.',
  dropzone_info_text: 'Drag and drop file here or ',
  browse: ' browse',
  project_label: 'Add to project',
  project_placeholder: 'Type project name and select to add ',
  file_added_label: 'Added file must be deleted before adding another file.',
  project_name_error_message:
    'Project name entered doesn’t exist. Please type and select a project from the list of existing projects.',
  add_participants_page: 'ADD_PARTICIPANTS_PAGE',
  add_participants_from_project_page: 'ADD_PARTICIPANTS_FROM_PROJECT_PAGE',
  file_upload_required_error_message: 'File upload is required.',
  two_rem: '2rem',
  project_cycle_required_error: 'Project cycle selection is required.',
  project_life_cycle_label:
    'Please select which project cycle they will start on.',
  project_life_cycle_placeholder: 'Select project cycle',
};
export const acceptedFilesInfo = {
  fileNotSupportedErrorText: 'File not supported. Please upload a .csv file.',
  extensions: ['.csv'],
  infoTextList: ['Files Supported: .csv'],
  mimeType: 'text/csv',
  maxFiles: 1,
  maxFileSize: 1024 * 1024 * 8,
};
export const formatBytes = (bytes) => {
  const megabyte = 1024 * 1024;
  return bytes / megabyte + ' MB';
};

export const maxLimitError = (maxLimit) =>
  `File size exceeds maximum of ${maxLimit} allowed.`;
export const maxFilesError = () => `Only one file can be uploaded at a time.`;

export const getError = (errorCode, maxLimit, fileNotSupportedText) => {
  switch (errorCode) {
    case 'file-too-large':
      return maxLimitError(formatBytes(maxLimit));
    case 'file-invalid-type':
      return fileNotSupportedText;
    default:
      return 'Error';
  }
};
