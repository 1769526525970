import { styled } from '@mui/material/styles';
import { Container, Link } from '@mui/material';
import { checkTernaryCondition } from 'utils/helper';
import { BLACK, QUILL_GRAY, WHITE } from 'theme/GlobalColors';

// eslint-disable-next-line no-unused-vars
export const useStyles = (theme) => ({
  MainContainer: styled(Container)(
    ({
      isParticipantLanding,
      isParticipantMenu,
      margin,
      isInActivityPage,
      isEnrollment,
    }) => ({
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: checkTernaryCondition(isEnrollment, '15px', '0px'),
      marginTop: checkTernaryCondition(isInActivityPage, '0px', '24.5px'),
      margin: margin,
      height: '50px',
      fontSize: '0.75rem', // 12px
      fontFamily: 'Open Sans, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      borderTop: checkTernaryCondition(
        isParticipantLanding,
        '',
        `1px ${QUILL_GRAY} solid`,
      ),
    }),
  ),
  // EBAC - Enrolment Baseline Activity Contributor Landing ages
  FooterDiv: styled('div')(({ isParticipantLanding }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    'z-index': 1,
    color: checkTernaryCondition(isParticipantLanding, WHITE, BLACK),
  })),

  LeftFooterDiv: styled('div')(({ isEBAC }) => ({
    display: 'flex',
    marginLeft: checkTernaryCondition(isEBAC, '1rem', '13rem'),
  })),

  RightFooterDiv: styled('div')(({ paddingRight }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingRight: paddingRight,
  })),

  IconSpan: styled('span')(() => ({
    color: QUILL_GRAY,
    marginLeft: '0.2em',
    marginRight: '0.2em',
    fontWeight: '100',
  })),

  FooterLink: styled(Link)(({ isParticipantLanding }) => ({
    textDecoration: 'none',
    cursor: 'pointer',
    color: checkTernaryCondition(isParticipantLanding, WHITE, BLACK),
  })),
});

export const landingPageFooterStyles = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '100%',
};
