import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DetailsWrapper,
  TextWrapper,
  HeaderWrapper,
  IconHeaderWrapper,
  IconSx,
  MenuWrapper,
  NoNotifDiv,
  NotificationMenu,
  DateTimeWrapper,
  ButtonWrapper,
  downloadBtnSx,
  PointerSx,
  NotificationIconSx,
} from './NotificationLog.style';
import {
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  Tooltip,
} from '@mui/material';
import { FETCH_PARTICIPANT_NOTIFICATIONS, UPDATE_JOB_STATUS } from 'urls';
import { NotificationLogContext } from 'containers/NotificationLogContext.react';
import axios from 'axios';
import {
  NOTIFICATION_LOG_CONTENT,
  NO_NOTIFICATIONS,
  getNotificationFailedContent,
  getNotificationHeading,
} from './NotificationLog.constants';
import { useInterval } from 'hooks/useInterval';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  GLOBAL_BLUE,
  RED,
  TOAST_SUCCESS_BORDER,
  WHITE,
} from 'theme/GlobalColors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  deletePrimaryButtonStyle,
  deleteTertiaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { HeaderIconBtnSx } from './style';
import {
  checkTernaryCondition,
  convertDateFormat,
  isEmpty,
} from 'utils/helper';

const NotificationLog = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenNotifications = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCloseNotifications = () => {
    setOpen(false);
    setNewNotification(false);
  };

  const {
    logs,
    setLogs,
    newNotification,
    setNewNotification,
    fetchNotificationLogs,
  } = useContext(NotificationLogContext);

  const handleDismiss = async (jobId) => {
    await axios.put(`${UPDATE_JOB_STATUS}/${jobId}`).then(() => {
      fetchNotificationLogs();
    });
  };

  const getIcon = (status) => {
    if (status === NOTIFICATION_LOG_CONTENT.success) {
      return <CheckCircleOutlinedIcon sx={IconSx(TOAST_SUCCESS_BORDER)} />;
    } else if (status === NOTIFICATION_LOG_CONTENT.failed) {
      return <WarningAmberOutlinedIcon sx={IconSx(RED)} />;
    } else if (status === NOTIFICATION_LOG_CONTENT.initiated) {
      return <InfoOutlinedIcon sx={IconSx(GLOBAL_BLUE)} />;
    }
  };

  const downloadCSVFile = async (fileUrl) => {
    const anchorElement = document.createElement('a');
    anchorElement.href = fileUrl;
    anchorElement.download = NOTIFICATION_LOG_CONTENT.fileName;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = convertDateFormat(new Date(timestamp));
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = checkTernaryCondition(hours >= 12, 'PM', 'AM');
    hours = hours % 12;
    hours = hours || 12;
    const formattedTime = `${hours}:${checkTernaryCondition(
      minutes < 10,
      '0' + minutes,
      minutes,
    )} ${ampm}`;

    return { formattedDate, formattedTime };
  };

  const setButton = (status, jobId, fileUrl) => {
    if (status === NOTIFICATION_LOG_CONTENT.failed) {
      return (
        <ButtonWrapper isError={true}>
          {!isEmpty(fileUrl) && (
            <Button
              size={NOTIFICATION_LOG_CONTENT.medium}
              hidden={true}
              sx={[downloadBtnSx, deletePrimaryButtonStyle('0.281rem 0.4rem')]}
              onClick={() => downloadCSVFile(fileUrl)}>
              <FileDownloadOutlinedIcon sx={IconSx(WHITE)} />
              {NOTIFICATION_LOG_CONTENT.errorFile}
            </Button>
          )}
          <Button
            size={NOTIFICATION_LOG_CONTENT.medium}
            sx={[downloadBtnSx, deleteTertiaryButtonStyle]}
            onClick={() => handleDismiss(jobId)}>
            {NOTIFICATION_LOG_CONTENT.dismiss}
          </Button>
        </ButtonWrapper>
      );
    } else {
      return (
        <ButtonWrapper isError={false}>
          <Button
            size={NOTIFICATION_LOG_CONTENT.medium}
            sx={[downloadBtnSx, tertiaryButtonStyle]}
            onClick={() => handleDismiss(jobId)}>
            {NOTIFICATION_LOG_CONTENT.dismiss}
          </Button>
        </ButtonWrapper>
      );
    }
  };

  useInterval(async () => {
    axios.get(FETCH_PARTICIPANT_NOTIFICATIONS).then((response) => {
      if (response?.data?.length > logs?.length) {
        setNewNotification(true);
      }
      setLogs(response?.data);
    });
  }, 10000);

  useEffect(() => {
    if (newNotification) {
      setOpen(true);
    }
  }, [newNotification]);

  useEffect(() => {
    if (isEmpty(logs)) {
      setNewNotification(false);
    }
  }, [logs]);

  return (
    <Fragment>
      <Tooltip title="Notification">
        <IconButton
          sx={HeaderIconBtnSx}
          onClick={handleOpenNotifications}
          anchorEl={anchorEl}
          aria-controls="notifications-log">
          {checkTernaryCondition(
            newNotification,
            <Badge color="primary" variant="dot" overlap="circular">
              <NotificationsNoneIcon style={NotificationIconSx} />
            </Badge>,
            <NotificationsNoneIcon style={NotificationIconSx} />,
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={NotificationMenu}
        id="notifications-log"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleCloseNotifications}>
        {isEmpty(logs) && <NoNotifDiv>{NO_NOTIFICATIONS}</NoNotifDiv>}
        {logs.map((data, index) => (
          <MenuWrapper key={data?.jobId}>
            <HeaderWrapper>
              <IconHeaderWrapper>
                {getIcon(data?.status)}
                <TextWrapper
                  color={
                    data?.status === NOTIFICATION_LOG_CONTENT.failed && RED
                  }>
                  {getNotificationHeading(
                    data?.status,
                    data?.totalSubmission,
                    data?.successfulSubmission,
                  )}
                </TextWrapper>
              </IconHeaderWrapper>
              <CloseOutlinedIcon
                onClick={() => handleDismiss(data?.jobId)}
                sx={[PointerSx, IconSx()]}
              />
            </HeaderWrapper>
            <DetailsWrapper>
              {data?.status === NOTIFICATION_LOG_CONTENT.failed && (
                <TextWrapper fontWeight={400}>
                  {getNotificationFailedContent(
                    data?.failedSubmission,
                    data?.totalSubmission,
                    data?.fileName,
                  )}
                </TextWrapper>
              )}
              {data?.status === NOTIFICATION_LOG_CONTENT.initiated && (
                <TextWrapper fontWeight={400}>
                  {NOTIFICATION_LOG_CONTENT.uploadInitiatedText}
                </TextWrapper>
              )}
              <DateTimeWrapper>
                {formatTimestamp(data?.initiatedAt)?.formattedDate}
                {NOTIFICATION_LOG_CONTENT.timestampDot}
                {formatTimestamp(data?.initiatedAt)?.formattedTime}
              </DateTimeWrapper>
            </DetailsWrapper>
            {setButton(data?.status, data?.jobId, data?.errorFile)}
            {index !== logs.length - 1 && (
              <Divider sx={{ marginBottom: '0.5rem' }} />
            )}
          </MenuWrapper>
        ))}
      </Menu>
    </Fragment>
  );
};

export default NotificationLog;
