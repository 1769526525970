import styled from 'styled-components';
import {
  COOL_GRAY,
  GLOBAL_BLUE,
  WHITE,
  DARK_GREEN,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';
import { SortColValues } from './OriginationParticipantsList.constants';
import { checkTernaryCondition } from 'utils/helper';
import { TextField } from '@mui/material';

export const MainWrapper = styled.div`
  width: 100%;
  background: #ffff;
`;

export const DataGridWrapper = styled.div`
  width: 100%;
  height: 82vh;
`;

export const RowCellWrapper = styled.div`
  display: flex;
  color: ${({ color }) => color};
  flex-direction: ${({ direction }) => direction};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignment }) => alignment};
  cursor: ${({ cursor }) => cursor};
`;

export const TextWrapper = styled.div`
  display: flex;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-style: normal;
  line-height: ${({ lineHeight }) => lineHeight};
  font-size: ${({ fontSize }) => fontSize};
  cursor: ${({ cursor }) => cursor};
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  word-wrap: break-word;
`;

export const CellValueWrapper = styled.div`
  max-width: 20rem;
  color: ${(props) => props.color};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const projectCellStyle = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.188rem',
  display: 'flex',
  alignItems: 'center',
  color: GLOBAL_BLUE,
  textOverflow: 'ellipsis',
};

export const datagridSx = {
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  borderBottom: 0,
  borderRadius: 0,
  borderRight: 0,
  borderLeft: 0,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '140%',
    fontSize: '0.875rem',
    color: '#333333',
    whiteSpace: 'break-spaces',
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '1.188rem',
    fontSize: '0.875rem',
    color: '#333333',
  },
  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '2.5rem',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
};

export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.26rem;
  border-radius: 50%;
`;
export const handleArrowColor = (col, sortColumn) => {
  return {
    color: sortColumn === SortColValues[col] ? COOL_GRAY : '#ffffff',
    '&:hover': {
      color: COOL_GRAY,
    },
  };
};
export const SortArrowsWrapper = styled.div`
  color: ${(props) => checkTernaryCondition(props.hovered, COOL_GRAY, WHITE)};
`;
export const HoverableContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const editPenIconStyle = (cursor) => {
  return {
    width: '1.3rem',
    height: '1.3rem',
    cursor: cursor,
  };
};

export const SearchAndFilterSection = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => width || '100%'};
  flex: 1 1 0%;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0.5rem'};
`;

export const SearchAndFiltersFlex = {
  display: 'flex',
  width: '90%',
  alignItems: 'center',
  gap: '0.25rem',
};

export const SearchBox = styled(TextField)(() => ({
  backgroundColor: WHITE,
  width: '50%',
  maxWidth: '18.75rem',

  '& .MuiTextField-root': {
    marginRight: '0.5rem',
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: DARK_GREEN,
    },
  },
  '& .MuiOutlinedInput-root': {
    padding: '0 0.5rem',
    height: '1.75rem',

    '& input': {
      padding: '0px 4px',
      fontSize: '0.75rem',
      color: COOL_GRAY,
      '::placeholder': {
        opacity: 1,
      },
    },

    '&.Mui-focused fieldset': {
      borderColor: DARK_GREEN,
    },
  },
}));

export const filterClearIconSx = {
  fontSize: '1rem',
  cursor: 'pointer',
  color: TYPOGRAPHY_GRAY,
};
