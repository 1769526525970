import React from 'react';

export const SettingsContext = React.createContext({});

export const AppSettingsProvider = ({ children }) => {
  const value = React.useMemo(
    () => ({
      clientId: process.env.REACT_APP_CLIENT_ID || window.env.CLIENT_ID || '',
      redirectUri:
        process.env.REACT_APP_REDIRECT_URI || window.env.REDIRECT_URI || '',
      appId: process.env.REACT_APP_APP_ID || window.env.APP_ID || '',
      googleMapKey:
        process.env.REACT_APP_GOOGLE_MAP_KEY || window.env.GOOGLE_MAP_KEY || '',
      authProvider:
        process.env.REACT_APP_AUTH_PROVIDER || window.env.AUTH_PROVIDER || '',
      firebaseApiKey:
        process.env.REACT_APP_FIREBASE_API_KEY ||
        window.env.FIREBASE_API_KEY ||
        '',
      firebaseAuthDomain:
        process.env.REACT_APP_AUTH_DOMAIN || window.env.AUTH_DOMAIN || '',
      isDevEnvironment:
        (process.env.REACT_APP_IS_DEV || window.env.REACT_APP_IS_DEV) ?? '',
    }),
    [],
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};
