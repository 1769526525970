import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  align-items: ${(props) => (props.alignment ? props.alignment : 'center')};
  gap: ${(props) => (props.gap ? props.gap : '1.5rem')};
`;
export const CropMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  gap: 1rem;
`;
