import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  CheckBoxStyle,
  formControlLabelStyle,
  Container,
  ItemToolTipWrapper,
  LabelTextStyle,
} from './CheckboxFormGroup.style';
import {
  MainWrapper,
  FormLabels,
  InstructionWrapper,
  ErrorWrapper,
  LabelToolTipWrapper,
  OptionalLabel,
  ToolTipSx,
  AlertIconSx,
  LabelTextWrapper,
  ToolTipAndAlertWrapper,
} from '../FormStyles';
import InfoTooltip from '../InfoTooltip/InfoTooltip.react';
import { FORM_CONTENT } from '../FormComponents.content';
import { checkTernaryCondition } from 'utils/helper';
import InputField from '../InputField/InputField.react';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';

const CheckboxFormGroup = ({
  list,
  onClickHandler,
  label,
  instructionText,
  instructionTextStyles,
  hasTooltipOnLabel,
  hasTooltipOnItem,
  tooltipTitle = '',
  tooltipContent,
  primaryError,
  errorMessage,
  gap,
  minWidth,
  stackedView,
  customStyles,
  checkBoxFillColor,
  checkboxPadding,
  customSx,
  actAsRadioButton,
  radioBtnSelectedValue,
  handleRadioBtnChange,
  toolTipNextToLabel,
  toolTipBottomMargin,
  isOptional,
  labelWidth,
  hideLabel = false,
  showFreeTextField = false,
  freeTextProps = null,
  noLabel = false,
  titleAndInfoGap,
  labelMarginBottom,
  labelMarginTop,
  disableAllOptions = false,
  applyTextTransform,
  backgroundColor,
  formLabelCustomSx,
  formLabelTooltipCustomSx,
}) => {
  const hasError = primaryError;
  return (
    <div style={customStyles}>
      {!noLabel && (
        <FormLabels
          gap={titleAndInfoGap}
          marginBottom={labelMarginBottom}
          marginTop={labelMarginTop}
          width={labelWidth}
          iserror={primaryError}
          className={primaryError && FORM_CONTENT.error_class}>
          {checkTernaryCondition(
            toolTipNextToLabel,
            <LabelToolTipWrapper iserror={hasError}>
              <LabelTextWrapper>{label}</LabelTextWrapper>
              <ToolTipAndAlertWrapper>
                {isOptional && (
                  <OptionalLabel marginLeft={'0rem'}>
                    {FORM_CONTENT.optional}
                  </OptionalLabel>
                )}
                {hasTooltipOnLabel && (
                  <InfoTooltip
                    title={tooltipTitle ?? ''}
                    content={tooltipContent}
                    csafToolTipSx={{
                      ...formLabelTooltipCustomSx,
                      ...ToolTipSx,
                    }}
                  />
                )}
                {hasError && (
                  <AlertIcon
                    data-testid="checkbox-error-icon"
                    style={AlertIconSx}
                  />
                )}
              </ToolTipAndAlertWrapper>
            </LabelToolTipWrapper>,
            <LabelToolTipWrapper iserror={hasError}>
              <LabelTextWrapper>{label}</LabelTextWrapper>
              <ToolTipAndAlertWrapper>
                {isOptional && (
                  <OptionalLabel marginLeft={'0rem'}>
                    {FORM_CONTENT.optional}
                  </OptionalLabel>
                )}
                {hasTooltipOnLabel && (
                  <InfoTooltip
                    title={FORM_CONTENT.empty_string}
                    content={tooltipContent}
                    csafToolTipSx={ToolTipSx}
                  />
                )}
                {hasError && (
                  <AlertIcon
                    data-testid="checkbox-error-icon"
                    style={AlertIconSx}
                  />
                )}
              </ToolTipAndAlertWrapper>
            </LabelToolTipWrapper>,
          )}
          <InstructionWrapper {...instructionTextStyles}>
            {instructionText}
          </InstructionWrapper>
        </FormLabels>
      )}
      <FormGroup column sx={customSx}>
        <MainWrapper gap={gap} stackedView={stackedView}>
          {list?.map((item, index) => {
            const keyVal = index;
            return (
              <Container className={'disabled-selector'} key={keyVal}>
                <FormControlLabel
                  key={keyVal}
                  control={checkTernaryCondition(
                    actAsRadioButton,
                    <Checkbox
                      checked={radioBtnSelectedValue === item.itemLabel}
                      onChange={handleRadioBtnChange}
                      value={item.itemLabel}
                      sx={CheckBoxStyle(
                        checkBoxFillColor,
                        checkboxPadding,
                        backgroundColor,
                      )}
                      data-testid={item.itemLabel}
                      color={FORM_CONTENT.success}
                      disabled={
                        (item.isDisabled &&
                          radioBtnSelectedValue !== item.itemLabel) ||
                        disableAllOptions
                      }
                    />,
                    <Checkbox
                      color={FORM_CONTENT.success}
                      type={FORM_CONTENT.checkbox}
                      disabled={item.isDisabled || disableAllOptions}
                      checked={item?.checked}
                      onClick={() => onClickHandler(keyVal)}
                      data-testid={item.itemLabel}
                      sx={CheckBoxStyle(
                        checkBoxFillColor,
                        checkboxPadding,
                        backgroundColor,
                      )}
                    />,
                  )}
                  label={
                    !hideLabel &&
                    checkTernaryCondition(
                      toolTipNextToLabel,
                      <>
                        <span style={LabelTextStyle}>{item.itemLabel}</span>
                        {hasTooltipOnItem && (
                          <ItemToolTipWrapper>
                            <InfoTooltip
                              title={item?.tooltipTitle}
                              content={item?.tooltipContent}
                              bottomMargin={toolTipBottomMargin}
                            />
                          </ItemToolTipWrapper>
                        )}
                      </>,
                      <span style={LabelTextStyle}>{item.itemLabel}</span>,
                    )
                  }
                  value={item.id}
                  sx={{
                    ...formControlLabelStyle(
                      minWidth,
                      hasTooltipOnItem,
                      applyTextTransform,
                    ),
                    ...formLabelCustomSx,
                  }}
                />
                {!toolTipNextToLabel && hasTooltipOnItem && (
                  <InfoTooltip
                    title={item?.tooltipTitle}
                    content={item?.tooltipContent}
                  />
                )}
              </Container>
            );
          })}
        </MainWrapper>
      </FormGroup>
      {showFreeTextField && <InputField {...freeTextProps} />}
      {primaryError && <ErrorWrapper>{errorMessage}</ErrorWrapper>}
    </div>
  );
};

CheckboxFormGroup.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      itemLabel: PropTypes.string,
      isDisabled: PropTypes.bool,
      checked: PropTypes.bool,
      tooltipTitle: PropTypes.string,
      tooltipContent: PropTypes.string,
    }),
  ).isRequired,
  onClickHandler: PropTypes.func.isRequired,
  label: PropTypes.string,
  instructionText: PropTypes.string,
  instructionTextStyles: PropTypes.object,
  hasTooltipOnLabel: PropTypes.bool,
  hasTooltipOnItem: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  tooltipContent: PropTypes.string,
  primaryError: PropTypes.bool,
  errorMessage: PropTypes.string,
  gap: PropTypes.string,
  minWidth: PropTypes.string,
  stackedView: PropTypes.bool,
  customStyles: PropTypes.object,
  checkBoxFillColor: PropTypes.string,
  checkboxPadding: PropTypes.string,
  customSx: PropTypes.object,
  actAsRadioButton: PropTypes.bool,
  radioBtnSelectedValue: PropTypes.string,
  handleRadioBtnChange: PropTypes.func,
  toolTipNextToLabel: PropTypes.bool,
  toolTipBottomMargin: PropTypes.string,
  isOptional: PropTypes.bool,
  labelWidth: PropTypes.string,
  hideLabel: PropTypes.bool,
  showFreeTextField: PropTypes.bool,
  freeTextProps: PropTypes.object,
  noLabel: PropTypes.bool,
  titleAndInfoGap: PropTypes.string,
  labelMarginBottom: PropTypes.string,
  labelMarginTop: PropTypes.string,
  disableAllOptions: PropTypes.bool,
  applyTextTransform: PropTypes.bool,
  backgroundColor: PropTypes.string,
  formLabelCustomSx: PropTypes.object,
  formLabelTooltipCustomSx: PropTypes.object,
};

export default CheckboxFormGroup;
