import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { ModalContent, ModalTitle } from './ActivityDeleteModal.content';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { containedBtnSx, pointerCursor, textBtnSx } from 'theme/GlobalStyles';
import { COOL_GRAY } from 'theme/GlobalColors';
import {
  DialogActionsSx,
  DialogContentSx,
  DialogSx,
  DialogTitleSx,
} from './ActivityDeleteModal.style';

const ActivityDeleteModal = ({
  open,
  closeModal,
  handleDeleteFromOpsData,
  activityTitle,
}) => {
  return (
    <Dialog open={open} fullWidth sx={DialogSx}>
      <DialogTitle sx={DialogTitleSx}>
        <ModalTitle activityTitle={activityTitle} />
        <CloseIcon
          onClick={closeModal}
          sx={{
            ...pointerCursor,
            color: COOL_GRAY,
          }}
        />
      </DialogTitle>
      <DialogContent sx={DialogContentSx}>
        <ModalContent activityTitle={activityTitle} />
      </DialogContent>
      <DialogActions sx={DialogActionsSx}>
        <Button
          sx={{ ...textBtnSx, height: '2rem' }}
          onClick={closeModal}
          disableElevation>
          Cancel
        </Button>
        <Button
          sx={{ ...containedBtnSx, height: '2rem' }}
          onClick={handleDeleteFromOpsData}
          disabled={false}
          disableElevation>
          Yes, delete data below
        </Button>
      </DialogActions>
    </Dialog>
  );
};

//proptypes
ActivityDeleteModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  handleDeleteFromOpsData: PropTypes.func,
  activityTitle: PropTypes.string,
};

export default ActivityDeleteModal;
