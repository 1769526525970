import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  GRAYISH_BLACK,
  HOVER_LIGHT_BLUE,
  NEW_DARK_GREEN,
  QUILL_GRAY,
  ROW_FOCUSED_BG,
  ROW_HOVER_BG,
  TABS_HIGHLIGHT_BORDER,
  BLACK,
  NEW_HOVER_DARK_GREEN,
  TEAL_GREEN_FOCUS,
  WHITE,
  NEW_HOVER_GREEN_BACKGROUND,
  NEW_FOCUS_DARK_GREEN,
  TEAL_GREEN_HOVER,
  DARK_GREEN,
  FOCUSED_BORDER,
  TYPOGRAPHY_GRAY,
  GRAYISH_BLUE,
} from './GlobalColors';
import landingPageImage from '../assets/ParticipantEnrolmentLandingPage.png';
import {
  checkTernaryCondition,
  setParticipantLayoutBackgorund,
} from 'utils/helper';

export const useStyles = () => ({
  MainContainer: styled('div')(
    ({
      isParticipantLanding,
      isParticipantEnrolled,
      isParticipantPortalAccessDenied,
      isAccessDenied,
      isMainAccessDeniedPage,
      isLandingOrMainAccessDeniedPage = isParticipantLanding ||
        isMainAccessDeniedPage,
    }) => ({
      minHeight: checkTernaryCondition(
        isMainAccessDeniedPage,
        '95vh',
        'calc(100vh - 120px)',
      ),
      display: 'flex',
      marginTop: '45px',

      '*': {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
      },
      '.MuiTab-root': {
        color: GLOBAL_BLUE,
        lineHeight: '1.188rem',
        padding: '0rem 0.5rem 0.5rem 0.5rem',
        fontWeight: 400,
        fontSize: '0.875rem',
        borderBottom: '3px solid transparent',
        boxSizing: 'border-box',
        height: '2.188rem',
        minHeight: '1.5rem',
        minWidth: 'auto',

        '&.Mui-selected': {
          color: '#333333',
          fontWeight: 600,
          borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
        },
        '&:hover': {
          borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
        },
      },
      '.MuiTabs-root': {
        minHeight: '2rem',
      },
      '.MuiTabs-indicator': {
        height: '3px',
        backgroundColor: TABS_HIGHLIGHT_BORDER,
      },
      '&& .Mui-checked': {
        color: NEW_DARK_GREEN,
      },
      '&& .Mui-disabled': {
        color: GRAYISH_BLACK,
      },
      '.MuiButton-root.Mui-disabled': {
        color: BLACK,
      },
      '.disabled-selector': {
        '& .MuiCheckbox-root.Mui-checked.Mui-disabled': {
          color: QUILL_GRAY,
        },
        '& .MuiRadio-root.Mui-checked.Mui-disabled': {
          color: QUILL_GRAY,
        },
        '& .MuiFormControlLabel-label.Mui-disabled': {
          color: BLACK,
        },
      },
      background: checkTernaryCondition(
        isLandingOrMainAccessDeniedPage,
        setParticipantLayoutBackgorund(
          landingPageImage,
          isParticipantEnrolled && !isAccessDenied,
          `linear-gradient(180deg, #56899a 0%, rgba(241, 241, 241, 0) 100%)`,
        ),
        setParticipantLayoutBackgorund(
          '',
          isParticipantPortalAccessDenied && isAccessDenied,
          `linear-gradient(180deg, #56899a 0%, rgba(241, 241, 241, 0) 100%)`,
        ),
      ),
      backgroundRepeat: checkTernaryCondition(
        isLandingOrMainAccessDeniedPage,
        'no-repeat',
        '',
      ),
      backgroundSize: checkTernaryCondition(
        isLandingOrMainAccessDeniedPage,
        '100% 100%',
        '',
      ),
      backgroundPosition: checkTernaryCondition(
        isLandingOrMainAccessDeniedPage,
        'center',
        '',
      ),
      '.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit': {
        padding: '0rem',
        '.MuiSvgIcon-root': {
          width: '1.5em',
          height: '1.5em',
        },
      },
      '.MuiTablePagination-selectLabel': {
        display: 'block',
        left: '20%',
        position: 'relative',
        alignSelf: 'self-start',
      },
      '.MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input': {
        marginRight: 0,
        left: '18%',
      },
      '.MuiTablePagination-displayedRows': {
        position: 'sticky',
        left: '71.5%',
        fontSize: '0.875rem',
      },
      '.MuiTablePagination-actions': {
        width: '200px',
        justifyContent: 'space-between',
        display: 'flex',
      },
      '.MuiDataGrid-root': {
        borderRadius: '2px',
        '.MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '.MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '.MuiDataGrid-row': {
          '&:focus-within': {
            backgroundColor: ROW_FOCUSED_BG,
          },
          '&:hover': {
            background: ROW_HOVER_BG,
          },
        },
      },
      '.MuiInput-underline.Mui-error:before': {
        borderBottomColor: GRAYISH_BLUE,
      },
      '.MuiInput-underline.Mui-error:after': {
        borderBottomColor: DARK_GREEN,
      },
      '.MuiInput-underline.Mui-focused:after': {
        borderBottomColor: DARK_GREEN,
      },
      '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: DARK_GREEN,
      },
    }),
  ),
  MainContent: styled('div')(({ marginLeft, fullWidth, padding }) => ({
    flexGrow: 1,
    marginLeft: marginLeft || '13.75rem',
    padding: padding || '1rem 2rem',
    paddingBottom: '0',
    maxWidth: fullWidth ? '100%' : `calc(100% - 13.75rem)`,
    boxSizing: 'border-box',
    height: '100%',

    '.MuiContainer-root': {
      padding: 0,
    },
  })),
  TabberBox: styled(Box)(() => ({
    borderBottom: '1px solid #D0D0CE',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    '.last-seen': {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      color: '#63666A',
    },
    '.button-section': {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      gridColumnGap: '1rem',
    },
  })),
  ToastContainer: styled(ToastContainer)`
    // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
    &&&.Toastify__toast-container {
      min-width: fit-content;
      padding: 0px;
      transform: translateX(-37%) translateY(40%);
    }
    .Toastify__toast {
      margin: 0px;
      padding: 0px;
      min-height: fit-content;
      width: fit-content;
    }
    .Toastify__toast-body {
      margin: 0px;
      padding: 0px;
      min-height: fit-content;
    }
    .Toastify__progress-bar {
    }
    .Toastify__close-button--light {
      display: none;
    }
  `,
});

export const PageTitle = styled('div')(() => ({
  fontWeight: '400',
  fontSize: '20px',
  lineHeight: '140%',
  color: '#313233',
  marginTop: '1rem',
  marginBottom: '1.1rem',
}));

export const pointerCursor = { cursor: 'pointer' };
export const flexSpaceBetween = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
};

export const yAxisAutoScroll = {
  overflowY: 'auto',
};

export const TruncatedWrapper = styled('div')(({ width }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: width,
}));

export const DialogTitleSx = {
  fontSize: '20px',
  color: DARK_CHARCOAL,
  fontWeight: 400,
};

export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const closeIconSx = {
  color: COOL_GRAY,
  cursor: 'pointer',
};

export const backdropSx = { color: '#fff', zIndex: 9999999 };

export const TablePaginationMenuListStyle = {
  MenuProps: {
    PaperProps: {
      sx: {
        '.MuiMenuItem-root:hover': {
          backgroundColor: ROW_HOVER_BG,
        },
        '.MuiMenuItem-root.Mui-selected': {
          backgroundColor: ROW_FOCUSED_BG,
        },
        '.MuiMenuItem-root.Mui-selected:hover': {
          backgroundColor: ROW_HOVER_BG,
        },
      },
    },
  },
};
export const CardHeaderStyle = {
  margin: 0,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: '140%',
  letterSpacing: '0px',
};

export const getMenuItemStyles = () => ({
  '&.Mui-selected': {
    backgroundColor: ROW_FOCUSED_BG,
    '&:hover': {
      backgroundColor: HOVER_LIGHT_BLUE,
    },
  },
  '&:hover': {
    backgroundColor: HOVER_LIGHT_BLUE,
  },
  '&:focus': {
    backgroundColor: ROW_FOCUSED_BG,
  },
});

export const OptionNoBoldStyle = {
  whiteSpace: 'pre',
};

export const pageButtonDefaultStyle = {
  height: '1.75rem',
};

export const modalActionButtonDefaultStyle = {
  height: '2.25rem',
};

export const emptyStateCTAButtonDefaultStyle = {
  height: '2.75rem',
};

export const CSAFToolTipSx = {
  marginLeft: '0.5rem',
  position: 'relative',
  top: '0.125rem',
};

export const textBtnSx = {
  height: '2.25rem',
  textTransform: 'none',
  backgroundColor: 'transparent',
  color: NEW_DARK_GREEN,
  boxShadow: 'none',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: NEW_HOVER_DARK_GREEN,
  },

  '&:focus': {
    color: TEAL_GREEN_FOCUS,
  },
};

export const outlinedBtnSx = {
  height: '2.25rem',
  textTransform: 'none',
  border: `1px solid ${NEW_DARK_GREEN}`,
  background: WHITE,
  color: NEW_DARK_GREEN,
  padding: '0.344rem 1rem',
  fontWeight: 400,

  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
    padding: '4.5px 15px',
  },
  '&.Mui-disabled': {
    color: TYPOGRAPHY_GRAY,
    backgroundColor: 'white',
    border: `1px solid ${TYPOGRAPHY_GRAY}`,
  },
};
export const disabledSx = {
  '&.Mui-disabled': {
    color: BLACK,
    backgroundColor: '#D0D0CE',
    border: `none`,
  },
};
export const containedBtnSx = {
  textTransform: 'none',
  backgroundColor: NEW_DARK_GREEN,
  height: '2.25rem',
  color: WHITE,
  fontWeight: 400,

  '&:hover': {
    backgroundColor: DARK_GREEN,
    color: WHITE,
  },
  '&:focus': {
    backgroundColor: NEW_FOCUS_DARK_GREEN,
    border: `2px solid ${FOCUSED_BORDER}`,
    padding: '4.5px 14.5px',
    color: WHITE,
  },
  '&.Mui-disabled': {
    color: COOL_GRAY,
    backgroundColor: QUILL_GRAY,
    border: 'none',
  },
};
