export const SEARCH_AND_FILTER_COMPONENT_CONTENT = {
  center: 'center',
  date_filter: 'date-filter',
  range_picker: 'range-picker',
  gap: '1.5rem',
  border: '1px solid #97999B',
  padding: '0.125rem 0.5rem',
  radius: '2px',
  fontSize: '0.75rem',
  fontWeight: 400,
  generatedOn: `${'Generated on:'}${' '}`,
};
