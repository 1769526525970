import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import {
  dataGridSxForSoil,
  DataGridWrapperForSoil,
  DownloadButton,
  EmptySoilSamplingBtnSx,
  EmptySoilSamplingBtnTextSx,
  EmptySoilSamplingContainer,
  EmptySoilSamplingContainerWrapper,
  EmptySoilSamplingIconContainer,
  EmptySoilTitleWrapper,
} from './ActivitiesTab.style';
import { DIM_GRAY, QUARTZ } from 'theme/GlobalColors';

import UploadSoilSamplingModal from './UploadSoilSamplingModal/UploadSoilSamplingModal.react';
import { DOWNLOAD_SOIL_SAMPLING_DATA, FETCH_SOIL_SAMPLING_DATA } from 'urls';
import { TAB_CONTENT } from './ActivitesTab.content';
import LightTooltip from 'components/LightTooltip';
import PropTypes from 'prop-types';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { hasAnyRequiredAccess } from 'utils/helper';
import {
  ORIGINATION_VIEWER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
} from 'utils/config';
import { ReactComponent as SoilSamplingEmptyStateIcon } from '../../../../../../assets/icons/soil_sampling_empty_state_icon.svg';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import { DataGrid } from '@mui/x-data-grid';
import { uniqueId } from 'utils/uniqueIdGenerator';
const ActivitiesTab = ({
  projectId,
  soilSamplingDialog,
  setSoilSamplingDialog,
  toggleAddSoilSamplingDialog,
}) => {
  const [rows, setRows] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const { userRoles } = useContext(userAuthRolesContext);

  useEffect(() => {
    setLoading(true);
    axios.get(`${FETCH_SOIL_SAMPLING_DATA}/${projectId}`).then((response) => {
      setLoading(false);
      setRows(() => {
        return response.data.map((row) => {
          return {
            id: row.soilSamplingDataId,
            'File name': row.fileName,
            'Project cycle': row.projectCycle,
            'Uploaded by': row.uploadedBy,
            'Uploaded on': row.uploadedOn,
          };
        });
      });
    });
  }, [soilSamplingDialog]);

  const downloadCSV = async (soilSampleDataId, filename) => {
    await axios
      .get(`${DOWNLOAD_SOIL_SAMPLING_DATA}/${soilSampleDataId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const csvBlob = new Blob([response.data], { type: 'application/csv' });

        const url = window.URL.createObjectURL(csvBlob);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', filename);
        tempLink.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {});
  };
  const columns = [
    {
      field: TAB_CONTENT.fileNameHeading,
      headerName: TAB_CONTENT.fileNameHeading,
      minWidth: 314,
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <DownloadButton
            onClick={() => {
              downloadCSV(
                params.row.id,
                params.row[TAB_CONTENT.fileNameHeading],
              );
            }}>
            <LightTooltip title={params.row[TAB_CONTENT.fileNameHeading]}>
              {params.row[TAB_CONTENT.fileNameHeading]}
            </LightTooltip>
          </DownloadButton>
        );
      },
    },
    {
      field: TAB_CONTENT.projectCycleHeading,
      headerName: TAB_CONTENT.projectCycleHeading,
      minWidth: 257,
      sortable: false,
      flex: 2,
    },
    {
      field: TAB_CONTENT.uploadedByHeading,
      headerName: TAB_CONTENT.uploadedByHeading,
      minWidth: 257,
      sortable: false,
      flex: 2,
    },
    {
      field: TAB_CONTENT.uploadedOnHeading,
      headerName: TAB_CONTENT.uploadedOnHeading,
      minWidth: 168,
      sortable: false,
      flex: 1,
    },
  ];
  return (
    <>
      <UploadSoilSamplingModal
        projectId={projectId}
        dialogStateHandler={setSoilSamplingDialog}
        dialogState={soilSamplingDialog}></UploadSoilSamplingModal>
      {rows.length === 0 ? (
        <EmptySoilSamplingContainerWrapper>
          <EmptySoilSamplingContainer>
            <EmptySoilSamplingIconContainer>
              <SoilSamplingEmptyStateIcon />
            </EmptySoilSamplingIconContainer>
            <EmptySoilTitleWrapper>
              <TextWrapper
                fontWeight="700"
                fontSize="1rem"
                lineHeight="1.362rem"
                marginBottom="0.3125rem"
                color={QUARTZ}>
                {TAB_CONTENT.emptyStateHeading}
              </TextWrapper>
              <TextWrapper
                fontWeight="400"
                fontSize="0.875rem"
                lineHeight="1.192rem"
                color={DIM_GRAY}>
                {TAB_CONTENT.emptyStateSubtitle}
              </TextWrapper>
            </EmptySoilTitleWrapper>
            <div>
              <Button
                onClick={() => toggleAddSoilSamplingDialog()}
                variant="contained"
                size="small"
                disabled={hasAnyRequiredAccess(userRoles, [
                  PARTICIPANT,
                  PARTICIPANT_CONTRIBUTOR,
                  ORIGINATION_VIEWER,
                ])}
                sx={{
                  ...EmptySoilSamplingBtnSx,
                }}>
                <p
                  style={{
                    ...EmptySoilSamplingBtnTextSx,
                  }}>
                  {TAB_CONTENT.btnText2}
                </p>
              </Button>
            </div>
          </EmptySoilSamplingContainer>
        </EmptySoilSamplingContainerWrapper>
      ) : (
        <DataGridWrapperForSoil>
          <DataGrid
            sx={{ ...dataGridSxForSoil }}
            hideFooter
            getRowId={(row) => row.id || uniqueId()}
            rowHeight={48}
            headerHeight={40}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableVirtualization
            disableSelectionOnClick
            loading={loading || rows.length === 0}
            columns={columns}
            rows={rows}></DataGrid>
        </DataGridWrapperForSoil>
      )}
    </>
  );
};
ActivitiesTab.propTypes = {
  projectId: PropTypes.number,
  soilSamplingDialog: PropTypes.bool,
  setSoilSamplingDialog: PropTypes.func,
  toggleAddSoilSamplingDialog: PropTypes.func,
};
export default ActivitiesTab;
