import styled from 'styled-components';
import { DARK_GREEN, GREEN } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const Container = styled.div`
  display: flex;
  display: -webkit-flex;
  gap: ${({ gap }) => gap || '0.625rem'};
  align-items: ${({ alignItem }) => alignItem};
  flex-direction: ${({ flexDirection }) => flexDirection};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ radius }) => radius};
  justify-content: ${({ content }) => content};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: hidden;
  '& .scrollable-element': {
    scrollbar-width: none;
  }
  &:hover {
    overflow-y: ${({ overFlow }) => overFlow};
  }
`;
export const Text = styled.text`
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const FormControlLabelStyle = {
  color: '#333333',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '25rem',
  minWidth: '12rem',
};
export const FilterAltIconStyle = (isFilterApplied, isPartOfOrigination) => {
  return {
    color: isFilterApplied
      ? checkTernaryCondition(isPartOfOrigination, DARK_GREEN, '#008239')
      : 'white',
    cursor: 'pointer',
    stroke: isFilterApplied
      ? checkTernaryCondition(isPartOfOrigination, DARK_GREEN, '#008239')
      : '#63666A',
    height: '1rem',
    width: '1rem',
    strokeWidth: 3,
    '&:hover': {
      stroke: checkTernaryCondition(isPartOfOrigination, DARK_GREEN, '#008239'),
    },
  };
};

export const PopoverStyle = {
  border: '2px solid black',
};
export const checkBoxStyle = (isPartOfOrigination) => {
  return {
    '&.Mui-checked': {
      color: checkTernaryCondition(isPartOfOrigination, DARK_GREEN, GREEN),
    },
  };
};
