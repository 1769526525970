import React from 'react';
import {
  Card,
  CardContent,
  CardTitle,
  DetailContainer,
  DetailContent,
  DetailHeader,
  DetailsSection,
  TextAndTooltipWrapper,
  TextWrapper,
} from './StakeholderParticipantIncentiveCard.style';
import { ACTUAL, PROJECTED, TOOLTIP_PLACEMENTS } from 'utils/config';
import {
  BLACK,
  COOL_GRAY,
  DARK_CHARCOAL,
  NEW_DARK_GREEN,
  QUILL_GRAY,
} from 'theme/GlobalColors';
import { CARD_DETAILS } from './StakeholderParticipantIncentiveCard.content';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import PropTypes from 'prop-types';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import { CARD_CONTENT } from 'pages/Origination/ParticipantIncentivesCard/ParticipantIncentivesCard.content';
import { tooltipSx } from 'pages/Origination/ParticipantIncentivesCard/ParticipantIncettivesCard.style';

/**
 * @description This component returns the value with label
 * @param {Number | undefined} value
 * @param {String} type
 * @returns {Element} - Returns the value with label based on type
 */
const IncentiveValueWithLabel = ({ value, type = ACTUAL }) => {
  const color = checkTernaryCondition(
    type === ACTUAL,
    NEW_DARK_GREEN,
    COOL_GRAY,
  );

  const labelColor = checkTernaryCondition(
    type === ACTUAL,
    NEW_DARK_GREEN,
    BLACK,
  );

  return (
    <DetailContent>
      <TextWrapper
        color={color}
        fontSize="1.25rem"
        fontWeight="600">{`$${checkTernaryCondition(
        value === 0 || isEmpty(value),
        CARD_DETAILS.emptyValue,
        value?.toFixed(2),
      )}`}</TextWrapper>
      <TextAndTooltipWrapper>
        <TextWrapper color={labelColor} fontSize="0.75rem" fontWeight="400">
          {type}
        </TextWrapper>
        <InfoTooltip
          content={checkTernaryCondition(
            type === ACTUAL,
            CARD_CONTENT.actualIncentivePayments,
            CARD_CONTENT.projectedIncentivePayments,
          )}
          placement={TOOLTIP_PLACEMENTS.TOP_START}
          customSx={tooltipSx}
        />
      </TextAndTooltipWrapper>
    </DetailContent>
  );
};

const StakeholderParticipantIncentiveCard = ({
  data: {
    averageProjectedIncentive,
    totalProjectedIncentive,
    averageActualIncentive,
    totalActualIncentive,
  },
}) => {
  return (
    <Card>
      <CardTitle color={DARK_CHARCOAL}>{CARD_DETAILS.cardHeading}</CardTitle>
      <CardContent>
        <DetailsSection
          padding="0rem 1.5rem 0rem 0rem"
          borderRight={`1px solid ${QUILL_GRAY}`}>
          <DetailHeader>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.875rem"
              fontWeight="600">
              {`${CARD_DETAILS.average}`}
            </TextWrapper>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.875rem"
              fontWeight="400">
              {CARD_DETAILS.perAcre}
            </TextWrapper>
          </DetailHeader>
          <DetailContainer>
            <IncentiveValueWithLabel
              type={PROJECTED}
              value={averageProjectedIncentive}
            />
            <IncentiveValueWithLabel
              type={ACTUAL}
              value={averageActualIncentive}
            />
          </DetailContainer>
        </DetailsSection>
        <DetailsSection padding="0rem 1rem 0rem 1.5rem">
          <DetailHeader>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.875rem"
              fontWeight="600">
              {CARD_DETAILS.total}
            </TextWrapper>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.875rem"
              fontWeight="400">
              ($)
            </TextWrapper>
          </DetailHeader>
          <DetailContainer>
            <IncentiveValueWithLabel
              type={PROJECTED}
              value={totalProjectedIncentive}
            />
            <IncentiveValueWithLabel
              type={ACTUAL}
              value={totalActualIncentive}
            />
          </DetailContainer>
        </DetailsSection>
      </CardContent>
    </Card>
  );
};

StakeholderParticipantIncentiveCard.propTypes = {
  data: PropTypes.object,
};

IncentiveValueWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.oneOf([ACTUAL, PROJECTED]),
};
export default StakeholderParticipantIncentiveCard;
